import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NextPageRoute, NextPageService } from '@cvx/nextpage';
import { CalGuardService } from '@cvx/cal-angular';
import { HomeComponent } from './pages/home/home.component';
import { ProposalTypesComponent } from './pages/proposal-types/proposal-types.component';
import { AcquisitionProposalComponent } from './pages/acquisition-proposal/acquisition-proposal.component';
import { CanDeativateProposalTypeGuardService } from './core/services/CanDeactivateAcquisitionGuardService';
import { DissolutionProposalComponent } from './pages/dissolution-proposal/dissolution-proposal.component';
import { ViewRequestsComponent } from './pages/view-requests/view-requests.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { MergerProposalComponent } from './pages/merger-proposal/merger-proposal.component';
import { SalesProposalComponent } from './pages/sales-proposal/sales-proposal.component';
import { FormationProposalComponent } from './pages/formation-proposal/formation-proposal.component';
import { OwnershipRestructuringProposalComponent } from './pages/ownership-restructuring-proposal/ownership-restructuring-proposal.component';
import { QualificationWithdrawalProposalComponent } from './pages/qualification-withdrawal-proposal/qualification-withdrawal-proposal.component';
import { OthersProposalComponent } from './pages/others-proposal/others-proposal.component';



export const routes: NextPageRoute[] = [
  {
    path: 'home',
    title: 'home',
    component: HomeComponent,
    canActivate: [CalGuardService]
  },
  {
    path: 'about_policy_30',
    title: 'about policy 30',
    component: HomeComponent,
    canActivate: [CalGuardService]
  },
  {
    path: 'dashboards',
    title: 'dashboards',
    component: HomeComponent,
    canActivate: [CalGuardService]
  },
  {
    path: 'support',
    title: 'support',
    component: HomeComponent,
    canActivate: [CalGuardService]
  },
  {
    path: 'setting',
    title: 'settings',
    children: [
      {
        path: 'manage_user_account',
        title: 'manage user account',
        component: UserManagementComponent,
        canActivate: [CalGuardService]
      }
    ]
  },
  { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [CalGuardService] },
  { path: 'proposal types', component: ProposalTypesComponent, canActivate: [CalGuardService], canDeactivate: [CanDeativateProposalTypeGuardService] },
  { path: 'acquisition/:proposalId', component: AcquisitionProposalComponent, canActivate: [CalGuardService] },
  { path: 'dissolution/:proposalId', component: DissolutionProposalComponent, canActivate: [CalGuardService] },
  { path: 'merger/:proposalId', component: MergerProposalComponent, canActivate: [CalGuardService] },
  { path: 'acquisition', component: AcquisitionProposalComponent, canActivate: [CalGuardService] },
  { path: 'merger', component: MergerProposalComponent, canActivate: [CalGuardService] },
  { path: 'dissolution', component: DissolutionProposalComponent, canActivate: [CalGuardService] },
  { path: 'view proposals', component: ViewRequestsComponent, canActivate: [CalGuardService] },
  { path: 'sales', component: SalesProposalComponent, canActivate: [CalGuardService] },
  { path: 'sales/:proposalId', component: SalesProposalComponent, canActivate: [CalGuardService] },
  { path: 'formation/:proposalId', component: FormationProposalComponent, canActivate: [CalGuardService] },
  { path: 'formation', component: FormationProposalComponent, canActivate: [CalGuardService] },
  { path: 'ownershiprestructuring/:proposalId', component: OwnershipRestructuringProposalComponent, canActivate: [CalGuardService] },
  { path: 'ownershiprestructuring', component: OwnershipRestructuringProposalComponent, canActivate: [CalGuardService] },
  { path: 'qualificationwithdrawal', component: QualificationWithdrawalProposalComponent, canActivate: [CalGuardService] },
  { path: 'qualificationwithdrawal/:proposalId', component: QualificationWithdrawalProposalComponent, canActivate: [CalGuardService] },
  { path: 'others', component: OthersProposalComponent, canActivate: [CalGuardService] },
  { path: 'others/:proposalId', component: OthersProposalComponent, canActivate: [CalGuardService] },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private NP: NextPageService) {
    this.NP.options.navigationRoutes = routes;
  }
}
