import { Component } from '@angular/core';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { P30APIService } from 'src/app/core/services/api';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.less']
})
export class UserManagementComponent {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  currentUserProfile!: ICvxClaimsPrincipal;
  uData: any[] = [];
  usersData: any[] = [];
  editUserData: any;
  userRoles: any[] =[];
  isSubmit:boolean = false;
  enableEditUser:boolean = false;
  isAdmin:boolean=false;
  constructor(private graph: MsGraphService, private apiService: P30APIService,private authService: CalAngularService) { }

  ngOnInit(){
    const queryParams = {
      page: '1',
      size: '20'
    };
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    this.apiService.getUser(this.currentUserProfile.objectId).subscribe((user: any) => {
      if (user.body) {
        if(user.body.userRoleId==8){
           this.isAdmin=true;
        }
    }});

    this.apiService.getAllUsers(queryParams).subscribe( {
      next: (res) =>{
        this.uData = [...res.body];
        this.usersData = [...res.body]
        this.uData.forEach(val => {
          //  this.usersData.push(val)
        });
      },
      error: (error) =>{
        console.log("error receiving users", error)
      },
      complete: () =>{

      }

    });

  }
  editUserRole(data: any){
    // this.apiService.get
    this.editUserData=data;
    this.enableEditUser=true;
  }

  userRoleChange(value: string): void {
    this.isSubmit = true;
  }

  receiveEnableEditUser(event:boolean){
    this.enableEditUser = event;
  }

}
