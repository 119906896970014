import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DataServiceComments {
  loggedUserDetails = new Subject<{}>();
  commentData$ = new Subject<string>();
  isCommentSaved$ = new BehaviorSubject<boolean>(false);
  commentData!: string;
  contactId!: number;
  coordinatorDetails!: { fullName: string; mail: string; objectId: string };

  constructor(
    private session: SessionStorageService,
    private apiService: P30APIService,
    private message: NzMessageService
  ) {
    this.getSubscribtionValues();
  }

  getUserDetail(value: {}) {
    this.loggedUserDetails.next(value);
  }

  getInputComment(value: string) {
    this.commentData$.next(value);
  }

  commentSavedSuccessfully(state: boolean) {
    this.isCommentSaved$.next(state);
  }

  submitComments(loader: boolean) {
    this.createComment('Note','Draft').subscribe({
      next: (event) => {
        if (event.body) {
          this.contactId = event.body['commentId'];
          this.getInputComment('');
          this.commentSavedSuccessfully(true);
          this.session.removeItem('commentsValue')
        }
      },
      error: (err) => {
        this.commentSavedSuccessfully(false);
        console.log('unable to submit the comment', err);
      },
      complete: () => {
        this.message.success('Successfully submitted comment');
        this.commentSavedSuccessfully(false);
        this.getInputComment('');
      },
    });
  }


  getCommnets(proposalId: string): Observable<HttpResponse<any>> {
    return this.apiService.getComments(proposalId).pipe(
      map((comments: any) => {
        if (comments) return comments;
      })
    );
  }



  createComment(typeOfComment:string, status:string) {
    const payload = {
      requestId: this.session.getItem(REQUEST_ID),
      coordinator: this.coordinatorDetails?.fullName,
      coordinatorEmail: this.coordinatorDetails?.mail,
      coordinatorId: this.coordinatorDetails?.objectId,
      comment: this.commentData,
      typeOfComment: typeOfComment,
      createdDate: new Date().toLocaleString("en-US"),
      status: status
    };
    return this.apiService
      .createComments(this.session.getItem(PROPOSAL_ID), payload)
      .pipe(
        map((comment) => {
          return comment;
        })
      );
  }

  updateComment(contactId:number,typeOfComment:string, status:string) {
    const payload = {
      requestId: this.session.getItem(REQUEST_ID),
      coordinator: this.coordinatorDetails?.fullName,
      coordinatorEmail: this.coordinatorDetails?.mail,
      coordinatorId: this.coordinatorDetails?.objectId,
      comment: this.commentData,
      typeOfComment: typeOfComment,
      createdDate: new Date().toLocaleString("en-US"),
      status: status
    };
    return this.apiService
      .updateComments(this.session.getItem(PROPOSAL_ID),contactId, payload)
      .pipe(
        map((comment) => {
          return comment;
        })
      );
  }

  getSubscribtionValues() {
    this.commentData$.subscribe((val: string) => {
      this.commentData = val;
    });
    this.loggedUserDetails.subscribe((user: any) => {
      this.coordinatorDetails = user;
    });
  }

  // to get status of Request created
  getRequestStatus() {
    let state = this.session?.getItem('requestStatus');
    if(state)
      return state.name.toLocaleLowerCase();
  }

  retriveCommentsValue(state: string, value: string) {
    let commentsFieldValue: string = '';
    switch(state) {
      case 'get': {
        commentsFieldValue = this.session.getItem('commentsValue');
        return commentsFieldValue;
      }
      case 'set': {
        commentsFieldValue = <any>this.session.setItem('commentsValue', value);
        return commentsFieldValue;
      }
      case 'delete': {
        commentsFieldValue = <any>this.session.removeItem('commentsValue');
        return commentsFieldValue;
      }
    }
    return;
  }
}
