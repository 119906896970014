<!-- Header -->
<np-header>
  <!-- Search -->
  <!-- Global (top menu) Navigation -->
  <np-menu [location]="NavigationLocation.Global"></np-menu>
</np-header>
<!-- Site-Specific Navigation -->
<np-menu [location]="NavigationLocation.SiteSpecific"></np-menu>
<!-- Main Content -->
<app-page-loader *ngIf="!isInitialized"></app-page-loader>
@if(serverError){
<app-server-error></app-server-error>
}
@if(!serverError && isInitialized){
  <router-outlet></router-outlet>
}
<!-- Footer -->
<np-footer [contentContactName]="'Banks, Scott'" [contentContactUrl]="'mailto:sbanks@chevron.com'"
  [technicalContactName]="'Antonio, Louie'" [technicalContactUrl]="'mailto:luisantonio@chevron.com'">
</np-footer>