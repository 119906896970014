import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { API_FAILING_ERROR_MESSAGE, PROPOSAL_ID, Qualification, Withdrawal } from 'src/app/constants/constants';
import { LegalEntity } from 'src/app/core/interfaces/LegalEntities';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceEntitySelection } from 'src/app/core/services/data_service_entity_selection';
import { DataServiceQualificationWithdrawalProposalOverview } from 'src/app/core/services/data_service_qualification_proposal_overview';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';

@Component({
  selector: 'app-qualification-withdrawal-entity-selection',
  templateUrl: './qualification-withdrawal-entity-selection.component.html',
  styleUrl: './qualification-withdrawal-entity-selection.component.less',
  animations: [formControlElement, formLabelElement],
})
export class QualificationWithdrawalEntitySelectionComponent {
  @Output() isLoader = new EventEmitter<boolean>;
  @Input() entitySelectionTabClass = '';
  @Input() legalEntityQualificationWithdrawalForm!: UntypedFormGroup;
  qualification = false;
  withdrawal: boolean = false;
  apiErrorMSG: string = API_FAILING_ERROR_MESSAGE;
  selectedLegalentity!: string;
  nzLoading: boolean = false;
  isEntityDetailsVisible: boolean = false;
  isCommentsVisible: boolean = false;
  listofEntities: LegalEntity[] = [];
  selectedLegalEntityDetails: LegalEntity[] = [];
  nzSearchValue: string = '';
  ownershipFormModel = {
    entityName: '',
    entityComments: '',
    summaryLegalActivities: '',
  };

  constructor(
    private apiService: P30APIService,
    private dataservice: DataService,
    private session: SessionStorageService,
    private message: NzMessageService,
    private entitySelectionDataService: DataServiceEntitySelection,
    private qualificationWithdrawalService:DataServiceQualificationWithdrawalProposalOverview
  ) {}

  ngOnDestroy() {
    console.log("ngOnDestroy ownership-form => ", this.legalEntityQualificationWithdrawalForm);
    this.qualificationWithdrawalService.zxzz = this.legalEntityQualificationWithdrawalForm;
    this.qualificationWithdrawalService.qualificationLegalEntitySelectionForm = this.legalEntityQualificationWithdrawalForm;
  }

  ngOnInit() {
    this.getLegalEntities();
    this.getOwnershipRestructuringSummary();
    this.listofEntities = [...this.qualificationWithdrawalService.commonEntityList];
  
    // Retrieve all entity indicator questions
    let proposalID = this.session.getItem(PROPOSAL_ID);
    if (proposalID) {
      this.qualificationWithdrawalService.getEntityIndicatorQuestions().subscribe({
        next: (event) => {
          let entityQuestions = [];
          if (event.body) {
            entityQuestions = [...event.body];
            let qualification; 
            let withdrawal;
            if (entityQuestions[0].entityType==Qualification) {
              qualification = true;
            } else {
              qualification = false;
            }
            if (entityQuestions[0].entityType==Withdrawal) {
              withdrawal = true;
            } else {
              withdrawal = false;
            }
            this.legalEntityQualificationWithdrawalForm.patchValue({
              qualification: qualification,
              withdrawal: withdrawal
            });

            if (qualification || withdrawal) {
              this.legalEntityQualificationWithdrawalForm.patchValue({
                entityIndicatorQuestionId: entityQuestions[0].entityQuestionsId,
                jurisdictionDescription: entityQuestions[0].jurisdiction,
              });
             
            }
          }
        }
      })
    }

  }


  getOwnershipRestructuringSummary() {
    this.isLoader.emit(true);
    this.qualificationWithdrawalService.getOwnershipRestructuringProposalOverview().subscribe((res:any) => {
      this.isLoader.emit(false);
      if(res['body']) {
        const form = this.legalEntityQualificationWithdrawalForm.controls['summaryLegalActivities']
        form.setValue(res['body'][0]['summaryofLegalEntitiesActivities']);
      }
    })
  }

  getLegalEntities(): Promise<boolean> {
    return new Promise<boolean>((resolved) => {
      if (this.session.getItem(PROPOSAL_ID)) {
        this.isLoader.emit(true);
        this.entitySelectionDataService.getLegalEntities().subscribe({
          next: (event) => {
            if (event['body']) {
              const form = this.legalEntityQualificationWithdrawalForm;
              this.selectedLegalentity=event['body'][0]['companyName'];
              if(event['body'][0]['companyName'] === 'NA') {
                form.controls['entityName'].setValue(event['body'][0]['companyName']);
                form.controls['entityComments'].setValue(event['body'][0]['legalEntityComments']);
              } else {
                form.controls['entityName'].setValue(event['body'][0]['companyName']);
                this.selectedLegalEntityDetails = [];
                this.selectedLegalEntityDetails.push(event['body'][0]);
                if (this.listofEntities.find((e: LegalEntity) => e.ccn == event['body'][0]['ccn']) === undefined) {
                  this.listofEntities.push(event['body'][0]);
                }
              }
            }
          },
          error: (err) => {
            this.message.error(this.apiErrorMSG);
            this.isLoader.emit(false);
            resolved(false);
          },
          complete: () => {
            this.isLoader.emit(false);
            resolved(true);
          }
        });
      }
    });
  }

  onEntitySearchEvent(event: any) {
    if (event) {
      this.nzSearchValue = event;
    }
  }

  onSearchEntity() {
    this.nzLoading = true;
    if (this.nzSearchValue) {
      this.apiService.getCompanies(this.nzSearchValue).subscribe({
        next: (event) => {
          let arrResult = [];
          arrResult = [...event.body.companies];
          arrResult.forEach(element => {
            let legalEntity: LegalEntity = {
              ccn: element.companyCodeNumber,
              companyName: element.companyName,
              jurisdiction: element.countryName
            }
            this.listofEntities.push(legalEntity);
          });
        },
        error: (err) => {
          this.message.error(this.apiErrorMSG);
          this.nzLoading = false;
        },
        complete: () => {
          this.nzLoading = false;
        }
      });
    }
  }

  async onEntityChange(event: any) {
    if (event == undefined) {
      return
    } else if(event.length > 0) {
      this.selectedLegalentity = event;
      const entityName = this.legalEntityQualificationWithdrawalForm.controls['entityName'];
      const entityCommentsValidation = this.legalEntityQualificationWithdrawalForm.controls['entityComments'];
      const summaryControl = this.legalEntityQualificationWithdrawalForm.controls["summaryLegalActivities"]
      if(event === '') {
        entityName.setValidators([Validators.required]);
        entityName.updateValueAndValidity();
      }
      else if (event === 'NA') {
        entityCommentsValidation.setValidators([Validators.required]);
        entityCommentsValidation.updateValueAndValidity();
        this.isCommentsVisible = true;
        this.isEntityDetailsVisible = false;
      } else {
        this.isLoader.emit(true);
        this.apiService.getCompanyDetails(this.listofEntities.find(data=>data.companyName===event)?.ccn).subscribe({
          next: (output) =>{
            if(output.body) {
              const modifiedLegalEntities = this.listofEntities.map(obj => {
                if (obj.ccn === output.body.companyCodeNumber) {
                    return { ...obj, ownershipDetail: output.body.ownership };
                }
                return obj;
              });
              this.listofEntities = [...modifiedLegalEntities];
            }
          },
          error: (err)=>{
            this.message.error(this.apiErrorMSG);
            this.isLoader.emit(false);
          },
          complete: () =>{
            this.addField(event, this.listofEntities);
            this.isLoader.emit(false);
          }
        });
        entityCommentsValidation.removeValidators([Validators.required]);
        entityCommentsValidation.setValue(null);
        entityCommentsValidation.updateValueAndValidity();
        this.isCommentsVisible = false;
        this.isEntityDetailsVisible = true;
      }
      if (summaryControl.value == '' || summaryControl.value == undefined || summaryControl.value == null) {
        summaryControl.setValidators([Validators.required]);
        summaryControl.updateValueAndValidity();
      }
    }
  }


  deleteLegalEntity(legalEntityId:any){
    this.isLoader.emit(true);
      this.apiService.deleteLegalEntity(this.session.getItem(PROPOSAL_ID), legalEntityId).subscribe({
        next: (out) =>{
        },
        error: (err) =>{
          this.message.error(this.apiErrorMSG);
          this.isLoader.emit(false);
        },
        complete: () => {
          this.getLegalEntities();
          this.isLoader.emit(false);
        }
      })
  }

  addField(value:string, array: LegalEntity[]) {
    const legalEntityForm: any = {
      legalEntityName: value,
      ccn: array.filter(data => data.companyName === value)[0]?.ccn,
      companyName: value,
      jurisdiction: array.filter(data => data.companyName === value)[0].jurisdiction !== undefined ? array.filter(data => data.companyName === value)[0].jurisdiction : '',
      ownershipDetail: array.filter(data => data.companyName === value)[0].ownershipDetail !== undefined ? array.filter(data => data.companyName === value)[0].ownershipDetail : '',
    }
    this.selectedLegalEntityDetails = [];
    this.selectedLegalEntityDetails.push(legalEntityForm);
    this.qualificationWithdrawalService.legalEntitySelected = [];
    this.qualificationWithdrawalService.legalEntitySelected.push(legalEntityForm)

  }

  getErrorTipForSellerLegalEntity(controlName: string) {
    const form = this.legalEntityQualificationWithdrawalForm;
    const control = form.controls[controlName];
    switch (control) {
      case <any>control.hasError('required'): {
        return 'Please select the legal entity!';
      }
      default: {
        return '';
      }
    }
  }

  getLegalActivitiesValue(event: any) {
    this.session.setItem("ownershipLegalActivityFormValue", event?.target?.value);
  }

  onQualificationChange(event: boolean) {
    if (event) {
      this.withdrawal = false;
    }
    else {
      this.legalEntityQualificationWithdrawalForm.patchValue({
        jurisdictionDescription:"" ,
      }); 
    }
  }

  onWithdrawalChange(event: boolean) {
    if (event) {
      this.qualification = false;
    }
    else {
      this.legalEntityQualificationWithdrawalForm.patchValue({
        jurisdictionDescription: "",
      });
    }
  }

  // actionOnEntityQuestionsQualification(event: any) {
  //   let qualification;
  //   if (event) {
  //     qualification = JSON.parse(event.toLowerCase());
  //     return qualification;
  //   } else {
  //     qualification = false
  //     return qualification;
  //   }
  // }

  // actionOnEntityQuestionsWithdrawal(event: any) {
  //   let withdrawal;
  //   if (event) {
  //     withdrawal = JSON.parse(event.toLowerCase());
  //     return withdrawal;
  //   } else {
  //     withdrawal = false
  //     return withdrawal;
  //   }
  // }
}
