import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { PROPOSAL_ID } from 'src/app/constants/constants';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-sales-proposal-details',
  templateUrl: './sales-proposal-details.component.html',
  styleUrl: './sales-proposal-details.component.less',
  animations: [formControlElement, formLabelElement],
  providers: [DatePipe],
})
export class SalesProposalDetailsComponent {
  entitySelection: string = 'entity selection';
  attachments: string = 'attachments';
  overview: string = 'overview';
  proposalId!: string;

  @Output() isLoader = new EventEmitter<boolean>();
  @Output() selectedTabIndexEmitValue=new EventEmitter<number>();
  @Input() entitySelectionTabClass = 'tab-default';
  @Input() summaryTabClass = 'tab-default';
  @Input() attachmentTabClass = 'tab-default';
  @Input() selectedTabIndexValue!:number;

  // @Input() salesSellerEntitySelectionForm!: UntypedFormGroup;
  // @Input() salesBuyerEntitySelectionForm!: UntypedFormGroup;
  @Input() legalEntitySalesForm!: FormGroup;
  @Input() proposalOverviewForm!: UntypedFormGroup;
   
  constructor(
    private session: SessionStorageService,
    private message: NzMessageService
  ) {}

  ngOnInit() {
    this.isLoader.emit(false);
    //being used by html file for attachments?
    if (this.session.getItem(PROPOSAL_ID)) {
      this.proposalId = this.session.getItem(PROPOSAL_ID);
    }
  }

  getCurrentTab(event: any) {
    this.selectedTabIndexEmitValue.emit(event);
    //console.log('getCurrentTab => ', event);
  }

  emitIsLoader(event: any) {
    this.isLoader.emit(event);
  }
}
