import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ACQUISITION_PROPOSAL, DISSOLUTION_PROPOSAL, ACQUISITION_PROPOSAL_URL_PATH, DISSOLUTION_PROPOSAL_URL_PATH, PROPOSAL_ID, REQUEST_ID, SALES_PROPOSAL_URL_PATH, SALES_PROPOSAL,MERGER_PROPOSAL, MERGER_PROPOSAL_URL_PATH , FORMATION_PROPOSAL, FORMATION_PROPOSAL_URL_PATH, OWNERSHIP_RESTRUCTURING_PROPOSAL_URL_PATH, OWNERSHIP_RESTRUCTURING_PROPOSAL, QUALIFICATION_WITHDRAWAL_PROPOSAL, QUALIFICATION_WITHDRAWAL_PROPOSAL_URL_PATH, OTHERS_PROPOSAL_URL_PATH, OTHERS_PROPOSAL } from 'src/app/constants/constants';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-proposal-types',
  templateUrl: './proposal-types.component.html',
  styleUrls: ['./proposal-types.component.less']
})
export class ProposalTypesComponent implements OnInit {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  currentUserProfile!: ICvxClaimsPrincipal;
  cursor!: string;
  proposalId: string = "";
  requestId!: number;
  userId!: number;
  displayStatus: boolean = false;
  proposalType: string = "";
  isLoader: boolean = false;

  constructor(private router: Router, private apiService: P30APIService, private authService: CalAngularService,
    private session: SessionStorageService, private dataservice: DataService,
    private modal: NzModalService) { }


  navigateToDissolutionProposal() {
    this.navigateToPageComponent("dissolution");
    this.proposalType = DISSOLUTION_PROPOSAL;
    // setting proposal type
    this.dataservice.proposalType = this.proposalType;
    if(this.proposalId){
      this.session.removeItem(REQUEST_ID);
      this.session.setItem(PROPOSAL_ID,this.proposalId);
      this.router.navigate([DISSOLUTION_PROPOSAL_URL_PATH,this.proposalId]);
    }else{
      this.navigateToPageComponent(DISSOLUTION_PROPOSAL_URL_PATH);
      this.clearTemptData();
    }
  }
  navigateToQualificationWithdrawalProposal() {
    this.navigateToPageComponent("qualificationwithdrawal");
    this.proposalType = QUALIFICATION_WITHDRAWAL_PROPOSAL;
    // setting proposal type
    this.dataservice.proposalType = this.proposalType;
    if(this.proposalId){
      this.session.removeItem(REQUEST_ID);
      this.session.setItem(PROPOSAL_ID,this.proposalId);
      this.router.navigate([QUALIFICATION_WITHDRAWAL_PROPOSAL_URL_PATH,this.proposalId]);
    }else{
      this.navigateToPageComponent(QUALIFICATION_WITHDRAWAL_PROPOSAL_URL_PATH);
      this.clearTemptData();
    }
  }

  navigateToMergerProposal() {
    this.proposalType = MERGER_PROPOSAL;
    // setting proposal type
    this.dataservice.proposalType = this.proposalType;
    if (this.proposalId) {
      this.session.removeItem(REQUEST_ID);
      this.session.setItem(PROPOSAL_ID, this.proposalId);
      this.router.navigate([MERGER_PROPOSAL_URL_PATH, this.proposalId]);
    } else {
      this.navigateToPageComponent(MERGER_PROPOSAL_URL_PATH);
      this.clearTemptData();
    }
  }

  navigateToAcquisitionProposal() {
    this.navigateToPageComponent("acquisition");
    this.proposalType = ACQUISITION_PROPOSAL;
    // setting proposal type
    this.dataservice.proposalType = this.proposalType;
    if (this.proposalId) {
      this.session.removeItem(REQUEST_ID);
      this.session.setItem(PROPOSAL_ID, this.proposalId);
      this.router.navigate([DISSOLUTION_PROPOSAL_URL_PATH, this.proposalId]);
    } else {
      this.navigateToPageComponent(DISSOLUTION_PROPOSAL_URL_PATH);
      this.clearTemptData();
    }
  }

  navigateToFormationProposal() {
    this.navigateToPageComponent("formation");
    this.proposalType = FORMATION_PROPOSAL;
    // setting proposal type
    this.dataservice.proposalType = this.proposalType;
    if(this.proposalId){
      this.session.removeItem(REQUEST_ID);
      this.session.setItem(PROPOSAL_ID,this.proposalId);
      this.router.navigate([FORMATION_PROPOSAL_URL_PATH,this.proposalId]);
    }else{
      this.navigateToPageComponent(FORMATION_PROPOSAL_URL_PATH);
      this.clearTemptData();
    }
  }

  navigateToOwnerShipProposal() {
    this.navigateToPageComponent("ownershiprestructuring");
    this.proposalType = OWNERSHIP_RESTRUCTURING_PROPOSAL;
    // setting proposal type
    this.dataservice.proposalType = this.proposalType;
    if(this.proposalId){
      this.session.removeItem(REQUEST_ID);
      this.session.setItem(PROPOSAL_ID,this.proposalId);
      this.router.navigate([OWNERSHIP_RESTRUCTURING_PROPOSAL_URL_PATH,this.proposalId]);
    }else{
      this.navigateToPageComponent(OWNERSHIP_RESTRUCTURING_PROPOSAL_URL_PATH);
      this.clearTemptData();
    }
  }
  
  ngOnInit() {
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    if (this.session.getItem(PROPOSAL_ID)) {
      this.displayStatus = true;
      this.proposalId = this.session.getItem(PROPOSAL_ID);
      this.proposalType=this.dataservice.proposalType
    }
  }

  navigateToPageComponent(data: string) {
    this.router.navigate([data]);
  }

  getProposalIdAndRequestId() {
    this.apiService.getUser(this.currentUserProfile.objectId).subscribe({
      next: (user) => {
        this.userId = user.body.userId;
        this.apiService.getProposalByUserIdAndStatusId(this.userId, 1, this.cursor).subscribe({
          next: (proposal) => {
            if (proposal.body) {
              this.proposalId = proposal.body.proposals[0].proposalId;
              this.requestId = proposal.body.proposals[0].requestId;
              this.proposalType = proposal.body.proposals[0].proposalType;
              this.displayStatus = true;
              this.dataservice.proposalType = this.proposalType;
              this.session.setItem(PROPOSAL_ID, this.proposalId);
              this.session.setItem(REQUEST_ID, this.requestId);
            }
          },
          error: (err) => {
            console.log("Unable to get proposal details", err)
          },
          complete: () => {

          }
        });
      },
      error: (err) => {
        console.log("Unable to get user details", err);
      },
      complete: () => {

      }
    });
  }

  //  The following code will call when user navigates to other screen when the form is in-progress by the user
  async canExitComponent(): Promise<boolean> {
    if (this.session.getItem(REQUEST_ID)) {
      const modalRef = new Promise<boolean>((resolved) => {
        this.modal.confirm({
          nzTitle: 'Do you want to discard the current changes?',
          nzContent: '',
          nzOkText: 'Yes',
          nzClosable: false,
          nzCentered: true,
          nzOnOk: () => {
            resolved(true);
            this.session.removeItem(REQUEST_ID);
            this.session.removeItem(PROPOSAL_ID);
          },
          nzOnCancel: () => { resolved(false) }
        });
      })
      return await modalRef;
    } else {
      return true;
    }

  }

  getProposalType() {
    this.apiService.getProposalTypes().subscribe();
  }

  popConfirm() {
    this.isLoader = true;
    this.apiService.deleteProposal(this.proposalId).subscribe({
      next: (proposal) => {
        this.session.clear();
        console.log("session proposal id after proposal delete", this.session.getItem(PROPOSAL_ID));
        this.isLoader = false;
      },
      error: (err) => {
        console.log("Unable to delete proposal", err);
        this.isLoader = false;
      },
      complete: () => {
        this.navigateToDissolutionProposal();
        this.isLoader = false;
      }
    })
  }
  popConfirmCommon(proposalName: string) {
    this.isLoader = true;
    this.apiService.deleteProposal(this.proposalId).subscribe({
      next: (proposal) => {
        this.session.clear();
        console.log("session proposal id after proposal delete", this.session.getItem(PROPOSAL_ID));
        this.isLoader = false;
      },
      error: (err) => {
        console.log("Unable to delete proposal", err);
        this.isLoader = false;
      },
      complete: () => {
        this.navigateToComponent(proposalName);
        this.isLoader = false;
      }
    })
  }
  changeBGColor(): any {
    if (this.proposalId) {
      return { 'background-color': '#0b2d71', 'border-color': '#0b2d71' }
    }
  }

  // navigate to the selected proposal component
  navigateToComponent(proposalName: string) {
    switch (proposalName) {
      case SALES_PROPOSAL_URL_PATH: { // Sales Proposal
        this.proposalType = SALES_PROPOSAL;
        this.dataservice.proposalType = this.proposalType;
        this.session.setItem('proposalSelected', this.proposalType);
        if (this.proposalId) {
          this.proposalNavigation(this.proposalId, SALES_PROPOSAL_URL_PATH);
        } else {
          this.navigateToPageComponent(SALES_PROPOSAL_URL_PATH);
          this.clearTemptData();
        }
        break;
      }
      case ACQUISITION_PROPOSAL_URL_PATH: { // Acquisition Proposal
        this.proposalType = ACQUISITION_PROPOSAL;
        this.dataservice.proposalType = this.proposalType;
        this.session.setItem('proposalSelected', this.proposalType);
        if (this.proposalId) {
          this.proposalNavigation(this.proposalId, ACQUISITION_PROPOSAL_URL_PATH);
        } else {
          this.navigateToPageComponent(ACQUISITION_PROPOSAL_URL_PATH);
          this.clearTemptData();
        }
        break;
      }
      case QUALIFICATION_WITHDRAWAL_PROPOSAL_URL_PATH: { // OWNERSHIP RESTRUCTURING Proposal
        this.proposalType = QUALIFICATION_WITHDRAWAL_PROPOSAL;
        this.dataservice.proposalType = this.proposalType;
        this.session.setItem('proposalSelected', this.proposalType);
        if (this.proposalId) {
          this.proposalNavigation(this.proposalId, QUALIFICATION_WITHDRAWAL_PROPOSAL_URL_PATH);
        } else {
          this.navigateToPageComponent(QUALIFICATION_WITHDRAWAL_PROPOSAL_URL_PATH);
          this.clearTemptData();
        }
        break;
      }
      case OTHERS_PROPOSAL_URL_PATH: { // Others Proposal
        this.proposalType = OTHERS_PROPOSAL;
        this.dataservice.proposalType = this.proposalType;
        this.session.setItem('proposalSelected', this.proposalType);
        if (this.proposalId) {
          this.proposalNavigation(this.proposalId, OTHERS_PROPOSAL_URL_PATH);
        } else {
          this.navigateToPageComponent(OTHERS_PROPOSAL_URL_PATH);
          this.clearTemptData();
        }
        break;
      }
      case FORMATION_PROPOSAL_URL_PATH: { // Others Proposal
        this.proposalType = FORMATION_PROPOSAL;
        this.dataservice.proposalType = this.proposalType;
        this.session.setItem('proposalSelected', this.proposalType);
        if (this.proposalId) {
          this.proposalNavigation(this.proposalId, FORMATION_PROPOSAL_URL_PATH);
        } else {
          this.navigateToPageComponent(FORMATION_PROPOSAL_URL_PATH);
          this.clearTemptData();
        }
        break;
      }
      case OWNERSHIP_RESTRUCTURING_PROPOSAL_URL_PATH: { // Others Proposal
        this.proposalType = OWNERSHIP_RESTRUCTURING_PROPOSAL;
        this.dataservice.proposalType = this.proposalType;
        this.session.setItem('proposalSelected', this.proposalType);
        if (this.proposalId) {
          this.proposalNavigation(this.proposalId, OWNERSHIP_RESTRUCTURING_PROPOSAL_URL_PATH);
        } else {
          this.navigateToPageComponent(OWNERSHIP_RESTRUCTURING_PROPOSAL_URL_PATH);
          this.clearTemptData();
        }
        break;
      }
      case MERGER_PROPOSAL_URL_PATH: { // Others Proposal
        this.proposalType = MERGER_PROPOSAL;
        this.dataservice.proposalType = this.proposalType;
        this.session.setItem('proposalSelected', this.proposalType);
        if (this.proposalId) {
          this.proposalNavigation(this.proposalId, MERGER_PROPOSAL_URL_PATH);
        } else {
          this.navigateToPageComponent(MERGER_PROPOSAL_URL_PATH);
          this.clearTemptData();
        }
        break;
      }
      case DISSOLUTION_PROPOSAL_URL_PATH: { // Others Proposal
        this.proposalType = DISSOLUTION_PROPOSAL;
        this.dataservice.proposalType = this.proposalType;
        this.session.setItem('proposalSelected', this.proposalType);
        if (this.proposalId) {
          this.proposalNavigation(this.proposalId, DISSOLUTION_PROPOSAL_URL_PATH);
        } else {
          this.navigateToPageComponent(DISSOLUTION_PROPOSAL_URL_PATH);
          this.clearTemptData();
        }
        break;
      }
    }
  }

  proposalNavigation(proposalId: any, urlPath: string) {
    this.session.removeItem(REQUEST_ID);
    this.session.setItem(PROPOSAL_ID, proposalId);
    this.router.navigate([urlPath, proposalId]);
  }

  clearTemptData() {
    // Remove request id session variable
    this.dataservice.contactsForm = null;
    this.session.removeItem(REQUEST_ID);
    this.session.removeItem(PROPOSAL_ID);
  }
}
