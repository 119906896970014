import { Injectable} from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpResponse } from '@angular/common/http';

@Injectable ({
    providedIn: 'root'
})
export class DataServiceGeneralDetails {

  constructor(private session:SessionStorageService,
    private fb: UntypedFormBuilder,
    private apiService:P30APIService,
    private message: NzMessageService){ }


    updateProposalStatus(statusId:number):Observable<HttpResponse<any>>{
      const data ={
        "statusId":statusId
      }
      return this.apiService.editProposal(this.session.getItem(PROPOSAL_ID),data).pipe(
        map(generalDetails =>{
          return generalDetails;
        })
      )
    }

    getProposalIdAndRequestId(userId: number, statusId:number,cursor:string):Observable<HttpResponse<any>>{
      return this.apiService.getProposalByUserIdAndStatusId(userId,statusId,cursor).pipe(
        map(proposal =>{
          return proposal;
        })
       );
    }


    reviewAndValidateGeneralDetails(form: UntypedFormGroup): Promise<boolean>{
      const bsuControl = form.controls["businessSegmentUnit"];
      bsuControl.setValidators([Validators.required]);
      bsuControl.markAsDirty();
      bsuControl.updateValueAndValidity();
      const regionControl = form.controls["region"];
      regionControl.setValidators([Validators.required]);
      regionControl.markAsDirty();
      regionControl.updateValueAndValidity();
      const opcoControl = form.controls["opco"];
      opcoControl.setValidators([Validators.required]);
      opcoControl.markAsDirty();
      opcoControl.updateValueAndValidity();
      return Promise.resolve(form.valid);
    }

    getProposal(): Observable<HttpResponse<any>>{
      return this.apiService.getProposal(this.session.getItem(PROPOSAL_ID)).pipe(
        map(generalDetails =>{
          return generalDetails;
        })
      )
    }

    getRegion(id: number): Observable<HttpResponse<any>>{
      return this.apiService.getRegion(id).pipe(
        map(region =>{
          return region;
        })
      )
    }
    getBSU(id: number): Observable<HttpResponse<any>>{
      return this.apiService.getBusinessSegmentUnit(id).pipe(
        map(bsu =>{
          return bsu;
        })
      )
    }

    patchGeneralDetailsDataToFormGroup(form: UntypedFormGroup){
      this.apiService.getProposal(this.session.getItem(PROPOSAL_ID)).subscribe({
        next: (event) => {
          if(event.body){
              this.getRegion(event.body.regionId).subscribe(region=>{
                if(region.status == 200 && region.body){
                  this.getBSU(event.body.businessSegmentId).subscribe(bsu=>{
                    if(bsu.status == 200 && bsu.body){
                      form.patchValue({
                        requestedFor: event.body.requestedFor,
                        requestedBy: event.body.requestedBy,
                        proposalId: event.body.proposalId,
                        urgent: event.body.urgent,
                        requestTitle: event.body.title,
                        businessSegmentUnit: bsu.body.name,
                        region: region.body.name,
                        urgencyExplanation: event.body.urgencyExplanation,
                        opco:event.body.opco
                      });
                    }
                  })
                }
              })
          }
        },
        error: (err) =>{
          console.log("Unable to get proposal overview from the database table ",err);
        },
        complete: () =>{

        }
       });
    }


}
