import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceProposalOverview } from 'src/app/core/services/data_service_proposal_overview';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-dissolution-proposal-overview',
  templateUrl: './dissolution-proposal-overview.component.html',
  styleUrl: './dissolution-proposal-overview.component.less',
  animations: [formControlElement, formLabelElement],
})
export class DissolutionProposalOverviewComponent {
  @Input() dissolutionProposalOverviewForm!: UntypedFormGroup;

  constructor(
    private session: SessionStorageService,
    private dataservice: DataService,
    private proposalOverviewDataService: DataServiceProposalOverview
  ) {
    console.log('DissolutionProposalOverviewComponent',this.dissolutionProposalOverviewForm);
  }
}
