import { Component, Input } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { PROPOSAL_ID, REQUEST_ID, SV_PROPOSAL_STATUS } from 'src/app/constants/constants';
import { Attachment } from 'src/app/core/interfaces/Attachment';
import { UserRole } from 'src/app/core/interfaces/UserRole';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceGeneralDetails } from 'src/app/core/services/data_service_general_details';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DataServiceContacts } from 'src/app/core/services/data_service_contacts';
import { AcquisitionEntityQuestion } from 'src/app/core/interfaces/EntityIndicatorQuestion';
import { AcquisitionProposalOverview } from 'src/app/core/interfaces/ProposalOverview';

@Component({
  selector: 'app-ownership-restructuring-proposal-review',
  templateUrl: './ownership-restructuring-proposal-review.component.html',
  styleUrls: ['./ownership-restructuring-proposal-review.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class OwnershipRestructuringProposalReviewComponent {
  collapseIcon: string = 'double-right';
  generalDetailsForm!: UntypedFormGroup;
  requestedForUser!: string;
  coordinators: string[] = [];
  additionalCcs: string[] = [];
  businessSegmentUnit!: string;
  region!: string;
  @Input() legalEntityOwnershipRestructuringForm!:  UntypedFormGroup;
  @Input() ownershipRestructuringProposalOverviewForm!: UntypedFormGroup;
  @Input() contactsForm!: UntypedFormGroup;
  // showAsset:boolean = false;
  // showOwnsershipInterest:boolean = false;
  showLegalEntityNA: boolean = false;
  selectedLegalEntities: any[] = [];
  files: Attachment[] = [];
  histories: any[] = [];
  userRoles: UserRole[] = [];
  requestStateName: string = '';
  ifcRequired!: boolean;
  isReviewComponent!: boolean;
  entityQuestions: AcquisitionProposalOverview[] = [];

  constructor(private dataservice: DataService, private generalDetailsDataService: DataServiceGeneralDetails,
    private apiService: P30APIService, private session: SessionStorageService,
    private commentService: DataServiceComments, private contactService: DataServiceContacts) {

  }

  ngOnInit() {
    this.getUserRoles();
    //this.getEntityIndicatorQuestions();
    this.getLegalEntities();
    this.getAttachments();
    this.getHistories();
    this.patchContactsFormValues();
    // to get the request state for dynamic UI show/Hide
    this.requestStateName = this.session?.getItem(SV_PROPOSAL_STATUS).name.toLocaleLowerCase();
    this.isReviewComponent = true;
    this.legalEntityOwnershipRestructuringForm.disable();
    this.ownershipRestructuringProposalOverviewForm.disable();
    this.contactsForm.disable();
    if (this.dataservice.aqGeneralDetailsForm) {
      this.generalDetailsDataService.patchGeneralDetailsDataToFormGroup(this.dataservice.aqGeneralDetailsForm);
      this.generalDetailsForm = this.dataservice.aqGeneralDetailsForm;
      this.requestedForUser = this.generalDetailsForm.controls["requestedFor"].value;
      this.businessSegmentUnit = this.generalDetailsForm.controls["businessSegmentUnit"].value;
      this.region = this.generalDetailsForm.controls["region"].value;
      this.generalDetailsForm.disable();
    }
  }

  async patchContactsFormValues() {
    this.apiService.getContacts(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if (event['body']) {
          this.ifcRequired = event.body[0].ifcRequired;
          let coordinatorNames = event.body[0].coordinator.includes(';') ? event.body[0].coordinator.split(';') : [event.body[0].coordinator];
          let additionalCcNames = event.body[0].additionalCcsForNotifications.includes(';') ? event.body[0].additionalCcsForNotifications.split(';') : [event.body[0].additionalCcsForNotifications];
          this.contactsForm.patchValue({
            requestId: this.session.getItem(REQUEST_ID),
            contactId: event.body[0].contactId,
            coordinator: coordinatorNames,
            additionalCcsForNotifications: additionalCcNames,
            ifcRequired: event.body[0].ifcRequired
          })
        }
      },
      error: (err) => {
        console.log("Unable to get general contacts from the database table ", err);
      },
      complete: () => {

      }
    })
  }


  ngOnDestroy() {
    this.dataservice.aqGeneralDetailsForm.enable();
    this.ownershipRestructuringProposalOverviewForm.enable();
    this.legalEntityOwnershipRestructuringForm.enable();
    this.contactsForm.enable();
    this.isReviewComponent = false;
  }


  patchOwnershipRestructuringProposalOverviewDataToFormGroup(form: UntypedFormGroup, loader: boolean) {
    this.apiService.getProposalOverview(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if (event.body) {
          form.patchValue({
            requestId: this.session.getItem(REQUEST_ID),
            summaryId: event.body[0].summaryId,
            overviewOfProposal: event.body[0].overviewOfProposal,
            businessReasonForProposal: event.body[0].businessReasonForProposal,
            targetCompletionDate: '',
            targetSigningDate: '',
            nameOfShareHolderAndMore: '',
            summaryofLegalEntitiesActivities: '',
            targetEffectiveDate: event.body[0].targetEffectiveDate,
            proposedLegalEntityName: '',
            countryorStateofIncorporation: ''
          });
        }
      },
      error: (err) => {
        console.log("Unable to get proposal overview from the database table ", err);
      },
      complete: () => {

      }
    });
  }

  getEntityIndicatorQuestions() {
    this.apiService.getProposalOverview(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (data) => {
        if (data.body) {
          this.entityQuestions = [...data.body];
          this.selectedLegalEntities.forEach((entity: any) => {
                        let element = this.entityQuestions[0];

            if (element) {
              entity.summaryofLegalEntitiesActivities = element.summaryofLegalEntitiesActivities;
              entity.summaryId = element.summaryId;
            }

          });
        }
      },
      error: (err) => {

      },
      complete: () => {

      }
    });
  }


  getLegalEntities(): Promise<void> {
    return new Promise<void>((resolved) => {
      this.apiService.getLegalEntities(this.session.getItem(PROPOSAL_ID)).subscribe({
        next: (event) => {
          if (event.body) {
            let entities = [];
            entities = event.body
            this.selectedLegalEntities = [...entities];
            if (this.selectedLegalEntities[0].ccn == 'NA' || this.selectedLegalEntities[0].companyName == 'NA') {
              this.showLegalEntityNA = true;
            }
            else {
              this.getEntityIndicatorQuestions();
            }
          }
        },
        error: (err) => {
          console.log("Unable to get legal entity record in the database table ", err);

        },
        complete: () => {


          resolved();
        }
      });
    })
  }
  getAttachments(): void {
    this.apiService.getAttachments(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      this.files = [];
      if (response.body) {
        const data = response.body
        data.forEach((element: Attachment) => {
          this.files.push(element)
        })
      }
    })

  }
  getHistories(): void {
    this.apiService.getHistories(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      if (response.body) {
        this.histories = [...response.body];
      }
    })
  }
  getUserRoles() {
    this.apiService.getAllUserRoles().subscribe(output => {
      if (output.status == 200) {
        this.userRoles = [...output.body];
      }
    })
  }

}
