<div nz-row>
  <nz-upload nzType="drag" [nzMultiple]="true" [nzCustomRequest]="nzAction" [nzShowUploadList]="false" >
    <p class="ant-upload-drag-icon">
      <span nz-icon nzType="inbox"></span>
    </p>
    <p class="ant-upload-text">Click or drag file to this area to upload</p>
    <p class="ant-upload-hint">
      Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
    </p>
  </nz-upload>
 
</div>
 <nz-space nzDirection="vertical" nzSize="large"></nz-space>
<div nz-row nzGutter="8" *ngFor="let file of files">
  <div nz-col nzSpan="8">
    <a [href]="file.filePath"><span nz-icon nzType="paper-clip" nzTheme="outline"></span>&nbsp;{{file.fileName}}</a>
  </div>
  <div nz-col nzOffset="1" nzSpan="1">   
    <a (click)="deleteAttachment(file.fileName)"><span nz-icon nzType="delete" nzTheme="outline" style="color: red;" ></span></a>
  </div>
</div>
