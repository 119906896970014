<main class="container">
  <section id="column2" npSetLayout [pageLayout]="PageLayout.Layout12">
    <np-menu [location]="NavigationLocation.Side" [navStartUrl]="'/3.html'"></np-menu>

  </section>
  <section id="column1">

  <div class="loader-container" *ngIf="isLoader">
      <nz-spin nzTip="Loading..." style="position: fixed;left: 50%;top:50%;" [nzSize]="'large'"></nz-spin>
  </div>
    <app-page-header [displayStatus]="displayStatus"></app-page-header>
    <div class="loader-container" *ngIf="isLoader">
      <nz-spin nzTip="Loading..." style="position: fixed;left: 50%;top:50%;" [nzSize]="'large'"></nz-spin>
    </div>
    <div class="proposal-content">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"  class="grid-row" nzJustify="start">
        <div nz-col nzSpan="15" nzOffset="5">
          <h2 style="color: #064A91;" *ngIf="!displayStatus">Select Proposal Type</h2>
          <h2 style="color: #064A91;" *ngIf="displayStatus">Selected Proposal Type: {{proposalType}}</h2>
          <h5 >Only one request can be submitted per proposal type.</h5>
        </div>
      </div>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"  class="grid-row" nzJustify="start" class="grid-row">
        <div nz-col nzSpan="15" nzOffset="5">
          <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
            <div nz-col>
              <!-- <button nz-button nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToDissolutionProposal()" *ngIf="!displayStatus">dissolution</button>
              <button nz-button nzShape="circle" nz-popconfirm [nzPopconfirmTitle]="popConfirmTitle" [nzPopconfirmPlacement]="'bottom'" (nzOnConfirm)="popConfirm()" nzType="primary" nzSize="large" *ngIf="displayStatus">dissolution</button> -->

              <button nz-button nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToComponent('dissolution')" [ngStyle]="(displayStatus && proposalType=='Dissolution') ? changeBGColor():''" *ngIf="(!displayStatus || (displayStatus && proposalType=='Dissolution' ))">dissolution<br> <span class="btn-innertext" *ngIf="displayStatus && proposalType=='Dissolution'">{{proposalId}}</span></button>
              <button nz-button nzShape="circle" nz-popconfirm [nzPopconfirmTitle]="popConfirmTitle" [nzPopconfirmPlacement]="'bottom'" (nzOnConfirm)="popConfirmCommon('dissolution')" nzType="primary" nzSize="large" *ngIf="displayStatus && proposalType!='Dissolution'">dissolution</button>

            </div>
            <div nz-col>
              <!-- <button nz-button  nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToFormationProposal()" [ngStyle]="changeBGColor()">formation <br> <span class="btn-innertext" *ngIf="displayStatus">{{proposalId}}</span></button> -->
              <button nz-button nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToComponent('formation')" [ngStyle]="(displayStatus && proposalType=='Formation') ? changeBGColor():''" *ngIf="(!displayStatus || (displayStatus && proposalType=='Formation' ))">formation<br> <span class="btn-innertext" *ngIf="displayStatus && proposalType=='Formation'">{{proposalId}}</span></button>
              <button nz-button nzShape="circle" nz-popconfirm [nzPopconfirmTitle]="popConfirmTitle" [nzPopconfirmPlacement]="'bottom'" (nzOnConfirm)="popConfirmCommon('formation')" nzType="primary" nzSize="large" *ngIf="displayStatus && proposalType!='Formation'">formation</button>
            </div>
            <div nz-col>
              <!-- <button nz-button nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToMergerProposal()" *ngIf="!displayStatus">merger</button>
              <button nz-button nzShape="circle" nz-popconfirm [nzPopconfirmTitle]="popConfirmTitle" [nzPopconfirmPlacement]="'bottom'" (nzOnConfirm)="popConfirm()" nzType="primary" nzSize="large" *ngIf="displayStatus">merger</button> -->
              <button nz-button nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToComponent('merger')" [ngStyle]="(displayStatus && proposalType=='Merger') ? changeBGColor():''" *ngIf="(!displayStatus || (displayStatus && proposalType=='Merger' ))">merger<br> <span class="btn-innertext" *ngIf="displayStatus && proposalType=='Merger'">{{proposalId}}</span></button>
              <button nz-button nzShape="circle" nz-popconfirm [nzPopconfirmTitle]="popConfirmTitle" [nzPopconfirmPlacement]="'bottom'" (nzOnConfirm)="popConfirmCommon('merger')" nzType="primary" nzSize="large" *ngIf="displayStatus && proposalType!='Merger'">merger</button>
            </div>
            <div nz-col>
              <!-- <button nz-button  nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToComponent('acquisition')" [ngStyle]="(displayStatus && proposalType=='Acquisition') ? changeBGColor():''">acquisition <br> <span class="btn-innertext" *ngIf="displayStatus && proposalType=='Acquisition'">{{proposalId}}</span></button> -->
              <button nz-button nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToComponent('acquisition')" [ngStyle]="(displayStatus && proposalType=='Acquisition') ? changeBGColor():''" *ngIf="(!displayStatus || (displayStatus && proposalType=='Acquisition' ))">acquisition<br> <span class="btn-innertext" *ngIf="displayStatus && proposalType=='Acquisition'">{{proposalId}}</span></button>
              <button nz-button nzShape="circle" nz-popconfirm [nzPopconfirmTitle]="popConfirmTitle" [nzPopconfirmPlacement]="'bottom'" (nzOnConfirm)="popConfirmCommon('acquisition')" nzType="primary" nzSize="large" *ngIf="displayStatus && proposalType!='Acquisition'">acquisition</button>
            </div>
          </div>
          <ng-template #popConfirmTitle>
           <span> <span nz-icon nzType="info-circle" nzTheme="outline" style="color: red;"></span> <br><span class="pop-confirm-header">Are you sure?</span><br><br>Changing the proposal type will result in <br>loss of data already entered.<br></span>
          </ng-template>
        </div>
      </div>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"  class="grid-row" nzJustify="start">
        <div nz-col nzSpan="15" nzOffset="5">
          <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
            <div nz-col>
              <button nz-button nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToComponent('sales')" [ngStyle]="(displayStatus && proposalType=='Sale') ? changeBGColor():''" *ngIf="(!displayStatus || (displayStatus && proposalType=='Sale' ))">sale<br> <span class="btn-innertext" *ngIf="displayStatus && proposalType=='Sale'">{{proposalId}}</span></button>
              <button nz-button nzShape="circle" nz-popconfirm [nzPopconfirmTitle]="popConfirmTitle" [nzPopconfirmPlacement]="'bottom'" (nzOnConfirm)="popConfirmCommon('sales')" nzType="primary" nzSize="large" *ngIf="displayStatus && proposalType!='Sale'">sale</button>
            </div>
            <div nz-col>
              <button nz-button nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToComponent('ownershiprestructuring')" [ngStyle]="(displayStatus && proposalType=='Ownership Restructuring') ? changeBGColor():''" *ngIf="(!displayStatus || (displayStatus && proposalType=='Ownership Restructuring' ))">ownership<br>  restructuring<br> <span class="btn-innertext" *ngIf="displayStatus && proposalType=='Ownership Restructuring'">{{proposalId}}</span></button>
              <button nz-button nzShape="circle" nz-popconfirm [nzPopconfirmTitle]="popConfirmTitle" [nzPopconfirmPlacement]="'bottom'" (nzOnConfirm)="popConfirmCommon('ownershiprestructuring')" nzType="primary" nzSize="large" *ngIf="displayStatus && proposalType!='Ownership Restructuring'">ownership<br> restructuring</button>
            </div>
            <div nz-col>
              <button nz-button nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToComponent('qualificationwithdrawal')" [ngStyle]="(displayStatus && proposalType=='Qualification/Withdrawal') ? changeBGColor():''" *ngIf="(!displayStatus || (displayStatus && proposalType=='Qualification/Withdrawal' ))">qualification / <br>withdrawal<br><span class="btn-innertext" *ngIf="displayStatus && proposalType=='Qualification/Withdrawal'">{{proposalId}}</span></button>
              <button nz-button nzShape="circle" nz-popconfirm [nzPopconfirmTitle]="popConfirmTitle" [nzPopconfirmPlacement]="'bottom'" (nzOnConfirm)="popConfirmCommon('qualificationwithdrawal')" nzType="primary" nzSize="large" *ngIf="displayStatus && proposalType!='Qualification/Withdrawal'">qualification / <br>withdrawal<br></button>
            </div>
            <div nz-col>
              <button nz-button nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToComponent('others')" [ngStyle]="(displayStatus && proposalType=='Others') ? changeBGColor():''" *ngIf="(!displayStatus || (displayStatus && proposalType=='Others' ))">others<br> <span class="btn-innertext" *ngIf="displayStatus && proposalType=='Others'">{{proposalId}}</span></button>
              <button nz-button nzShape="circle" nz-popconfirm [nzPopconfirmTitle]="popConfirmTitle" [nzPopconfirmPlacement]="'bottom'" (nzOnConfirm)="popConfirmCommon('others')" nzType="primary" nzSize="large" *ngIf="displayStatus && proposalType!='Others'">others</button>
              
              <!-- <button nz-button nzShape="circle" nzType="primary" nzSize="large" (click)="navigateToComponent('others')"
              [ngStyle]="(displayStatus && proposalType=='Others') ? changeBGColor():''"
              *ngIf="(!displayStatus || (displayStatus && proposalType=='Others' ))">
                Others
                <span class="btn-innertext" *ngIf="displayStatus && proposalType=='Others'">
                  {{proposalId}}
                </span></button>
              <button nz-button nzShape="circle" nz-popconfirm [nzPopconfirmTitle]="popConfirmTitle" [nzPopconfirmPlacement]="'bottom'" (nzOnConfirm)="popConfirm()" nzType="primary" nzSize="large" *ngIf="displayStatus && proposalType!='Others'">Others</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
