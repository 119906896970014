import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { PROPOSAL_ID } from 'src/app/constants/constants';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataServiceProposalOverview } from 'src/app/core/services/data_service_proposal_overview';
import { UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { MergerEntityDetailsComponent } from '../merger-entity-details/merger-entity-details.component';
import { AcquisitionProposalOverviewComponent } from '../acquisition-proposal-overview/acquisition-proposal-overview.component';
import { MergerProposalAttachmentsComponent } from '../merger-proposal-attachments/merger-proposal-attachments.component';
import { MergerProposalOverviewComponent } from '../merger-proposal-overview/merger-proposal-overview.component';

@Component({
  selector: 'app-merger-proposal-details',
  templateUrl: './merger-proposal-details.component.html',
  styleUrl: './merger-proposal-details.component.less',
  animations: [formControlElement, formLabelElement],
})

export class MergerProposalDetailsComponent {
  @ViewChild(MergerEntityDetailsComponent) childMergerEntityDetailsComponent!: MergerEntityDetailsComponent;
  @ViewChild(MergerProposalOverviewComponent) childMergerProposalOverviewComponent!: MergerProposalOverviewComponent;
  @ViewChild(MergerProposalAttachmentsComponent) childMergerProposalAttachmentsComponent!: MergerProposalAttachmentsComponent;

  @Output() isLoader = new EventEmitter<boolean>();
  @Output() selectedTabIndexEmitValue=new EventEmitter<number>();
  @Input() mergerEntityDetailsForm!: UntypedFormGroup;
  @Input() mergingSurviveEntitySelectionForm!: UntypedFormGroup;
  
  @Input() mergerProposalOverviewForm!:  UntypedFormGroup;
  @Input() mergerProposalAttachmentForm!: UntypedFormGroup;
  @Input() selectedTabIndexValue!:number;

  entityDetails: string = "entity details";
  entityQuestions: string = "entity questions";
  overview: string = "overview";
  attachments: string = "attachments";
  selectedTabTitle: string = this.entityDetails;
  @Input() entityDetailsTabClass = 'tab-default';
  @Input() summaryTabClass = 'tab-default';
  @Input() attachmentTabClass = 'tab-default';
  proposalId!: string;
  constructor(private session: SessionStorageService, private message: NzMessageService,
    private proposalOverviewDataService: DataServiceProposalOverview){}
  ngOnInit(){
    this.isLoader.emit(false);
    //being used by html file for attachments?
    if(this.session.getItem(PROPOSAL_ID)){
      this.proposalId = this.session.getItem(PROPOSAL_ID);
    }

  }
  emitIsLoader(event:boolean){
    this.isLoader.emit(event);
  }
  async getCurrentTab(event:any){
    this.selectedTabIndexEmitValue.emit(event);
  }


}
