import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';

@Component({
  selector: 'app-qualification-withdrawal-proposal-overview',
  templateUrl: './qualification-withdrawal-proposal-overview.component.html',
  styleUrl: './qualification-withdrawal-proposal-overview.component.less',
  animations: [formControlElement, formLabelElement],
})
export class QualificationWithdrawalProposalOverviewComponent {
  @Input() qualificationWithdrawalProposalOverviewForm!: UntypedFormGroup;
}
