import { Component, Input } from '@angular/core';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataServiceProposalOverview } from 'src/app/core/services/data_service_proposal_overview';
import { UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DataService } from 'src/app/core/services/data_service';

@Component({
  selector: 'app-merger-proposal-overview',
  templateUrl: './merger-proposal-overview.component.html',
  styleUrl: './merger-proposal-overview.component.less',
  animations: [formControlElement, formLabelElement],
})

export class MergerProposalOverviewComponent {
  @Input() mergerProposalOverviewForm!: UntypedFormGroup;

  constructor(
    private session: SessionStorageService,
    private dataservice: DataService,
    private proposalOverviewDataService: DataServiceProposalOverview
  ) {
    console.log('MergerProposalOverviewComponent',this.mergerProposalOverviewForm);
  }
}
