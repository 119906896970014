import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzCardModule } from 'ng-zorro-antd/card';
import { SubmitResultsComponent } from './components/submit-results/submit-results.component';
import { HomeComponent } from './pages/home/home.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { MyDashboard } from './pages/my-dashboard/my-dashboard.component';
import { DashboardGridComponent } from './components/dashboard-grid/dashboard-grid.component';
import { CardDashboardComponent } from './components/card-dashboard/card-dashboard.component';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NgxEchartsModule } from 'ngx-echarts';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CalAngularModule } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { SubmitFailedComponent } from './components/submit-failed/submit-failed.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NextPage } from '@cvx/nextpage';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { ProposalTypesComponent } from './pages/proposal-types/proposal-types.component';
import { RequestGeneralDetailsComponent } from './components/request-general-details/request-general-details.component';
import { AcquisitionProposalDetailsComponent } from './components/acquisition-proposal-details/acquisitionproposal-details.component';
import { DissolutionProposalDetailsComponent } from './components/dissolution-proposal-details/dissolution-proposal-details.component';
import { AcquisitionProposalOverviewComponent } from './components/acquisition-proposal-overview/acquisition-proposal-overview.component';
import { DissolutionProposalOverviewComponent } from './components/dissolution-proposal-overview/dissolution-proposal-overview.component';
import { AcquisitionProposalComponent } from './pages/acquisition-proposal/acquisition-proposal.component';
import { DissolutionProposalComponent } from './pages/dissolution-proposal/dissolution-proposal.component';
import { MergerProposalComponent } from './pages/merger-proposal/merger-proposal.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { AcquisitionEntitySelectionComponent } from './components/acquisition-entity-selection/acquisition-entity-selection.component';
import { DissolutionEntitySelectionComponent } from './components/dissolution-entity-selection/dissolution-entity-selection.component';
import { AcquisitionEntityQuestionsComponent } from './components/acquisition-entity-questions/acquisition-entity-questions.component';
import { MergerEntityDetailsComponent } from './components/merger-entity-details/merger-entity-details.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { AcquisitionProposedSlateComponent } from './components/acquisition-proposed-slate/acquisition-proposed-slate.component';
import { AssignmentsComponent } from './components/assignments/assignments.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ProposalReviewComponent } from './components/proposal-review/proposal-review.component';
import { CanDeativateProposalTypeGuardService } from './core/services/CanDeactivateAcquisitionGuardService';
import { DataServiceProposalOverview } from './core/services/data_service_proposal_overview';
import { DataService } from './core/services/data_service';
import { DataServiceEntitySelection } from './core/services/data_service_entity_selection';
import { DataServiceGeneralDetails } from './core/services/data_service_general_details';
import { DataServiceContacts } from './core/services/data_service_contacts';
import { DataServiceEntityQuestions } from './core/services/data_service_entity_questions';
import { NzWaterMarkModule } from 'ng-zorro-antd/water-mark';
import { ViewRequestsComponent } from './pages/view-requests/view-requests.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { CommentsComponent } from './components/comments/comments.component';
import { DataServiceComments } from './core/services/data_service_comments';
import { PageLoaderComponent } from './pages/page-loader/page-loader.component';
import { ServerErrorComponent } from './components/server-error/server-error.component';
import { ConcurDeclineComponent } from './components/concur-decline/concur-decline.component';
import { IfcReviewerComponent } from './components/ifc-reviewer/ifc-reviewer.component';
import { NotesSubmissionComponent } from './components/notes-submission/notes-submission.component';
import { DataServiceApprovals } from './core/services/data_service_approvals';
import { DissolutionProposalAttachmentsComponent } from './components/dissolution-proposal-attachments/dissolution-proposal-attachments.component';
import { DissolutionProposalReviewComponent } from './components/dissolution-proposal-review/dissolution-proposal-review.component';
import { MergerProposalOverviewComponent } from './components/merger-proposal-overview/merger-proposal-overview.component';
import { MergerProposalDetailsComponent } from './components/merger-proposal-details/merger-proposal-details.component';
import { MergerProposalAttachmentsComponent } from './components/merger-proposal-attachments/merger-proposal-attachments.component';
import { MergerProposalReviewComponent } from './components/merger-proposal-review/merger-proposal-review.component';
import { SalesProposalComponent } from './pages/sales-proposal/sales-proposal.component';
import { SalesProposalDetailsComponent } from './components/sales-proposal-details/sales-proposal-details.component';
import { SalesEntitySelectionComponent } from './components/sales-entity-selection/sales-entity-selection.component';
import { SalesProposalReviewComponent } from './components/sales-proposal-review/sales-proposal-review.component';
import { FormationProposalComponent } from './pages/formation-proposal/formation-proposal.component';
import { FormationProposalDetailsComponent } from './components/formation-proposal-details/formation-proposal-details.component';
import { FormationProposalReviewComponent } from './components/formation-proposal-review/formation-proposal-review.component';
import { FormationEntitySelectionComponent } from './components/formation-entity-selection/formation-entity-selection.component';
import { FormationProposedSlateComponent } from './components/formation-proposal-slate/formation-proposal-slate.component';
import { FormationProposalOverviewComponent } from './components/formation-proposal-overview/formation-proposal-overview.component';
import { OwnershipRestructuringProposalDetailsComponent } from './components/ownership-restructuring-proposal-details/ownership-restructuring-proposal-details.component';
import { OwnershipRestructuringProposalOverviewComponent } from './components/ownership-restructuring-proposal-overview/ownership-restructuring-proposal-overview.component';
import { OwnershipRestructuringProposalComponent } from './pages/ownership-restructuring-proposal/ownership-restructuring-proposal.component';
import { OwnershipRestructuringProposalReviewComponent } from './components/ownership-restructuring-proposal-review/ownership-restructuring-proposal-review.component';
import { OwnershipRestructuringEntitySelectionComponent } from './components/ownership-restructuring-entity-selection/ownership-restructuring-entity-selection.component';
import { QualificationWithdrawalProposalComponent } from './pages/qualification-withdrawal-proposal/qualification-withdrawal-proposal.component';
import { QualificationWithdrawalProposalDetailsComponent } from './components/qualification-withdrawal-proposal-details/qualification-withdrawal-proposal-details.component';
import { QualificationWithdrawalEntitySelectionComponent } from './components/qualification-withdrawal-entity-selection/qualification-withdrawal-entity-selection.component';
import { QualificationWithdrawalProposalReviewComponent } from './components/qualification-withdrawal-proposal-review/qualification-withdrawal-proposal-review.component';
import { QualificationWithdrawalProposalOverviewComponent } from './components/qualification-withdrawal-proposal-overview/qualification-withdrawal-proposal-overview.component';
import { OthersProposalComponent } from './pages/others-proposal/others-proposal.component'
import { OthersProposalReviewComponent } from './components/others-proposal-review/others-proposal-review.component';
import { OthersProposalOverviewComponent } from './components/others-proposal-overview/others-proposal-overview.component';
import { OthersProposalDetailsComponent } from './components/others-proposal-details/others-proposal-details.component';
import { OthersEntitySelectionComponent } from './components/others-entity-selection/others-entity-selection.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';


@NgModule({
  declarations: [
    AppComponent,
    SubmitResultsComponent,
    HomeComponent,
    MyDashboard,
    DashboardGridComponent,
    CardDashboardComponent,
    SubmitFailedComponent,
    FileUploadComponent,
    PageHeaderComponent,
    UserManagementComponent,
    EditUserComponent,
    ProposalTypesComponent,
    RequestGeneralDetailsComponent,
    DissolutionProposalDetailsComponent,
    AcquisitionProposalComponent,
    MergerEntityDetailsComponent,
    MergerProposalComponent,
    MergerProposalOverviewComponent,
    MergerProposalDetailsComponent,
    MergerProposalAttachmentsComponent,
    MergerProposalReviewComponent,
    AcquisitionProposalDetailsComponent,
    AcquisitionProposalOverviewComponent,
    DissolutionProposalOverviewComponent,
    AcquisitionEntitySelectionComponent,
    DissolutionEntitySelectionComponent,
    AcquisitionEntityQuestionsComponent,
    DissolutionProposalAttachmentsComponent,
    AssignmentsComponent,
    ContactsComponent,
    CommentsComponent,
    AcquisitionProposedSlateComponent,
    ProposalReviewComponent,
    DissolutionProposalReviewComponent,
    DissolutionProposalComponent,
    ViewRequestsComponent,
    ServerErrorComponent,
    PageLoaderComponent,
    ConcurDeclineComponent,
    IfcReviewerComponent,
    NotesSubmissionComponent,
    DateAgoPipe,
    SalesProposalComponent,
    SalesProposalDetailsComponent,
    SalesEntitySelectionComponent,
    SalesProposalReviewComponent,
    FormationProposalComponent,
    FormationProposalDetailsComponent,
    FormationProposalOverviewComponent,
    FormationProposalReviewComponent,
    FormationEntitySelectionComponent,
    FormationProposedSlateComponent,
    OwnershipRestructuringProposalComponent,
    OwnershipRestructuringProposalOverviewComponent,
    OwnershipRestructuringProposalDetailsComponent,
    OwnershipRestructuringProposalReviewComponent,
    OwnershipRestructuringEntitySelectionComponent,
    QualificationWithdrawalProposalComponent,
    QualificationWithdrawalProposalDetailsComponent,
    QualificationWithdrawalEntitySelectionComponent,
    QualificationWithdrawalProposalReviewComponent,
    QualificationWithdrawalProposalOverviewComponent,
    OthersProposalComponent,
    OthersProposalReviewComponent,
    OthersProposalOverviewComponent,
    OthersProposalDetailsComponent,
    OthersEntitySelectionComponent,
  ],
  imports: [
    BrowserModule,
    NextPage,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzLayoutModule,
    NzMenuModule,
    NzButtonModule,
    NzSliderModule,
    NzBreadCrumbModule,
    NzCollapseModule,
    NzFormModule,
    NzStepsModule,
    NzCardModule,
    NzInputModule,
    NzSelectModule,
    NzSwitchModule,
    NzUploadModule,
    NzTabsModule,
    NzSegmentedModule,
    NzModalModule,
    NzListModule,
    NzIconModule,
    NzDatePickerModule,
    NzResultModule,
    ReactiveFormsModule,
    NzTypographyModule,
    NzAvatarModule,
    NzDividerModule,
    NzTimelineModule,
    NzGridModule,
    NzDropDownModule,
    NzTableModule,
    NzCommentModule,
    NzSpaceModule,
    NzImageModule,
    NzDrawerModule,
    NzStatisticModule,
    NzPageHeaderModule,
    NzDescriptionsModule,
    NzNotificationModule,
    NzToolTipModule,
    NzSpinModule,
    NzAlertModule,
    NzPopconfirmModule,
    ScrollingModule,
    NzBadgeModule,
    DragDropModule,
    NzProgressModule,
    NzCarouselModule,
    NzCheckboxModule,
    NzTagModule,
    NzSegmentedModule,
    NzWaterMarkModule,
    NzRadioModule,
    CalAngularModule.forRoot(environment.calConfig),
    LottieModule.forRoot({ player: playerFactory }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')}
    )
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
     NzMessageService, CanDeativateProposalTypeGuardService,
     DataService, DataServiceGeneralDetails,
     DataServiceEntitySelection, DataServiceEntityQuestions,
     DataServiceProposalOverview, DataServiceContacts, DataServiceComments,
     DataServiceApprovals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function playerFactory() {
  return player;
}
