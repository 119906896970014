import { Component, Input } from '@angular/core';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-submit-results',
  templateUrl: './submit-results.component.html',
  styleUrls: ['./submit-results.component.less'],
})
export class SubmitResultsComponent {
  @Input() proposalId!: string;
  @Input() title!: string;

  constructor(private session: SessionStorageService) {}
}
