import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';

@Component({
  selector: 'app-card-dashboard',
  templateUrl: './card-dashboard.component.html',
  styleUrls: ['./card-dashboard.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class CardDashboardComponent {
  @Input() title: string = '';

  // loader
  completed: AnimationOptions = {
    path: 'assets/completed.json',
    loop: true,
    autoplay: true,
  };
  attention: AnimationOptions = {
    path: 'assets/attention.json',
    loop: true,
    autoplay: true,
  };
  workInProgress: AnimationOptions = {
    path: 'assets/work_in_progress.json',
    loop: true,
    autoplay: true,
  };
  h1Styles = {
    backgroundColor: '#0066b2',
    color: '#FFFFFF',
  };
}
