<p><strong>Acquisition - Buyer</strong></p>
<form  nz-form [formGroup]="acquisitionEntitySelectionForm">
  <div nz-row nzGutter="8">
    <div nz-col nzSpan="10">
      <nz-form-item>
        <nz-form-label [@formLabelAnimation]>Legal Entity</nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <nz-select nzShowSearch [nzServerSearch]="true" nzPlaceHolder="Search by name. Select one or more." formControlName="legalEntity" [nzLoading]="nzLoading" 
            (ngModelChange)="onChange($event)" (nzOnSearch)="onSearchEvent($event)"
            [nzSuffixIcon]="suffixIconSearch" >
            <nz-option *ngFor="let entity of legalEntities" [nzValue]="entity.companyName"
              [nzLabel]="entity.companyName"></nz-option>
          </nz-select>
          <ng-template #suffixIconSearch>
            <span nz-icon nzType="search" nzTheme="outline" (click)="onSearch()"></span>
          </ng-template>
          <nz-tag  *ngFor="let selectedItem of selectedEntity" nzMode="closeable" style="margin-top: 5px;" (nzOnClose)="onCloseTag(selectedItem)">{{selectedItem}}</nz-tag>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="showComments">
        <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Comments</nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <textarea nz-input placeholder="Comments" formControlName="comments"
            [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSpan="12" nzOffset="1">
      <ng-container formArrayName="legalEntitiesFormArray">
          <div nz-row nzGutter="8" [nzAlign]="'middle'" *ngIf="legalEntitiesFormArray.controls.length > 0">
            <div nz-col nzSpan="1">
              <button nz-button nzType="primary" nzShape="circle" (click)="prevSlide()" [disabled]="current === 0"><span nz-icon nzType="left" nzTheme="outline" style="color: white;"></span></button>
            </div>
            <div nz-col nzSpan="19" nzOffset="1">
                <nz-carousel [nzEffect]="'scrollx'" [nzDots]="false" #carouselLegalEntity style="height: 350px;" [nzEnableSwipe]="false">
                  <div nz-carousel-content *ngFor="let legalEntityForm of legalEntitiesFormArray.controls; let i = index" >
                    <nz-descriptions [nzColumn]="1">
                      <nz-descriptions-item nzTitle="Company Code Number">{{legalEntityForm.get('ccn')?.value}}</nz-descriptions-item>
                      <nz-descriptions-item nzTitle="Company Name">{{legalEntityForm.get('companyName')?.value}}</nz-descriptions-item>
                      <nz-descriptions-item nzTitle="Jurisdiction">{{legalEntityForm.get('jurisdiction')?.value}}</nz-descriptions-item>
                      <nz-descriptions-item nzTitle="Ownership Details">{{legalEntityForm.get('ownershipDetail')?.value}}</nz-descriptions-item>
                    </nz-descriptions>
                  </div>
                </nz-carousel>
            </div>
            <div nz-col nzSpan="1">
              <button nz-button nzType="primary" nzShape="circle" (click)="nextSlide()" [disabled]="current === legalEntitiesFormArray.length -1"><span nz-icon nzType="right" nzTheme="outline" style="color: white;"></span></button>
            </div>
          </div>
      </ng-container>
    </div>
  </div>
</form>