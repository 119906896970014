import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { P30APIService } from 'src/app/core/services/api';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { UserDetails } from 'src/app/core/interfaces/User';
import { PROPOSAL_ID, SV_PROPOSAL_STATUS } from 'src/app/constants/constants';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceContacts } from 'src/app/core/services/data_service_contacts';
import { Contacts } from 'src/app/core/interfaces/Contacts';
import { DataServiceGeneralDetails } from 'src/app/core/services/data_service_general_details';
import { NzFormTooltipIcon } from 'ng-zorro-antd/form';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class ContactsComponent {
  @Output() ifIFCRequired = new EventEmitter<boolean>();
  @Output() isLoader = new EventEmitter<boolean>;
  @Output() isContactFormValid = new EventEmitter<boolean>;
  @Input() contactsForm!: UntypedFormGroup

  tooltipIcon: NzFormTooltipIcon = {
    type: 'question-circle',
    theme: 'twotone'
  };

  contactsFormModel: Contacts = {
    coordinator: [],
    additionalCc: [],
    contactId: '',
    ifcRequired: false
  };

  coordinatorNames = []
  addCCNames = [];
  coordinatorEmails = []
  addCCEmails = [];
  coordinators: UserDetails[] = [];
  additionalCCs: UserDetails[] = [];
  selectedCoordinators: UserDetails[] = [];
  selectedAdditionalCcs: UserDetails[] = [];
  proposalStatusName!: string;
  xyz!: boolean;



  constructor(private fb: UntypedFormBuilder, private apiService: P30APIService,
    private session: SessionStorageService, private message: NzMessageService,
    private graph: MsGraphService,
    private contactsDataService: DataServiceContacts,
    private generalDetailsDataService: DataServiceGeneralDetails,
    private dataservice: DataService) {
  }

  checkSessionAndAppendValue(event: any) {
    let sessionCord = this.session.getItem("coordinatorSelected");
    let sessionAdditionalCc = this.session.getItem("additionalCcSelected");
    if (sessionCord == null || sessionCord.length == 0) {
      this.getCoordinator(event);
    } else {
      sessionCord.forEach((ele: any) => {
        this.contactsFormModel.coordinator.push(ele.fullName);
        this.coordinators.push(ele);
        this.contactsDataService.coordsInfoList.push(ele);
        const control = this.contactsForm.controls["coordinator"];
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      })
    } if ((sessionAdditionalCc == null || sessionAdditionalCc.length == 0)) {
      this.additionalCcsForNotifications(event);
    } else {
      switch (this.isEventEmpty) {
        case true: {
          break;
        }
        case false: {
          this.contactsFormModel.additionalCc = [];
          this.additionalCCs = [];
          this.contactsDataService.addCCInfoList = [];
          sessionAdditionalCc.forEach((ele: any) => {
            this.contactsFormModel.additionalCc.push(ele.fullName);
            this.additionalCCs.push(ele);
            this.contactsDataService.addCCInfoList.push(ele);
          })
          break;
        }
        default: {
          this.contactsFormModel.additionalCc = [];
          this.additionalCCs = [];
          this.contactsDataService.addCCInfoList = [];
          sessionAdditionalCc.forEach((ele: any) => {
            this.contactsFormModel.additionalCc.push(ele.fullName);
            this.additionalCCs.push(ele);
            this.contactsDataService.addCCInfoList.push(ele);
          })
          break;
        }
      }
    }
  }


  ngOnInit() {
    this.proposalStatusName = this.session.getItem(SV_PROPOSAL_STATUS).name.toLocaleLowerCase();
    this.isLoader.emit(true);
    const proposalID = this.session.getItem(PROPOSAL_ID);
    this.enableDisableIFCToggleBTN();
    if (proposalID) {
      this.contactsDataService.getContacts(proposalID).subscribe({
        next: (event) => {
          if (event?.body) {
            // this.additionalCcsForNotifications(event);
            this.checkSessionAndAppendValue(event);
            this.contactsForm?.controls['ifcRequired'].setValue(event?.body[0]?.ifcRequired)
            this.contactsFormModel.ifcRequired = event?.body[0]?.ifcRequired;
            this.checkIFCRequired();
            this.xyz = false;
          } else {
            this.getRegionCoordinators();
          }
        }, error: (err) => {
          this.isLoader.emit(false);
        },
        complete: () => {
          this.isLoader.emit(false);
        }
      });
    } else {
      this.checkSessionAndAppendValue('');
    }
  }

  additionalCcsForNotifications(event: any) {
    if (event.body[0].additionalCcsForNotifications) {
      this.additionalCCs = [];
      this.contactsDataService.addCCInfoList = [];
      this.contactsFormModel.additionalCc = [];
      let addCCNames = event.body[0].additionalCcsForNotifications.includes(';') ? event.body[0].additionalCcsForNotifications.split(';') : [event.body[0].additionalCcsForNotifications];
      let addCCEmails = event.body[0].additionalCcsForNotificationsEmail.includes(';') ? event.body[0].additionalCcsForNotificationsEmail.split(';') : [event.body[0].additionalCcsForNotificationsEmail];
      for (let i = 0; i < addCCEmails.length; i++) {
        let addCcTemp: UserDetails = {
          mail: addCCEmails[i],
          fullName: addCCNames[i]
        }
        this.contactsFormModel.additionalCc.push(addCcTemp.fullName);
        this.additionalCCs.push(addCcTemp);
        this.contactsDataService.addCCInfoList.push(addCcTemp);
      }
    } else {
      return
    }
  }

  getCoordinator(event: any) {
    if (event.body[0].coordinator) {
      let coordinatorNames = event.body[0].coordinator.includes(';') ? event.body[0].coordinator.split(';') : [event.body[0].coordinator];
      let coordinatorEmails = event.body[0].coordinatorEmail.includes(';') ? event.body[0].coordinatorEmail.split(';') : [event.body[0].coordinatorEmail];
      for (let i = 0; i < coordinatorEmails.length; i++) {
        let coordinatorTemp: UserDetails = {
          mail: coordinatorEmails[i],
          fullName: coordinatorNames[i]
        }
        this.contactsFormModel.coordinator.push(coordinatorTemp.fullName);
        this.coordinators.push(coordinatorTemp);
        this.contactsDataService.coordsInfoList.push(coordinatorTemp);
        const control = this.contactsForm.controls["coordinator"];
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    } else {
      this.getRegionCoordinators();
    }
  }
  getCoordinators(coordinator: any) {
    return coordinator.body.coordinatorEmailId.includes(';') ? coordinator.body.coordinatorEmailId.split(';') : [coordinator.body.coordinatorEmailId];
  }
  async getRegionId() {
    const resultGetProposal: any = await new Promise((resolve) => {
      this.generalDetailsDataService.getProposal().subscribe({
        next: (event) => {
          if (event.body.regionId) {
            resolve(event.body.regionId)
          } else {
            resolve(null);
          }
        }, error: (err) => {
          this.isLoader.emit(false);
        },
        complete: () => {
          this.isLoader.emit(false);
        }
      })
    });
    if (resultGetProposal) {
      return resultGetProposal;
    } else {
      return null;
    }

  }

  async getRegionCoordinators() {
    this.isLoader.emit(true);
    let result: any;
    const resultGetProposal: any = await this.getRegionId();
    if (resultGetProposal) {
      result = await new Promise((resolve) => {
        this.generalDetailsDataService.getRegion(resultGetProposal).subscribe(element => {
          resolve(element);
        });
      });
    }
    if (result) {
      let emails = this.getCoordinators(result);
      for (let email of emails) {
        this.graph.getUserByEmail(email).subscribe(element => {
          let coordinatorTemp: UserDetails = {
            mail: element.mail,
            fullName: `${element.givenName} ${element.surname}`
          }
          this.contactsFormModel.coordinator.push(coordinatorTemp.fullName);
          this.coordinators.push(coordinatorTemp);
          this.contactsDataService.coordsInfoList.push(coordinatorTemp);
          const control = this.contactsForm.controls["coordinator"];
          control.updateValueAndValidity();
        })
      }
      this.isContactFormValid.emit(true);
    }
  }
  getErrorTip(controlName: string): string {
    const control = this.contactsForm.controls[controlName];
    if (control.hasError('required')) {
      return "Please input coordinator!"
    }
    return ""
  }

  onCoordinatorChange(event: string) {
    if (event.length == 0) {
      this.coordinators = [];
      this.contactsDataService.coordsInfoList = [];
    } else if (event) {
      // //Add user in dropdown
      let selectedCoorinator: any = this.coordinators.find(x => x.fullName == event[event.length - 1]);
      if (selectedCoorinator) {
        this.contactsDataService.isCoordinatorContactData(selectedCoorinator);
        this.contactsDataService.coordsInfoList.push(selectedCoorinator);
      }
      this.updateStoreCoordinatorInSession(event);
    }
  }

  updateStoreCoordinatorInSession(event: any) {
    let arr: any = [];
    for (let i = 0; i < event.length; i++) {
      this.contactsDataService.coordsInfoList.forEach((records: any) => {
        if (records['fullName'].includes(event[i])) {
          if (arr.find((e: any) => e.fullName === event[i]) === undefined) {
            let x: any = {
              mail: records?.mail,
              fullName: records?.fullName
            }
            arr.push(x);
          }
        } return;
      })
    }
    this.session.setItem("coordinatorSelected", arr);
  }

  isEventEmpty: boolean = false;
  onAdditionalCcChange(event: string) {
    if (event.length == 0) {
      if(this.xyz) {
        this.isEventEmpty = true;
        this.additionalCCs = [];
        this.contactsFormModel.additionalCc = [];
        this.contactsDataService.addCCInfoList = [];
        this.session.setItem("additionalCcSelected", []);
      }
      return;
    } else if (event) {
      // //Add user in dropdown
      let selectedadditionalCc = this.additionalCCs.find(x => x.fullName == event[event.length - 1]);
      if (selectedadditionalCc) {
        this.contactsDataService.setAddCC(event, selectedadditionalCc);
      }
      this.updateStoreAdditionalCcInSession(event);
    }
  }
  updateStoreAdditionalCcInSession(event: any) {
    let arr: any = [];
    for (let i = 0; i < event.length; i++) {
      this.contactsDataService.addCCInfoList.forEach((records: any) => {
        if (records['fullName'].includes(event[i])) {
          if (arr.find((e: any) => e.fullName === event[i]) === undefined) {
            let x: any = {
              mail: records?.mail,
              fullName: records?.fullName
            }
            arr.push(x);
          }
        } return;
      })
    }
    this.contactsDataService.addCCInfoList = [];
    this.contactsDataService.addCCInfoList = [...arr]
    this.session.setItem("additionalCcSelected", arr);
  }

  async searchCoordinatorUser(value: string) {
    this.coordinators = [];
    if (value) {
      let users = [...await this.dataservice.searchUser(value)];
      this.coordinators = [...new Set(users)];
    }
  }

  async searchAdditionalCcUser(value: string) {
    this.additionalCCs = [];
    if (value) {
      this.xyz = true;
      let users = [...await this.dataservice.searchUser(value)];
      this.additionalCCs = [...new Set(users)];
    }
  }

  // is ifc required
  ifcRequiredToggleBTN(event: any) {
    this.checkIFCRequiredToggleState(event);
  }

  checkIFCRequired() {
    let ifcState = this.contactsForm.controls['ifcRequired'].value;
    this.checkIFCRequiredToggleState(ifcState);
  }

  checkIFCRequiredToggleState(state: any) {
    this.session.setItem('ifcRequiredValue', state);
    switch (state) {
      case true: {
        this.ifIFCRequired.emit(true);
        this.session.setItem('ifcRequiredValue', true);
        break;
      }
      case false: {
        this.ifIFCRequired.emit(false);
        this.session.setItem('ifcRequiredValue', false);
        break;
      }
      case null: {
        this.ifIFCRequired.emit(false);
        break;
      }
      case 'default': {
        this.ifIFCRequired.emit(false);
        break;
      }
    }
  }

  enableDisableIFCToggleBTN() {
    switch (this.proposalStatusName) {
      case ('pending governance review'): {
        this.contactsForm.controls['ifcRequired'].enable();
        break;
      }
      default: {
        this.contactsForm.controls['ifcRequired'].disable();
        break;
      }
    }
  }
}
