import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { P30APIService } from 'src/app/core/services/api';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { API_FAILING_ERROR_MESSAGE, PROPOSAL_ID } from 'src/app/constants/constants';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { DataService } from 'src/app/core/services/data_service';
import { LegalEntity } from 'src/app/core/interfaces/LegalEntities';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataServiceEntitySelection } from 'src/app/core/services/data_service_entity_selection';
import { DataServiceEntityQuestions } from 'src/app/core/services/data_service_entity_questions';
import { filter, map } from 'rxjs';
import { DataServiceMergerSelection } from 'src/app/core/services/data_service_merger_selection';

@Component({
  selector: 'app-dissolution-entity-selection',
  templateUrl: './dissolution-entity-selection.component.html',
  styleUrl: './dissolution-entity-selection.component.less',
  animations: [formControlElement, formLabelElement],
})
export class DissolutionEntitySelectionComponent{
  @Output() isLoader = new EventEmitter<boolean>;
  componentIsVisible:boolean =false;
  legalEntityForm!: UntypedFormGroup;
  nzLoading:boolean = false;
  nzSearchValue!:string;
  @Input() dissolutionEntitySelectionForm!: UntypedFormGroup;
  legalEntities: LegalEntity[] = [{
    companyName: "NA"
  }];
  current = 0;
  selectedLegalEntities: LegalEntity[] = [];
  entityQuestions: any= [];
  entityQuestionsSet: any= [];
  showComments:boolean = false;
  selectedEntity: string[] =[];
  apiErrorMSG: string = API_FAILING_ERROR_MESSAGE;

  @ViewChild('carouselLegalEntity')
  carouselLegalEntity!: NzCarouselComponent;
  constructor(private fb: UntypedFormBuilder, private apiService: P30APIService, private session: SessionStorageService, private message:NzMessageService ,
    private dataservice: DataService,
    private entitySelectionDataService: DataServiceEntitySelection,
    private entityQuestionsDataService: DataServiceEntityQuestions,
    private dataServiceMergerSelection: DataServiceMergerSelection){
  }

  async ngOnInit(){
    if(this.dissolutionEntitySelectionForm.controls["legalEntity"].value == "NA"){
      this.showComments = true;
    }else{
      for(let i=0;i<this.legalEntitiesFormArray.length;i++){
        this.selectedEntity.push(this.legalEntitiesFormArray.at(i).value.companyName);
      }
      await this.getLegalEntities();
      this.legalEntities = [...this.selectedLegalEntities];
      this.legalEntities.unshift({
        companyName: "NA"
      });     
    }


  }
  
  
  ngOnDestroy(){
    this.dataservice.legalEntities = [...this.legalEntities];
    this.dataservice.selectedLegalEntities = [...this.selectedLegalEntities];
  }

  get legalEntitiesFormArray(){
    return this.dissolutionEntitySelectionForm.controls["legalEntitiesFormArray"] as FormArray;
  }

  get legalEntitiesQuestionsFormArray(){
    return this.dissolutionEntitySelectionForm.controls["legalEntitiesQuestionsFormArray"] as FormArray;
  }

  onSearch(){
    this.nzLoading = true;
    this.legalEntities = [{
        companyName: "NA"
      }];
    if(this.nzSearchValue){
      this.apiService.getCompanies(this.nzSearchValue)
      .subscribe({
        next: (event) =>{
          let arrResult = [];
          arrResult = [...event.body.companies];
          arrResult.forEach(element => {
            let legalEntity:LegalEntity = {
              ccn: element.companyCodeNumber,
              companyName: element.companyName,
              jurisdiction: element.countryName
            }
            this.legalEntities.push(legalEntity);
          });
        },
        error: (err)=>{
          this.message.error(this.apiErrorMSG);
          this.nzLoading = false;
        },
        complete: () =>{
          this.nzLoading = false;
        }
      });
    }
  }

  onSearchEvent(event:string){
    this.nzSearchValue = event;
  }
  async onChangeActiveReg(form:any)
  {
  console.log(this.dissolutionEntitySelectionForm);
  let formArray = this.dissolutionEntitySelectionForm.controls["legalEntitiesFormArray"] as FormArray;
  let formQuestionsArray = this.dissolutionEntitySelectionForm.controls["legalEntitiesQuestionsFormArray"] as FormArray;
  let flagES = await this.dataServiceMergerSelection.reviewAndValidateMergerLEntitySelection(this.dissolutionEntitySelectionForm,"NX");
  console.log("formArray", formArray);
  console.log("formQuestionsArray", formQuestionsArray);
  }

  async onChange(event:string){
    if(event==='NA'){
      this.removeFormArray();
      this.removeFormArray1();
      this.selectedEntity = [];
      await this.onChangeGetLegalEntities(false);
      await this.getLegalEntities();
      this.showComments = true;
      const control = this.dissolutionEntitySelectionForm.controls["comments"]
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    }else{
      await this.onChangeGetLegalEntities(true);
      await this.getLegalEntities();
      if(!this.selectedEntity.includes(event)){
        this.isLoader.emit(true);
        this.apiService.getCompanyDetails(this.legalEntities.find(data=>data.companyName===event)?.ccn).subscribe({
          next: (output) =>{
            if(output.body){
              const modifiedLegalEntities = this.legalEntities.map(obj => {
                if (obj.ccn === output.body.companyCodeNumber) {
                    return { ...obj, ownershipDetail: output.body.ownership };
                }
                return obj;
              });
              this.legalEntities = [...modifiedLegalEntities];
            }
            this.addField(event);
          },
          error: (err)=>{
            this.message.error(this.apiErrorMSG);
            this.isLoader.emit(false);
          },
          complete: () =>{
           
            this.selectedEntity.push(event);
            this.isLoader.emit(false);
          }
        });
      }
      this.showComments=false;
      const control = this.dissolutionEntitySelectionForm.controls["comments"]
      control.removeValidators([Validators.required]);
      control.setValue(null);
      control.updateValueAndValidity();
    }
  }

 
  async onChangeGetLegalEntities(isNa: boolean) {
    this.entitySelectionDataService.getLegalEntities().subscribe({
      next:(event)=>{
        let legalEntities = [];
        if(event.body){
          legalEntities = [...event.body];
          legalEntities.forEach(findItem =>{
            switch (isNa) {
              case (true): {
                if(findItem.legalEntityComments || findItem.companyName == "NA"){
                  this.deleteLegalEntity(findItem.legalEntityId);
                }
                return;
              }
              case (false) : {
                if(findItem.legalEntityId && findItem.companyName !=="NA"){
                  this.deleteLegalEntity(findItem.legalEntityId);
                }
                return;
              } default: {
                return
              }
            }



          });
        }
      },
      error: (err)=>{
        this.message.error(this.apiErrorMSG);
      }
    });
  }

  addField(value:string) {
    const legalEntityForm = this.fb.group({
      legalEntityId: [this.legalEntities.filter(data =>data.companyName === value)[0].legalEntityId],
      legalEntityName:[value],
      ccn:[this.legalEntities.filter(data =>data.companyName === value)[0].ccn],
      companyName:[value],
      jurisdiction:[this.legalEntities.filter(data =>data.companyName === value)[0].jurisdiction],
      ownershipDetail:[this.legalEntities.filter(data =>data.companyName === value)[0].ownershipDetail],
      entityQuestionsId:0,
      activeRegistration:false,
      activeRegistrationComments: "",
      subsidiaries:  false,
      subsidiariesComments: "",      
      anyEmployees: false,
      anyEmployeesComments:  "",      
      activeBankaccount: false,
      activeBankaccountComments:  "", 
    })
    this.legalEntitiesFormArray.push(legalEntityForm);   
    this.legalEntitiesQuestionsFormArray.push(legalEntityForm);   
  }

  removeFormArray(){
    this.legalEntitiesFormArray.clear();
  }

  removeFormArray1(){
    this.legalEntitiesQuestionsFormArray.clear();
  }

  deleteLegalEntity(legalEntityId:any){
    this.isLoader.emit(true);
      this.apiService.deleteLegalEntity(this.session.getItem(PROPOSAL_ID), legalEntityId).subscribe({
        next: (out) =>{

        },
        error: (err) =>{
          this.message.error(this.apiErrorMSG);
          console.log("Unable to create legal entity record in the database table ",err);
          this.isLoader.emit(false);
        },
        complete: () => {
          this.entityQuestionsSet = this.entityQuestions.filter((x: { legalEntityId: any; }) => x.legalEntityId === legalEntityId)
          if(this.entityQuestionsSet.body){
          this.apiService.deleteEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), this.entityQuestionsSet[0].entityQuestionsId).subscribe
          ({
            next: (out) =>{
    
            },
            error: (err) =>{
              this.message.error(this.apiErrorMSG);
              console.log("Unable to create legal entity questions record in the database table ",err);
              this.isLoader.emit(false);
            },
            complete: () => {
              
              this.getLegalEntities();
              this.isLoader.emit(false);
            }
          });}
          // this.getLegalEntities();
           this.isLoader.emit(false);
        }
      })
  }

  onCloseTag(event:string){
    let index = this.selectedEntity.indexOf(event);
    this.selectedEntity.splice(index,1);
    let findItem = this.selectedLegalEntities.find(e=>e.companyName===event);
    const index1 = this.legalEntitiesQuestionsFormArray.controls.findIndex(i=>i.value.legalEntityName === event)
    if(index1 !== -1){ 
      this.legalEntitiesQuestionsFormArray.removeAt(index1);
      this.legalEntitiesFormArray.removeAt(index1);
      }
    if(findItem){
      if(findItem.legalEntityId){
        this.deleteLegalEntity(findItem.legalEntityId);
      }
    }
    // this.removeFormArray();
    // this.removeFormArray1();
    // for(let entityName of this.selectedEntity){
    //   this.addField(entityName);
    // }
  }

  
  getLegalEntities(): Promise<boolean> {
    return new Promise<boolean> ((resolved)=>{
      if(this.session.getItem(PROPOSAL_ID)){
        this.isLoader.emit(true);
        this.entityQuestionsDataService.getEntityIndicatorQuestions()
        .subscribe({
          next:(event) =>{
            if(event.body){
              this.entityQuestions = [...event.body];
            }
          },
          complete: () => {
            this.entitySelectionDataService.getLegalEntities().subscribe({
              next: (event) =>{
                this.selectedLegalEntities =[];
                if(event.body){
                  for(const element of event.body){
                    let leglaEntity: LegalEntity = {
                      legalEntityId: element.legalEntityId,
                      ccn: element.ccn,
                      companyName: element.companyName,
                      jurisdiction: element.jurisdiction,
                      ownershipDetail: element.ownershipDetail,
                      legalEntityComments: element.legalEntityComments,
                      typeofLegalEntity: element.typeofLegalEntity
                    }
                    this.selectedLegalEntities.push(leglaEntity);
                      this.entityQuestionsSet = this.entityQuestions.filter((x: { legalEntityId: any; }) => x.legalEntityId === element.legalEntityId)
                      const legalEntityForm = this.fb.group({
                        legalEntityId: element.legalEntityId,
                        legalEntityName:leglaEntity.companyName,
                        ccn:leglaEntity.ccn,//[this.legalEntities.filter(data =>data.companyName === leglaEntity.companyName)[0].ccn],
                        companyName:leglaEntity.companyName,
                        jurisdiction:leglaEntity.jurisdiction,
                        ownershipDetail:leglaEntity.ownershipDetail,
                        entityQuestionsId: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].entityQuestionsId : '',
                        activeRegistration: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].haveActiveRegistrationsInOtherStatesCountries : '',
                        activeRegistrationComments:this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].commentsForHavingActiveRegistrations : '',
                        subsidiaries:  this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].haveSubsidiaries : '',
                        subsidiariesComments: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].commentsForHavingSubsidiaries: '',    
                        anyEmployees: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].haveAnyEmployees : '',
                        anyEmployeesComments:  this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].commentsForHavingEmployees : '',     
                        activeBankaccount: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].haveActiveBankAccounts : '',
                        activeBankaccountComments:  this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].commentsForHavingBankAccounts : '',
                      })
                  }
                }
              },
              error: (err) =>{
                this.message.error(this.apiErrorMSG);
                console.log("Unable to get legal entity record in the database table ",err);
                this.isLoader.emit(false);
                resolved(false);
              },
              complete: () =>{
                this.isLoader.emit(false);
                resolved(true);
              }
            });
            this.isLoader.emit(false);
          }
        });
        
      }
    });
  }

  nextSlide(){
    this.carouselLegalEntity.next();
    this.current++;
  }

  prevSlide(){
    this.carouselLegalEntity.pre();
    this.current--;
  }


}

