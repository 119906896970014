import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { P30APIService } from './api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { IFCGroupsDTO } from '../interfaces/ifcReviewers';
import { IFCCOMPTROLLERS, IFCCreateSuccess, IFCDueDateUpdateError, IFCDueDateUpdateSuccess, IFCGOVERNANCE, IFCLEGAL, IFCTAX, IFCTREASURY, PROPOSAL_ID } from 'src/app/constants/constants';
import { UntypedFormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DataServiceIFCReviewers {
  ifcReviewersPayload$ = new BehaviorSubject<IFCGroupsDTO[]>([]);
  isConcurDateEmpty$ = new BehaviorSubject<boolean>(true);
  isRecordsToUpdate$ = new BehaviorSubject<boolean| undefined>(undefined);
  isReviewSubmitClicked$ = new BehaviorSubject<boolean | undefined>(undefined);
  isActionCompletd!: boolean;

  constructor(
    private session: SessionStorageService,
    private apiService: P30APIService,
    private message: NzMessageService
  ) {}

  reviewersPayload(state: IFCGroupsDTO[]) {
    this.ifcReviewersPayload$.next(state);
  }

  ifcConcurDateValue(state: boolean) {
    this.isConcurDateEmpty$.next(state);
  }

  getIFCUpdateRecordsStatus(state: boolean | undefined) {
    this.isRecordsToUpdate$.next(state);
  }
  reviewNsubmitStatus(state: boolean | undefined) {
    this.isReviewSubmitClicked$.next(state);
  }

  reviewAndValidateIfcReviewers(form: UntypedFormGroup): Promise<boolean> {
    const control = form.controls["dueDate"];
      control.setValidators([Validators.required]);
      control.markAsDirty();
      control.updateValueAndValidity();
      return Promise.resolve(form.valid);
  }

  async defaultGroupMemberAPICall() {
    return this.apiService.getReviewers().pipe(
      map((data: any) => {
        if (data) return data;
      })
    );
  }

  async getIFCApprovals() {
    const proposal_ID = this.session.getItem(PROPOSAL_ID);
    return this.apiService.getApprovals(proposal_ID).pipe(
      map((approvals: any) => {
        if (approvals) return approvals;
      })
    );
  }

  async submitIfcForApproval(form: any) {
    this.isReviewSubmitClicked$.subscribe((reviewStatus: boolean | undefined) => {
      switch(reviewStatus) {
        case true: {
          this.isRecordsToUpdate$.subscribe((updateState: boolean | undefined) => {
            if(this.isActionCompletd) {
              this.isActionCompletd = false;
              switch(updateState) {
                case true: {
                  this.updateDueDateForAllGroups();
                  this.getIFCUpdateRecordsStatus(true);
                  break;
                }
                case false: {
                  this.createIFCApproval();
                  this.getIFCUpdateRecordsStatus(true);
                  break;
                }
                case undefined: {
                  break;
                }
              }
            } else {
              return;
            }
          })
          break;
        }
        case false: {
          break;
        }
        case undefined: {
          break;
        }
      }
    })
  }

  async createIFCApproval() {
    let responseStatus: boolean;
    let payload: IFCGroupsDTO[] = [];
    let Proposal_ID = this.session.getItem(PROPOSAL_ID);
    this.ifcReviewersPayload$.subscribe((value: IFCGroupsDTO[]) => {
      payload = value;
    });
    let legal = this.apiService.createApprovals(Proposal_ID, payload[0]);
    let comptrollers = this.apiService.createApprovals(Proposal_ID, payload[1]);
    let tax = this.apiService.createApprovals(Proposal_ID, payload[2]);
    let treasury = this.apiService.createApprovals(Proposal_ID, payload[3]);
    let governance = this.apiService.createApprovals(Proposal_ID, payload[4]);
    combineLatest([legal, comptrollers, tax, treasury, governance]).subscribe((result:any) => {
      this.isActionCompletd = false;
      responseStatus = result.every((res: any) => Number(res.status) == 200 || Number(res.status) == 201);
      if(responseStatus) {
        this.message.success(IFCCreateSuccess);
      } else {
        this.message.error(IFCDueDateUpdateError);
      }
    })
  }

  async updateDueDateForAllGroups() {
    let responseStatus: boolean;
    let payload: IFCGroupsDTO[] = [];
    this.ifcReviewersPayload$.subscribe((value: IFCGroupsDTO[]) => {
      payload = value;
      return payload
    });
    let proposal_ID = this.session.getItem(PROPOSAL_ID);
    let legal = this.apiService.updateApprovals(proposal_ID, Number(payload[0]?.approvalsId), payload[0]);
    let comptrollers = this.apiService.updateApprovals(proposal_ID, Number(payload[1]?.approvalsId), payload[1]);
    let tax = this.apiService.updateApprovals(proposal_ID, Number(payload[2]?.approvalsId), payload[2]);
    let treasury = this.apiService.updateApprovals(proposal_ID, Number(payload[3]?.approvalsId), payload[3]);
    let governance = this.apiService.updateApprovals(proposal_ID, Number(payload[4]?.approvalsId), payload[4]);
    combineLatest([legal, comptrollers, tax, treasury, governance]).subscribe((result:any) => {
      this.isActionCompletd = false;
      responseStatus = result.every((res: any) => Number(res.status) == 200 || Number(res.status) == 201);
      if(responseStatus) {
        this.message.success(IFCDueDateUpdateSuccess);
      } else {
        this.message.error(IFCDueDateUpdateError);
      }
    })
  }

  updateIFCApproval(type:string){
    let payload: IFCGroupsDTO[] = [];
    let Proposal_ID = this.session.getItem(PROPOSAL_ID);
    this.ifcReviewersPayload$.subscribe((value: IFCGroupsDTO[]) => {
      payload = value;
    });
    switch(type){
      case IFCLEGAL:{
        let payload_data: IFCGroupsDTO = payload[0]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[0]?.approvalsId), payload_data).subscribe(legal =>{
          if(legal.body){
            console.log("this is legal response => ", legal);
          }
        });
        break;
      }
      case IFCCOMPTROLLERS:{
        let payload_data: IFCGroupsDTO = payload[1]
        payload_data.status = null;
        payload_data.comment = '';
       this.apiService.updateApprovals(Proposal_ID, Number(payload[1]?.approvalsId), payload_data).subscribe(comptroller=>{
          if(comptroller.body){
            console.log("this is comptroller response => ", comptroller);
          }
        });
        break;
      }
      case IFCTAX:{
        let payload_data: IFCGroupsDTO = payload[2]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[2]?.approvalsId), payload_data).subscribe(tax=>{
          if(tax.body){
            console.log("this is tax response => ", tax);
          }
        });
        break;
      }
      case IFCTREASURY:{
        let payload_data: IFCGroupsDTO = payload[3]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[3]?.approvalsId), payload_data).subscribe(treasury=>{
          if(treasury.body){
            console.log("this is treasury response => ", treasury);
          }
        });
        break;
      }
      case IFCGOVERNANCE:{
        let payload_data: IFCGroupsDTO = payload[4]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[4]?.approvalsId),payload_data).subscribe(governance=>{
          if(governance.body){
            console.log("this is governance response => ", governance);
          }
        });
        break;
      }
    }

  }

}
