<p><strong>Name of Entity(ies) Being Dissolved</strong></p>
<form nz-form [formGroup]="dissolutionEntitySelectionForm" >
  <div nz-row nzGutter="8">
    <div nz-col nzSpan="10">
      <nz-form-item>
        <nz-form-label [@formLabelAnimation]>Legal Entity(ies)</nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <nz-select nzShowSearch [nzServerSearch]="true" nzPlaceHolder="Search by name. Select one or more."
            formControlName="legalEntity" [nzLoading]="nzLoading" (ngModelChange)="onChange($event)"
            (nzOnSearch)="onSearchEvent($event)" [nzSuffixIcon]="suffixIconSearch">
            <nz-option *ngFor="let entity of legalEntities" [nzValue]="entity.companyName"
              [nzLabel]="entity.companyName"></nz-option>
          </nz-select>
          <ng-template #suffixIconSearch>
            <span nz-icon nzType="search" nzTheme="outline" (click)="onSearch()"></span>
          </ng-template>
          <nz-tag *ngFor="let selectedItem of selectedEntity" nzMode="closeable" style="margin-top: 5px;"
            (nzOnClose)="onCloseTag(selectedItem)">{{selectedItem}}</nz-tag>
        </nz-form-control>
      </nz-form-item>
      <nz-form-label [@formLabelAnimation] *ngIf="legalEntitiesFormArray.controls.length > 0">Answer the following questions for the entities being dissolved</nz-form-label>
      <div class="entity-dissolution-questions">
        <ng-container formArrayName="legalEntitiesQuestionsFormArray">
          <div *ngFor="let proposedSlateForm of legalEntitiesQuestionsFormArray.controls; let i = index">
            <div [formGroupName]="i">
              <nz-collapse [nzGhost]="true">
                  <nz-collapse-panel [nzHeader]="legalEntitiesQuestionsFormArray.at(i).value.companyName" [nzActive]="true">
                  <nz-form-item>
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Have active registrations in other
                      states/countries?</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <nz-switch formControlName="activeRegistration" (ngModelChange)="onChangeActiveReg(proposedSlateForm)"></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item *ngIf="legalEntitiesQuestionsFormArray.at(i).value.activeRegistration">
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Comments</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <textarea nz-input placeholder="Active Registrations" formControlName="activeRegistrationComments"
                        [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Have any employees?</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <nz-switch formControlName="anyEmployees"></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item *ngIf="legalEntitiesQuestionsFormArray.at(i).value.anyEmployees">
                    <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <textarea nz-input placeholder="Any Employees" formControlName="anyEmployeesComments"
                        [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Have active bank accounts?</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <nz-switch formControlName="activeBankaccount"></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item *ngIf="legalEntitiesQuestionsFormArray.at(i).value.activeBankaccount">
                    <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <textarea nz-input placeholder="Active Bank Accounts" formControlName="activeBankaccountComments"
                        [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Have subsidiaries?</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <nz-switch formControlName="subsidiaries"></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item *ngIf="legalEntitiesQuestionsFormArray.at(i).value.subsidiaries">
                    <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <textarea nz-input placeholder="Subsidiaries" formControlName="subsidiariesComments"
                        [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                    </nz-form-control>
                  </nz-form-item>
                </nz-collapse-panel>
              </nz-collapse>
            </div>
          </div>
        </ng-container>
      </div>
<nz-form-item *ngIf="showComments">
  <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Comments</nz-form-label>
  <nz-form-control [@formControlAnimation]>
    <textarea nz-input placeholder="Comments" formControlName="comments"
      [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
  </nz-form-control>
</nz-form-item>
</div>
<div nz-col nzSpan="12" nzOffset="1">
  <ng-container formArrayName="legalEntitiesFormArray">
    <div nz-row nzGutter="8" [nzAlign]="'middle'" *ngIf="legalEntitiesFormArray.controls.length > 0">
      <div nz-col nzSpan="1">
        <button nz-button nzType="primary" nzShape="circle" (click)="prevSlide()" [disabled]="current === 0"><span
            nz-icon nzType="left" nzTheme="outline" style="color: white;"></span></button>
      </div>
      <div nz-col nzSpan="19" nzOffset="1">
        <nz-carousel [nzEffect]="'scrollx'" [nzDots]="false" #carouselLegalEntity style="height: 350px;"
          [nzEnableSwipe]="false">
          <div nz-carousel-content *ngFor="let legalEntityForm of legalEntitiesFormArray.controls; let i = index">
            <nz-descriptions [nzColumn]="1">
              <nz-descriptions-item
                nzTitle="Company Code Number">{{legalEntityForm.get('ccn')?.value}}</nz-descriptions-item>
              <nz-descriptions-item
                nzTitle="Company Name">{{legalEntityForm.get('companyName')?.value}}</nz-descriptions-item>
              <nz-descriptions-item
                nzTitle="Jurisdiction">{{legalEntityForm.get('jurisdiction')?.value}}</nz-descriptions-item>
              <nz-descriptions-item
                nzTitle="Ownership Details">{{legalEntityForm.get('ownershipDetail')?.value}}</nz-descriptions-item>
            </nz-descriptions>
          </div>
        </nz-carousel>
      </div>
      <div nz-col nzSpan="1">
        <button nz-button nzType="primary" nzShape="circle" (click)="nextSlide()"
          [disabled]="current === legalEntitiesFormArray.length -1"><span nz-icon nzType="right" nzTheme="outline"
            style="color: white;"></span></button>
      </div>
    </div>
  </ng-container>
</div>
</div>
</form>