import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { CalAngularService } from '@cvx/cal-angular';
import { UserDetails } from '../interfaces/User';
import { MsGraphService } from './ms-graph';
import { P30APIService } from './api';
import { GeneralDetails } from 'src/app/core/interfaces/GeneralDetails';
import { Region } from 'src/app/core/interfaces/Regions';
import { BusinessSegmentUnit } from 'src/app/core/interfaces/BusinessSegmentUnits';
import { DataService } from 'src/app/core/services/data_service';
import { Observable, map } from 'rxjs';
import { HttpResponse } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class DataServiceProposal {

    regions: Region[] = [];
    businessSegmentUnits: BusinessSegmentUnit[] = [];
    requestedForUser: UserDetails[] = [];
    proposalDetail: GeneralDetails = {
        requestedFor: '',
        requestedBy: '',
        proposalId: '',
        urgent: false,
        requestTitle: '',
        businessSegmentUnit: '',
        region: '',
        urgencyExplanation: '',
        opco:''
    };

    constructor(private session: SessionStorageService, private authService: CalAngularService,
        private graph: MsGraphService,
        private apiService: P30APIService,
        private dataservice: DataService,) { }

    getAccessToken() {
        this.authService.getAADToken().subscribe(result => {
            this.session.setItem('token', JSON.parse(JSON.stringify(result)));
        });
    }

    getBusinessSegmentUnits() {
        this.apiService.getBusinessSegmentUnits().subscribe({
            next: (out) => {
                this.businessSegmentUnits = [...out.body]
            },
            error: (err) => {
                console.log("Unable to retrieve business segment units", err);
            },
            complete: () => {

            }
        });

    }
    getRegions() {
        this.apiService.getRegions().subscribe({
            next: (out) => {
                this.regions = [...out.body];
            },
            error: (err) => {
                console.log("Unable to retrieve regions", err);

            },
            complete: () => {

            }
        });
    }

    getProposalInfo(proposalId: string): Observable<HttpResponse<any>> {
        return this.apiService.getProposal(proposalId).pipe(
            map(proposalInfo => {
                return proposalInfo;
            })
        );
    }
}
