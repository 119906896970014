<p><strong>Entity Information</strong></p>
<form  nz-form  [formGroup]="acquisitionEntityQuestionsForm">
    <div nz-row nzGutter="8">
        <div nz-col nzSpan="2">
            <nz-form-item>
                <nz-form-control [@formControlAnimation]>
                    <label nz-checkbox [(ngModel)]="asset" (ngModelChange)="onAssetChange($event)" formControlName="asset">Asset</label>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="4" nzOffset="1">
            <nz-form-item>
                <nz-form-control [@formControlAnimation]>
                    <label nz-checkbox [(ngModel)]="ownershipInterest" (ngModelChange)="onOwnershipChange($event)" formControlName="ownershipInterest">Ownership Interest</label>
                </nz-form-control>
            </nz-form-item>
        </div>
      </div>
</form>

<div *ngIf="asset" class="form-content">
    <form [nzLayout]="'vertical'" nz-form  [formGroup]="acquisitionAssetForm">
        <div nz-row nzGutter="8" >
            <div nz-col nzSpan="12">
                <div nz-row nzGutter="8">
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Seller Entity</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <input nz-input nzPlaceHolder="Seller Entity" formControlName="sellerEntity"/>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row nzGutter="8">
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Approximate Cost</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <input nz-input nzPlaceHolder="Approximate Cost" formControlName="approximateCost"/>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
            <div nz-col nzSpan="8" nzOffset="1">
                <nz-form-item>
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Description of Asset</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                        <textarea nz-input placeholder="Asset Description" formControlName="assetDescription"
                        [nzAutosize]="{ minRows: 5, maxRows: 10 }" ></textarea>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
</div>
<div *ngIf="ownershipInterest" class="form-content">
    <form [nzLayout]="'vertical'" nz-form  [formGroup]="acquisitionOwnershipInterestForm">
        <div nz-row nzGutter="8" >
            <div nz-col nzSpan="11">
                <nz-form-item>
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Name of Entity Being Acquired</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                        <input nz-input nzPlaceHolder="Name of Entity Being Acquired" formControlName="nameOfEntityBeingAcquired"/>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="11" nzOffset="1">
                <nz-form-item>
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Jurisdiction</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                        <input nz-input nzPlaceHolder="Jurisdiction" formControlName="jurisdiction"/>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <p><strong>Answer the following questions for the entity being acquired:</strong></p>
        <div nz-row nzGutter="8" >
            <div nz-col nzSpan="5">
                <nz-form-label [@formLabelAnimation]><label class="mandate asteric-2">*</label> Have active registrations in other states/countries?</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                    <nz-switch [(ngModel)]="activeRegistrations" formControlName="haveActiveRegistrations" ></nz-switch>
                </nz-form-control>
            </div>
            <div nz-col nzSpan="5" nzOffset="1" *ngIf="activeRegistrations">
                <nz-form-item>
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Comments</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                        <textarea nz-input placeholder="Comments" formControlName="haveActiveRegistrationsComments"
                        [nzAutosize]="{ minRows: 3, maxRows: 10 }" ></textarea>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="5" nzOffset="1">
                <nz-form-label [@formLabelAnimation]><label class="mandate asteric-4">*</label> Amount of Initial Investment (Please note that any additional investments will require a separate Policy 161) </nz-form-label>
                <nz-form-control [@formControlAnimation]>
                    <nz-switch [(ngModel)]="additionalContribution" formControlName="additionalContributions" ></nz-switch>
                </nz-form-control>
            </div>
            <div nz-col nzSpan="5" nzOffset="1" *ngIf="additionalContribution">
                <nz-form-item>
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Comments</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                        <textarea nz-input placeholder="Comments" formControlName="additionalContributionComments"
                        [nzAutosize]="{ minRows: 3, maxRows: 10 }" ></textarea>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <br>
            <div nz-col nzSpan="11" nzOffset="1"></div>
            <div nz-col nzSpan="5"  class="m-t-b-5">
              <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Cash/Non-Cash </nz-form-label>
            </div>
            <div nz-col nzSpan="5" nzOffset="1" id="typeOfInvestment">
              <nz-form-control [@formControlAnimation] [ngClass]="isOwnerFormError == true ? 'showOwnerFormError' : ''">
                <nz-select formControlName="investmentMethod" [(ngModel)]="selectedInvestmentMethod"
                    nzPlaceHolder="Select Investment Type">
                    <nz-option *ngFor="let type of investmentType" [nzValue]="type.name"
                        [nzLabel]="type.name"></nz-option>
                </nz-select>
              </nz-form-control>
            </div>
        </div>

        <div nz-row nzGutter="8" >
            <div nz-col nzSpan="5">
                <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Have subsidiaries?</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                    <nz-switch [(ngModel)]="subsidiaries" formControlName="haveSubsidiaries" ></nz-switch>
                </nz-form-control>
            </div>
            <div nz-col nzSpan="5" nzOffset="1" *ngIf="subsidiaries">
                <nz-form-item>
                    <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                        <textarea nz-input placeholder="Comments" formControlName="haveSubsidiariesComments"
                        [nzAutosize]="{ minRows: 3, maxRows: 10 }" ></textarea>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="5" nzOffset="1">
                <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Have bank accounts?</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                    <nz-switch [(ngModel)]="bankAccount" formControlName="needBankAccounts" ></nz-switch>
                </nz-form-control>
            </div>
            <div nz-col nzSpan="5" nzOffset="1" *ngIf="bankAccount">
                <nz-form-item>
                    <nz-form-label [@formLabelAnimation]> Comments</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                        <textarea nz-input placeholder="Comments" formControlName="needBankAccountComments"
                        [nzAutosize]="{ minRows: 3, maxRows: 10 }" ></textarea>
                    </nz-form-control>
                </nz-form-item>
            </div>

        </div>
    </form>
</div>
