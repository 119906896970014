
    <nz-result
      nzTitle="Sorry, the submit failed."
      nzStatus="error"
      nzSubTitle="Please check and modify the following information before resubmitting."
    >
      <div nz-result-extra>
        <button nz-button (click)="updateAppError()">Try Again</button>
      </div>
    </nz-result>
  