<form [nzLayout]="'vertical'" nz-form  [formGroup]="dissolutionProposalAttachmentForm">
  <div nz-row nzGutter="8">
    <div nz-col nzSpan="13">   
      <h5 style="margin: 0 334px 0px 0;">Dissolution Attachments</h5>  
      <span class="custom-header"><label class="mandate">*</label>For Upstream companies, has the Upstream Dissolution Checklist been uploaded?
        <nz-form-label class="attachmentTip" [@formLabelAnimation] nzTooltipTitle="Please toggle and click or drag file to save" ></nz-form-label></span>
      <nz-form-item nz-row style="flex-direction:row !important;">
          <nz-form-control [@formControlAnimation]>
            <nz-switch formControlName="upstreamChecklistuploaded" [(ngModel)]="upstreamChecklistuploaded" [ngClass]="attachmentUploadClass"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      <div >
        <div nz-row>
         <nz-upload nzType="drag" [nzMultiple]="true" [nzCustomRequest]="nzAction" [nzShowUploadList]="false" 
         >
            <p class="ant-upload-drag-icon">
              <span nz-icon nzType="inbox"></span>
            </p>
            <p class="ant-upload-text">Click or drag file to this area to upload</p>
            <p class="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
            </p>
          </nz-upload> 
          <nz-form-label class="attachmentTip" [@formLabelAnimation] nzTooltipTitle="File size limit is 4GB." ></nz-form-label>            
        </div>
         <nz-space nzDirection="vertical" nzSize="large"></nz-space>
        <div nz-row nzGutter="8" *ngFor="let file of files">
          <div nz-col nzSpan="22">
            <a [href]="file.filePath"><span nz-icon nzType="paper-clip" nzTheme="outline"></span>&nbsp;{{file.fileName}}</a>
          </div>
          <div nz-col nzOffset="1" nzSpan="1">   
            <a (click)="deleteAttachment(file.fileName)"><span nz-icon nzType="delete" nzTheme="outline" style="color: red;" ></span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
