import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AcquisitionEntityQuestion } from '../interfaces/EntityIndicatorQuestion';


@Injectable({
  providedIn: 'root'
})
export class DataServiceEntityQuestions {
  ownerShipFormError$ = new BehaviorSubject<[]>([]);
  investmentType = [
    { name: 'Cash', id: 1 },
    { name: 'Non-Cash', id: 2 },
  ]

  constructor(private session: SessionStorageService, private apiService: P30APIService, private message: NzMessageService) { }

  isOwnerShipFError(state: []) {
    this.ownerShipFormError$.next(state);
  }

  getEntityIndicatorQuestions(): Observable<HttpResponse<any>> {
    return this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID)).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );
  }

  addAsset(form: UntypedFormGroup, asset: boolean): Observable<HttpResponse<any>> {
    console.log("addAsset form => ", form)
    const data = {
      "requestId": Number(this.session.getItem(REQUEST_ID)),
      "legalEntityId": null,
      "assests": String(asset),
      "ownershipInterest": "",
      "nameOfEntityBeingAcquired": "",
      "sellerEntity": form.controls["sellerEntity"].value,
      "approximateCost": form.controls["approximateCost"].value,
      "descriptionOfAsset": form.controls["assetDescription"].value,
      "jurisdiction": "",
      "haveActiveRegistrationsInOtherStatesCountries": false,
      "commentsForHavingActiveRegistrations": "",
      "haveSubsidiaries": false,
      "commentsForHavingSubsidiaries": "",
      "needBankAccounts": false,
      "commentsForNeedingBankAccounts": "",
      "willThereBeaNeedForAdditionalContributionsAfterInitialInvestment": false,
      "commentsForNeedingAdditionalContributions": "",
      "haveAnyEmployees": false,
      "commentsForHavingEmployees": "",
      "haveActiveBankAccounts": false,
      "commentsForHavingBankAccounts": "",
      "willTheNewEntityBeRegisteredInOtherStatesCountries": false,
      "commentsForNewEntityBeingRegistered": "",
      "willTheSurvivingEntityChangeItsNameAfterTheMerger": false,
      "commentsForSurvivingEntityChangingName": "",
      "entityType": "",
      "typeOfSale": "",
      "investmentMethod": ""
    }
    return this.apiService.createEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), data).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );
  }

  addOwnershipInterest(form: UntypedFormGroup, ownsershipInterest: boolean): Observable<HttpResponse<any>> {
    const data = {
      "requestId": Number(this.session.getItem(REQUEST_ID)),
      "legalEntityId": null,
      "assests": "",
      "ownershipInterest": String(ownsershipInterest),
      "nameOfEntityBeingAcquired": form.controls["nameOfEntityBeingAcquired"].value,
      "sellerEntity": "",
      "approximateCost": "",
      "descriptionOfAsset": "",
      "jurisdiction": form.controls["jurisdiction"].value,
      "haveActiveRegistrationsInOtherStatesCountries": form.controls["haveActiveRegistrations"].value,
      "commentsForHavingActiveRegistrations": form.controls["haveActiveRegistrationsComments"].value,
      "haveSubsidiaries": form.controls["haveSubsidiaries"].value,
      "commentsForHavingSubsidiaries": form.controls["haveSubsidiariesComments"].value,
      "needBankAccounts": form.controls["needBankAccounts"].value,
      "commentsForNeedingBankAccounts": form.controls["needBankAccountComments"].value,
      "willThereBeaNeedForAdditionalContributionsAfterInitialInvestment": form.controls["additionalContributions"].value,
      "commentsForNeedingAdditionalContributions": form.controls["additionalContributionComments"].value,
      "haveAnyEmployees": false,
      "commentsForHavingEmployees": "",
      "haveActiveBankAccounts": false,
      "commentsForHavingBankAccounts": "",
      "willTheNewEntityBeRegisteredInOtherStatesCountries": false,
      "commentsForNewEntityBeingRegistered": "",
      "willTheSurvivingEntityChangeItsNameAfterTheMerger": false,
      "commentsForSurvivingEntityChangingName": "",
      "entityType": "",
      "typeOfSale": "",
      "investmentMethod": form.controls["investmentMethod"]?.value
    }

    return this.apiService.createEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), data).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );

  }

  updateOwnershipInterest(entityIndicatoryQuestionId: number, form: UntypedFormGroup, ownershipInterest: boolean): Observable<HttpResponse<any>> {
    const data = {
      "requestId": Number(this.session.getItem(REQUEST_ID)),
      "legalEntityId": null,
      "assests": "",
      "ownershipInterest": String(ownershipInterest),
      "nameOfEntityBeingAcquired": form.controls["nameOfEntityBeingAcquired"].value,
      "sellerEntity": "",
      "approximateCost": "",
      "descriptionOfAsset": "",
      "jurisdiction": form.controls["jurisdiction"].value,
      "haveActiveRegistrationsInOtherStatesCountries": form.controls["haveActiveRegistrations"].value,
      "commentsForHavingActiveRegistrations": form.controls["haveActiveRegistrationsComments"].value,
      "haveSubsidiaries": form.controls["haveSubsidiaries"].value,
      "commentsForHavingSubsidiaries": form.controls["haveSubsidiariesComments"].value,
      "needBankAccounts": form.controls["needBankAccounts"].value,
      "commentsForNeedingBankAccounts": form.controls["needBankAccountComments"].value,
      "willThereBeaNeedForAdditionalContributionsAfterInitialInvestment": form.controls["additionalContributions"].value,
      "commentsForNeedingAdditionalContributions": form.controls["additionalContributionComments"].value,
      "haveAnyEmployees": false,
      "commentsForHavingEmployees": "",
      "haveActiveBankAccounts": false,
      "commentsForHavingBankAccounts": "",
      "willTheNewEntityBeRegisteredInOtherStatesCountries": false,
      "commentsForNewEntityBeingRegistered": "",
      "willTheSurvivingEntityChangeItsNameAfterTheMerger": false,
      "commentsForSurvivingEntityChangingName": "",
      "entityType": "",
      "typeOfSale": "",
      "investmentMethod": form.controls["investmentMethod"]?.value
    }
    return this.apiService.updateEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), entityIndicatoryQuestionId, data).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );

  }
  updateAsset(entityIndicatoryQuestionId: number, form: UntypedFormGroup, asset: boolean): Observable<HttpResponse<any>> {
    const data = {
      "requestId": Number(this.session.getItem(REQUEST_ID)),
      "legalEntityId": null,
      "assests": String(asset),
      "ownershipInterest": "",
      "nameOfEntityBeingAcquired": "",
      "sellerEntity": form.controls["sellerEntity"].value,
      "approximateCost": form.controls["approximateCost"].value,
      "descriptionOfAsset": form.controls["assetDescription"].value,
      "jurisdiction": "",
      "haveActiveRegistrationsInOtherStatesCountries": false,
      "commentsForHavingActiveRegistrations": "",
      "haveSubsidiaries": false,
      "commentsForHavingSubsidiaries": "",
      "needBankAccounts": false,
      "commentsForNeedingBankAccounts": "",
      "willThereBeaNeedForAdditionalContributionsAfterInitialInvestment": false,
      "commentsForNeedingAdditionalContributions": "",
      "haveAnyEmployees": false,
      "commentsForHavingEmployees": "",
      "haveActiveBankAccounts": false,
      "commentsForHavingBankAccounts": "",
      "willTheNewEntityBeRegisteredInOtherStatesCountries": false,
      "commentsForNewEntityBeingRegistered": "",
      "willTheSurvivingEntityChangeItsNameAfterTheMerger": false,
      "commentsForSurvivingEntityChangingName": "",
      "entityType": "",
      "typeOfSale": "",
      "investmentMethod": ""
    }
    return this.apiService.updateEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), entityIndicatoryQuestionId, data).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );
  }

  patchEntityQuestionsDataToFormGroup(eqForm: UntypedFormGroup, assetForm: UntypedFormGroup, oiForm: UntypedFormGroup) {
    this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        let entityQuestions = [];
        if (event.body) {
          entityQuestions = event.body;
          let asset;
          let ownershipInterest;
          if (entityQuestions[0].assests) {
            asset = JSON.parse(entityQuestions[0].assests.toLowerCase())
          } else {
            asset = false;
          }
          if (entityQuestions[0].ownershipInterest) {
            ownershipInterest = JSON.parse(entityQuestions[0].ownershipInterest.toLowerCase())
          } else {
            ownershipInterest = false;
          }
          eqForm.patchValue({
            asset: asset,
            ownershipInterest: ownershipInterest
          });
          if (asset) {
            assetForm.patchValue({
              entityIndicatorQuestionId: entityQuestions[0].entityQuestionsId,
              sellerEntity: entityQuestions[0].sellerEntity,
              approximateCost: entityQuestions[0].approximateCost,
              assetDescription: entityQuestions[0].descriptionOfAsset
            });
            oiForm.reset();
          } else if (ownershipInterest) {
            oiForm.patchValue({
              entityIndicatorQuestionId: entityQuestions[0].entityQuestionsId,
              nameOfEntityBeingAcquired: entityQuestions[0].nameOfEntityBeingAcquired,
              jurisdiction: entityQuestions[0].jurisdiction,
              haveActiveRegistrations: entityQuestions[0].haveActiveRegistrationsInOtherStatesCountries,
              haveActiveRegistrationsComments: entityQuestions[0].commentsForHavingActiveRegistrations,
              haveSubsidiaries: entityQuestions[0].haveSubsidiaries,
              haveSubsidiariesComments: entityQuestions[0].commentsForHavingSubsidiaries,
              additionalContributions: entityQuestions[0].willThereBeANeedForAdditionalContributionsAfterInitialInvestment,
              additionalContributionComments: entityQuestions[0].commentsForNeedingAdditionalContributions,
              needBankAccounts: entityQuestions[0].needBankAccounts,
              needBankAccountComments: entityQuestions[0].commentsForNeedingBankAccounts,
              investmentMethod: entityQuestions[0].investmentMethod,
            });
            assetForm.reset();
          }
        }
      },
      error: (err) => {
        console.log("Unable to get entity question from the database table ", err);
      },
      complete: () => {
      }
    });
  }

  async submitEntityQuestions(form: UntypedFormGroup, assetForm: UntypedFormGroup, ownershipInterestForm: UntypedFormGroup, tabClass: string): Promise<boolean> {
    const entityQuestionSubmisionResult: any = await new Promise<boolean>((resolved) => {
      this.getEntityIndicatorQuestions().subscribe({
        next: (event) => {
          resolved(event.body);
        }
      });
    })
    if (entityQuestionSubmisionResult) {
      let entityQuestions = []
      let asset;
      let ownershipInterest;
      entityQuestions = [...entityQuestionSubmisionResult];
      asset = form.value.asset;
      ownershipInterest = form.value.ownershipInterest;
      return await this.entityIndicatorQuestionsIfAssets(assetForm, entityQuestions, asset, ownershipInterest, ownershipInterestForm);
    } else {
      return await this.entityIndicatorQuestionsElseBlock(form, assetForm, ownershipInterestForm, tabClass);
    }


  }

  async entityIndicatorQuestionsIfAssets(assetForm: any, entityQuestions: any, asset: any, ownershipInterest: any, ownershipInterestForm: any): Promise<boolean> {
    return await new Promise((resolved) => {
      if (asset) {
        if (assetForm.valid) {
          this.updateAsset(entityQuestions[0].entityQuestionsId, assetForm, asset).subscribe({
            next: (event) => {
              resolved(true);
            }
          });

        } else {
          Object.values(assetForm.controls).forEach((control: any) => {
            if (control.invalid) {
              control.markAsDirty();
              control.updateValueAndValidity({ onlySelf: false });
            }
          });
          resolved(false);
        }
      } else if (ownershipInterest) {
        const result = this.getOwnershipInterestFormValidResponse(ownershipInterestForm, entityQuestions, ownershipInterest);
        resolved(result);
      }
    })

  }

  async getOwnershipInterestFormValidResponse(ownershipInterestForm: any, entityQuestions: any, ownershipInterest: any,) {
    return await new Promise<boolean>((resolved) => {
      if (ownershipInterestForm.valid) {
        this.updateOwnershipInterest(entityQuestions[0].entityQuestionsId, ownershipInterestForm, ownershipInterest).subscribe({
          next: (event) => {
            return;
          }
        });
        resolved(true);
      } else {
        Object.values(ownershipInterestForm.controls).forEach((control: any) => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: false });
          }
        });
        resolved(false);
      }
    })
  }

  async entityIndicatorQuestionsElseBlock(form: UntypedFormGroup, assetForm: UntypedFormGroup, ownershipInterestForm: UntypedFormGroup, tabClass: string): Promise<boolean> {
    return await new Promise((resolved) => {
      if (form.controls["asset"].value) {
        this.addAsset(assetForm, form.controls["asset"].value).subscribe({
          next: (event) => {
            resolved(true);
          },
          error: (err) => {
            console.log("Unable to save asset", err);
            resolved(false);
          },
          complete: () => {

          }
        })
      } else if (form.controls["ownershipInterest"].value) {
        this.addOwnershipInterest(ownershipInterestForm, form.controls["ownershipInterest"].value).subscribe({
          next: (event) => {
            resolved(true);
          },
          error: (err) => {
            console.log("Unable to save ownership interest", err);
            this.isOwnerShipFError(err.error.detail[0].loc);
            resolved(false);
          },
          complete: () => { }
        });
      } else if (assetForm.valid && ownershipInterestForm.valid) {
        resolved(true);
      }
    })

  }

  reviewAndValidateAcquisitionEntityQuestions(form: UntypedFormGroup): Promise<boolean> {
    const assetControlValue = form.value.asset;
    const ownershipInterestControlValue = form.value.ownershipInterest;
    if (assetControlValue || ownershipInterestControlValue) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

  reviewAndValidateAcquisitionAssetDetails(form: UntypedFormGroup, assetForm: UntypedFormGroup): Promise<boolean> {
    if (form.controls["asset"].value) {
      const sellerEntityControl = assetForm.controls["sellerEntity"];
      sellerEntityControl.setValidators([Validators.required]);
      sellerEntityControl.markAsDirty();
      sellerEntityControl.updateValueAndValidity();
      const approximateCostControl = assetForm.controls["approximateCost"];
      approximateCostControl.setValidators([Validators.required]);
      approximateCostControl.markAsDirty();
      approximateCostControl.updateValueAndValidity();
      const assetDescriptionControl = assetForm.controls["assetDescription"];
      assetDescriptionControl.setValidators([Validators.required]);
      assetDescriptionControl.markAsDirty();
      assetDescriptionControl.updateValueAndValidity();
      return Promise.resolve(assetForm.valid);
    } else {
      return Promise.resolve(assetForm.valid);
    }
  }
  reviewAndValidateAcquisitionOwnershipInterestDetails(form: UntypedFormGroup, ownershipInterestForm: UntypedFormGroup): Promise<boolean> {
    if (form.controls["ownershipInterest"].value) {
      const nameOfEntityBeingAcquiredControl = ownershipInterestForm.controls["nameOfEntityBeingAcquired"];
      nameOfEntityBeingAcquiredControl.setValidators([Validators.required]);
      nameOfEntityBeingAcquiredControl.markAsDirty();
      nameOfEntityBeingAcquiredControl.updateValueAndValidity();
      const jurisdictionCostControl = ownershipInterestForm.controls["jurisdiction"];
      jurisdictionCostControl.setValidators([Validators.required]);
      jurisdictionCostControl.markAsDirty();
      jurisdictionCostControl.updateValueAndValidity();
      const haveActiveRegistrationsControl = ownershipInterestForm.controls["haveActiveRegistrations"];
      if (haveActiveRegistrationsControl.value) {
        const haveActiveRegistrationsCommentsControl = ownershipInterestForm.controls["haveActiveRegistrationsComments"];
        haveActiveRegistrationsCommentsControl.setValidators([Validators.required]);
        haveActiveRegistrationsCommentsControl.markAsDirty();
        haveActiveRegistrationsCommentsControl.updateValueAndValidity();
      }
      
      const haveAdditionalContributionControl = ownershipInterestForm.controls["additionalContributions"];
      if (haveAdditionalContributionControl.value) {
        const haveAdditionalContributionCommentsControl = ownershipInterestForm.controls["additionalContributionComments"];
        haveAdditionalContributionCommentsControl.setValidators([Validators.required]);
        haveAdditionalContributionCommentsControl.markAsDirty();
        haveAdditionalContributionCommentsControl.updateValueAndValidity();
      }
      const haveInvestmentMethodControl = ownershipInterestForm.controls["investmentMethod"];
      haveInvestmentMethodControl.setValidators([Validators.required]);
      haveInvestmentMethodControl.markAsDirty();
      haveInvestmentMethodControl.updateValueAndValidity();
      return Promise.resolve(ownershipInterestForm.valid);
    } else {
      return Promise.resolve(ownershipInterestForm.valid);
    }
  }

//##Seletion entity
addDissolutionEntityQuestion(data:any): Observable<HttpResponse<any>>{
  return this.apiService.createEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), data).pipe(
    map(entityQuestions =>{
      return entityQuestions;
    })
  );

}

updateDissolutionEntityQuestion(entityIndicatoryQuestionId: number, data:any): Observable<HttpResponse<any>>{
  return this.apiService.updateEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), entityIndicatoryQuestionId,data).pipe(
    map(entityQuestions =>{
      return entityQuestions;
    })
  );
}

}

