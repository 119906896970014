<form [formGroup]="legalEntityOwnershipRestructuringForm">
    <p><strong>Legal Entity(ies)</strong></p>
    <div class="entityContainer">
      <div nz-col nzSpan="11">
        <nz-form-item class="ownershipRLegalEntity">
          <nz-form-label [@formLabelAnimation]
            nzTooltipTitle="Comments will be required if Legal Entity is N/A."><label class="mandate">*</label>
            Legal Entity(ies)
          </nz-form-label>
          <nz-form-control [@formControlAnimation] [nzErrorTip]="getErrorTipForSellerLegalEntity('entityName')">
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Search by Active CCN or Company Name"
              formControlName="entityName" [(ngModel)]="selectedLegalentity" (ngModelChange)="onEntityChange($event)"
              (nzOnSearch)="onEntitySearchEvent($event)" [nzSuffixIcon]="suffixIconSearch" [nzLoading]="nzLoading">
              @for(list of listofEntities;track list.ccn){
              <nz-option [nzValue]="list.companyName" [nzLabel]="list.companyName"></nz-option>
              }
            </nz-select>
            <ng-template #suffixIconSearch>
              <span nz-icon nzType="search" nzTheme="outline" (click)="onSearchEntity()"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        @if(isCommentsVisible) {
          <nz-form-item class="ownershipRLegalEntity">
            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input placeholder="Comments" formControlName="entityComments"
                [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        }
      </div>
      <div nz-col nzSpan="12" nzOffset="2">
        <ng-container *ngTemplateOutlet="
              entityDetails;
              context: { $implicit: 'Legal Entity', data: selectedLegalEntityDetails, showHide: isEntityDetailsVisible }
            ">
        </ng-container>
      </div>
    </div>
    <div nz-col nzSpan="12" id="legalSummaryField">
      <nz-form-item style="display: block;">
        <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Summary of Legal Entity(ies) activites</nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <textarea nz-input placeholder="Comments" formControlName="summaryLegalActivities"
            [nzAutosize]="{ minRows: 5, maxRows: 10 }" ></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
  
  <ng-template #entityDetails let-name let-data="data" let-showHide="showHide">
    @if(showHide) {
    <div nz-row nzGutter="8" [nzAlign]="'middle'" id="entityInfo">
      <div nz-col nzSpan="15" nzOffset="1">
        <nz-carousel [nzEffect]="'scrollx'" [nzDots]="false" #carouselLegalEntity style="height: 300px"
          [nzEnableSwipe]="false">
          <div nz-carousel-content *ngFor="let list of data">
            <nz-descriptions [nzColumn]="1">
              <nz-descriptions-item nzTitle="Company Code Number">
                {{list.ccn}}
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Company Name">
                {{list.companyName }}
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Jurisdiction">
                {{list.jurisdiction}}
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Ownership Details">
                {{list.ownershipDetail}}
              </nz-descriptions-item>
            </nz-descriptions>
          </div>
        </nz-carousel>
      </div>
      <div class="entityType">
        <p>{{ name }}</p>
      </div>
    </div>
    }
  </ng-template>
  