<div class="row">
    <nz-breadcrumb>
        <nz-breadcrumb-item>Dashboard</nz-breadcrumb-item>
        <nz-breadcrumb-item>Dashboard</nz-breadcrumb-item>
    </nz-breadcrumb>
</div>
<br>
<div class="row">
    <app-dashboard-grid></app-dashboard-grid>
    <nz-card nzTitle="Total Requests | In Progress vs. Completed" [nzExtra]="extraTemplate">
        <div nz-row [nzGutter]="24">
            <div nz-col class="gutter-row" [nzSpan]="4"><div></div></div>
            <div nz-col class="gutter-row" [nzSpan]="8"><div class="inner-box"> <div echarts [options]="barOptions"></div></div></div>
            <div nz-col class="gutter-row" [nzSpan]="8"><div class="inner-box"><div echarts [options]="options"></div></div></div>
          </div>        
    </nz-card>
    <ng-template #extraTemplate>
        <span nz-icon nzType="pie-chart" nzTheme="twotone" nzTwotoneColor="#0066b2"></span>
      </ng-template>
</div>