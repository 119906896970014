<p><strong>Contacts</strong></p>
<form [nzLayout]="'vertical'" nz-form [formGroup]="contactsForm">
  <div nz-row nzGutter="8">
    <div nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label [@formLabelAnimation]
          nzTooltipTitle="Coordinator will be defaulted based on the selected region."
          [nzTooltipIcon]="tooltipIcon"><label class="mandate">*</label> Coordinator</nz-form-label>
        <nz-form-control [nzErrorTip]="getErrorTip('coordinator')" [@formControlAnimation]>
          <nz-select nzShowSearch nzPlaceHolder="Select one or more Coordinators" nzMode="multiple"
            [(ngModel)]="contactsFormModel.coordinator" formControlName="coordinator"
            (ngModelChange)="onCoordinatorChange($event)" [nzSuffixIcon]="usrTemplate"
            (nzOnSearch)="searchCoordinatorUser($event)">
            <nz-option-group *ngFor="let coordinator of coordinators" [nzLabel]="coordinator.mail">
              <nz-option [nzValue]="coordinator.fullName" [nzLabel]="coordinator.fullName">
              </nz-option>
            </nz-option-group>
          </nz-select>
          <ng-template #usrTemplate>
            <span nz-icon nzType="user" nzTheme="outline"></span>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row nzGutter="8">
    <div nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label [@formLabelAnimation]>Additional CC's For Notifications</nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <nz-select nzShowSearch nzPlaceHolder="Select one or more Additional CC(s)" nzMode="multiple"
            [(ngModel)]="contactsFormModel.additionalCc" formControlName="additionalCcsForNotifications"
            (nzOnSearch)="searchAdditionalCcUser($event)" (ngModelChange)="onAdditionalCcChange($event)"
            [nzSuffixIcon]="usrTemplate2">
            <nz-option-group *ngFor="let addCC of additionalCCs" [nzLabel]="addCC.mail">
              <nz-option [nzValue]="addCC.fullName" [nzLabel]="addCC.fullName"></nz-option>
            </nz-option-group>
          </nz-select>
          <ng-template #usrTemplate2>
            <span nz-icon nzType="user" nzTheme="outline"></span>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <!-- IFC Required toggle button -->
  @if(proposalStatusName !== 'draft'){
  <div nz-row nzGutter="8" class="pl-1">
    <nz-form-item nz-row style="flex-direction:row !important;">
      <nz-form-label [@formLabelAnimation] class="pt-1 pb-0 pr-4">IFC Required?</nz-form-label>
      <nz-form-control [@formControlAnimation]>
        <nz-switch [(ngModel)]="contactsFormModel.ifcRequired" formControlName="ifcRequired"
          (ngModelChange)="ifcRequiredToggleBTN($event)"></nz-switch>
      </nz-form-control>
    </nz-form-item>
  </div>
  }
</form>
