<nz-card style="width:100%;" nzTitle="Update User Role">
    <div class="edit-user-form">
        <form nz-form [formGroup]="validateEditUserForm">
            <div nz-row nzGutter="8">
              <div nz-col [nzSpan]="8" >
                <nz-form-item>
                    <nz-form-label>first name</nz-form-label>
                    <nz-form-control>
                      <input
                        nz-input
                        formControlName="firstName"
                      />
                    </nz-form-control>
                  </nz-form-item>
              </div>
              <div nz-col [nzSpan]="8" >
                <nz-form-item>
                    <nz-form-label>last name</nz-form-label>
                    <nz-form-control>
                      <input
                        nz-input
                        formControlName="lastName"
                      />
                    </nz-form-control>
                  </nz-form-item>
              </div>
            </div>
            <div nz-row>
                <div nz-col [nzSpan]="16" >
                    <nz-form-item>
                        <nz-form-label nzRequired>role title</nz-form-label>
                        <nz-form-control>
                            <nz-select nzAllowClear nzPlaceHolder="select user role" formControlName="userRoleTitle" >
                                <nz-option *ngFor="let role of userRoles" [nzValue]="role.roleTitle" [nzLabel]="role.roleTitle"></nz-option>
                            </nz-select>
                        </nz-form-control>
                      </nz-form-item>

                </div>
            </div>
            <div nz-row nzGutter="8">
                <div nz-col nzSpan="6" nzOffset="18">
                    <button nz-button [nzType]="'primary'" (click)="onSubmit()">Submit</button>
                    <nz-divider nzType="vertical"></nz-divider>
                    <a nz-button nzType="link" (click)="onCancel()">Cancel</a>
                </div>

            </div>
          </form>
    </div>

  </nz-card>

