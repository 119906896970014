import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpResponse } from '@angular/common/http';
import { LegalEntity } from '../interfaces/LegalEntities';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';

@Injectable({
  providedIn: 'root',
})

export class DataServiceOthersProposal {
  legalEntitySelected: LegalEntity[] = [];
  storeSelectionValue!: any[];
  isSaveForLaterClicked$ = new BehaviorSubject<boolean>(false);
  isOtherSummariesEmpty$ = new BehaviorSubject<boolean>(true);
  ownerShipLegalEntitySelectionForm: any;
  commonEntityList: LegalEntity[] = [
    { companyName: 'NA', ccn: 'NA' },
  ];

  constructor(
    private session: SessionStorageService,
    private fb: UntypedFormBuilder,
    private apiService: P30APIService,
    private message: NzMessageService
  ) {}

  checkBTNStatus(state: boolean) {
    this.isSaveForLaterClicked$.next(state);
  }

  othersProposalSumariesStatus(state: boolean) {
    this.isOtherSummariesEmpty$.next(state);
  }

  getOthersProposalOverview(): Observable<HttpResponse<any>> {
    return this.apiService
      .getProposalOverview(this.session.getItem(PROPOSAL_ID))
      .pipe(
        map((proposalOverview) => {
          return proposalOverview;
        })
      );
  }

  createOwnershipRestructuringProposalOverview(form: UntypedFormGroup): Observable<HttpResponse<any>> {
    let summary = this.session.getItem('OthersLegalActivityFormValue');
    const data = {
      requestId: this.session.getItem(REQUEST_ID),
      overviewOfProposal: form.controls['overviewOfProposal'].value,
      businessReasonForProposal: form.controls['businessReasonForProposal'].value,
      targetCompletionDate: '',
      targetSigningDate: '',
      nameOfShareHolderAndMore: '',
      summaryofLegalEntitiesActivities: summary != '' ? summary : '',
      targetEffectiveDate: form.controls['targetEffectiveDate'].value,
      proposedLegalEntityName: '',
      countryorStateofIncorporation: '',
    };
    return this.apiService
      .createProposalOverview(this.session.getItem(PROPOSAL_ID), data)
      .pipe(
        map((proposalOverview) => {
          return proposalOverview;
        })
      );
  }

  updateOwnershipRestructuringProposalOverview(form: UntypedFormGroup): Observable<HttpResponse<any>> {
    let summary = this.session.getItem('OthersLegalActivityFormValue');
    const data = {
      requestId: this.session.getItem(REQUEST_ID),
      overviewOfProposal: form.controls['overviewOfProposal'].value,
      businessReasonForProposal: form.controls['businessReasonForProposal'].value,
      targetCompletionDate: '',
      targetSigningDate: '',
      nameOfShareHolderAndMore: '',
      summaryofLegalEntitiesActivities: summary != '' ? summary : '',
      targetEffectiveDate: form.controls['targetEffectiveDate'].value,
      proposedLegalEntityName: '',
      countryorStateofIncorporation: '',
    };
    return this.apiService
      .updateProposalOverview(this.session.getItem(PROPOSAL_ID), data)
      .pipe(
        map((proposalOverview) => {
          return proposalOverview;
        })
      );
  }

  patchOthersOverviewDataToFormGroup(form: UntypedFormGroup, loader: boolean) {
    this.apiService
      .getProposalOverview(this.session.getItem(PROPOSAL_ID))
      .subscribe({
        next: (event) => {
          if (event.body) {
            form.patchValue({
              requestId: this.session.getItem(REQUEST_ID),
              summaryId: event.body[0].summaryId,
              overviewOfProposal: event.body[0].overviewOfProposal,
              businessReasonForProposal: event.body[0].businessReasonForProposal,
              targetCompletionDate: '',
              targetSigningDate: '',
              nameOfShareHolderAndMore: '',
              summaryofLegalEntitiesActivities: '',
              targetEffectiveDate: event.body[0].targetEffectiveDate,
              proposedLegalEntityName: '',
              countryorStateofIncorporation: '',
            });
          }
        },
        error: (err) => {
          console.log('Unable to get proposal overview from the database table ', err);
        },
        complete: () => {},
      });
  }

  createOwnershipRestructuringProposalOverviewWithFormData(form: UntypedFormGroup, loader: boolean) {
    this.createOwnershipRestructuringProposalOverview(form).subscribe({
      next: (event) => {
        this.getOthersProposalOverview().subscribe({
          next: (event) => {
            if (event.body) {
              form.patchValue({
                requestId: this.session.getItem(REQUEST_ID),
                summaryId: event.body[0].summaryId,
                overviewOfProposal: event.body[0].overviewOfProposal,
                businessReasonForProposal: event.body[0].businessReasonForProposal,
                targetCompletionDate: '',
                targetSigningDate: '',
                nameOfShareHolderAndMore: '',
                summaryofLegalEntitiesActivities: '',
                targetEffectiveDate: event.body[0].targetEffectiveDate,
                proposedLegalEntityName: '',
                countryorStateofIncorporation: '',
              });
            }
          },
        });
      },
      error: (err) => {
        console.log('unable to create proposal overview', err);
      },
      complete: () => {},
    });
  }

  updateOwnershipRestructuringProposalOverviewWithFormData(form: UntypedFormGroup, loader: boolean) {
    this.updateOwnershipRestructuringProposalOverview(form).subscribe({
      next: (event) => {},
      error: (err) => {
        console.log('unable to update proposal overview', err);
      },
      complete: () => {},
    });
  }

  submitProposalOverview(form: UntypedFormGroup, loader: boolean, tabClass: string): Promise<boolean> {
    console.log('submitProposalOverview', form);
    if (form.valid) {
      this.getOthersProposalOverview().subscribe({
        next: (event) => {
          if (event.body) {
            this.updateOwnershipRestructuringProposalOverviewWithFormData(form, loader);
          } else {
            this.createOwnershipRestructuringProposalOverviewWithFormData(form, loader);
          }
        },
      });
      return Promise.resolve(true);
    } else {
      Object.values(form.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return Promise.resolve(false);
    }
  }

  reviewAndValidateOthersProposalOverview(form: UntypedFormGroup, legalEntityOthersForm: UntypedFormGroup): Promise<boolean> {
    const overviewOfProposalControl = form.controls['overviewOfProposal'];
    overviewOfProposalControl.setValidators([Validators.required]);
    overviewOfProposalControl.markAsDirty();
    overviewOfProposalControl.updateValueAndValidity();
    const businessReasonForProposalControl = form.controls['businessReasonForProposal'];
    businessReasonForProposalControl.setValidators([Validators.required]);
    businessReasonForProposalControl.markAsDirty();
    businessReasonForProposalControl.updateValueAndValidity();
    const targetCompletionDateControl = form.controls['targetEffectiveDate'];
    targetCompletionDateControl.setValidators([Validators.required]);
    targetCompletionDateControl.markAsDirty();
    targetCompletionDateControl.updateValueAndValidity();
    const entitySummariesFormField = legalEntityOthersForm.controls['summaryLegalActivities'];
    entitySummariesFormField.setValidators([Validators.required]);
    entitySummariesFormField.markAsDirty();
    entitySummariesFormField.updateValueAndValidity();
    if (entitySummariesFormField.value) {
      this.othersProposalSumariesStatus(true);
    } else {
      this.othersProposalSumariesStatus(false);
    }
    return Promise.resolve(form.valid);
  }

  getLegalEntities(): Observable<HttpResponse<any>> {
    return this.apiService
      .getLegalEntities(this.session.getItem(PROPOSAL_ID))
      .pipe(
        map((legalEntities) => {
          return legalEntities;
        })
      );
  }

  reviewAndValidateOwnershipEntitySelection(form: UntypedFormGroup): Promise<boolean> {
    const entitySelectionControlValue = form.value.entityName;
    const commentsControlValue = form.value.entityComments;
    if (entitySelectionControlValue == 'NA') {
      if (commentsControlValue) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    } else {
      return Promise.resolve(true);
    }
  }

  async submitOthersLegalEntitySelection(form: UntypedFormGroup): Promise<boolean> {
    if (form.valid) {
      this.getLegalEntities().subscribe({
        next: (event) => {
          if (event.body) {
            this.createOrUpdateLegalEntityRecordIfRecordExist(form, event.body);
            this.updateEntitySelectionSummaryIfRecordExist(form);
          } else {
            this.createOrUpdateLegalEntityRecordIfNoRecord(form);
            this.createEntitySelectionSummaryIfNoRecords(form);
          }
        },
      });
      return Promise.resolve(true);
    } else {
      let status;
      this.isSaveForLaterClicked$.subscribe((result: boolean) => {
        switch (result) {
          case true: {
            status = true;
            this.saveForLater(form);
            break;
          }
          case false: {
            status = false;
            Object.values(form.controls).forEach((control) => {
              if (control.invalid) {
                control.markAsDirty();
                control.updateValueAndValidity({ onlySelf: true });
              }
            });
            break;
          }
        }
      });
      if (status) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    }
  }

  async createOrUpdateLegalEntityRecordIfRecordExist(form: UntypedFormGroup, event: any) {
    let commentsFormValue = form.controls['entityComments'].value;
    let entityNameValue = form.controls['entityName'].value;
    let leglaEntityPayload: LegalEntity = {
      ccn: event[0]['ccn'],
      companyName: entityNameValue,
      jurisdiction: event[0]['jurisdiction'],
      ownershipDetail: event[0]['ownershipDetail'],
      legalEntityComments: commentsFormValue != '' ? commentsFormValue : '',
      typeofLegalEntity: 'Default',
      requestId: Number(this.session.getItem(REQUEST_ID)),
    };
    if (event[0].legalEntityId) {
      leglaEntityPayload.legalEntityId = event[0]?.legalEntityId;
    }
    this.apiService
      .updateLegalEntity(
        this.session.getItem(PROPOSAL_ID),
        Number(event[0].legalEntityId),
        leglaEntityPayload
      )
      .subscribe((res: any) => {});
  }

  createOrUpdateLegalEntityRecordIfNoRecord(form: UntypedFormGroup) {
    let sessionValue = this.session.getItem('otherSelectionFormValue');
    let entityName = form.controls['entityName'].value;
    if (entityName == 'NA') {
      let leglaEntity: LegalEntity = {
        ccn: 'NA',
        companyName: 'NA',
        jurisdiction: '',
        ownershipDetail: '',
        legalEntityComments: form.controls['entityComments'].value,
        typeofLegalEntity: '',
      };
      this.createLegalEntity(leglaEntity).subscribe({
        next: (event) => {
          return;
        },
      });
    } else {
      let leglaEntity: LegalEntity = {
        ccn: this.legalEntitySelected[0]['ccn']?.toString(),
        companyName: this.legalEntitySelected[0]['companyName']?.toString(),
        jurisdiction: this.legalEntitySelected[0]['jurisdiction']?.toString(),
        ownershipDetail:
          this.legalEntitySelected[0]['ownershipDetail']?.toString(),
        legalEntityComments: '',
        typeofLegalEntity: 'Default',
      };
      this.createLegalEntity(leglaEntity).subscribe({
        next: (event) => {
          return;
        },
      });
    }
  }

  createLegalEntity(legalEntity: LegalEntity): Observable<HttpResponse<any>> {
    const data = {
      ccn: legalEntity.ccn,
      companyName: legalEntity.companyName,
      jurisdiction: legalEntity.jurisdiction,
      ownershipDetail: legalEntity.ownershipDetail,
      legalEntityComments: legalEntity.legalEntityComments
        ? legalEntity.legalEntityComments
        : null,
      typeofLegalEntity: 'Default',
      requestId: Number(this.session.getItem(REQUEST_ID)),
    };
    return this.apiService
      .createLegalEntity(this.session.getItem(PROPOSAL_ID), data)
      .pipe(
        map((legelaEntities) => {
          return legelaEntities;
        })
      );
  }

  createSummaryPayload(value: string) {
    const payload = {
      requestId: this.session.getItem(REQUEST_ID),
      overviewOfProposal: '',
      businessReasonForProposal: '',
      targetCompletionDate: '',
      targetSigningDate: '',
      nameOfShareHolderAndMore: '',
      summaryofLegalEntitiesActivities: value,
      targetEffectiveDate: '',
      proposedLegalEntityName: '',
      countryorStateofIncorporation: '',
    };
    return payload;
  }

  createEntitySelectionSummaryIfNoRecords(form: UntypedFormGroup): Observable<HttpResponse<any>> {
    let summaryValue = form.controls['summaryLegalActivities'].value;
    let payload = this.createSummaryPayload(summaryValue);
    return this.apiService
      .createProposalOverview(this.session.getItem(PROPOSAL_ID), payload)
      .pipe(
        map((proposalOverview) => {
          return proposalOverview;
        })
      );
  }

  updateEntitySelectionSummaryIfRecordExist(form: UntypedFormGroup): Observable<HttpResponse<any>> {
    let summaryValue = form.controls['summaryLegalActivities'].value;
    let payload = this.createSummaryPayload(summaryValue);
    return this.apiService
      .updateProposalOverview(this.session.getItem(PROPOSAL_ID), payload)
      .pipe(
        map((proposalOverview) => {
          return proposalOverview;
        })
      );
  }

  saveForLater(form: UntypedFormGroup) {
    this.getLegalEntities().subscribe({
      next: (event) => {
        if (event.body) {
          this.createOrUpdateLegalEntityRecordIfRecordExist(form, event.body);
          this.updateEntitySelectionSummaryIfRecordExist(form);
        } else {
          this.createOrUpdateLegalEntityRecordIfNoRecord(form);
          this.createEntitySelectionSummaryIfNoRecords(form);
        }
      },
    });
  }

  patchLegalEntitiesToFormGroup(loader: boolean, form: UntypedFormGroup) {
    this.getLegalEntities().subscribe({
      next: (event) => {
        if (event.body) {
          let entities: LegalEntity[] = [];
          entities = [...event.body];
          entities.forEach((item) => {
            if (item.ccn == 'NA') {
              const control = form.controls['entityComments'];
              control.setValue(item.legalEntityComments);
              control.updateValueAndValidity();
              form.controls['entityName'].setValue(item.ccn);
              form.updateValueAndValidity();
            }
          });
        }
      },
      error: (err) => {
        console.log('Unable to get legal entity record in the database table ', err);
        this.message.error('Unable to retrieve legal entities!');
      },
      complete: () => {},
    });
  }

  reviewAndValidateOthersEntitySelection(form: UntypedFormGroup): Promise<boolean> {
    const entityValue = form.controls['entityName'].value;
    const entity = form.controls['entityName'];
    const control = form.controls['entityComments'];
    if(entityValue == undefined || entityValue == null ) {
      entity.setValidators([Validators.required]);
      entity.markAsDirty();
      entity.updateValueAndValidity();
      return Promise.resolve(false);
    }
    else if (!isNonEmptyString(control.value) && entityValue === 'NA') {
      control.setValidators([Validators.required]);
      control.markAsDirty();
      control.updateValueAndValidity();
      return Promise.resolve(form.valid);
    } else {
      control.removeValidators([Validators.required]);
      control.updateValueAndValidity();
      return Promise.resolve(form.valid);
    }
  }

  reviewAndValidateOthersSummary(form: UntypedFormGroup): Promise<boolean> {
    const summaryControl = form.controls['summaryLegalActivities'];
    if (!isNonEmptyString(summaryControl.value)) {
      summaryControl.setValidators([Validators.required]);
      summaryControl.markAsDirty();
      summaryControl.updateValueAndValidity();
      return Promise.resolve(form.valid);
    } else {
      summaryControl.removeValidators([Validators.required]);
      summaryControl.updateValueAndValidity();
      return Promise.resolve(form.valid);
    }
  }
}
