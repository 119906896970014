import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { API_FAILING_ERROR_MESSAGE, PROPOSAL_ID } from 'src/app/constants/constants';
import { LegalEntity } from 'src/app/core/interfaces/LegalEntities';
import { P30APIService } from 'src/app/core/services/api';
import { DataServiceEntitySelection } from 'src/app/core/services/data_service_entity_selection';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DataServiceOthersProposal } from 'src/app/core/services/data_service_others_proposal';

@Component({
  selector: 'app-others-entity-selection',
  templateUrl: './others-entity-selection.component.html',
  styleUrl: './others-entity-selection.component.less',
  animations: [formControlElement, formLabelElement],
})
export class OthersEntitySelectionComponent {
  @Output() isLoader = new EventEmitter<boolean>();
  @Input() entitySelectionTabClass = '';
  @Input() legalEntityOthersForm!: UntypedFormGroup;
  apiErrorMSG: string = API_FAILING_ERROR_MESSAGE;
  selectedLegalentity!: string;
  nzLoading: boolean = false;
  isEntityDetailsVisible: boolean = false;
  isCommentsVisible: boolean = false;
  listofEntities: LegalEntity[] = [];
  selectedLegalEntityDetails: LegalEntity[] = [];
  nzSearchValue: string = '';

  constructor(
    private apiService: P30APIService,
    private session: SessionStorageService,
    private message: NzMessageService,
    private entitySelectionDataService: DataServiceEntitySelection,
    private othersProposalService: DataServiceOthersProposal
  ) {}

  async ngOnInit() {
    this.listofEntities = [...this.othersProposalService.commonEntityList];
    await this.getLegalEntities();
    await this.getOthersProposalSummary();
    this.setNotSavedFormValues();
  }

  // to get the summeries of Other proposal entity selections
  async getOthersProposalSummary() {
    let summary = '';
    let sessionValue = this.session.getItem('otherSelectionFormValue');
    if (sessionValue) {
      summary = sessionValue['summary'] != '' ? sessionValue['summary'] : '';
    }
    this.isLoader.emit(true);
    this.othersProposalService
      .getOthersProposalOverview()
      .subscribe((res: any) => {
        this.isLoader.emit(false);
        if (res['body']) {
          let val = summary != '' ? summary : res['body'][0]['summaryofLegalEntitiesActivities'];
          let form = this.legalEntityOthersForm.controls['summaryLegalActivities'];
          form.setValue(val);
        }
      });
  }

  // to get the legal entites list
  async getLegalEntities() {
    const proposalID = this.session.getItem(PROPOSAL_ID);
    if (proposalID) {
      this.entitySelectionDataService.getLegalEntities().subscribe({
        next: (event) => {
          if (event['body']) {
            const form = this.legalEntityOthersForm;
            this.selectedLegalEntityDetails = [];
            if (event['body'][0]['companyName'] === 'NA') {
              this.isCommentsVisible = true;
              this.selectedLegalentity = event['body'][0]['companyName'];
              form.controls['entityComments'].setValue(event['body'][0]['legalEntityComments']);
            } else {
              this.selectedLegalentity = event['body'][0]['companyName'];
              this.isCommentsVisible = false;
              this.selectedLegalEntityDetails.push(event['body'][0]);
              if (this.listofEntities.find((e: LegalEntity) => e.ccn === event['body'][0]?.ccn) === undefined) {
                this.listofEntities.push(event['body'][0]);
              }
            }
          }
        },
        error: (err) => {
          this.message.error(this.apiErrorMSG);
          this.isLoader.emit(false);
        },
        complete: () => {
          this.isLoader.emit(false);
        },
      });
    }
  }

  onEntitySearchEvent(event: any) {
    if (event) {
      this.nzSearchValue = event;
    }
  }

  // search the enisting legal entites
  onSearchEntity() {
    this.nzLoading = true;
    if (this.nzSearchValue) {
      this.apiService.getCompanies(this.nzSearchValue).subscribe({
        next: (event) => {
          let arrResult = [];
          arrResult = [...event.body.companies];
          arrResult.forEach((element) => {
            let legalEntity: LegalEntity = {
              ccn: element.companyCodeNumber,
              companyName: element.companyName,
              jurisdiction: element.countryName,
            };
            this.listofEntities.push(legalEntity);
          });
        },
        error: (err) => {
          this.message.error(this.apiErrorMSG);
          this.nzLoading = false;
        },
        complete: () => {
          this.nzLoading = false;
        },
      });
    }
  }

  // to change the legal entity
  async onEntityChange(event: any) {
    if (event == undefined) {
      return;
    } else if (event.length > 0) {
      this.selectedLegalentity = event;
      const entityCommentsValidation =
        this.legalEntityOthersForm.controls['entityComments'];
      if (event === 'NA') {
        await this.onChangeGetLegalEntities(false);
        await this.getLegalEntities();
        this.isCommentsVisible = true;
        this.isEntityDetailsVisible = false;
        entityCommentsValidation.setValidators([Validators.required]);
        entityCommentsValidation.updateValueAndValidity();
        return;
      } else {
        await this.onChangeGetLegalEntities(true);
        await this.getLegalEntities();
        this.selectedLegalentity = event;
        this.apiService
          .getCompanyDetails(this.listofEntities.find((data) => data.companyName === event)?.ccn)
          .subscribe({
            next: (output) => {
              if (output.body) {
                const modifiedLegalEntities = this.listofEntities.map((obj) => {
                  if (obj.ccn === output.body.companyCodeNumber) {
                    return { ...obj, ownershipDetail: output.body.ownership };
                  }
                  return obj;
                });
                this.listofEntities = [...modifiedLegalEntities];
              }
            },
            error: (err) => {
              this.message.error(this.apiErrorMSG);
              this.isLoader.emit(false);
            },
            complete: () => {
              this.addField(event, this.listofEntities);
              this.isLoader.emit(false);
            },
          });
        entityCommentsValidation.removeValidators([Validators.required]);
        entityCommentsValidation.setValue(null);
        entityCommentsValidation.updateValueAndValidity();
        this.isCommentsVisible = false;
        this.isEntityDetailsVisible = true;
      }
    }
  }

  async onChangeGetLegalEntities(isNa: boolean) {
    this.entitySelectionDataService.getLegalEntities().subscribe({
      next: (event) => {
        let legalEntities = [];
        if (event.body) {
          legalEntities = [...event.body];
          legalEntities.forEach((findItem) => {
            switch (isNa) {
              case true: {
                if (findItem.legalEntityComments || findItem.companyName == 'NA') {
                  this.deleteLegalEntity(findItem.legalEntityId);
                }
                return;
              }
              case false: {
                if (findItem.legalEntityId && findItem.companyName !== 'NA') {
                  this.deleteLegalEntity(findItem.legalEntityId);
                }
                return;
              }
              default: {
                return;
              }
            }
          });
        }
      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
      },
    });
  }

  deleteLegalEntity(legalEntityId: any) {
    this.isLoader.emit(true);
    this.apiService
      .deleteLegalEntity(this.session.getItem(PROPOSAL_ID), legalEntityId)
      .subscribe({
        next: (out) => {},
        error: (err) => {
          this.message.error(this.apiErrorMSG);
          this.isLoader.emit(false);
        },
        complete: () => {
          this.getLegalEntities();
          this.isLoader.emit(false);
        },
      });
  }

  // add details to legal-entity, when not NA
  addField(value: string, array: LegalEntity[]) {
    this.selectedLegalEntityDetails = [];
    this.othersProposalService.legalEntitySelected = [];
    const legalEntityForm: any = {
      legalEntityName: [value],
      ccn: [array.filter((data) => data.companyName === value)[0]?.ccn],
      companyName: [value],
      jurisdiction: [array.filter((data) => data.companyName === value)[0]?.jurisdiction],
      ownershipDetail: [array.filter((data) => data.companyName === value)[0]?.ownershipDetail],
    };
    this.selectedLegalEntityDetails.push(legalEntityForm);
    this.othersProposalService.legalEntitySelected.push(legalEntityForm);
  }

  getErrorTipForLegalEntity(controlName: string) {
    const form = this.legalEntityOthersForm;
    const control = form.controls[controlName];
    switch (control) {
      case <any>control.hasError('required'): {
        return 'Please select the legal entity!';
      }
      default: {
        return '';
      }
    }
  }

  getLegalActivitiesValue(event: any) {
    this.session.setItem('OthersLegalActivityFormValue', event?.target?.value);
  }

  setNotSavedFormValues() {
    if (this.session.getItem('otherSelectionFormValue')) {
      let sessionValue = this.session.getItem('otherSelectionFormValue');
      if (sessionValue?.summary != '') {
        this.legalEntityOthersForm.controls['summaryLegalActivities']?.setValue(sessionValue?.summary);
      }
      if (sessionValue?.comment != '') {
        this.legalEntityOthersForm.controls['entityComments']?.setValue(sessionValue?.comment);
      }
      if (sessionValue?.name != '') {
        this.selectedLegalentity = sessionValue?.name;
      }
      if (sessionValue?.entityDetails?.length) {
        this.isLoader.emit(true);
        this.selectedLegalEntityDetails = [];
        let legalEntity = sessionValue?.entityDetails[0];
        const entityDetails: any = {
          legalEntityName: legalEntity['companyName'],
          ccn: legalEntity['ccn'],
          companyName: legalEntity['companyName'],
          jurisdiction: legalEntity['jurisdiction'],
          ownershipDetail: legalEntity['ownershipDetail'],
        };
        this.selectedLegalEntityDetails.push(entityDetails);
        this.isLoader.emit(false);
      }
    }
  }

  ngOnDestroy() {
    const form = this.legalEntityOthersForm;
    let comment = form['controls']['entityComments'].value;
    let d = {
      name: form['controls']['entityName'].value,
      comment: comment != '' ? comment : '',
      summary: form['controls']['summaryLegalActivities'].value,
      entityDetails: this.selectedLegalEntityDetails,
    };
    this.session.setItem('otherSelectionFormValue', d);
  }
}
