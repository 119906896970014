import { Injectable} from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpResponse } from '@angular/common/http';
;

@Injectable ({
    providedIn: 'root'
})
export class DataServiceMergerProposalOverview {


  constructor(private session:SessionStorageService,
    private fb: UntypedFormBuilder,
    private apiService:P30APIService,
    private message: NzMessageService){ }


  getProposalOverview(): Observable<HttpResponse<any>>{
    return this.apiService.getProposalOverview(this.session.getItem(PROPOSAL_ID)).pipe(
      map(proposalOverview =>{
        return proposalOverview;
      })
    );
  }

  createAcquisitionProposalOverview(form: UntypedFormGroup):Observable<HttpResponse<any>>{
    const data = {
      "requestId": this.session.getItem(REQUEST_ID),
      "overviewOfProposal": form.controls["overviewOfProposal"].value,
      "businessReasonForProposal": form.controls["businessReasonForProposal"].value,
      "targetCompletionDate": form.controls["targetCompletionDate"].value,
      "targetSigningDate": '',
      "nameOfShareHolderAndMore": '',
      "summaryofLegalEntitiesActivities": '',
      "targetEffectiveDate": '',
      "proposedLegalEntityName": '',
      "countryorStateofIncorporation": ''
    }
    return this.apiService.createProposalOverview(this.session.getItem(PROPOSAL_ID), data).pipe(
      map(proposalOverview =>{
        return proposalOverview;
      })
    );

  }
  updateAcquisitionProposalOverview(form: UntypedFormGroup): Observable<HttpResponse<any>>{
    const data = {
      "requestId":this.session.getItem(REQUEST_ID),
      "overviewOfProposal": form.controls["overviewOfProposal"].value,
      "businessReasonForProposal": form.controls["businessReasonForProposal"].value,
      "targetCompletionDate": form.controls["targetCompletionDate"].value,
      "targetSigningDate": '',
      "nameOfShareHolderAndMore": '',
      "summaryofLegalEntitiesActivities": '',
      "targetEffectiveDate": '',
      "proposedLegalEntityName": '',
      "countryorStateofIncorporation": ''
    }
    return this.apiService.updateProposalOverview(this.session.getItem(PROPOSAL_ID), data).pipe(
      map(proposalOverview =>{
        return proposalOverview;
      })
    );
  }
  deleteProposalOverview(){
    return this.apiService.deleteProposalOverview(this.session.getItem(PROPOSAL_ID)).subscribe(output =>{
      if(output.status == 200){
        return
      }else{
        console.log("Unable to delete proposal overview");
      }
    })
  }

  patchProposalOverviewDataToFormGroup(form: UntypedFormGroup,loader:boolean){
    this.apiService.getProposalOverview(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if(event.body){
            form.patchValue({
            requestId: this.session.getItem(REQUEST_ID),
            summaryId: event.body[0].summaryId,
            overviewOfProposal: event.body[0].overviewOfProposal,
            businessReasonForProposal: event.body[0].businessReasonForProposal,
            targetCompletionDate: event.body[0].targetCompletionDate,
            targetSigningDate: '',
            nameOfShareHolderAndMore: '',
            summaryofLegalEntitiesActivities: '',
            targetEffectiveDate: '',
            proposedLegalEntityName: '',
            countryorStateofIncorporation: ''
          });
        }
      },
      error: (err) =>{
        console.log("Unable to get proposal overview from the database table ",err);
      },
      complete: () =>{

      }
     });
  }
  createProposalOverviewWithFormData(form: UntypedFormGroup,loader:boolean){
    this.createAcquisitionProposalOverview(form).subscribe({
      next: (event) =>{
        this.getProposalOverview().subscribe({
          next: (event) =>{
            if(event.body){
              form.patchValue({
                requestId: this.session.getItem(REQUEST_ID),
                summaryId: event.body[0].summaryId,
                overviewOfProposal: event.body[0].overviewOfProposal,
                businessReasonForProposal: event.body[0].businessReasonForProposal,
                targetCompletionDate: event.body[0].targetCompletionDate,
                targetSigningDate: '',
                nameOfShareHolderAndMore: '',
                summaryofLegalEntitiesActivities: '',
                targetEffectiveDate: '',
                proposedLegalEntityName: '',
                countryorStateofIncorporation: ''
              });
            }
          }
        });
      },
      error:(err) =>{
        console.log("unable to create proposal overview", err);
      },
      complete: () =>{

      }
    });
  }
  updateProposalOverviewWithFormData(form: UntypedFormGroup,loader:boolean){
    this.updateAcquisitionProposalOverview(form).subscribe({
      next: (event) =>{

      },
      error:(err) =>{
        console.log("unable to update proposal overview", err);

      },
      complete: () =>{

      }
    });
  }
  submitProposalOverview(form:UntypedFormGroup, loader:boolean, tabClass:string):Promise<boolean>{
    if(form.valid){
      this.getProposalOverview().subscribe({
        next: (event) =>{
          if(event.body){
            this.updateProposalOverviewWithFormData(form,loader);
          }else{
            this.createProposalOverviewWithFormData(form,loader);
          }
        }
      });

      return Promise.resolve(true);
    }else{

      Object.values(form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return Promise.resolve(false);
    }
  }

  reviewAndValidateProposalOverview(form:UntypedFormGroup): Promise<boolean>{
    const overviewOfProposalControl = form.controls["overviewOfProposal"];
    overviewOfProposalControl.setValidators([Validators.required]);
    overviewOfProposalControl.markAsDirty();
    overviewOfProposalControl.updateValueAndValidity();
    const businessReasonForProposalControl = form.controls["businessReasonForProposal"];
    businessReasonForProposalControl.setValidators([Validators.required]);
    businessReasonForProposalControl.markAsDirty();
    businessReasonForProposalControl.updateValueAndValidity();
    const targetCompletionDateControl = form.controls["targetCompletionDate"];
    targetCompletionDateControl.setValidators([Validators.required]);
    targetCompletionDateControl.markAsDirty();
    targetCompletionDateControl.updateValueAndValidity();
    return Promise.resolve(form.valid);
  }
}
