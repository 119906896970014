import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceProposalOverview } from 'src/app/core/services/data_service_proposal_overview';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-acquisition-proposal-overview',
  templateUrl: './acquisition-proposal-overview.component.html',
  styleUrls: ['./acquisition-proposal-overview.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class AcquisitionProposalOverviewComponent {
  @Input() acquisitionProposalOverviewForm!: UntypedFormGroup;
  @Input() isShareHolderControlVisible:boolean=true;
  @Input() isSlatedisable:boolean=false;

  constructor(
    private session: SessionStorageService,
    private dataservice: DataService,
    private proposalOverviewDataService: DataServiceProposalOverview
  ) {}
}
