import { Component, Input } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { PROPOSAL_ID, REQUEST_ID, SV_PROPOSAL_STATUS } from 'src/app/constants/constants';
import { Attachment } from 'src/app/core/interfaces/Attachment';
import { AcquisitionEntityQuestion } from 'src/app/core/interfaces/EntityIndicatorQuestion';
import { UserRole } from 'src/app/core/interfaces/UserRole';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceGeneralDetails } from 'src/app/core/services/data_service_general_details';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DataServiceContacts } from 'src/app/core/services/data_service_contacts';

@Component({
  selector: 'app-formation-proposal-review',
  templateUrl: './formation-proposal-review.component.html',
  styleUrls: ['./formation-proposal-review.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class FormationProposalReviewComponent {
  collapseIcon:string = 'double-right';
  generalDetailsForm!:UntypedFormGroup;
  requestedForUser!: string;
  coordinators: string[] =[];
  additionalCcs: string[] =[];
  businessSegmentUnit!: string;
  region!: string;
  // selectedFormationLegalEntities: any[] = [];
 //@Input() acquisitionProposalOverviewForm!:  UntypedFormGroup;
  // @Input() legalEntityFormationForm!: UntypedFormGroup;
 @Input() acquisitionEntitySelectionForm!: UntypedFormGroup;
  @Input() formationProposalOverviewForm!:  UntypedFormGroup;  
 // @Input() acquisitionEntitySelectionForm!: UntypedFormGroup;
  @Input() formationPropossedSlateForm!: UntypedFormGroup;
  @Input() isShareHolderControlVisible:boolean=true;

  @Input() contactsForm!:UntypedFormGroup;
  
  entityQuestions: AcquisitionEntityQuestion[] = [];
  showAsset:boolean = false;
  showOwnsershipInterest:boolean = false;
  showLegalEntityNA:boolean = false;
  selectedLegalEntities: any[] = [];
  files: Attachment[] = [];
  histories: any[] = [];
  userRoles: UserRole[] = [];
  requestStateName: string = '';
  ifcRequired!: boolean;
  isReviewComponent!: boolean;

  constructor(private dataservice:DataService, private generalDetailsDataService:DataServiceGeneralDetails,
    private apiService:P30APIService, private session:SessionStorageService,
    private commentService: DataServiceComments, private contactService: DataServiceContacts){

  }

  ngOnInit(){
    this.getUserRoles();
//    this.getEntityIndicatorQuestions();
    this.getLegalEntities();
    this.getAttachments();
    this.getHistories();
    this.patchContactsFormValues();
    // to get the request state for dynamic UI show/Hide
    this.requestStateName = this.session?.getItem(SV_PROPOSAL_STATUS).name.toLocaleLowerCase();
    this.isReviewComponent = true;
    this.acquisitionEntitySelectionForm.disable();
    this.formationPropossedSlateForm.disable();
    this.formationProposalOverviewForm.disable();    
    this.contactsForm.disable();
    if(this.dataservice.aqGeneralDetailsForm){
      this.generalDetailsDataService.patchGeneralDetailsDataToFormGroup(this.dataservice.aqGeneralDetailsForm);
      this.generalDetailsForm = this.dataservice.aqGeneralDetailsForm;
      this.requestedForUser = this.generalDetailsForm.controls["requestedFor"].value;
      this.businessSegmentUnit = this.generalDetailsForm.controls["businessSegmentUnit"].value;
      this.region = this.generalDetailsForm.controls["region"].value;
      this.generalDetailsForm.disable();
    }
  }

  async patchContactsFormValues() {
    this.apiService.getContacts(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if(event['body']){
          this.ifcRequired = event.body[0].ifcRequired;
          let coordinatorNames = event.body[0].coordinator.includes(';') ? event.body[0].coordinator.split(';') : [event.body[0].coordinator];
          let additionalCcNames = event.body[0].additionalCcsForNotifications.includes(';') ? event.body[0].additionalCcsForNotifications.split(';') : [event.body[0].additionalCcsForNotifications];
          this.contactsForm.patchValue({
            requestId: this.session.getItem(REQUEST_ID),
            contactId: event.body[0].contactId,
            coordinator: coordinatorNames,
            additionalCcsForNotifications: additionalCcNames,
            ifcRequired: event.body[0].ifcRequired
          })
        }
      },
      error: (err) =>{
        console.log("Unable to get general contacts from the database table ",err);
      },
      complete: () =>{

      }
    })
  }

  get proposedSlates(){
    return this.formationPropossedSlateForm.controls["proposedSlates"] as FormArray;
  }

  ngOnDestroy(){
    this.dataservice.aqGeneralDetailsForm.enable();
    this.acquisitionEntitySelectionForm.enable();  
    this.formationProposalOverviewForm.enable();
    this.formationPropossedSlateForm.enable();
    this.contactsForm.enable();
    this.isReviewComponent = false;
  }

  getEntityIndicatorQuestions(){
    this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (data)=>{
        console.log("Entity Indicator Questions", data.body);
        this.entityQuestions = [...data.body];
      
        this.selectedLegalEntities.forEach((entity: any) => {
          this.showLegalEntityNA = false;
         // let que = this.entityQuestions.filter(p => p.legalEntityId === Number(entity.legalEntityId));
          let element = this.entityQuestions[0];

          if (element) {
            entity.needBankAccounts = element.needBankAccounts;
            entity.commentsForNeedingBankAccounts = element.commentsForNeedingBankAccounts
            entity.willTheNewEntityBeRegisteredInOtherStatesCountries = element.willTheNewEntityBeRegisteredInOtherStatesCountries
            entity.commentsForNewEntityBeingRegistered = element.commentsForNewEntityBeingRegistered
            entity.entityQuestionsId = element.entityQuestionsId ? element.entityQuestionsId : 0
            entity.legalEntityId = element.legalEntityId
          }

        });

      },
      error:(err) =>{

      },
      complete: () =>{

      }
    });
  }
  getLegalEntities():Promise<void>{
    return new Promise<void>((resolved)=>{
      this.apiService.getLegalEntities(this.session.getItem(PROPOSAL_ID)).subscribe({
        next: (event) => {
          let entities = [];
          console.log("Legal Entities", event.body);
          entities = event.body
          this.selectedLegalEntities = [...entities];
        },
        error: (err) => {
          console.log("Unable to get legal entity record in the database table ", err);

        },
        complete: () => {
          this.getEntityIndicatorQuestions();
          resolved();
        }
      });
    })
  }
  getAttachments(): void {
    this.apiService.getAttachments(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      this.files = [];
      if (response.body) {
        const data = response.body
        data.forEach((element: Attachment) => {
          this.files.push(element)
        })
      }
    })

  }
  getHistories():void {
    this.apiService.getHistories(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      if(response.body) {
        this.histories = [...response.body];
      }
    })
  }
  getUserRoles(){
    this.apiService.getAllUserRoles().subscribe(output=>{
      if(output.status == 200){
        this.userRoles = [...output.body];
      }
    })
  }

}
