import { Injectable} from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import {BehaviorSubject, Observable, map } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { DataService } from './data_service';
import { UserDetails } from '../interfaces/User';
import { MsGraphService } from './ms-graph';
import { DataServiceGeneralDetails } from './data_service_general_details';


@Injectable ({
    providedIn: 'root'
})
export class DataServiceContacts {
  coordsInfoList: UserDetails[] = [];
  addCCInfoList: UserDetails[] = [];
  defaultcoordinators: UserDetails[] = [];
  contactId!: number;
  coordinatorNames: [] = [];
  additionalCcNames: [] = [];
  selectedContact = new BehaviorSubject<UserDetails[]>([]);
  additionalCc: UserDetails[] = [];
  // additionalCcs: UserDetails[] = [];
  coordinators: UserDetails[] = [];

  constructor(private session:SessionStorageService,
    private fb: UntypedFormBuilder,
    private apiService:P30APIService,
    private dataservice: DataService,
    private generalDetailsDataService: DataServiceGeneralDetails,
    private graph: MsGraphService,
    private message: NzMessageService){ }

  reviewAndValidateContacts(form: UntypedFormGroup): Promise<boolean> {
    const control = form.controls["coordinator"];
      control.setValidators([Validators.required]);
      control.markAsDirty();
      control.updateValueAndValidity();
      return Promise.resolve(form.valid);
  }

  submitContacts(form: UntypedFormGroup, loader: boolean, tabClass: string) {
    if(form.valid){
      this.getContacts(this.session.getItem(PROPOSAL_ID)).subscribe({
        next: (event) =>{
          if(event.body){
            this.contactId = event.body[0].contactId;
            this.updateContactsWithFormData(form,loader);
          } else{
            this.createContactsWithFormData(form, loader);
          }
        }
      });
    }else{
      Object.values(form.controls).forEach(control => {
        if(control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      })
    }
  }
  createContactsWithFormData(form: UntypedFormGroup, loader: boolean) {
    this.createContacts(form).subscribe({
      next: (event)=>{

      },
      error:(err) =>{
        console.log("unable to create contacts", err);
      },
      complete: () =>{
        this.message.success("Successfully saved contacts");
      }
    });
  }
  updateContactsWithFormData(form: UntypedFormGroup, loader: boolean) {
    this.updateContacts(form).subscribe({
      next: (event)=>{
      },
      error:(err) =>{
        console.log("unable to update contacts", err);
      },
      complete: () =>{
        this.message.success("Successfully updated contacts");
      }
    })

  }
  getContacts(proposalId:string): Observable<HttpResponse<any>> {
    return this.apiService.getContacts(proposalId).pipe(
      map(contacts =>{
        return contacts;
      })
    )
  }
  updateContacts(form: UntypedFormGroup): Observable<HttpResponse<any>>{
    const data = {
      "requestId": this.session.getItem(REQUEST_ID),
      "coordinator": this.coordsInfoList?.map(x => x?.fullName).join(";"),
      "coordinatorEmail": this.coordsInfoList?.map(x => x?.mail).join(";"),
      "ifcRequired": form?.controls['ifcRequired'].value,
      "additionalCcsForNotifications": this.addCCInfoList.map(x => x?.fullName).join(";"),
      "additionalCcsForNotificationsEmail": this.addCCInfoList.map(x => x?.mail).join(";"),
      "ifcSecretary": null,
      "ifcSecretaryEmail": null,
      "completionNotes": null
    }
    return this.apiService.updateContacts(this.session.getItem(PROPOSAL_ID), this.contactId, data).pipe(
      map(contacts =>{
        return contacts;
      })
    )
  }
  createContacts(form: UntypedFormGroup): Observable<HttpResponse<any>> {
    const data = {
      "requestId": this.session.getItem(REQUEST_ID),
      "coordinator": this.coordsInfoList.map(x => x?.fullName).join(";"),
      "coordinatorEmail": this.coordsInfoList.map(x => x?.mail).join(";"),
      "ifcRequired": form?.controls['ifcRequired'].value,
      "additionalCcsForNotifications": this.addCCInfoList.map(x => x?.fullName).join(";"),
      "additionalCcsForNotificationsEmail": this.addCCInfoList.map(x => x?.mail).join(";"),
      "ifcSecretary": null,
      "ifcSecretaryEmail": null,
      "completionNotes": null
    }
    return this.apiService.createContacts(this.session.getItem(PROPOSAL_ID), data).pipe(
      map(contacts =>{
        return contacts;
      })
    )
  }
  getUsersInfo(emails: string[]) {
    for (let email of emails) {
      this.graph.getUserByEmail(email).subscribe(element =>{
        let coordinatorTemp: UserDetails = {
          mail: element.mail,
          fullName: `${element.givenName} ${element.surname}`
        }
        this.coordsInfoList.push(coordinatorTemp)
      })
    }
  }

  getRegionCoordinators() {
    let emails = []
    this.generalDetailsDataService.getProposal().subscribe({
      next: (event) => {
        if(event.body.regionId){
          this.generalDetailsDataService.getRegion(event.body.regionId).subscribe(element =>{
            emails = element.body.coordinatorEmailId.includes(';') ? element.body.coordinatorEmailId.split(';') : [element.body.coordinatorEmailId];
            this.getUsersInfo(emails);
          })
        }
      }
    })
  }

  patchContactsToFormGroup(form: UntypedFormGroup, loader: boolean) {
    this.apiService.getContacts(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if(event.body){
          this.contactId = event.body[0].contactId;
          this.coordinatorNames = event.body[0].coordinator.includes(';') ? event.body[0].coordinator.split(';') : [event.body[0].coordinator];
          this.additionalCcNames = event.body[0].additionalCcsForNotifications.includes(';') ? event.body[0].additionalCcsForNotifications.split(';') : [event.body[0].additionalCcsForNotifications];
          form.patchValue({
            requestId: this.session.getItem(REQUEST_ID),
            contactId: event.body[0].contactId,
            coordinator: this.coordinatorNames,
            additionalCcsForNotifications: this.additionalCcNames,
            ifcRequired: event.body[0].ifcRequired
          })
        }

      },
      error: (err) =>{
        console.log("Unable to get general contacts from the database table ",err);
      },
      complete: () =>{

      }
    })
  }

  setCoordinators(event: string, selectedCoorinator: UserDetails): void {
    if (selectedCoorinator) {
      if (this.coordsInfoList.filter(x => x.mail == selectedCoorinator?.mail).length == 0) {
        this.coordsInfoList.push({
          objectId:selectedCoorinator.objectId,
          displayName: selectedCoorinator.displayName!,
          fullName: selectedCoorinator.fullName,
          mail: selectedCoorinator.mail
        });
      }
    }
    // Remove unselected user from dropdown
    this.coordsInfoList.forEach((element, index) => {
      if (!event.includes(element.fullName)) {
        this.coordsInfoList.splice(index, 1);
      }
    });
  }

  setAddCC(event: string, selectedadditionalCc: UserDetails): void {
    //Add user in dropdown
    let users: any[] = [];
    if (selectedadditionalCc) {
      if (this.addCCInfoList.filter(x => x.mail == <any>selectedadditionalCc?.mail).length == 0) {
        users.push({
          objectId:selectedadditionalCc?.objectId,
          displayName: selectedadditionalCc?.displayName!,
          fullName: <any>selectedadditionalCc?.fullName,
          mail: selectedadditionalCc?.mail
        });
        if(this.addCCInfoList.find((e: any) => e.fullName === selectedadditionalCc?.fullName) === undefined) {
          this.addCCInfoList.push(...users);
          // console.log("service.contactsDataService.addCCInfoList => ", this.addCCInfoList)
        }
      }

    }
    // Remove unselected user from dropdown
    this.addCCInfoList.forEach((element, index) => {
      if (!event.includes(element.fullName)) {
        this.addCCInfoList.splice(index, 1);
      }
    })
  }

  isCoordinatorContactData(data: UserDetails[]) {
    this.selectedContact.next(data);
  }
}
