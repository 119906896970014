import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  formControlElement,
  formLabelElement,
} from 'src/app/animations/animations';
import { API_FAILING_ERROR_MESSAGE, FormEmptyFieldErrorMessage, PROPOSAL_ID, REQUEST_ID, SalesBuyer, SalesSeller } from 'src/app/constants/constants';
// import {FormBuilder} from '@angular/forms'
import { LegalEntity } from 'src/app/core/interfaces/LegalEntities';
import { SaleEntityQuestionsDTO } from 'src/app/core/interfaces/salesEntityQuestions';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceEntitySelection } from 'src/app/core/services/data_service_entity_selection';
import { DataServiceSalesEntity } from 'src/app/core/services/data_service_sale_entityQuestion';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-sales-entity-selection',
  templateUrl: './sales-entity-selection.component.html',
  styleUrl: './sales-entity-selection.component.less',
  animations: [formControlElement, formLabelElement],
  providers: [DatePipe],
})
export class SalesEntitySelectionComponent {
  @Output() isLoader = new EventEmitter<boolean>;
  @Input() entitySelectionTabClass = '';
  @Input() legalEntitySalesForm!: FormGroup;
  apiErrorMSG: string = API_FAILING_ERROR_MESSAGE;
  nzLoading: boolean = false;
  nzLoadingBuyer: boolean = false;
  nzSearchValue: string = '';
  selectedLegalSeller!: string;
  selectedLegalBuyer!: string;
  selectedSaleType!: string;
  haveEmployee: boolean = false;
  bankAccount: boolean = false;
  subsidiaries: boolean = false;
  showEntityDetailsBlock: boolean = false;
  showEntityDetailsBuyerBlock: boolean = false;
  emptyFormFieldErrorMSG: string = FormEmptyFieldErrorMessage;
  typeOfSalesRecords: SaleEntityQuestionsDTO[] = this.salesEntityService.salesTypeList;
  isPageReload: boolean = false;
  showQuestionsList: boolean = false;
  showBuyerComments:boolean = false;
  showSellerComments:boolean = false;
  selectedEntity: string[] = [];
  investmentType = [
    { name: 'Cash', id: 1 },
    { name: 'Non-Cash', id: 2 },
  ]
  sellerEntityList: LegalEntity[] = [];
  buyerEntityList: LegalEntity[] = [];
  salesLegalEntitiesList: LegalEntity[] = [];
  selectedSellerEntityDetails: LegalEntity[] = [];
  selectedBuyerEntityDetails: LegalEntity[] = [];

  constructor(private fb: FormBuilder, private salesEntityService: DataServiceSalesEntity,
    private entitySelectionDataService: DataServiceEntitySelection,
    private apiService: P30APIService, private session: SessionStorageService,
    private message: NzMessageService,
    private dataservice: DataService,
    private acEntitySelectionService: DataServiceEntitySelection) {
  }

  ngOnDestroy() {
    this.salesEntityService.salesLegalEntityNQuestionGroupForm = this.legalEntitySalesForm;
    this.acEntitySelectionService.salesLegalEntitySelectionQuestionGroupForm = this.legalEntitySalesForm;
  }

  ngOnInit() {
    this.legalEntitySalesForm.reset();
    this.getLegalEntities();
    this.getEntityQuestions();
    this.buyerEntityList = [...this.salesEntityService.commonEntityList];
    this.sellerEntityList = [...this.salesEntityService.commonEntityList];
  }

  patchEntityDetailsValue(arr: any[]) {
    const legalEntityForm: any = {
      ccn: arr[0].ccn,
      companyName: arr[0].companyName,
      jurisdiction: arr[0].jurisdiction,
      ownershipDetail: arr[0].ownershipDetail,
      legalEntityName: arr[0].legalEntityName,
    }
    return legalEntityForm;
  }

  storeEntitiesValues(records: LegalEntity, entityType: string) {
    let salesLeglaEntity = {
      ccn: records.ccn?.toString(),
      companyName: records.companyName.toString(),
      jurisdiction: records.jurisdiction?.toString(),
      ownershipDetail: records.ownershipDetail?.toString(),
      legalEntityComments: records.legalEntityComments?.toString(),
      typeofLegalEntity: records.typeofLegalEntity,
      legalEntityId: records.legalEntityId,
      requestId: Number(this.session.getItem(REQUEST_ID))
    }
    return salesLeglaEntity;
  }

  getLegalEntities(): Promise<boolean> {
    return new Promise<boolean>((resolved) => {
      if (this.session.getItem(PROPOSAL_ID)) {
        this.isLoader.emit(true);
        this.entitySelectionDataService.getLegalEntities().subscribe({
          next: (event) => {
            if (event['body']) {
              const sellerForm: FormGroup = this.legalEntitySalesForm.controls['sellerFormGroup'] as FormGroup;
              const buyerFormGroup: FormGroup = this.legalEntitySalesForm.controls['buyerFormGroup'] as FormGroup;

              this.selectedSellerEntityDetails = [];
              this.selectedBuyerEntityDetails = [];
              let sellerEntity = event['body'].find((e: any) => e.typeofLegalEntity === SalesSeller)
              let buyerEntity = event['body'].find((e: any) => e.typeofLegalEntity === SalesBuyer);
              if (sellerEntity !== undefined) {
                let s: LegalEntity = this.storeEntitiesValues(sellerEntity, SalesSeller);
                this.selectedLegalSeller = s.companyName;
                sellerForm.controls['sellerLegalEntity'].setValue(s.companyName);
                sellerForm.controls['sellerComments'].setValue(s.legalEntityComments);
                if (this.sellerEntityList.find((e: LegalEntity) => e.ccn === s?.ccn) === undefined) {
                  this.sellerEntityList.push(s);
                  this.selectedSellerEntityDetails.push(s);
                }
              }
              if(buyerEntity !== undefined) {
                let b: LegalEntity = this.storeEntitiesValues(buyerEntity, SalesBuyer);
                this.selectedLegalBuyer = b.companyName;
                buyerFormGroup.controls['buyerLegalEntity'].setValue(b.companyName);
                buyerFormGroup.controls['buyerComments'].setValue(b.legalEntityComments);
                if (this.buyerEntityList.find((e: LegalEntity) => e.ccn === b?.ccn) === undefined) {
                  this.buyerEntityList.push(b);
                  this.selectedBuyerEntityDetails.push(b);
                }
              }
            }
          },
          error: (err) => {
            this.message.error(this.apiErrorMSG);
            console.log("Unable to get legal entity record in the database table ", err);
            this.isLoader.emit(false);
            resolved(false);
          },
          complete: () => {
            this.isLoader.emit(false);
            resolved(true);
          }
        });
      }
    });
  }

  getEntityQuestions() {
    this.salesEntityService.getEntityIndicatorQuestions().subscribe((response: any) => {
      if (response['body']) {
        let records: any = [...response['body']]
        this.legalEntitySalesForm.controls['salesType'].setValue(records[0].typeOfSale)
        const salesTypeFormGroup: FormGroup = this.legalEntitySalesForm.controls['salesTypeQuestionsFormGroup'] as FormGroup;
        salesTypeFormGroup.controls['haveEmployees'].setValue(records[0].haveAnyEmployees);
        salesTypeFormGroup.controls['haveEmployeesComments'].setValue(records[0].commentsForHavingEmployees);
        salesTypeFormGroup.controls['needBankAccounts'].setValue(records[0].haveActiveBankAccounts);
        salesTypeFormGroup.controls['needBankAccountsComments'].setValue(records[0].commentsForHavingBankAccounts);
        salesTypeFormGroup.controls['haveSubsidiaries'].setValue(records[0].haveSubsidiaries);
        salesTypeFormGroup.controls['haveSubsidiariesComments'].setValue(records[0].commentsForHavingSubsidiaries);
        this.haveEmployee = records[0].haveAnyEmployees;
        this.bankAccount = records[0].haveActiveBankAccounts;
        this.subsidiaries = records[0].haveSubsidiaries;
      }
    })
  }

  getSellerForm() {
    const sellerFormGroup = this.salesEntityService.legalEntitySalesForm.controls['sellerFormGroup'] as FormGroup;
    return sellerFormGroup;
  }
  getBuyerForm() {
    const buyerFormGroup = this.salesEntityService.legalEntitySalesForm.controls['buyerFormGroup'] as FormGroup;
    return buyerFormGroup;
  }

  // to change the legal entity for Sale Seller
  async onSellerChange(event: string) {
    if (event == undefined) {
      return
    } else {
      this.salesEntityService.legalEntitySalesForm = this.legalEntitySalesForm;
      this.selectedLegalSeller = event;
      const sellerValidation = this.legalEntitySalesForm.controls['sellerFormGroup'] as FormGroup;
      const control = sellerValidation.controls['sellerComments'];
      if (event === 'NA') {
      
        this.showEntityDetailsBlock = false;
        this.showSellerComments = true;
        control.setValidators([Validators.required]);
        control.markAsDirty();
        control.updateValueAndValidity();
      } else {
        this.isLoader.emit(true);
      
        this.showEntityDetailsBlock = true;
        this.bindSellerDetails(event, 'Seller');
        this.showSellerComments = false;
        control.removeValidators([Validators.required]);
      }
    }
  }

  // to change the legal entity for Sale Buyer
  async onBuyerChange(event: string) {
    if (event == undefined) {
      return
    } else {
      this.salesEntityService.legalEntitySalesForm = this.legalEntitySalesForm;
      this.selectedLegalBuyer = event;
      const buyerValidation = this.legalEntitySalesForm.controls['buyerFormGroup'] as FormGroup;
      const control = buyerValidation.controls['buyerComments'];
      if (event === 'NA') {
 
        this.showEntityDetailsBuyerBlock = false;
        this.showBuyerComments = true;
        control.setValidators([Validators.required]);
        control.markAsDirty();
        control.updateValueAndValidity();
      } else {
 
        this.showEntityDetailsBuyerBlock = true;
        this.bindSellerDetails(event, 'Buyer');
        this.showBuyerComments = false;
        control.removeValidators([Validators.required]);
      }
    }
  }

  bindSellerDetails(selectedValue: string, type: string) {
    switch (type) {
      case ('Seller'): {
        this.getCompanyDetailsForEntity(selectedValue, type, this.sellerEntityList);
        break;
      }
      case ('Buyer'): {
        this.getCompanyDetailsForEntity(selectedValue, type, this.buyerEntityList);
        break;
      }
    }
  }

  getCompanyDetailsForEntity(selectedValue: string, legalType: string, array: LegalEntity[]) {
    this.isLoader.emit(true);
    this.apiService.getCompanyDetails(array.find(data => data.companyName === selectedValue)?.ccn).subscribe({
      next: (output) => {
        if (output.body) {
          const modifiedLegalEntities = array.map(obj => {
            if (obj.ccn === output.body.companyCodeNumber) {
              return { ...obj, ownershipDetail: output.body.ownership };
            }
            return obj;
          });
          switch (legalType) {
            case ('Seller'): {
              this.sellerEntityList = [...modifiedLegalEntities];
              break;
            }
            case ('Buyer'): {
              this.buyerEntityList = [...modifiedLegalEntities];
              break;
            }
          }
        }
      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
        this.isLoader.emit(false);
      },
      complete: () => {
        switch (legalType) {
          case ('Seller'): {
            this.addField(selectedValue, 'Seller', this.sellerEntityList);
            break;
          }
          case ('Buyer'): {
            this.addField(selectedValue, 'Buyer', this.buyerEntityList);
            break;
          }
        }
      }
    });
  }

  addField(value: string, entityType: string, array: LegalEntity[]) {
    const legalEntityForm: any = {
      legalEntityName: [value],
      ccn: [array.filter(data => data.companyName === value)[0].ccn],
      companyName: [value],
      jurisdiction: [array.filter(data => data.companyName === value)[0].jurisdiction],
      ownershipDetail: [array.filter(data => data.companyName === value)[0].ownershipDetail],
    }
    this.isLoader.emit(false);
    switch (entityType) {
      case ('Seller'): {
        this.salesEntityService.sellerLegalEntitySelected = [];
        this.selectedSellerEntityDetails.push(legalEntityForm);
        this.salesEntityService.sellerLegalEntitySelected.push(legalEntityForm);
        break;
      }
      case ('Buyer'): {
        this.salesEntityService.buyerLegalEntitySelected = [];
        this.selectedBuyerEntityDetails.push(legalEntityForm);
        this.salesEntityService.buyerLegalEntitySelected.push(legalEntityForm);
        break;
      }
    }
  }

  typeOfEntity: string = ''
  onSellerSearchEvent(event: string) {
    if (event) {
      this.nzSearchValue = event;
    }
  }
  onBuyerSearchEvent(event: string) {
    if (event) {
      this.nzSearchValue = event;
    }
  }

  onSearchBuyer() {
    this.nzLoadingBuyer = true;
    if (this.nzSearchValue) {
      this.apiService.getCompanies(this.nzSearchValue).subscribe({
        next: (event) => {
          let arrResult = [];
          arrResult = [...event.body.companies];
          arrResult.forEach(element => {
            let legalEntity: LegalEntity = {
              ccn: element.companyCodeNumber,
              companyName: element.companyName,
              jurisdiction: element.countryName
            }
            this.buyerEntityList.push(legalEntity);
          });
        },
        error: (err) => {
          this.message.error(this.apiErrorMSG);
          this.nzLoadingBuyer = false;
        },
        complete: () => {
          this.nzLoadingBuyer = false;
        }
      });
    }
  }

  onSearchSeller() {
    this.nzLoading = true;
    if (this.nzSearchValue) {
      this.apiService.getCompanies(this.nzSearchValue).subscribe({
        next: (event) => {
          let arrResult = [];
          arrResult = [...event.body.companies];
          arrResult.forEach(element => {
            let legalEntity: LegalEntity = {
              ccn: element.companyCodeNumber,
              companyName: element.companyName,
              jurisdiction: element.countryName
            }
            this.sellerEntityList.push(legalEntity);
          });
        },
        error: (err) => {
          this.message.error(this.apiErrorMSG);
          this.nzLoading = false;
        },
        complete: () => {
          this.nzLoading = false;
        }
      });
    }
  }

  
  // to change the type of sales
  async onSaleTypeChange(event: string) {
    this.salesEntityService.legalEntitySalesForm = this.legalEntitySalesForm;
    if (event === 'Sale of Entity') {
      this.showQuestionsList = true;
    } else if (event === 'Sale of Asset' || event === 'Sale of Entity & Asset'){
      this.showQuestionsList = false;
    }
  }

  onSearchSaleTypeEvent(event: string) {
    console.log('onSearchSaleTypeEvent => ', event);
  }

  salesQuestionsActionBTN(event: any, quesName: string) {
    const salesTypeFormGroup: FormGroup = this.legalEntitySalesForm.controls['salesTypeQuestionsFormGroup'] as FormGroup;
    const salesTypeFormControl = this.legalEntitySalesForm.controls['salesType'];
    console.log('salesTypeFormControl => ', salesTypeFormControl);
    if (salesTypeFormControl.value === 'Sale of Entity') {  
      switch (quesName) {
        case 'haveAccounts': {
          let control = salesTypeFormGroup.controls['needBankAccountsComments'];
          if (event == true) {
          control.setValidators([Validators.required]);
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
          }
          else
          {
            control.removeValidators([Validators.required]);
            control.updateValueAndValidity();
          }
          break;
        }
        case 'haveSubsidiaries': {
          let control = salesTypeFormGroup.controls['haveSubsidiariesComments'];
          if (event == true) {
          control.setValidators([Validators.required]);
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
          }
          else
          {
            control.removeValidators([Validators.required]);
            control.updateValueAndValidity();
          }
          break;
        }
      }
    }
    
  }

  getErrorTipForSellerLegalEntity(controlName: string) {
    const sellerForm = this.getSellerForm();
    const control = sellerForm.controls[controlName];
    switch (control) {
      case (<any>control.hasError('required')): {
        return "Please select the legal entity!";
      }
      default: {
        return '';
      }
    }
  }

  onCloseTag(event: string) {
    console.log('onCloseTag => ', event);
  }
}
