<div nz-row [nzGutter]="{ xs: 24, sm: 24, md: 24, lg: 32 }" nzAlign="middle">
  <div nz-col [nzSpan]="18">
    <div *ngIf="displayStatus" class="custom-div-section">
      <nz-steps [nzCurrent]="currentStep"  nzSize="small"  nzProgressDot >
        <nz-step [nzTitle]="stepTitle" *ngFor="let status of statuses; let i = index" >
          <ng-template #stepTitle>
            <span *ngIf="i == currentStep">{{status.name}}</span>
          </ng-template>
        </nz-step>
      </nz-steps>
    </div>
  </div>
  <div nz-col [nzSpan]="6" style="text-align: right;">
    <span class="header-avatar">Welcome, {{userName}} <nz-avatar [nzSize]="35" [nzSrc]="userPhoto"
        style="margin-left:2px ;"></nz-avatar> </span>
  </div>
</div>

