<form [nzLayout]="'vertical'" nz-form  [formGroup]="dissolutionProposalOverviewForm">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="10">
        <nz-form-item>
            <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Overview of Proposal</nz-form-label>
            <nz-form-control [@formControlAnimation]>
                <textarea nz-input placeholder="Overview of Proposal"
                    formControlName="overviewOfProposal" [nzAutosize]="{ minRows: 5, maxRows: 10 }" ></textarea>
            </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="10" nzOffset="1">
        <nz-form-item>
            <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Business Reason for Proposal</nz-form-label>
            <nz-form-control [@formControlAnimation]>
                <textarea nz-input placeholder="Business Reason for Proposal" formControlName="businessReasonForProposal"
                [nzAutosize]="{ minRows: 5, maxRows: 10 }" ></textarea>
            </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row nzGutter="8">
      
      <div nz-col nzSpan="5">
        <nz-form-item>
            <nz-form-label  [@formLabelAnimation] nzTooltipTitle="If unknown, please use best estimate."><label class="mandate">*</label> Target Completion Date</nz-form-label>
            <nz-form-control [@formControlAnimation]>
                <nz-date-picker formControlName="targetCompletionDate" nzPlaceHolder="Target Completion Date" style="width: 100%" ></nz-date-picker>
            </nz-form-control>
        </nz-form-item>
      </div>     
    </div>
  </form>
  