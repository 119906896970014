import { Injectable} from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, Qualification, REQUEST_ID, Withdrawal } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, from, map } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpResponse } from '@angular/common/http';
import { LegalEntity } from '../interfaces/LegalEntities';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';


@Injectable ({
    providedIn: 'root'
})
export class DataServiceQualificationWithdrawalProposalOverview {
  qualificationFormError$ = new BehaviorSubject<[]>([]);
  legalEntitySelected: LegalEntity[] = [];
  commonEntityList: LegalEntity[] = [{
    companyName: "NA", ccn: "NA"
  }];

  isSaveForLaterClicked$ = new BehaviorSubject<boolean>(false);
  qualificationLegalEntitySelectionForm: any;

  zxzz!: UntypedFormGroup;

  constructor(private session:SessionStorageService,
    private fb: UntypedFormBuilder,
    private apiService:P30APIService,
    private message: NzMessageService){ }

    checkBTNStatus(state: boolean) {
      this.isSaveForLaterClicked$.next(state);
    }

  getOwnershipRestructuringProposalOverview(): Observable<HttpResponse<any>>{
    return this.apiService.getProposalOverview(this.session.getItem(PROPOSAL_ID)).pipe(
      map(proposalOverview =>{
        return proposalOverview;
      })
    );
  }

  createOwnershipRestructuringProposalOverview(form: UntypedFormGroup,formEntity:UntypedFormGroup):Observable<HttpResponse<any>>{
    let summary = formEntity.controls["summaryLegalActivities"];
    const data = {
      "requestId": this.session.getItem(REQUEST_ID),
      "overviewOfProposal": form.controls["overviewOfProposal"].value,
      "businessReasonForProposal": form.controls["businessReasonForProposal"].value,
      "targetCompletionDate": '',
      "targetSigningDate": '',
      "nameOfShareHolderAndMore": '',
      "summaryofLegalEntitiesActivities": summary != undefined ? summary.value : '',
      "targetEffectiveDate": form.controls["targetEffectiveDate"].value,
      "proposedLegalEntityName": '',
      "countryorStateofIncorporation": '' }
    return this.apiService.createProposalOverview(this.session.getItem(PROPOSAL_ID), data).pipe(
      map(proposalOverview =>{
        return proposalOverview;
      })
    );
  }

  updateOwnershipRestructuringProposalOverview(form: UntypedFormGroup,formEntity:UntypedFormGroup): Observable<HttpResponse<any>>{
    let summary = formEntity.controls["summaryLegalActivities"];
    const data = {
      "requestId": this.session.getItem(REQUEST_ID),
      "overviewOfProposal": form.controls["overviewOfProposal"].value,
      "businessReasonForProposal": form.controls["businessReasonForProposal"].value,
      "targetCompletionDate": '',
      "targetSigningDate": '',
      "nameOfShareHolderAndMore": '',
      "summaryofLegalEntitiesActivities": summary != undefined ? summary.value : '',
      "targetEffectiveDate": form.controls["targetEffectiveDate"].value,
      "proposedLegalEntityName": '',
      "countryorStateofIncorporation": ''
    }
    return this.apiService.updateProposalOverview(this.session.getItem(PROPOSAL_ID), data).pipe(
      map(proposalOverview =>{
        return proposalOverview;
      })
    );
  }

  patchOwnershipRestructuringProposalOverviewDataToFormGroup(form: UntypedFormGroup,loader:boolean){
    this.apiService.getProposalOverview(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if(event.body){
            form.patchValue({
            requestId: this.session.getItem(REQUEST_ID),
            summaryId: event.body[0].summaryId,
            overviewOfProposal: event.body[0].overviewOfProposal,
            businessReasonForProposal: event.body[0].businessReasonForProposal,
            targetCompletionDate: '',
            targetSigningDate: '',
            nameOfShareHolderAndMore: '',
            summaryofLegalEntitiesActivities: '',
            targetEffectiveDate: event.body[0].targetEffectiveDate,
            proposedLegalEntityName: '',
            countryorStateofIncorporation: ''
          });
        }
      },
      error: (err) =>{
        console.log("Unable to get proposal overview from the database table ",err);
      },
      complete: () =>{

      }
     });
  }
  createOwnershipRestructuringProposalOverviewWithFormData(form: UntypedFormGroup,formEntity:UntypedFormGroup,loader:boolean){
    this.createOwnershipRestructuringProposalOverview(form,formEntity).subscribe({
      next: (event) =>{
        this.getOwnershipRestructuringProposalOverview().subscribe({
          next: (event) =>{
            if(event.body){
              form.patchValue({
                requestId: this.session.getItem(REQUEST_ID),
                summaryId: event.body[0].summaryId,
                overviewOfProposal: event.body[0].overviewOfProposal,
                businessReasonForProposal: event.body[0].businessReasonForProposal,
                targetCompletionDate: '',
                targetSigningDate: '',
                nameOfShareHolderAndMore: '',
                summaryofLegalEntitiesActivities: '',
                targetEffectiveDate: event.body[0].targetEffectiveDate,
                proposedLegalEntityName: '',
                countryorStateofIncorporation: ''
              });
            }
          }
        });
      },
      error:(err) =>{
        console.log("unable to create proposal overview", err);
      },
      complete: () =>{

      }
    });
  }
  updateOwnershipRestructuringProposalOverviewWithFormData(form: UntypedFormGroup,formEntity:UntypedFormGroup,loader:boolean){
    this.updateOwnershipRestructuringProposalOverview(form,formEntity).subscribe({
      next: (event) =>{

      },
      error:(err) =>{
        console.log("unable to update proposal overview", err);

      },
      complete: () =>{

      }
    });
  }
  submitProposalOverview(form:UntypedFormGroup,formEntity:UntypedFormGroup, loader:boolean, tabClass:string):Promise<boolean>{
    if(form.valid){
      this.getOwnershipRestructuringProposalOverview().subscribe({
        next: (event) =>{
          if(event.body){
            this.updateOwnershipRestructuringProposalOverviewWithFormData(form,formEntity,loader);
          }else{
            this.createOwnershipRestructuringProposalOverviewWithFormData(form,formEntity,loader);
          }
        }
      });

      return Promise.resolve(true);
    }else{

      Object.values(form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return Promise.resolve(false);
    }
  }

  reviewAndValidateOwnershipRestructuringProposalOverview(form:UntypedFormGroup): Promise<boolean>{
    const overviewOfProposalControl = form.controls["overviewOfProposal"];
    overviewOfProposalControl.setValidators([Validators.required]);
    overviewOfProposalControl.markAsDirty();
    overviewOfProposalControl.updateValueAndValidity();
    const businessReasonForProposalControl = form.controls["businessReasonForProposal"];
    businessReasonForProposalControl.setValidators([Validators.required]);
    businessReasonForProposalControl.markAsDirty();
    businessReasonForProposalControl.updateValueAndValidity();
    const targetCompletionDateControl = form.controls["targetEffectiveDate"];
    targetCompletionDateControl.setValidators([Validators.required]);
    targetCompletionDateControl.markAsDirty();
    targetCompletionDateControl.updateValueAndValidity();
    return Promise.resolve(form.valid);
  }


  getLegalEntities(): Observable<HttpResponse<any>> {
    return this.apiService.getLegalEntities(this.session.getItem(PROPOSAL_ID)).pipe(
      map(legalEntities => {
        return legalEntities;
      })
    );
  }


  reviewAndValidateOwnershipEntitySelection(form: UntypedFormGroup): Promise<boolean> {
    const entitySelectionControlValue = form.value.entityName;
    const commentsControlValue = form.value.entityComments;
    if (entitySelectionControlValue == 'NA') {
      if(commentsControlValue) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    } else {
      return Promise.resolve(true);
    }
  }

  async submitQualificationLegalEntitySelection(form: UntypedFormGroup): Promise<boolean> {
    console.log('form', form);
    if (form.valid) {
      console.log('entered if block');
      this.getLegalEntities().subscribe({
        next: (event) => {
          console.log('event', event);
          if (event.body) {
            this.createOrUpdateLegalEntityRecordIfRecordExist(form, event.body);
            this.updateEntitySelectionSummaryIfRecordExist(form);
          } else {
            this.createOrUpdateLegalEntityRecordIfNoRecord(form);
            this.createEntitySelectionSummaryIfNoRecords(form);
          }
        }
      });
      return Promise.resolve(true);
    } else {
      console.log('entered else block');
      let status;
      this.isSaveForLaterClicked$.subscribe((result: boolean) => {
        switch(result) {
          case true: {
            status = true;
            this.saveForLater(form)
            break;
          }
          case false: {
            status = false;
            Object.values(form.controls).forEach(control => {
              if (control.invalid) {
                control.markAsDirty();
                control.updateValueAndValidity({ onlySelf: true });
              }
            });
            break;
          }
        }
      })
      if(status) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    }
  }

  async createOrUpdateLegalEntityRecordIfRecordExist(form: UntypedFormGroup, event: any) {
    let commentsFormValue = form.controls['entityComments'].value;
    let entityNameValue = form.controls['entityName'].value;
    let ccn = '';
    let jurisdiction = '';
    let ownershipDetail = '';
    if (this.legalEntitySelected.length > 0) {  
      ccn = this.legalEntitySelected[0].ccn ?? '';
      jurisdiction = this.legalEntitySelected[0].jurisdiction?? '';
      ownershipDetail = this.legalEntitySelected[0].ownershipDetail?? '';
    }
    let leglaEntityPayload: LegalEntity = {
      ccn: (form.controls["entityName"].value) != 'NA' ? ccn ?? '' : '',
      companyName: entityNameValue,
      jurisdiction: (form.controls["entityName"].value) != 'NA' ? jurisdiction : '',
      ownershipDetail: (form.controls["entityName"].value) != 'NA' ? ownershipDetail : '',
      legalEntityComments: (form.controls["entityName"].value) != 'NA' ? '' : commentsFormValue,
      typeofLegalEntity: "Default",
      requestId: Number(this.session.getItem(REQUEST_ID))
    }
    if(event[0].legalEntityId) {
      leglaEntityPayload.legalEntityId = event[0]?.legalEntityId;
    }
    this.apiService.updateLegalEntity(this.session.getItem(PROPOSAL_ID), Number(event[0].legalEntityId), leglaEntityPayload).subscribe((res:any) => {
    })

  }

  createOrUpdateLegalEntityRecordIfNoRecord(form: UntypedFormGroup) {
    let ccnval="";
    if(form.controls["entityName"].value!= undefined && form.controls["entityName"].value!="NA" && form.controls["entityName"].value!=""){
      ccnval= this.legalEntitySelected.filter(data => data.companyName == form.controls["entityName"].value)[0]?.ccn?this.legalEntitySelected.filter(data => data.companyName == form.controls["entityName"].value)[0]?.ccn+"":"NA";
    }
    else if(form.controls["entityName"].value !=undefined && form.controls["entityName"].value=="NA"){
      ccnval="NA";
    }
    if (ccnval!=''){
      let leglaEntity: LegalEntity = {
        //ccn: (form.controls["entityName"].value!="NA" && form.controls["entityName"].value!="")?this.legalEntitySelected.filter(data => data.companyName == form.controls["entityName"].value)[0]?.ccn+"": "NA",
        ccn:ccnval,
        companyName: (form.controls["entityName"].value)?form.controls["entityName"].value:'',
        jurisdiction: "",
        ownershipDetail: "",
        legalEntityComments: form.controls["entityComments"].value,
        typeofLegalEntity: ""
      }
      this.createLegalEntity(leglaEntity).subscribe({
        next: (event) => {
          return;
        }
      });
    }
  }

  createLegalEntity(legalEntity: LegalEntity): Observable<HttpResponse<any>> {
    const data = {
      "ccn": legalEntity.ccn,
      "companyName": legalEntity.companyName,
      "jurisdiction": legalEntity.jurisdiction,
      "ownershipDetail": legalEntity.ownershipDetail,
      "legalEntityComments": legalEntity.legalEntityComments ? legalEntity.legalEntityComments : null,
      "typeofLegalEntity": "Default",
      "requestId": Number(this.session.getItem(REQUEST_ID))
    }
    return this.apiService.createLegalEntity(this.session.getItem(PROPOSAL_ID), data).pipe(
      map(legelaEntities => {
        return legelaEntities;
      })
    )
  }

  createSummaryPayload(value: string) {
    const payload = {
      "requestId": this.session.getItem(REQUEST_ID),
      "overviewOfProposal": '',
      "businessReasonForProposal": '',
      "targetCompletionDate": '',
      "targetSigningDate": '',
      "nameOfShareHolderAndMore": '',
      "summaryofLegalEntitiesActivities": value,
      "targetEffectiveDate": '',
      "proposedLegalEntityName": '',
      "countryorStateofIncorporation": ''
    }
    return payload;
  }

  createEntitySelectionSummaryIfNoRecords(form: UntypedFormGroup): Observable<HttpResponse<any>> {
    console.log("createEntitySelectionSummaryIfNoRecords form => ", form);
    let summaryValue = form.controls["summaryLegalActivities"].value;
    let payload = this.createSummaryPayload(summaryValue);
    return this.apiService.createProposalOverview(this.session.getItem(PROPOSAL_ID), payload).pipe(
      map(proposalOverview =>{
        return proposalOverview;
      })
    );
  }

  updateEntitySelectionSummaryIfRecordExist(form: UntypedFormGroup): Observable<HttpResponse<any>> {
    console.log("updateEntitySelectionSummaryIfRecordExist form => ", form);
    let summaryValue = form.controls["summaryLegalActivities"].value;
    let payload = this.createSummaryPayload(summaryValue);
    return this.apiService.updateProposalOverview(this.session.getItem(PROPOSAL_ID), payload).pipe(
      map(proposalOverview =>{
        return proposalOverview;
      })
    );
  }

  saveForLater(form: UntypedFormGroup) {
    this.getLegalEntities().subscribe({
      next: (event) => {
        if (event.body) {
          this.createOrUpdateLegalEntityRecordIfRecordExist(form, event.body);
          this.updateEntitySelectionSummaryIfRecordExist(form);
        } else {
          this.createOrUpdateLegalEntityRecordIfNoRecord(form);
          this.createEntitySelectionSummaryIfNoRecords(form);
        }
      }
    });
  }

  patchLegalEntitiesToFormGroup(loader: boolean, form: UntypedFormGroup) {
    let entityQuestions: any= [];
    let entityQuestionsSet: any= [];
    this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID))
    .subscribe({
      next:(event) =>{
        if(event.body){
          entityQuestions = [...event.body];
        }
      }
    });
     this.getLegalEntities().subscribe({
       next: (event) => {
         if (event.body) {
           let entities: LegalEntity[] = [];
           entities = [...event.body];
           entities.forEach(item => {
             if (item.ccn == "NA") {
               const control = form.controls["entityComments"]
               control.setValue(item.legalEntityComments);
               control.updateValueAndValidity();
               form.controls["entityName"].setValue(item.ccn);
               form.updateValueAndValidity();
             }
           })
         }

       },
       error: (err) => {
         this.message.error('Unable to retrieve legal entities!');
       },
       complete: () => {

       }
     });
   }


  reviewAndValidateEntitySelection(form: UntypedFormGroup): Promise<boolean> { 
    const control = form.controls["entityComments"]
    const summaryControl = form.controls["summaryLegalActivities"]
    const controlEntityName = form.controls["entityName"].value;
    const entity = form.controls['entityName'];
    if(controlEntityName == undefined || controlEntityName == null ) {
      console.log('entered');
      entity.setValidators([Validators.required]);
      entity.markAsDirty();
      entity.updateValueAndValidity();
      return Promise.resolve(false);
    }
    if (controlEntityName == undefined || controlEntityName == '') 
    {
      form.controls["entityName"].setValidators([Validators.required]);
      form.controls["entityName"].markAsDirty();
      return Promise.resolve(false);
    }
    if (summaryControl.value == '' || summaryControl.value == undefined || summaryControl.value == null) {
      summaryControl.setValidators([Validators.required]);
      summaryControl.markAsDirty();
      return Promise.resolve(false);
    }
    if (!isNonEmptyString(control.value) && controlEntityName=="NA") {
      control.setValidators([Validators.required]);
      control.markAsDirty();
      control.updateValueAndValidity();
      return Promise.resolve(form.valid);
    } else {
      control.removeValidators([Validators.required]);
      control.updateValueAndValidity();
      return Promise.resolve(form.valid);
    }
  }

  addQualificationOrWithdrawal(form: UntypedFormGroup, entityType: string): Observable<HttpResponse<any>> {
    console.log("addQualification form => ", form)
    const data = {
      "requestId": Number(this.session.getItem(REQUEST_ID)),
      "legalEntityId": null,
      "assests": "",
      "ownershipInterest": "",
      "nameOfEntityBeingAcquired": "",
      "sellerEntity": "",
      "approximateCost": "",
      "descriptionOfAsset": "",
      "jurisdiction": form.controls["jurisdictionDescription"].value,
      "haveActiveRegistrationsInOtherStatesCountries": false,
      "commentsForHavingActiveRegistrations": "",
      "haveSubsidiaries": false,
      "commentsForHavingSubsidiaries": "",
      "needBankAccounts": false,
      "commentsForNeedingBankAccounts": "",
      "willThereBeaNeedForAdditionalContributionsAfterInitialInvestment": false,
      "commentsForNeedingAdditionalContributions": "",
      "haveAnyEmployees": false,
      "commentsForHavingEmployees": "",
      "haveActiveBankAccounts": false,
      "commentsForHavingBankAccounts": "",
      "willTheNewEntityBeRegisteredInOtherStatesCountries": false,
      "commentsForNewEntityBeingRegistered": "",
      "willTheSurvivingEntityChangeItsNameAfterTheMerger": false,
      "commentsForSurvivingEntityChangingName": "",
      "entityType": entityType,
      "typeOfSale": "",
      "investmentMethod": ""
    }
    return this.apiService.createEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), data).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );
  }
  updateqQalificationOrWithdrawal(entityIndicatoryQuestionId: number, form: UntypedFormGroup, entityType: string): Observable<HttpResponse<any>> {
    const data = {
      "requestId": Number(this.session.getItem(REQUEST_ID)),
      "legalEntityId": null,
      "assests": "",
      "ownershipInterest": "",
      "nameOfEntityBeingAcquired": "",
      "sellerEntity": "",
      "approximateCost": "",
      "descriptionOfAsset": "",
      "jurisdiction": form.controls["jurisdictionDescription"].value,
      "haveActiveRegistrationsInOtherStatesCountries": false,
      "commentsForHavingActiveRegistrations": "",
      "haveSubsidiaries": false,
      "commentsForHavingSubsidiaries": "",
      "needBankAccounts": false,
      "commentsForNeedingBankAccounts": "",
      "willThereBeaNeedForAdditionalContributionsAfterInitialInvestment": false,
      "commentsForNeedingAdditionalContributions": "",
      "haveAnyEmployees": false,
      "commentsForHavingEmployees": "",
      "haveActiveBankAccounts": false,
      "commentsForHavingBankAccounts": "",
      "willTheNewEntityBeRegisteredInOtherStatesCountries": false,
      "commentsForNewEntityBeingRegistered": "",
      "willTheSurvivingEntityChangeItsNameAfterTheMerger": false,
      "commentsForSurvivingEntityChangingName": "",
      "entityType": entityType,
      "typeOfSale": "",
      "investmentMethod": "",
    }
    return this.apiService.updateEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), entityIndicatoryQuestionId, data).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );
  }

  getEntityIndicatorQuestions(): Observable<HttpResponse<any>> {
    return this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID)).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );
  }

  async submitEntityQuestions(form: UntypedFormGroup, tabClass: string): Promise<boolean> {
    const entityQuestionSubmisionResult: any = await new Promise<boolean>((resolved) => {
      this.getEntityIndicatorQuestions().subscribe({
        next: (event) => {
          resolved(event.body);
        }
      });
    })
    if (entityQuestionSubmisionResult) {
      let entityQuestions = []
      let qualification;
      let withdrawal;
      entityQuestions = [...entityQuestionSubmisionResult];
      qualification = form.value.qualification;
      withdrawal = form.value.withdrawal;
      return await this.entityIndicatorQuestionsIfQualification(form, entityQuestions, qualification, withdrawal);
    } else {
      return await this.entityIndicatorQuestionsElseBlock(form, tabClass);
    }
  }

  async entityIndicatorQuestionsIfQualification(qualificationWithdrawalForm : any, entityQuestions: any, qualification: any, withdrawal: any): Promise<boolean> {
    return await new Promise((resolved) => {
      let entitities: any[] = []
      this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID))
        .subscribe({
          next: (event) => {
            if (event.body) {
              entitities = [...event.body];
            

              if (((entitities[0].entityType == Qualification || entitities[0].entityType == Withdrawal) && qualification) || (entitities[0].entityType == "" && qualification)) {
                if (qualificationWithdrawalForm.valid) {
                  this.updateqQalificationOrWithdrawal(entityQuestions[0].entityQuestionsId, qualificationWithdrawalForm, qualification == true ? Qualification : "").subscribe({
                    next: (event) => {
                      resolved(true);
                    }
                  });

                } else {
                  Object.values(qualificationWithdrawalForm.controls).forEach((control: any) => {
                    if (control.invalid) {
                      control.markAsDirty();
                      control.updateValueAndValidity({ onlySelf: false });
                    }
                  });
                  resolved(false);
                }
              }
              else if (((entitities[0].entityType == Withdrawal || entitities[0].entityType == Qualification) && withdrawal) || (entitities[0].entityType == "" && withdrawal)) {
                const result = this.getQualificationFormValidResponse(qualificationWithdrawalForm, entityQuestions, withdrawal);
                resolved(result);
              }
              else {
                const result = this.getQualificationFormValidResponse(qualificationWithdrawalForm, entityQuestions, "");
                resolved(result);
              }
            }
          }
        });
      resolved(true);
    })
  }

  async entityIndicatorQuestionsElseBlock(form: UntypedFormGroup, tabClass: string): Promise<boolean> {
    return await new Promise((resolved) => {
      if (form.controls["qualification"].value) {
        this.addQualificationOrWithdrawal(form, Qualification).subscribe({
          next: (event) => {
            resolved(true);
          },
          error: (err) => {
            console.log("Unable to save qualification", err);
            resolved(false);
          },
          complete: () => {

          }
        })
      } else if (form.controls["withdrawal"].value) {
        this.addQualificationOrWithdrawal(form, Withdrawal).subscribe({
          next: (event) => {
            resolved(true);
          },
          error: (err) => {
            console.log("Unable to save withdrawal", err);
            this.isWithdrawalFError(err.error.detail[0].loc);
            resolved(false);
          },
          complete: () => { }
        });
      } else if (form.valid) {
        //form.valid && ownershipInterestForm.valid for ref
        resolved(true);
      }
    })
  }

  isWithdrawalFError(state: []) {
    this.qualificationFormError$.next(state);
  }
  
  async getQualificationFormValidResponse(qualificationWithdrawalSelectionForm: any, entityQuestions: any, withdrawal: any,) {
    return await new Promise<boolean>((resolved) => {
      if (qualificationWithdrawalSelectionForm.valid) {
        this.updateqQalificationOrWithdrawal(entityQuestions[0].entityQuestionsId, qualificationWithdrawalSelectionForm, withdrawal == true ? Withdrawal:"").subscribe({
          next: (event) => {
            return;
          }
        });
        resolved(true);
      } else {
        Object.values(qualificationWithdrawalSelectionForm.controls).forEach((control: any) => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: false });
          }
        });
        resolved(false);
      }
    })
  }

  reviewAndValidateQualificationEntityQuestions(form: UntypedFormGroup): Promise<boolean> {
    const assetControlValue = form.value.qualification;
    const ownershipInterestControlValue = form.value.withdrawal;
    if (assetControlValue || ownershipInterestControlValue) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

  reviewAndValidateQualificationDetails(form: UntypedFormGroup): Promise<boolean> {
    if (form.controls["qualification"].value || form.controls["withdrawal"].value) {
      const jurisdictionCostControl = form.controls["jurisdictionDescription"];
      jurisdictionCostControl.setValidators([Validators.required]);
      jurisdictionCostControl.markAsDirty();
      jurisdictionCostControl.updateValueAndValidity();
      return Promise.resolve(form.valid);
    } else {
      return Promise.resolve(form.valid);
    }
  }

  // reviewAndValidateWithdrawalDetails(form: UntypedFormGroup): Promise<boolean> {
  //   if (form.controls["withdrawal"].value) {
  //     const activeBankAccountsControl = form.controls["activeBankAccounts"];
  //     activeBankAccountsControl.setValidators([Validators.required]);
  //     activeBankAccountsControl.markAsDirty();
  //     activeBankAccountsControl.updateValueAndValidity();
  //     if (activeBankAccountsControl.value=="other") {
  //       const commentsControl = form.controls["withdrawalOthersComments"];
  //       commentsControl.setValidators([Validators.required]);
  //       commentsControl.markAsDirty();
  //       commentsControl.updateValueAndValidity();
  //     }
  //     return Promise.resolve(form.valid);
  //   } else {
  //     return Promise.resolve(form.valid);
  //   }
  // }
  
  patchEntityQuestionsDataToFormGroup(form: UntypedFormGroup) {
    this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        let entityQuestions = [];
        if (event.body) {
          entityQuestions = event.body;
          let qualification;
          let withdrawal;
          if (entityQuestions[0].entityType==Qualification) {
            qualification = true;
          } else {
            qualification = false;
          }
          if (entityQuestions[0].entityType==Withdrawal) {
            withdrawal = true;
          } else {
            withdrawal = false;
          }
          form.patchValue({
            qualification: qualification,
            withdrawal: withdrawal
          });
          if (qualification || withdrawal) {
            form.patchValue({
              entityIndicatorQuestionId: entityQuestions[0].entityQuestionsId,
              jurisdictionDescription: entityQuestions[0].jurisdiction,
            });
          } 
        }
      },
      error: (err) => {
        console.log("Unable to get entity question from the database table ", err);
      },
      complete: () => {
      }
    });
  }
}
