import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HOME_URL_PATH, SV_USER, SV_USER_ROLE } from 'src/app/constants/constants';
import { UserRole } from 'src/app/core/interfaces/UserRole';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.less']
})
export class EditUserComponent {
  validateEditUserForm!: UntypedFormGroup;
  userRoles: any[] =[];
  @Input() userData: any;
  @Output() enableEditUser = new EventEmitter<boolean>;
  constructor(private fb: UntypedFormBuilder, private apiService: P30APIService, private dataservice: DataService,
    private authService: CalAngularService,
    private router: Router,
    private session:SessionStorageService,
    private message: NzMessageService ){}

  ngOnInit(){
    this.dataservice.getUserRoles();
    if(this.dataservice.userRoles)
      this.userRoles = this.dataservice.userRoles;
    if(this.userData){
      this.validateEditUserForm = this.fb.group({
        firstName: [{value:this.userData.firstName,disabled:true}],
        lastName: [{value:this.userData.lastName,disabled:true}],
        userRoleTitle: [this.userData.roleTitle,[Validators.required]],
      });
    }else{
      this.validateEditUserForm = this.fb.group({
        firstName: [null],
        lastName: [null],
        userRoleTitle: [null,[Validators.required]],
      });
    }

  }

  onSubmit(){
    if(this.validateEditUserForm.valid){
      let userRoleId = this.dataservice.userRoles.find(e=>e.roleTitle===this.validateEditUserForm.value.userRoleTitle)?.userRoleId;
      if(userRoleId){
        const userFormData = {
          "firstName": this.userData.firstName,
          "lastName": this.userData.lastName,
          "cai": this.userData.cai,
          "jobTitle": this.userData.jobTitle,
          "objectId": this.userData.objectId,
          "provisioningId": this.userData.provisioningId,
          "emailAddress": this.userData.emailAddress,
          "userRoleId": userRoleId,
          "updatedBy": this.authService.cvxClaimsPrincipal.name,
          "updatedDate":new Date().toLocaleString("en-US")
        }
        this.apiService.updateUser(this.userData.objectId,userFormData).subscribe({
          next: (event)=>{
            if(event.body){
              if(this.authService.cvxClaimsPrincipal.objectId === event.body.objectId){
                this.session.setItem(SV_USER,event.body);
                let userRole = this.dataservice.userRoles.find(e=>e.userRoleId==event.body.userRoleId);
                this.session.setItem(SV_USER_ROLE,userRole);
              }
            }
          },
          error:(err)=>{
            console.log(`Unable to update users data ${err}`);
            this.message.error(`Unable to updated user role. Please try again later!`)
          },
          complete: () =>{
            this.message.success(`Successfully updated user role to: ${this.validateEditUserForm.value.userRoleTitle}`);
            this.navigateToPageComponent(HOME_URL_PATH);
          }
        })

      }

    }else{
      Object.values(this.validateEditUserForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }

  }
  navigateToPageComponent(data: string){
    this.router.navigate([data]);
  }
  onCancel(){
    this.enableEditUser.emit(false);
  }
}
