import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, ChangeDetectorRef, } from '@angular/core';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { P30APIService } from 'src/app/core/services/api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CalAngularService } from '@cvx/cal-angular';
import { Attachment } from 'src/app/core/interfaces/Attachment';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs/internal/observable/of';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { ADMIN, COMPLETE, COORDINATOR, REQUESTOR, SV_USER_ROLE } from 'src/app/constants/constants';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { DataServiceDissolutionSelection } from 'src/app/core/services/data_service_dissolution_selection';

@Component({
  selector: 'app-dissolution-proposal-attachments',
  templateUrl: './dissolution-proposal-attachments.component.html',
  styleUrl: './dissolution-proposal-attachments.component.less',
  animations: [formControlElement, formLabelElement],
})
export class DissolutionProposalAttachmentsComponent {

  constructor(private http: HttpClient, private apiService: P30APIService, private authService: CalAngularService,
    private msg: NzMessageService, private cdr: ChangeDetectorRef, private session: SessionStorageService,
    private commentService: DataServiceComments, private dataServiceDissolutionSelection: DataServiceDissolutionSelection) { }

  @Output() isLoader = new EventEmitter<boolean>;
  @Input() requestId: any;
  @Input() proposalId: any;
  @Input() proposalType: any;
  files: Attachment[] = [];
  //hideUpstreamAttachment:boolean=true;
  //disableAttachment:boolean=true;
  //hideDeleteAttachment: boolean = false;

  @Input() dissolutionProposalAttachmentForm!: UntypedFormGroup;

  //@ViewChild(FileUploadComponent) FileUploadComponent!: FileUploadComponent;

  upstreamChecklistuploaded: boolean=false;
  attachments: string = "attachments";

  @Input() attachmentUploadClass = "";
  Dissolution=true;


  ngOnInit() {
    //this.ShowAttachementOnUserRole();
    this.getAttachments();
    //this.getProposal(this.proposalId);
  }

  ShowAttachementOnUserRole() {

    const userRole = this.session.getItem(SV_USER_ROLE).roleTitle;
    const proposalStatus = this.commentService.getRequestStatus();
    if (userRole.toLocaleLowerCase() == ADMIN.toLocaleLowerCase())
    {
      //this.disableAttachment=false;
//      this.hideDeleteAttachment=false;
    }
    else if(userRole.toLocaleLowerCase() == REQUESTOR.toLocaleLowerCase() && proposalStatus.toLocaleLowerCase() == "draft".toLocaleLowerCase())
    //|| (userRole.toLocaleLowerCase() == COORDINATOR.toLocaleLowerCase())
    {
      console.log("proposalStatus1",proposalStatus);
      //this.disableAttachment=false;
      //this.hideDeleteAttachment=false;
    }
    else if(userRole.toLocaleLowerCase() == COORDINATOR.toLocaleLowerCase())
    {
      console.log("proposalStatus2",proposalStatus);

      //this.disableAttachment=false;
      //this.disableAttachment=false;
      if (proposalStatus.toLocaleLowerCase() == "complete".toLocaleLowerCase())
      {
        //this.disableAttachment=true;
      }
    }
    else {
      console.log("proposalStatus3",proposalStatus);
      //this.disableAttachment=true;
      //this.hideDeleteAttachment=true;
    }
  }


  upstreamDissolutionChecklistRequiredToggleBTN(event: any) {
    ;console.log(event)
  }

  emitIsLoader(event:boolean){
    this.isLoader.emit(event);
  }

  async getProposal(proposalId: string) {
    let proposal:any;
    const proposalResult = await new Promise((resolve)=>{
      this.isLoader.emit(true);
      this.apiService.getProposal(proposalId).subscribe({
        next: (event) => {
          proposal = event.body;
        },
        error: (err) => {
          //  this.message.error(this.apiErrorMSG);
          console.log("Unable to retrieve proposal record from the database table ", err);
          this.isLoader.emit(false);
          resolve(false);
        },
        complete: () => {
          this.isLoader.emit(false);
          resolve(true);
        }
      });
    });
    if(proposalResult){
      if (proposal && proposal.businessSegment) {
        //this.hideUpstreamAttachment=true;
        if(proposal.businessSegment.toLowerCase()=="upstream")
        {
          //this.hideUpstreamAttachment=false;
          this.getAttachments();
        }
      }
    }
  }
  getAttachments(): void {
    this.isLoader.emit(true);
    this.apiService.getAttachments(this.proposalId).subscribe(response => {
      this.files = [];
      if (response.body) {
        const data = response.body
        data.forEach((element: Attachment) => {
          this.files.push(element)
          if (element.upstreamDissolutionChecklistUploaded == true) {
            this.dissolutionProposalAttachmentForm.controls["upstreamChecklistuploaded"].setValue(true); 
            console.log("upstreamChecklistuploaded",this.upstreamChecklistuploaded);  
            return;
          }
        })
      }
    })
    this.isLoader.emit(false);
  }

  nzAction = (item: any) => {
    if(item.file.size!= undefined && item.file.size>=4294967296)//4gb
    {
      return of({}).subscribe({
        next:()=>{this.msg.error(`Invalid file size of '${item.file.name}',upload limit is 4 GB`);}
      });
    }
    this.isLoader.emit(true);
    let upstreamChecklistuploaded= this.upstreamChecklistuploaded?"1":"0";
    return this.apiService.createAttachment(item.file, this.proposalId, upstreamChecklistuploaded, this.authService.cvxClaimsPrincipal.name, this.authService.cvxClaimsPrincipal.email, new Date().toLocaleString("en-US")).subscribe({
      next: (event: HttpEvent<any> | HttpResponse<any>) => {
        if (event instanceof HttpResponse) {
          if (this.upstreamChecklistuploaded==true)
          {
            this.dissolutionProposalAttachmentForm.controls["upstreamChecklistuploaded"].setValue(true); 
          }
          console.log('File uploaded successfully');
        } else if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(95 * event.loaded / event.total!);
          console.log(`Upload progress: ${percentDone}%`);
        }
      },
      error: (err) => {
        console.log(`Unable to upload file or create attachment record${err}`)
        this.isLoader.emit(false);
      },
      complete: () => {
        setTimeout(() => {
          this.cdr.detectChanges();
        }, 500);

        this.getAttachments();
        this.isLoader.emit(false);

      }
    })
  }
  deleteAttachment(fileName: any) {
    let attachmentId = this.files.filter(data => data.fileName==fileName)[0].attachmentId
    if(attachmentId){
      this.isLoader.emit(true);
      this.apiService.deleteAttachment(this.proposalId,attachmentId,fileName).subscribe({
        next: (output) =>{
          if(output.status==200){
            console.log("file deleted successfully")
          }
        },
        error: (error) => {
          console.log('An error occured during file delete:', error);
          this.isLoader.emit(false);
        },
        complete: () => {
          console.log('completed the file delete process');
          this.cdr.detectChanges();
          this.getAttachments();
          this.isLoader.emit(false);
          // write the code to delete record from database
        }
      })
    }
  }
  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`);
    }
  }



}
