import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { API_FAILING_ERROR_MESSAGE, PROPOSAL_ID } from 'src/app/constants/constants';
import { LegalEntity } from 'src/app/core/interfaces/LegalEntities';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceEntitySelection } from 'src/app/core/services/data_service_entity_selection';
import { DataServiceOwnershipRestructuringProposalOverview } from 'src/app/core/services/data_service_ownership_proposal_overview';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';

@Component({
  selector: 'app-ownership-restructuring-entity-selection',
  templateUrl: './ownership-restructuring-entity-selection.component.html',
  styleUrl: './ownership-restructuring-entity-selection.component.less',
  animations: [formControlElement, formLabelElement],
})
export class OwnershipRestructuringEntitySelectionComponent {
  @Output() isLoader = new EventEmitter<boolean>;
  @Input() entitySelectionTabClass = '';
  @Input() legalEntityOwnershipRestructuringForm!: UntypedFormGroup;
  apiErrorMSG: string = API_FAILING_ERROR_MESSAGE;
  selectedLegalentity!: string;
  nzLoading: boolean = false;
  isEntityDetailsVisible: boolean = false;
  isCommentsVisible: boolean = false;
  listofEntities: LegalEntity[] = [];
  selectedLegalEntityDetails: LegalEntity[] = [];
  nzSearchValue: string = '';
  ownershipFormModel = {
    entityName: '',
    entityComments: '',
    summaryLegalActivities: '',
  };

  constructor(
    private apiService: P30APIService,
    private dataservice: DataService,
    private session: SessionStorageService,
    private message: NzMessageService,
    private entitySelectionDataService: DataServiceEntitySelection,
    private ownershipRestructuringService: DataServiceOwnershipRestructuringProposalOverview
  ) {}

  ngOnDestroy() {
    console.log("ngOnDestroy ownership-form => ", this.legalEntityOwnershipRestructuringForm);
    this.ownershipRestructuringService.zxzz = this.legalEntityOwnershipRestructuringForm;
    this.ownershipRestructuringService.ownerShipLegalEntitySelectionForm = this.legalEntityOwnershipRestructuringForm;
    //this.ownershipRestructuringService.patchLegalSelectionFormFieldsValue(this.legalEntityOwnershipRestructuringForm)
  }

  ngOnInit() {
    this.getLegalEntities();
    this.getOwnershipRestructuringSummary();
    console.log('legalEntityOwnershipForm => ', this.legalEntityOwnershipRestructuringForm);
    console.log('service form onload  => ', this.ownershipRestructuringService.ownerShipLegalEntitySelectionForm);
    console.log('service form zxzz onload  => ', this.ownershipRestructuringService.zxzz);
    this.listofEntities = [...this.ownershipRestructuringService.commonEntityList];
  }

  getOwnershipRestructuringSummary() {
    this.isLoader.emit(true);
    this.ownershipRestructuringService.getOwnershipRestructuringProposalOverview().subscribe((res:any) => {
      console.log("getOwnershipRestructuringProposalOverview => ", res);
      this.isLoader.emit(false);
      if(res['body']) {
        const form = this.legalEntityOwnershipRestructuringForm.controls['summaryLegalActivities']
        form.setValue(res['body'][0]['summaryofLegalEntitiesActivities']);
      }
    })
  }

  getLegalEntities(): Promise<boolean> {
    return new Promise<boolean>((resolved) => {
      if (this.session.getItem(PROPOSAL_ID)) {
        this.isLoader.emit(true);
        this.entitySelectionDataService.getLegalEntities().subscribe({
          next: (event) => {
            //console.log("get_LegalEntities => ", event);
            if (event['body']) {
              const form = this.legalEntityOwnershipRestructuringForm;
              console.log("get_LegalEntities body => ", event['body']);
              this.selectedLegalentity=event['body'][0]['companyName'];
              if(event['body'][0]['companyName'] === 'NA') {
                form.controls['entityName'].setValue(event['body'][0]['companyName']);
                form.controls['entityComments'].setValue(event['body'][0]['legalEntityComments']);
              } else {
                form.controls['entityName'].setValue(event['body'][0]['companyName']);
                this.selectedLegalEntityDetails.push(event['body'][0]);
                if (this.listofEntities.find((e: LegalEntity) => e.ccn == event['body'][0]['ccn']) === undefined) {
                  this.listofEntities.push(event['body'][0]);
                }
              }
            }
          },
          error: (err) => {
            this.message.error(this.apiErrorMSG);
            this.isLoader.emit(false);
            resolved(false);
            console.log("Unable to get legal entity record in the database table ", err);
          },
          complete: () => {
            this.isLoader.emit(false);
            resolved(true);
          }
        });
      }
    });
  }

  onEntitySearchEvent(event: any) {
    console.log('onSellerSearchEvent => ', event);
    if (event) {
      this.nzSearchValue = event;
    }
  }

  onSearchEntity() {
    this.nzLoading = true;
    if (this.nzSearchValue) {
      this.apiService.getCompanies(this.nzSearchValue).subscribe({
        next: (event) => {
          console.log('getCompanies', event);
          let arrResult = [];
          arrResult = [...event.body.companies];
          arrResult.forEach(element => {
            let legalEntity: LegalEntity = {
              ccn: element.companyCodeNumber,
              companyName: element.companyName,
              jurisdiction: element.countryName
            }
            this.listofEntities.push(legalEntity);
          });
        },
        error: (err) => {
          this.message.error(this.apiErrorMSG);
          this.nzLoading = false;
        },
        complete: () => {
          this.nzLoading = false;
        }
      });
    }
  }
  async onEntityChange(event: any) {
    if (event == undefined) {
      return
    } else if(event.length > 0) {
      console.log('onEntityChange => ', event);
      this.selectedLegalentity = event;
      const entityName = this.legalEntityOwnershipRestructuringForm.controls['entityName'];
      const entityCommentsValidation = this.legalEntityOwnershipRestructuringForm.controls['entityComments'];
      const summaryControl = this.legalEntityOwnershipRestructuringForm.controls["summaryLegalActivities"]
      if(event === '') {
        entityName.setValidators([Validators.required]);
        entityName.updateValueAndValidity();
      }
      else if (event === 'NA') {
        entityCommentsValidation.setValidators([Validators.required]);
        entityCommentsValidation.updateValueAndValidity();
        this.isCommentsVisible = true;
        this.isEntityDetailsVisible = false;
      } else {
        this.isLoader.emit(true);
        this.apiService.getCompanyDetails(this.listofEntities.find(data=>data.companyName===event)?.ccn).subscribe({
          next: (output) =>{
            console.log("getCompanyDetails => ", output);
            if(output.body) {
              const modifiedLegalEntities = this.listofEntities.map(obj => {
                if (obj.ccn === output.body.companyCodeNumber) {
                    return { ...obj, ownershipDetail: output.body.ownership };
                }
                return obj;
              });
              this.listofEntities = [...modifiedLegalEntities];
            }
          },
          error: (err)=>{
            this.message.error(this.apiErrorMSG);
            this.isLoader.emit(false);
          },
          complete: () =>{
            this.addField(event, this.listofEntities);
            this.isLoader.emit(false);
          }
        });
        entityCommentsValidation.removeValidators([Validators.required]);
        entityCommentsValidation.setValue(null);
        entityCommentsValidation.updateValueAndValidity();
        this.isCommentsVisible = false;
        this.isEntityDetailsVisible = true;
      }
      if (summaryControl.value == '' || summaryControl.value == undefined || summaryControl.value == null) {
        summaryControl.setValidators([Validators.required]);
        summaryControl.updateValueAndValidity();
      }
    }
  }

  async onChangeGetLegalEntities(isNa: boolean) {
    this.entitySelectionDataService.getLegalEntities().subscribe({
      next:(event)=>{
        let legalEntities = [];
        if(event.body){
          legalEntities = [...event.body];
          legalEntities.forEach(findItem =>{
            switch (isNa) {
              case (true): {
                if(findItem.legalEntityComments || findItem.companyName == "NA" || findItem.companyName == ""){
                  this.deleteLegalEntity(findItem.legalEntityId);
                }
                return;
              }
              case (false) : {
                if(findItem.legalEntityId && (findItem.companyName !=="NA" || findItem.companyName!="")){
                  this.deleteLegalEntity(findItem.legalEntityId);
                }
                return;
              } default: {
                return
              }
            }
          });
        }
      },
      error: (err)=>{
        this.message.error(this.apiErrorMSG);
      }
    });
  }


  deleteLegalEntity(legalEntityId:any){
    this.isLoader.emit(true);
      this.apiService.deleteLegalEntity(this.session.getItem(PROPOSAL_ID), legalEntityId).subscribe({
        next: (out) =>{
        },
        error: (err) =>{
          this.message.error(this.apiErrorMSG);
          console.log("Unable to create legal entity record in the database table ",err);
          this.isLoader.emit(false);
        },
        complete: () => {
          this.getLegalEntities();
          this.isLoader.emit(false);
        }
      })
  }

  addField(value:string, array: LegalEntity[]) {
    console.log("addField_ => ", {value}, {array});
    const legalEntityForm: any = {
      legalEntityName: value,
      ccn: array.filter(data => data.companyName === value)[0]?.ccn,
      companyName: value,
      jurisdiction: array.filter(data => data.companyName === value)[0].jurisdiction !== undefined ? array.filter(data => data.companyName === value)[0].jurisdiction : '',
      ownershipDetail: array.filter(data => data.companyName === value)[0].ownershipDetail !== undefined ? array.filter(data => data.companyName === value)[0].ownershipDetail : '',
    }
    console.log("addField_legalEntityForm => ", legalEntityForm);
    this.selectedLegalEntityDetails = [];
    this.selectedLegalEntityDetails.push(legalEntityForm);
    this.ownershipRestructuringService.legalEntitySelected = [];
    this.ownershipRestructuringService.legalEntitySelected.push(legalEntityForm)
  }

  getErrorTipForSellerLegalEntity(controlName: string) {
    const form = this.legalEntityOwnershipRestructuringForm;
    const control = form.controls[controlName];
    switch (control) {
      case <any>control.hasError('required'): {
        return 'Please select the legal entity!';
      }
      default: {
        return '';
      }
    }
  }

  getLegalActivitiesValue(event: any) {
    console.log('getLegalActivitiesValue => ', event?.target?.value);
    this.session.setItem("ownershipLegalActivityFormValue", event?.target?.value);
  }
}
