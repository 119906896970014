<div class="content-proposal-review">
  <nz-collapse [nzGhost]="true">
    <nz-collapse-panel nzHeader="General Details" nzDisabled="false" [nzExpandedIcon]="collapseIcon">
      <div class="general-details">
        <form nz-form class="request-details-form" [formGroup]="generalDetailsForm">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Requested For</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <input formControlName="requestedFor" type="text" nz-input />
            </nz-form-control>
          </nz-form-item>
          @if(requestStateName !== 'draft'){
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Requested By</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <input formControlName="requestedBy" type="text" nz-input />
            </nz-form-control>
          </nz-form-item>
          }
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Request Title</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <input formControlName="requestTitle" type="text" nz-input />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Proposal Id</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <input formControlName="proposalId" type="text" nz-input [disabled]="true" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Business Unit Segment</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <input formControlName="businessSegmentUnit" type="text" nz-input />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Region</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <input formControlName="region" type="text" nz-input />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>OPCO</nz-form-label>
            <nz-form-control [@formControlAnimation]>
                <input formControlName="opco" type="text" nz-input />
            </nz-form-control>
        </nz-form-item>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Urgent</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-switch formControlName="urgent"></nz-switch>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Urgency Explanation</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="urgencyExplanation"
                [nzAutosize]="{ minRows: 8, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </form>
      </div>
    </nz-collapse-panel>
    <nz-collapse-panel nzHeader="Proposal Details" nzDisabled="false" [nzExpandedIcon]="collapseIcon">
      <p><strong>Entity Selection</strong></p>
      <div nz-row nzGutter="10">
        <!-- Sales Seller details -->
        <nz-collapse [nzGhost]="true">
          <nz-collapse-panel nzHeader="Sales Seller" nzDisabled="false" [nzExpandedIcon]="collapseIcon">
            <div nz-col nzGutter="8">
              @switch(isSellerNASelected){
              @case(true){
              <ng-container *ngTemplateOutlet="salelsEntityIfNASelected;
                      context: { $implicit: 'Buyer Entity',
                      data: sellerEntityCommentsValue}
                      ">
              </ng-container>
              }
              @case(false){
              <ng-container *ngTemplateOutlet="salelsEntityIfNANotSelected;
                      context: { $implicit: 'Buyer Entity',
                      data: selectedSellerEntityDetails}
                      ">
              </ng-container>}
              }
            </div>
          </nz-collapse-panel>
        </nz-collapse>
        <!-- Sales Buyer details -->
        <nz-collapse [nzGhost]="true" style="margin-left: 3rem;">
          <nz-collapse-panel nzHeader="Sales Buyer" nzDisabled="false" [nzExpandedIcon]="collapseIcon">
            <div nz-col nzGutter="8" nzOffset="1">
              @switch(isBuyerNASelected){
              @case(true){
              <ng-container *ngTemplateOutlet="salelsEntityIfNASelected;
                      context: { $implicit: 'Buyer Entity',
                      data: buyerEntityCommentsValue}
                      ">
              </ng-container>
              }
              @case(false){
              <ng-container *ngTemplateOutlet="salelsEntityIfNANotSelected;
                      context: { $implicit: 'Buyer Entity',
                      data: selectedBuyerEntityDetails}
                      ">
              </ng-container>}
              }
            </div>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
      <p style="margin-top: 0.75rem;">Entity Questions</p>
      <div nz-row nzGutter="12">
        <nz-table #basicTable [nzData]="entityQuestionsRecords" class="reviewPageBlurTable">
          <thead>
            <tr>
              <th scope="col">Type of Sale</th>
              <th scope="col">Have any Employees</th>
              <th scope="col">Have any Employees Comments</th>
              <th scope="col">Have active bank Accounts</th>
              <th scope="col">Have active bank Accounts Comments</th>
              <th scope="col">Have Subsidiaries</th>
              <th scope="col">Have Subsidiaries Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of entityQuestionsRecords">
              <td>{{ data?.typeOfSale }}</td>
              <td>{{ data?.haveAnyEmployees }}</td>
              <td>{{ data?.commentsForHavingEmployees }}</td>
              <td>{{ data?.haveActiveBankAccounts }}</td>
              <td>{{ data?.commentsForHavingBankAccounts }}</td>
              <td>{{ data?.haveSubsidiaries }}</td>
              <td>{{ data?.commentsForHavingSubsidiaries }}</td>
            </tr>
          </tbody>
        </nz-table>
      </div><br>

      <p><strong>Proposal Overview</strong></p>
      <div class="proposal-overview">
        <form nz-form [formGroup]="acquisitionProposalOverviewForm">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Overview of Proposal</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="overviewOfProposal"
                [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Business Reason for Proposal</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="businessReasonForProposal"
                [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Target Signing Date</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-date-picker formControlName="targetSigningDate" style="width: 100%"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Target Completion Date</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-date-picker formControlName="targetCompletionDate" style="width: 100%"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngIf="isShareHolderControlVisible">
            <nz-form-label [@formLabelAnimation]>Name of Shareholder(s) or Owner(s) and<br>
              Proposed Voting and Capital Structure Percentages
            </nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="nameOfShareHolderAndMore"
                [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </form>
      </div>
      <p><strong>Attachments</strong></p>
      <div class="attachments">
        <div *ngFor="let file of files">
          <a [href]="file.filePath"><span nz-icon nzType="paper-clip"
              nzTheme="outline"></span>&nbsp;{{file.fileName}}</a>
        </div>
      </div>
    </nz-collapse-panel>
    <nz-collapse-panel nzHeader="Assignments" nzDisabled="false" [nzExpandedIcon]="collapseIcon">
      <div class="assignment">
        <p><strong>General</strong></p>
        <form nz-form [formGroup]="contactsForm">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Coordinator</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="coordinator" [nzAutosize]="{ minRows: 2, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Additional CC's For Notifications</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="additionalCcsForNotifications"
                [nzAutosize]="{ minRows: 2, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
          @if(requestStateName !== 'draft') {
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>IFC Required?</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-switch formControlName="ifcRequired"></nz-switch>
            </nz-form-control>
          </nz-form-item>
          }
        </form>
      </div>

      <!-- IFC Reviewers -->
      <div class="assignment" *ngIf="requestStateName !== 'draft' && ifcRequired == true">
        <p><strong>IFC Reviewers</strong></p>
        <app-ifc-reviewer [reviewComponent]="isReviewComponent" [isIFCRequired]="ifcRequired"></app-ifc-reviewer>
      </div>
      <!-- comments -->
      <div class="assignment">
        <p><strong>Comments</strong></p>
        <app-comments [reviewComponent]="isReviewComponent"></app-comments>
      </div>
    </nz-collapse-panel>
    <nz-collapse-panel nzHeader="History" nzDisabled="false" [nzExpandedIcon]="collapseIcon">
      <div nz-row nzGutter="8" *ngFor="let item of histories">
        @for(role of userRoles;track role.userRoleId){
        @if(item.action.includes('Endorsed') && role.userRoleId == item.userRoleId){
        <label style="margin-left:30px ;">
          {{item.action}} on <strong>{{item.date}}</strong>
        </label>
        }@else if(role.userRoleId == item.userRoleId) {
        <label style="margin-left:30px ;">
          {{item.action}} by <strong>{{role.roleTitle}} {{item.user}}</strong> on <strong>{{item.date}}</strong>
        </label>
        }
        }
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</div>


<ng-template #salelsEntityIfNASelected let-name let-data="data">
  <div class="entity-selection">
      <div class="NASelectedBlock">
        <div class="name">
          <span>Legal Entity:</span>
          <p> NA </p>
        </div>
        <div class="comment">
          <span>Comments:</span>
          <p> {{data}} </p>
        </div>
      </div>
  </div>
</ng-template>

<ng-template #salelsEntityIfNANotSelected let-name let-data="data">
  <div class="legal-entity" *ngFor="let legalEntity of data">
    <p class="paragraph">Legal Entity Name:&nbsp;<span>{{legalEntity.companyName}}</span></p>
    <p class="paragraph">Company Code Number:&nbsp;<span>{{legalEntity.ccn}}</span></p>
    <p class="paragraph">Company Name:&nbsp;<span>{{legalEntity.companyName}}</span></p>
    <p class="paragraph">Jurisdiction:&nbsp;<span>{{legalEntity.jurisdiction}}</span></p>
    <p class="paragraph">Ownership Details:&nbsp;<span>{{legalEntity.ownershipDetail}}</span>
    </p>
</div>
</ng-template>

