import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';
import { PROPOSAL_ID, REQUEST_ID, SV_PROPOSAL_STATUS, SalesBuyer, SalesSeller } from 'src/app/constants/constants';
import { Attachment } from 'src/app/core/interfaces/Attachment';
import { AcquisitionEntityQuestion } from 'src/app/core/interfaces/EntityIndicatorQuestion';
import { UserRole } from 'src/app/core/interfaces/UserRole';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceGeneralDetails } from 'src/app/core/services/data_service_general_details';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DataServiceContacts } from 'src/app/core/services/data_service_contacts';
import { DataServiceSalesEntity } from 'src/app/core/services/data_service_sale_entityQuestion';
import { LegalEntity } from 'src/app/core/interfaces/LegalEntities';

@Component({
  selector: 'app-sales-proposal-review',
  templateUrl: './sales-proposal-review.component.html',
  styleUrls: ['./sales-proposal-review.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class SalesProposalReviewComponent {
  collapseIcon:string = 'double-right';
  generalDetailsForm!:UntypedFormGroup;
  requestedForUser!: string;
  coordinators: string[] =[];
  additionalCcs: string[] =[];
  businessSegmentUnit!: string;
  region!: string;
  @Input() acquisitionProposalOverviewForm!:  UntypedFormGroup;
  @Input() legalEntitySalesForm!: UntypedFormGroup;
  @Input() acquisitionEntitySelectionForm!: UntypedFormGroup;
  @Input() contactsForm!:UntypedFormGroup;
  @Input() isShareHolderControlVisible:boolean=true;
  entityQuestions: AcquisitionEntityQuestion[] = [];
  showAsset:boolean = false;
  showOwnsershipInterest:boolean = false;
  showLegalEntityNA:boolean = false;
  selectedLegalEntities: any[] = [];
  files: Attachment[] = [];
  histories: any[] = [];
  userRoles: UserRole[] = [];
  requestStateName: string = '';
  ifcRequired!: boolean;
  isReviewComponent!: boolean;
  isSellerNASelected: boolean = false;
  isBuyerNASelected: boolean = false;
  selectedSellerEntityDetails: LegalEntity[] = [];
  selectedBuyerEntityDetails: LegalEntity[] = [];
  sellerEntityCommentsValue: string = '';
  buyerEntityCommentsValue: string = '';
  selectedNA: string = 'NA';
  entityQuestionsRecords: any[] = [];

  constructor(private dataservice:DataService, private generalDetailsDataService:DataServiceGeneralDetails,
    private apiService:P30APIService, private session:SessionStorageService,
    private commentService: DataServiceComments, private contactService: DataServiceContacts,
    private salesDataService: DataServiceSalesEntity){

  }

  async ngOnInit(){
    this.getUserRoles();
    this.getAttachments();
    this.getHistories();
    this.patchContactsFormValues();
    await this.getEntitesSelections();
    await this.getEntitesQuestions();
    // to get the request state for dynamic UI show/Hide
    this.requestStateName = this.session?.getItem(SV_PROPOSAL_STATUS).name.toLocaleLowerCase();
    this.isReviewComponent = true;
    this.acquisitionEntitySelectionForm.disable();
    this.acquisitionProposalOverviewForm.disable();
    this.contactsForm.disable();
    if(this.dataservice.aqGeneralDetailsForm){
      this.generalDetailsDataService.patchGeneralDetailsDataToFormGroup(this.dataservice.aqGeneralDetailsForm);
      this.generalDetailsForm = this.dataservice.aqGeneralDetailsForm;
      this.requestedForUser = this.generalDetailsForm.controls["requestedFor"].value;
      this.businessSegmentUnit = this.generalDetailsForm.controls["businessSegmentUnit"].value;
      this.region = this.generalDetailsForm.controls["region"].value;
      this.generalDetailsForm.disable();
    }
  }

  async patchContactsFormValues() {
    this.apiService.getContacts(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if(event['body']){
          this.ifcRequired = event.body[0].ifcRequired;
          let coordinatorNames = event.body[0].coordinator.includes(';') ? event.body[0].coordinator.split(';') : [event.body[0].coordinator];
          let additionalCcNames = event.body[0].additionalCcsForNotifications.includes(';') ? event.body[0].additionalCcsForNotifications.split(';') : [event.body[0].additionalCcsForNotifications];
          this.contactsForm.patchValue({
            requestId: this.session.getItem(REQUEST_ID),
            contactId: event.body[0].contactId,
            coordinator: coordinatorNames,
            additionalCcsForNotifications: additionalCcNames,
            ifcRequired: event.body[0].ifcRequired
          })
        }
      },
      error: (err) =>{
        console.log("Unable to get general contacts from the database table ",err);
      },
      complete: () =>{

      }
    })
  }

  ngOnDestroy(){
    this.dataservice.aqGeneralDetailsForm.enable();
    this.acquisitionEntitySelectionForm.enable();
    this.legalEntitySalesForm.enable();
    this.acquisitionProposalOverviewForm.enable();
    this.contactsForm.enable();
    this.isReviewComponent = false;
  }

  getAttachments(): void {
    this.apiService.getAttachments(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      this.files = [];
      if (response.body) {
        const data = response.body
        data.forEach((element: Attachment) => {
          this.files.push(element)
        })
      }
    })

  }
  getHistories():void {
    this.apiService.getHistories(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      if(response.body) {
        this.histories = [...response.body];
      }
    })
  }
  getUserRoles(){
    this.apiService.getAllUserRoles().subscribe(output=>{
      if(output.status == 200){
        this.userRoles = [...output.body];
      }
    })
  }

  async getEntitesSelections() {
    let apiResponse = this.salesDataService.getLegalEntities();
    apiResponse.subscribe({
      next: (entitiesList) => {
        console.log("entitiesList => ", entitiesList);
        if(entitiesList.body) {
          let sellerEntity = entitiesList['body'].find((e: any) => e.typeofLegalEntity === SalesSeller)
          let buyerEntity = entitiesList['body'].find((e: any) => e.typeofLegalEntity === SalesBuyer);
          console.log("entitiesList-sellerEntity-buyerEntity => ", {sellerEntity}, {buyerEntity});
          this.selectedSellerEntityDetails = [];
          this.selectedBuyerEntityDetails = [];
          if(sellerEntity.ccn == 'NA' || sellerEntity.companyName == 'NA' ) {
            this.isSellerNASelected = true;
            this.sellerEntityCommentsValue = sellerEntity.legalEntityComments;
          } else {
            this.isSellerNASelected = false;
            this.selectedSellerEntityDetails.push(sellerEntity);
          }
          if(buyerEntity.ccn == 'NA' || buyerEntity.companyName == 'NA' ) {
            this.isBuyerNASelected = true;
            this.buyerEntityCommentsValue = buyerEntity.legalEntityComments;
          } else {
            this.isBuyerNASelected = false;
            this.selectedBuyerEntityDetails.push(buyerEntity);
          }
        }
      },
      error: (err) => {
        console.log("unable to get sales legal entity ", err);
      },
      complete: () => {
        console.log("this.selectedSellerEntityDetails => ", this.selectedSellerEntityDetails);
        console.log("this.selectedBuyerEntityDetails => ", this.selectedBuyerEntityDetails);
      }
    })
  }

  async getEntitesQuestions() {
    let apiResponse = this.salesDataService.getEntityIndicatorQuestions();
    apiResponse.subscribe({
      next: (entitiesQuestionsList) => {
        this.entityQuestionsRecords = [];
        console.log("entitiesQuestionsList => ", entitiesQuestionsList);
        if(entitiesQuestionsList['body']) {
          console.log("entitiesQuestionsList body => ", entitiesQuestionsList['body']);
          let activeAccounts = entitiesQuestionsList['body'][0].haveActiveBankAccounts == true ? 'Yes' : 'No';
          let haveEmployee = entitiesQuestionsList['body'][0].haveAnyEmployees == true ? 'Yes' : 'No';
          let haveSubsidiaries = entitiesQuestionsList['body'][0].haveSubsidiaries == true ? 'Yes' : 'No';
          let data = {};
          let typeOfSale = entitiesQuestionsList['body'][0].typeOfSale;
          if (typeOfSale === 'Sale of Entity') {
             data = {
              typeOfSale: entitiesQuestionsList['body'][0].typeOfSale,
              haveActiveBankAccounts: activeAccounts,
              commentsForHavingBankAccounts: entitiesQuestionsList['body'][0].commentsForHavingBankAccounts,
              haveAnyEmployees: haveEmployee,
              commentsForHavingEmployees: entitiesQuestionsList['body'][0].commentsForHavingEmployees,
              haveSubsidiaries: haveSubsidiaries,
              commentsForHavingSubsidiaries: entitiesQuestionsList['body'][0].commentsForHavingSubsidiaries
            }
          } else if (typeOfSale === 'Sale of Asset' || typeOfSale === 'Sale of Entity & Asset'){
             data = {
              typeOfSale: entitiesQuestionsList['body'][0].typeOfSale,
              // haveActiveBankAccounts: activeAccounts,
              // commentsForHavingBankAccounts: entitiesQuestionsList['body'][0].commentsForHavingBankAccounts,
              // haveAnyEmployees: haveEmployee,
              // commentsForHavingEmployees: entitiesQuestionsList['body'][0].commentsForHavingEmployees,
              // haveSubsidiaries: haveSubsidiaries,
              // commentsForHavingSubsidiaries: entitiesQuestionsList['body'][0].commentsForHavingSubsidiaries
            }
          }         
          console.log("question payload create to bind => ", data);
          this.entityQuestionsRecords.push(data)
        }
      },
      error: (err) => {
        console.log("unable to get sales legal entity ", err);
      },
      complete: () => {
        console.log("legalEntitySalesForm => ", this.legalEntitySalesForm);
      }
    })
  }
}
