<nz-tabset  (nzSelectedIndexChange)="getCurrentTab($event)" [(nzSelectedIndex)]="selectedTabIndexValue">
    <nz-tab [nzTitle]="entitySelect" nzTabPaneStyle>
      <ng-template nz-tab>
        <app-merger-entity-details [mergerEntityDetailsForm]="mergerEntityDetailsForm" [mergingSurviveEntitySelectionForm] ="mergingSurviveEntitySelectionForm"  (isLoader)="emitIsLoader($event)"></app-merger-entity-details>
      </ng-template>
      <ng-template #entitySelect>
        <button nz-button nzType="link" [ngClass]="entityDetailsTabClass">{{entityDetails}}</button>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="summary">
      <ng-template nz-tab>
        <app-merger-proposal-overview [mergerProposalOverviewForm]="mergerProposalOverviewForm">
        </app-merger-proposal-overview>
      </ng-template>
      <ng-template #summary>
        <button nz-button nzType="link" [ngClass]="summaryTabClass">{{overview}}</button>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="attachment1">
      <ng-template nz-tab>
        <app-file-upload [proposalId]="proposalId" (isLoader)="emitIsLoader($event)"></app-file-upload>
      </ng-template>
      <ng-template #attachment1>
        <button nz-button nzType="link" [ngClass]="attachmentTabClass">{{attachments}}</button>
      </ng-template>
  </nz-tab>
  </nz-tabset>
