import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Attachment } from '../interfaces/Attachment';

@Injectable({
  providedIn: 'root',
})
export class DataServiceAttachments {
  

  constructor(
    private session: SessionStorageService,
    private apiService: P30APIService,
    private message: NzMessageService
  ) {
    
  }

  patchAttachmentsToFormGroup(form: UntypedFormGroup) {
    this.apiService.getAttachments(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if (event.body) {
          let attachments: any = event.body;
          attachments.forEach((element: Attachment) => {
            if (element.upstreamDissolutionChecklistUploaded == true) {
              const control = form.controls["upstreamChecklistuploaded"]
              control.setValue(true);
              control.updateValueAndValidity();
              form.updateValueAndValidity();
            }
            })
          }
  
        },
        error: (err) => {
          console.log("Unable to get upstream checklist details ", err);
          this.message.error('Unable to get upstream checklist details!');
        },
        complete: () => {
          return Promise.resolve(true);
        }
      });
      return Promise.resolve(false);
  }

  reviewAndValidateAttachments(form: UntypedFormGroup,formGeneralDetails: UntypedFormGroup): Promise<boolean> {    
    const upstreamChecklistuploaded = form.controls["upstreamChecklistuploaded"];
    let businessSegment = formGeneralDetails.controls["businessSegmentUnit"];

    console.log('upstreamChecklistuploaded',upstreamChecklistuploaded.value);
    console.log('businessSegment',businessSegment.value);
    if ((upstreamChecklistuploaded.value ==  null || upstreamChecklistuploaded.value == false) && businessSegment.value == "Upstream") {  
        upstreamChecklistuploaded.setValidators([Validators.required]);
        upstreamChecklistuploaded.markAsDirty();
        upstreamChecklistuploaded.updateValueAndValidity();
        return Promise.resolve(false);
    }
    else
    {
        upstreamChecklistuploaded.removeValidators([Validators.required]);
        upstreamChecklistuploaded.updateValueAndValidity();
        return Promise.resolve(true);
    }
}

}
