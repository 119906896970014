<p><strong>Proposed Directors and/or Officers for Entity Being Formed </strong></p>
<form nz-form [formGroup]="formationPropossedSlateForm" [nzLayout]="'vertical'">
    <ng-container formArrayName="proposedSlates">
        <div *ngFor="let proposedSlateForm of proposedSlates.controls; let i = index">
            <div [formGroupName]="i">
                <div nz-row nzGutter="8">
                  <div nz-col nzSpan="11">
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Name</nz-form-label>
                        <nz-form-control  [@formControlAnimation]>
                            <input nz-input nzPlaceHolder="Name" formControlName="name" >
                        </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="11">
                    <nz-form-item>
                        <nz-form-label  [@formLabelAnimation]>Title</nz-form-label>
                        <nz-form-control  [@formControlAnimation]>
                            <input nz-input nzPlaceHolder="Title" formControlName="title" >
                        </nz-form-control>
                    </nz-form-item>
                  </div>
                  <div nz-col nzSpan="2" style="margin-top: 35px;">
                    <span nz-icon nzType="minus-circle-o" class="dynamic-delete-button"
                                        (click)="removeForm(i)"></span>
                  </div>
                </div>
            </div>
        </div>
        <nz-form-item>
            <nz-form-control [@formLabelAnimation]>
                <button nz-button nzType="dashed" class="add-button" (click)="addField()">
                    <span nz-icon nzType="plus"></span>
                    Add Proposed Directors and/or Officers
                </button>
            </nz-form-control>
        </nz-form-item>
    </ng-container>
</form>
