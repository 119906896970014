import { Component } from '@angular/core';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.less'],
})
export class PageLoaderComponent {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  // loader
  loader: AnimationOptions = {
    path: 'assets/loader.json',
    loop: true,
    autoplay: true,
    renderer: 'svg',
  };
}
