import { HttpClient, HttpEvent,HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input ,Output,EventEmitter, ChangeDetectorRef, } from '@angular/core';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { P30APIService } from 'src/app/core/services/api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CalAngularService } from '@cvx/cal-angular';
import { Attachment } from 'src/app/core/interfaces/Attachment';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-merger-proposal-attachments',
  templateUrl: './merger-proposal-attachments.component.html',
  styleUrl: './merger-proposal-attachments.component.less',
  animations: [formControlElement, formLabelElement],
})

export class MergerProposalAttachmentsComponent {
  
  constructor(private http: HttpClient, private apiService: P30APIService, private authService: CalAngularService, private msg: NzMessageService, private cdr: ChangeDetectorRef) { }

  @Output() isLoader = new EventEmitter<boolean>;
  @Input() requestId: any;
  @Input() proposalId: any;
  @Input() proposalType: any;
  files: Attachment[] = [];
  showAttachment:boolean=false;

  @Input() mergerProposalAttachmentForm!: UntypedFormGroup;

    //@ViewChild(FileUploadComponent) FileUploadComponent!: FileUploadComponent;
  
    mergerChecklistUploaded: boolean=false;
    attachments: string = "attachments";
   
    @Input() attachmentTabClass = 'tab-default'; 
    merger=true;
  

  ngOnInit() {    
    //this.getAttachments();
    this.getProposal(this.proposalId);
  }

    upstreamMergerChecklistRequiredToggleBTN(event: any) {
    console.log(event)
  }

  emitIsLoader(event:boolean){
    this.isLoader.emit(event);
  }

  async getProposal(proposalId: string) {
    let proposal:any;
    const proposalResult = await new Promise((resolve)=>{
      this.isLoader.emit(true);
      this.apiService.getProposal(proposalId).subscribe({
        next: (event) => {
          proposal = event.body;
        },
        error: (err) => {
        //  this.message.error(this.apiErrorMSG);
          console.log("Unable to retrieve proposal record from the database table ", err);
          this.isLoader.emit(false);
          resolve(false);
        },
        complete: () => {
          this.isLoader.emit(false);
          resolve(true);
        }
      });
    });
    if(proposalResult){
      if (proposal) {
        this.showAttachment=false;
        if(proposal.businessSegment.toLowerCase()=="upstream")
        {
          this.showAttachment=true;
          this.getAttachments();
        }
      }
    }
  }
  // getFiles(): void {
  //   this.apiService.getFiles(this.requestId).subscribe(response => {
  //     this.files = [];
  //     if(response.body){
  //       const data = response.body['files']
  //       data.forEach((element: file_array) => {
  //         this.files.push(element)
  //       })
  //     }
  //   })
  // }
  getAttachments(): void {
    this.isLoader.emit(true);
    this.apiService.getAttachments(this.proposalId).subscribe(response => {
      this.files = [];
      if (response.body) {
        const data = response.body
        data.forEach((element: Attachment) => {
          this.files.push(element)
        })
      }
    })
    this.isLoader.emit(false);
  }

  nzAction = (item: any) => {
    this.isLoader.emit(true);
    let upstreamChecklistuploaded=this.mergerChecklistUploaded?"1":"0";
    return this.apiService.createAttachment(item.file, this.proposalId, upstreamChecklistuploaded, this.authService.cvxClaimsPrincipal.name, this.authService.cvxClaimsPrincipal.email, new Date().toLocaleString("en-US")).subscribe({
      next: (event: HttpEvent<any> | HttpResponse<any>) => {
        if (event instanceof HttpResponse) {
          console.log('File uploaded successfully');
        } else if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(95 * event.loaded / event.total!);
          console.log(`Upload progress: ${percentDone}%`);
        }
      },
      error: (err) => {
        console.log(`Unable to upload file or create attachment record${err}`)
        this.isLoader.emit(false);
      },
      complete: () => {
        setTimeout(() => {
          this.cdr.detectChanges();
        }, 500);

        this.getAttachments();
        this.isLoader.emit(false);

      }
    })
  }
  deleteAttachment(fileName: any) {
    let attachmentId = this.files.filter(data => data.fileName==fileName)[0].attachmentId
    if(attachmentId){
      this.isLoader.emit(true);
      this.apiService.deleteAttachment(this.proposalId,attachmentId,fileName).subscribe({
        next: (output) =>{
          if(output.status==200){
            console.log("file deleted successfully")
          }
        },
        error: (error) => {
          console.log('An error occured during file delete:', error);
          this.isLoader.emit(false);
        },
        complete: () => {
          console.log('completed the file delete process');
          this.cdr.detectChanges();
          this.getAttachments();
          this.isLoader.emit(false);
          // write the code to delete record from database
        }
      })
    }
  }
  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`);
    }
  }



}
