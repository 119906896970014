<p><strong>General Details</strong></p>
<form nz-form [nzLayout]="'vertical'" class="request-details-form" [formGroup]="generalDetailsForm">
    <div nz-row nzGutter="8">
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Requested For</nz-form-label>
                <nz-form-control nzErrorTip="Please input requested for!" [@formControlAnimation]>
                    <nz-select nzShowSearch formControlName="requestedFor"
                        [(ngModel)]="generalDetailsFormModel.requestedFor"
                        (nzOnSearch)="searchUser($event)"
                        [nzSuffixIcon]="usrTemplate"
                        [nzServerSearch]="true"
                        (ngModelChange)="onChangeRequestedFor($event)">
                        <nz-option-group *ngFor="let data of requestedForUser" [nzLabel]="data.mail">
                            <nz-option [nzValue]="data.fullName" [nzLabel]="data.fullName"></nz-option>
                        </nz-option-group>

                    </nz-select>
                    <ng-template #usrTemplate>
                        <span nz-icon nzType="user" nzTheme="outline"></span>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="15" nzOffset="1">
            <nz-form-item>
                <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Request Title</nz-form-label>
                <nz-form-control [nzErrorTip]="getErrorTipForRequestTitle('requestTitle')" [@formControlAnimation]>
                    <input formControlName="requestTitle" type="text" nz-input
                        [(ngModel)]="generalDetailsFormModel.requestTitle"  placeholder="Request Title" (ngModelChange)="onChangeRequestTitle()" />
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div nz-row nzGutter="8">
        <div nz-col nzSpan="8">
            <nz-form-item>
                <nz-form-label [@formLabelAnimation]>Proposal ID</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                    <input formControlName="proposalId" type="text" nz-input
                        [(ngModel)]="generalDetailsFormModel.proposalId" placeholder="Proposal Id"
                        [disabled]="true" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="5" nzOffset="1">
            <nz-form-item>
                <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Business Unit Segment</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                    <nz-select formControlName="businessSegmentUnit"
                        [(ngModel)]="generalDetailsFormModel.businessSegmentUnit" nzAllowClear
                        nzPlaceHolder="Select Business Unit Segment">
                        <nz-option *ngFor="let segment of businessSegmentUnits" [nzValue]="segment.name"
                            [nzLabel]="segment.name"></nz-option>

                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="5" nzOffset="1">
            <nz-form-item>
                <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Region</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                    <nz-select formControlName="region" [(ngModel)]="generalDetailsFormModel.region" nzAllowClear
                        nzPlaceHolder="Select Region">
                        <nz-option *ngFor="let region of regions" [nzValue]="region.name" [nzLabel]="region.name"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="3" nzOffset="1">
            <nz-form-item>
                <nz-form-label [@formLabelAnimation]>Urgent</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                    <nz-switch formControlName="urgent" [(ngModel)]="generalDetailsFormModel.urgent" (ngModelChange)="setUrgencyExplanationControlValidator()"></nz-switch>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div nz-row nzGutter="8">
      @if(proposalStatus !== 'draft') {
        <div nz-col nzSpan="8">
          <nz-form-item>
          <nz-form-label [@formLabelAnimation]><label class="mandate"></label>Requested By</nz-form-label>
            <nz-form-control [@formControlAnimation]>
                <input formControlName="requestedBy" type="text" nz-input
                    [(ngModel)]="generalDetailsFormModel.requestedBy" placeholder="Requested By"
                    [disabled]="true" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="5" nzOffset="1">
            <nz-form-item>
                <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> OPCO</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                    <input formControlName="opco" type="text" nz-input
                        [(ngModel)]="generalDetailsFormModel.opco"  placeholder="OPCO" />
                </nz-form-control>
            </nz-form-item>
          </div>
      }
      @else {
        <div nz-col nzSpan="5" nzOffset="9">
            <nz-form-item>
                <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> OPCO</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                    <input formControlName="opco" type="text" nz-input
                        [(ngModel)]="generalDetailsFormModel.opco"  placeholder="OPCO" />
                </nz-form-control>
            </nz-form-item>
          </div>
      }
      
      <div nz-col nzSpan="9" nzOffset="1">
        <nz-form-item *ngIf="generalDetailsFormModel.urgent">
          <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Urgency Explanation</nz-form-label>
          <nz-form-control [@formControlAnimation]>
              <textarea nz-input placeholder="Urgency Explanation" formControlName="urgencyExplanation"
                  [nzAutosize]="{ minRows: 8, maxRows: 10 }"
                  [(ngModel)]="generalDetailsFormModel.urgencyExplanation"></textarea>
          </nz-form-control>
      </nz-form-item>
      </div>
  </div>
</form>
