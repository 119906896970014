import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID, SalesBuyer, SalesSeller } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroup, Validators, FormControl, Form } from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpResponse } from '@angular/common/http';
import { SaleEntityQuestionsDTO } from '../interfaces/salesEntityQuestions';
import { LegalEntity } from '../interfaces/LegalEntities';



@Injectable({
  providedIn: 'root'
})
export class DataServiceSalesEntity {
  storeFieldsValue: any;
  salesFromForEntity$ = new BehaviorSubject<any[]>([]);
  commonEntityList: LegalEntity[] = [{
    companyName: "NA", ccn: "NA"
  }];

  salesTypeList: SaleEntityQuestionsDTO[] = [
    { entityID: 1, entityName: 'Sale of Entity' },
    { entityID: 2, entityName: 'Sale of Asset' },
    { entityID: 3, entityName: 'Sale of Entity & Asset' },
  ];

  sellerLegalEntityRecords: SaleEntityQuestionsDTO[] = [
    { entityID: 1, entityName: 'Chevron Nigeria' },
    { entityID: 2, entityName: 'Chevron Canada' },
    { entityID: 3, entityName: 'NA' },
  ];
  buyerLegalEntityRecords: SaleEntityQuestionsDTO[] = [
    { entityID: 1, entityName: 'Chevron Nigeria' },
    { entityID: 2, entityName: 'Chevron Canada' },
    { entityID: 3, entityName: 'NA' },
  ];

  sellerLegalEntitySelected: LegalEntity[] = [];
  buyerLegalEntitySelected: LegalEntity[] = [];
  salesLegalEntityNQuestionGroupForm!: FormGroup;

  legalEntitySalesForm = new FormGroup({
    sellerFormGroup: new FormGroup({
      sellerLegalEntity: new FormControl(null),
      sellerComments:  new FormControl(''),
    }),
    buyerFormGroup: new FormGroup({
      buyerLegalEntity: new FormControl(null),
      buyerComments: new FormControl(''),
    }),
    salesType: new FormControl(null),
    // salesTypeFormGroup: new FormGroup({
    salesTypeQuestionsFormGroup: new FormGroup({
      haveEmployees: new FormControl(''),
      haveEmployeesComments: new FormControl(''),
      needBankAccounts: new FormControl(''),
      needBankAccountsComments: new FormControl(''),
      haveSubsidiaries: new FormControl(''),
      haveSubsidiariesComments: new FormControl(''),
      saleTypeComments: new FormControl(''),
      // }),
    }),
  });

  constructor(private session: SessionStorageService,
    private fb: UntypedFormBuilder,
    private apiService: P30APIService,
    private message: NzMessageService) {
  }

  getLegalEntities(): Observable<HttpResponse<any>> {
    return this.apiService.getLegalEntities(this.session.getItem(PROPOSAL_ID)).pipe(
      map(legalEntities => {
        return legalEntities;
      })
    );
  }

  getCompanies(name: string): Observable<HttpResponse<any>> {
    return this.apiService.getCompanies(name).pipe(
      map(legalEntities => {
        return legalEntities;
      })
    );
  }

  getEntityIndicatorQuestions(): Observable<HttpResponse<any>> {
    return this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID)).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );
  }


  getSalesEntityFormData(state: any) {
    this.salesFromForEntity$.next(state);
  }

  reviewAndValidateEntitySelection(form: UntypedFormGroup): Promise<boolean> {
    const buyerFromGroup = this.legalEntitySalesForm.controls['buyerFormGroup'] as FormGroup;
    const buyerValidation = buyerFromGroup.controls['buyerLegalEntity'];
    let esValid = true;

    if(buyerFromGroup?.value == null || buyerFromGroup?.value == undefined || buyerFromGroup?.value == "") {
    buyerValidation.setValidators([Validators.required]);
    buyerValidation.markAsDirty();
    buyerValidation.updateValueAndValidity();
    esValid = false;
    }
    if (buyerValidation.value === 'NA' && (buyerFromGroup.controls['buyerComments'].value == null || buyerFromGroup.controls['buyerComments'].value == undefined || buyerFromGroup.controls['buyerComments'].value == "")) {
      buyerFromGroup.controls['buyerComments'].setValidators([Validators.required]);
      buyerFromGroup.controls['buyerComments'].markAsDirty();
      buyerFromGroup.controls['buyerComments'].updateValueAndValidity();
      esValid = false;
    }
    const sellerFromGroup = this.legalEntitySalesForm.controls['sellerFormGroup'] as FormGroup;
    const sellerValidation = sellerFromGroup.controls['sellerLegalEntity'];
    if(sellerValidation.value == null || sellerValidation.value == undefined || sellerValidation.value == "") {
    sellerValidation.setValidators([Validators.required]);
    sellerValidation.markAsDirty();
    sellerValidation.updateValueAndValidity();
    esValid = false;
    }

    if(sellerValidation.value === 'NA' && (sellerFromGroup.controls['sellerComments'].value == null || sellerFromGroup.controls['sellerComments'].value == undefined || sellerFromGroup.controls['sellerComments'].value == "")) {
      sellerFromGroup.controls['sellerComments'].setValidators([Validators.required]);
      sellerFromGroup.controls['sellerComments'].markAsDirty();
      sellerFromGroup.controls['sellerComments'].updateValueAndValidity();
      esValid = false;
    }
      if (esValid == false) {
          return Promise.resolve(false);
      }
      else {
          return Promise.resolve(true);
      }
      }

  submitSalesLegalEntitySelection(form: FormGroup): Promise<boolean> {
    if (form.valid) {
      this.getLegalEntities().subscribe({
        next: (event) => {
          if (event.body) {
            this.createOrUpdateSalesLegalEntityRecord(form, 'update', event.body);
          } else {
            this.createOrUpdateSalesLegalEntityRecord(form, 'create', []);
          }
        }
      });
      return Promise.resolve(true);
    } else {
      Object.values(form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return Promise.resolve(false);
    }
  }

  async createOrUpdateSalesLegalEntityRecord(legalEntityForm: FormGroup, state: string, entitiesRecords?: any) {
    let sellerPayloadLeglaEntity: LegalEntity;
    let buyerPayloadLeglaEntity: LegalEntity;
    console.log('entitiesRecords', entitiesRecords);
    let recordsExists = entitiesRecords?.length > 0 ? entitiesRecords : []
    const buyerValidation = legalEntityForm.controls['buyerFormGroup'] as FormGroup;
    const sellerValidation = legalEntityForm.controls['sellerFormGroup'] as FormGroup;
    let sellerComment = sellerValidation.controls['sellerComments']?.value;
    let buyerComment = buyerValidation.controls['buyerComments']?.value;
    if(sellerComment == null || sellerComment == undefined || sellerComment == "") {
      sellerComment ='';
    }
    if(buyerComment == null || buyerComment == undefined || buyerComment == "") {
      buyerComment ='';
    }
    let sellerRecordsExists =  entitiesRecords.find((e: any) => e.typeofLegalEntity === SalesSeller);
    console.log('sellerRecordsExists', sellerRecordsExists);
    if(sellerRecordsExists == undefined) {
      state='create';
    }
    else {  
      state='update';
    }
    console.log('sellerValidation.controls', sellerValidation.controls['sellerLegalEntity'].value);
    if (sellerValidation.controls['sellerLegalEntity'].value === 'NA') { 
      sellerPayloadLeglaEntity = this.createPayloadForNAEntityLegal(sellerComment , SalesSeller, recordsExists);
      console.log('sellerPayloadLeglaEntity', sellerPayloadLeglaEntity);
      console.log('state', state);
      switch (state) {
        case 'create': {
          await this.createSalesLegalEntity(sellerPayloadLeglaEntity);
          break;
        }
        case 'update': {
          await this.updateSalesLegalEntity(entitiesRecords, sellerPayloadLeglaEntity);
          break;
        }
      }
    
    } else if (sellerValidation.controls['sellerLegalEntity'].value != null && sellerValidation.controls['sellerLegalEntity'].value != undefined && sellerValidation.controls['sellerLegalEntity'].value != "") {
      sellerPayloadLeglaEntity = this.createPayloadIfEntityIsNotNA(sellerComment, SalesSeller, this.sellerLegalEntitySelected, recordsExists);
      switch (state) {
        case 'create': {
          await this.createSalesLegalEntity(sellerPayloadLeglaEntity);
          break;
        }
        case 'update': {
          await this.updateSalesLegalEntity(entitiesRecords, sellerPayloadLeglaEntity);
          break;
        }
      }
    }
    let buyerRecordsExists =  entitiesRecords.find((e: any) => e.typeofLegalEntity === SalesBuyer);
    console.log('buyerRecordsExists', buyerRecordsExists);
    console.log('buyerValidation.controls', buyerValidation.controls['buyerLegalEntity'].value);
    if(buyerRecordsExists == undefined) {
      state='create';
    }
    else {  
      state='update';
    }
    if (buyerValidation.controls['buyerLegalEntity'].value === 'NA') {
      buyerPayloadLeglaEntity = this.createPayloadForNAEntityLegal(buyerComment, SalesBuyer, recordsExists);
      switch (state) {
        case 'create': {
          await this.createSalesLegalEntity(buyerPayloadLeglaEntity);
          break;
        }
        case 'update': {
          await this.updateSalesLegalEntity(entitiesRecords, buyerPayloadLeglaEntity);
          break;
        }
      }
    } else if (buyerValidation.controls['buyerLegalEntity'].value != null && buyerValidation.controls['buyerLegalEntity'].value != undefined && buyerValidation.controls['buyerLegalEntity'].value != "") {
      buyerPayloadLeglaEntity = this.createPayloadIfEntityIsNotNA(buyerComment, SalesBuyer, this.buyerLegalEntitySelected, recordsExists);
      switch (state) {
        case 'create': {
          await this.createSalesLegalEntity(buyerPayloadLeglaEntity);
          break;
        }
        case 'update': {
          await this.updateSalesLegalEntity(entitiesRecords, buyerPayloadLeglaEntity);
          break;
        }
      }
    }
    let salesTypeFormControl = legalEntityForm.controls['salesType'];
    if(salesTypeFormControl.value!= null && salesTypeFormControl.value != undefined && salesTypeFormControl.value != "") {
    this.createSalesEntityQuestions(legalEntityForm);
    }
    
  }

  async createSalesLegalEntity(sellerBuyerPayload: LegalEntity) {
    console.log('called');
    let entityForSeller = this.apiService.createLegalEntity(this.session.getItem(PROPOSAL_ID), sellerBuyerPayload).subscribe((res: any) => {
    })
    console.log('entityForSeller', entityForSeller);
  }

  async updateSalesLegalEntity(entitiesRecords: LegalEntity[], sellerBuyerPayload: LegalEntity) {
    let entityForSeller = this.apiService.updateLegalEntity(this.session.getItem(PROPOSAL_ID), Number(sellerBuyerPayload.legalEntityId), sellerBuyerPayload).subscribe((res: any) => {
    });
  }

  async createSalesEntityQuestions(form: FormGroup) {
    const salesTypeFormGroup: FormGroup = form.controls['salesTypeQuestionsFormGroup'] as FormGroup;
    const data = {
      "requestId": Number(this.session.getItem(REQUEST_ID)),
      "legalEntityId": null,
      "assests": null,
      "ownershipInterest": "",
      "nameOfEntityBeingAcquired": "",
      "sellerEntity": '',
      "approximateCost": null,
      "descriptionOfAsset": null,
      "jurisdiction": "",
      "haveActiveRegistrationsInOtherStatesCountries": false,
      "commentsForHavingActiveRegistrations": "",
      "haveSubsidiaries": salesTypeFormGroup.controls["haveSubsidiaries"].value == '' ? false: salesTypeFormGroup.controls["haveSubsidiaries"].value,
      "commentsForHavingSubsidiaries": salesTypeFormGroup.controls["haveSubsidiariesComments"].value,
      "needBankAccounts": false,
      "commentsForNeedingBankAccounts": "",
      "willThereBeaNeedForAdditionalContributionsAfterInitialInvestment": false,
      "commentsForNeedingAdditionalContributions": "",
      "haveAnyEmployees": salesTypeFormGroup.controls["haveEmployees"].value ==''?false : salesTypeFormGroup.controls["haveEmployees"].value,
      "commentsForHavingEmployees": salesTypeFormGroup.controls["haveEmployeesComments"].value,
      "haveActiveBankAccounts": salesTypeFormGroup.controls["needBankAccounts"].value == ''?false: salesTypeFormGroup.controls["needBankAccounts"].value,
      "commentsForHavingBankAccounts": salesTypeFormGroup.controls["needBankAccountsComments"].value,
      "willTheNewEntityBeRegisteredInOtherStatesCountries": false,
      "commentsForNewEntityBeingRegistered": "",
      "willTheSurvivingEntityChangeItsNameAfterTheMerger": false,
      "commentsForSurvivingEntityChangingName": "",
      "entityType": "",
      "typeOfSale": form.controls['salesType'].value,
      "investmentMethod": ""
    }
    this.getEntityIndicatorQuestions().subscribe((res: any) => {
      if (res['body']) {
        let questionID = res['body'][0]?.entityQuestionsId;
        this.apiService.updateEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), questionID, data).subscribe((res: any) => {
        })
      } else {
        this.apiService.createEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), data).subscribe((res: any) => {
        })
      }
    })
  }


  createPayloadIfEntityIsNotNA(comment: string, entityType: string, entitySelected: LegalEntity[], existingEntitiesRecord?: any) {
    let salesLeglaEntity: LegalEntity = {
      ccn: entitySelected[0].ccn?.toString(),
      companyName: entitySelected[0].companyName.toString(),
      jurisdiction: entitySelected[0].jurisdiction?.toString(),
      ownershipDetail: entitySelected[0].ownershipDetail?.toString(),
      legalEntityComments: comment,
      typeofLegalEntity: entityType,
      requestId: Number(this.session.getItem(REQUEST_ID))
    }
    if (existingEntitiesRecord == undefined) {
      return salesLeglaEntity;
    } else if (existingEntitiesRecord?.length > 0) {
      let seller: any = existingEntitiesRecord.find((e: any) => e.typeofLegalEntity === SalesSeller);
      let buyer: any = existingEntitiesRecord.find((e: any) => e.typeofLegalEntity === SalesBuyer);
      switch (entityType) {
        case SalesSeller: {
          salesLeglaEntity.legalEntityId = seller?.legalEntityId;
          break;
        }
        case SalesBuyer: {
          salesLeglaEntity.legalEntityId = buyer?.legalEntityId;
          break;
        }
      }
    }
    return salesLeglaEntity;
  }

  createPayloadForNAEntityLegal(comment: string, entityType: string, recordsExists: any) {
    let salesLeglaEntity: any = {
      ccn: "NA",
      companyName: "NA",
      jurisdiction: "",
      ownershipDetail: "",
      legalEntityComments: comment,
      typeofLegalEntity: entityType,
      requestId: Number(this.session.getItem(REQUEST_ID))
    }
    if (recordsExists == undefined) {
      return salesLeglaEntity;
    } else if (recordsExists?.length > 0) {
      let seller: any = recordsExists.find((e: any) => e.typeofLegalEntity === SalesSeller);
      let buyer: any = recordsExists.find((e: any) => e.typeofLegalEntity === SalesBuyer);
      switch (entityType) {
        case SalesSeller: {
          salesLeglaEntity.legalEntityId = seller?.legalEntityId;
          break;
        }
        case SalesBuyer: {
          salesLeglaEntity.legalEntityId = buyer?.legalEntityId;
          break;
        }
      }
    }
    return salesLeglaEntity;
  }

  async submitLegalEntity(form: FormGroup): Promise<boolean> {
    this.getLegalEntities().subscribe({
      next: (event) => {
        if (event.body) {
          this.createOrUpdateSalesLegalEntityRecord(form, 'update', event.body);
        } else {
          this.createOrUpdateSalesLegalEntityRecord(form, 'create', []);
        }
      }
    });
    return Promise.resolve(true);
  }
}
