import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
@Component({
  selector: 'app-others-proposal-overview',
  templateUrl: './others-proposal-overview.component.html',
  styleUrl: './others-proposal-overview.component.less',
  animations: [formControlElement, formLabelElement],
})
export class OthersProposalOverviewComponent {
  @Input() OthersProposalOverviewForm!: UntypedFormGroup;
}
