@if(title){
    <nz-result nzStatus="success" [nzIcon]="iconSuccess" [nzTitle]="title"
    nzSubTitle="Proposal {{proposalId}} successfully saved and submitted.">
    </nz-result>
}@else {
    <nz-result nzStatus="success" [nzIcon]="iconSuccess" nzTitle="Successfully submitted your request!"
    nzSubTitle="Proposal {{proposalId}} successfully saved and submitted.">
</nz-result>
}

<ng-template #iconSuccess>
    <span nz-icon>
        <!-- icon666.com - MILLIONS vector ICONS FREE -->
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;"
        xml:space="preserve">
        <circle style="fill: rgb(118, 146, 49);" cx="25" cy="25" r="25"/>
        <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
        points=" 38,15 22,33 12,25 "/></svg>
    </span>
</ng-template>

