<main class="container">
    <section id="column1" ></section>
    <section id="column2" >
        <nz-result nzStatus="500" nzTitle="500" nzSubTitle="Sorry, there is an error on server.">
            <div nz-result-extra>
                <button nz-button nzType="primary" (click)="backToHome()">Back Home</button>
            </div>
        </nz-result>
    </section>
</main>
