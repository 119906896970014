<nz-tabset  (nzSelectedIndexChange)="getCurrentTab($event)" [(nzSelectedIndex)]="selectedTabIndexValue">
  <nz-tab [nzTitle]="entitySelect" nzTabPaneStyle>
    <ng-template nz-tab>
      <app-dissolution-entity-selection [dissolutionEntitySelectionForm]="dissolutionEntitySelectionForm" (isLoader)="emitIsLoader($event)"></app-dissolution-entity-selection>
    </ng-template>
    <ng-template #entitySelect>
      <button nz-button nzType="link" [ngClass]="entitySelectionTabClass">{{entitySelection}}</button>
    </ng-template>
  </nz-tab>
  <nz-tab [nzTitle]="summary">
    <ng-template nz-tab>
      <app-dissolution-proposal-overview [dissolutionProposalOverviewForm]="proposalOverviewForm">
      </app-dissolution-proposal-overview>
    </ng-template>
    <ng-template #summary>
      <button nz-button nzType="link" [ngClass]="summaryTabClass">{{overview}}</button>
    </ng-template>
  </nz-tab>
  <nz-tab [nzTitle]="attachment">
   
    
      <ng-template nz-tab>
        <app-dissolution-proposal-attachments [attachmentUploadClass]="attachmentUploadClass" [proposalId]="proposalId" #attachment [dissolutionProposalAttachmentForm]="dissolutionProposalAttachmentForm"></app-dissolution-proposal-attachments>
      </ng-template>
      <ng-template #attachment>
        <button nz-button nzType="link" [ngClass]="attachmentTabClass">{{attachments}}</button>
      </ng-template>
  </nz-tab>
</nz-tabset>
