@if(!reviewComponent) {
<div class="ifc-reviewer-component"
  *ngIf="proposalStatus !== 'pending governance review' && userRoleTitle == 'coordinator'">
  <p><strong>Responses</strong></p>

  @if(showHideModal) {
  <div id="confirmationBox">
    <ng-container>
      <ng-template [ngTemplateOutlet]="confirmationModal"></ng-template>
    </ng-container>
  </div>
  }

  <!-- IFC duedate concur by date -->
  <form nz-form [formGroup]="ifcSecretaryForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>IFC Due By Date (Applies to All)</nz-form-label>
          <nz-form-control [@formControlAnimation] [nzErrorTip]="getErrorTipForDueDate('dueDate')">
            <nz-date-picker formControlName="dueDate" [(ngModel)]="ifcSecretaryFormModel.dueDate"
              nzPlaceHolder="mm/dd/yyyy" style="width: 100%" (ngModelChange)="selectDueByDate($event)"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="14" style="display: none;">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>IFC Concur By Date (Applies to All)</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-date-picker formControlName="concurDate" [(ngModel)]="ifcSecretaryFormModel.concurDate"
              nzPlaceHolder="mm/dd/yyyy" style="width: 100%"
              (ngModelChange)="selectConcurByDate($event)"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="7" nzOffset="1">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>IFC Secretary</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-select formControlName="ifcSecretary" [(ngModel)]="ifcSecretaryFormModel.ifcSecretary"
              [nzSuffixIcon]="usrTemplate">
              <ng-container *ngFor="let data of ifcSecretaryDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #usrTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>

  <!-- IFC Legal -->
  <form nz-form [formGroup]="ifcLegalForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14" class="left-side-form">
        <!-- dropdown input form -->
        <div class="left-side-form-dropdown">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>IFC Legal</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-select nzShowSearch formControlName="ifcLegal" [(ngModel)]="ifcLegalFormModel.ifcLegal"
                [nzSuffixIcon]="usrTemplate" [nzServerSearch]="true"
                (nzOnSearch)="searchGroupMember($event, 'ifcLegal')" (ngModelChange)="onGroupMemberChange($event)">
                <ng-container *ngFor="let data of ifcLegalDefaultMember">
                  <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
                </ng-container>
              </nz-select>
              <ng-template #usrTemplate>
                <span nz-icon nzType="user" nzTheme="outline"></span>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- comments input field -->
        <div class="left-side-form-comments">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="legalComments"
                [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7" nzOffset="1" class="right-side-action-buttons">
        <!-- Concurrence status -->
        <span> Concurrence: <br> {{legalConcurrence}} </span>
        <!-- Resend Request toggle button -->
        <span>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation] class="pb-0">Resend Request</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-switch formControlName="ifcLegalSwitch" (ngModelChange)="ifcLegalFormModel.ifcLegalSwitch"
                (ngModelChange)="resetApprovalToggle($event, 'legal')"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </span>
        <!-- email icon -->
        <span>
          <nz-form-item>
            <a (click)="sendReminderEmail()"><span nz-icon nzType="mail" nzTheme="twotone" style="font-size: 2.5rem;"></span></a>
          </nz-form-item>
        </span>
      </div>
    </div>
  </form>

  <!-- IFC Controllers -->
  <form nz-form [formGroup]="ifcControllersForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14" class="left-side-form">
        <!-- dropdown input form -->
        <div class="left-side-form-dropdown">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>IFC Controllers</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-select nzShowSearch formControlName="ifcControllers"
                [(ngModel)]="ifcControllersFormModel.ifcControllers" [nzSuffixIcon]="ControllersTemplate"
                [nzServerSearch]="true" (nzOnSearch)="searchGroupMember($event, 'ifcControllers')"
                (ngModelChange)="onGroupMemberChange($event)">
                <ng-container *ngFor="let data of ifcControllersDefaultMember">
                  <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
                </ng-container>
              </nz-select>
              <ng-template #ControllersTemplate>
                <span nz-icon nzType="user" nzTheme="outline"></span>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- comments input field -->
        <div class="left-side-form-comments">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="controllersComments"
                [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7" nzOffset="1" class="right-side-action-buttons">
        <!-- Concurrence status -->
        <span> Concurrence: <br> {{controllersConcurrence}} </span>
        <!-- Resend Request toggle button -->
        <span>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation] class="pb-0">Resend Request</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-switch formControlName="ifcControllersSwitch"
                (ngModelChange)="resetApprovalToggle($event, 'controllers')"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </span>
        <!-- email icon -->
        <span>
          <nz-form-item>
            <a (click)="sendReminderEmail()"><span nz-icon nzType="mail" nzTheme="twotone" style="font-size: 2.5rem;"></span></a>
          </nz-form-item>
        </span>
      </div>
    </div>
  </form>

  <!-- IFC Tax -->
  <form nz-form [formGroup]="ifcTaxForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14" class="left-side-form">
        <!-- dropdown input form -->
        <div class="left-side-form-dropdown">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>IFC Tax</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-select nzShowSearch formControlName="ifcTax" [(ngModel)]="ifcTaxFormModel.ifcTax"
                [nzSuffixIcon]="taxTemplate" [nzServerSearch]="true" (nzOnSearch)="searchGroupMember($event, 'ifcTax')"
                (ngModelChange)="onGroupMemberChange($event)">
                <ng-container *ngFor="let data of ifcTaxDefaultMember">
                  <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
                </ng-container>
              </nz-select>
              <ng-template #taxTemplate>
                <span nz-icon nzType="user" nzTheme="outline"></span>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- comments input field -->
        <div class="left-side-form-comments">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="taxComments"
                [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7" nzOffset="1" class="right-side-action-buttons">
        <!-- Concurrence status -->
        <span> Concurrence: <br> {{taxConcurrence}} </span>
        <!-- Resend Request toggle button -->
        <span>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation] class="pb-0">Resend Request</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-switch formControlName="ifcTaxSwitch"
                (ngModelChange)="resetApprovalToggle($event, 'tax')"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </span>
        <!-- email icon -->
        <span>
          <nz-form-item>
            <a (click)="sendReminderEmail()"><span nz-icon nzType="mail" nzTheme="twotone" style="font-size: 2.5rem;"></span></a>
          </nz-form-item>
        </span>
      </div>
    </div>
  </form>

  <!-- IFC Treasury-->
  <form nz-form [formGroup]="ifcTreasuryForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14" class="left-side-form">
        <!-- dropdown input form -->
        <div class="left-side-form-dropdown">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>IFC Treasury</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-select nzShowSearch formControlName="ifcTreasury" [(ngModel)]="ifcTreasuryFormModel.ifcTreasury"
                [nzSuffixIcon]="treasuryTemplate" [nzServerSearch]="true"
                (nzOnSearch)="searchGroupMember($event, 'ifcTreasury')" (ngModelChange)="onGroupMemberChange($event)">
                <ng-container *ngFor="let data of ifcTreasuryDefaultMember">
                  <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
                </ng-container>
              </nz-select>
              <ng-template #treasuryTemplate>
                <span nz-icon nzType="user" nzTheme="outline"></span>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- comments input field -->
        <div class="left-side-form-comments">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="treasuryComments"
                [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7" nzOffset="1" class="right-side-action-buttons">
        <!-- Concurrence status -->
        <span> Concurrence: <br> {{treasuryConcurrence}} </span>
        <!-- Resend Request toggle button -->
        <span>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation] class="pb-0">Resend Request</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-switch formControlName="ifcTreasurySwitch"
                (ngModelChange)="resetApprovalToggle($event, 'treasury')"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </span>
        <!-- email icon -->
        <span>
          <nz-form-item>
            <a (click)="sendReminderEmail()"><span nz-icon nzType="mail" nzTheme="twotone" style="font-size: 2.5rem;"></span></a>
          </nz-form-item>
        </span>
      </div>
    </div>
  </form>

  <!-- IFC Governance -->
  <form nz-form [formGroup]="ifcGovernanceForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14" class="left-side-form">
        <!-- dropdown input form -->
        <div class="left-side-form-dropdown">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>IFC Governance</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-select nzShowSearch formControlName="ifcGovernance" [(ngModel)]="ifcGovernanceFormModel.ifcGovernance"
                [nzSuffixIcon]="governanceTemplate" [nzServerSearch]="true"
                (nzOnSearch)="searchGroupMember($event, 'ifcGovernance')" (ngModelChange)="onGroupMemberChange($event)">
                <ng-container *ngFor="let data of ifcGovernanceDefaultMember">
                  <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
                </ng-container>
              </nz-select>
              <ng-template #governanceTemplate>
                <span nz-icon nzType="user" nzTheme="outline"></span>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- comments input field -->
        <div class="left-side-form-comments">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="governanceComments"
                [nzAutosize]="{ minRows: 3, maxRows: 10 }" [(ngModel)]="ifcGovernanceFormModel.governanceComments"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7" nzOffset="1" class="right-side-action-buttons">
        <!-- Concurrence status -->
        <span> Concurrence: <br> {{governanceConcurrence}} </span>
        <!-- Resend Request toggle button -->
        <span>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation] class="pb-0">Resend Request</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-switch formControlName="ifcGovernanceSwitch"
                (ngModelChange)="resetApprovalToggle($event, 'governance')"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </span>
        <!-- email icon -->
        <span>
          <nz-form-item>
            <a (click)="sendReminderEmail()"><span nz-icon nzType="mail" nzTheme="twotone" style="font-size: 2.5rem;"></span></a>
          </nz-form-item>
        </span>
      </div>
    </div>
  </form>
</div>

<!--  -->

<div *ngIf="proposalStatus == 'pending governance review' && userRoleTitle == 'coordinator'">
  <form nz-form [formGroup]="coordianatorIFCForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14">
        <nz-form-item>
          <nz-form-label nzRequired [@formLabelAnimation]>IFC Due By Date (Applies to All)</nz-form-label>
          <nz-form-control [nzErrorTip]="getErrorTipForDueDate('dueDate')" [@formControlAnimation]>
            <nz-date-picker formControlName="dueDate" [(ngModel)]="coordianatorIFCFormModel.dueDate"
              nzPlaceHolder="mm/dd/yyyy" style="width: 100%" (ngModelChange)="selectDueByDate($event)"></nz-date-picker>
          </nz-form-control>
            <!-- <nz-date-picker formControlName="dueDate" [(ngModel)]="coordianatorIFCFormModel.dueDate"
              nzPlaceHolder="mm/dd/yyyy" style="width: 100%" (ngModelChange)="selectDueByDate($event)"></nz-date-picker> -->
          <!-- </nz-form-control> -->
        </nz-form-item>
      </div>
      <div nz-col nzSpan="7" style="display: none;">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>IFC Concur By Date (Applies to All)</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-date-picker formControlName="concurDate" [(ngModel)]="coordianatorIFCFormModel.concurDate"
              nzPlaceHolder="mm/dd/yyyy" style="width: 100%"
              (ngModelChange)="selectConcurByDate($event)"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <!--  -->
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="7">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>IFC Legal</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-select nzShowSearch formControlName="ifcLegal" [(ngModel)]="coordianatorIFCFormModel.ifcLegal"
              [nzSuffixIcon]="usrTemplate" [nzServerSearch]="true" (nzOnSearch)="searchGroupMember($event, 'ifcLegal')"
              (ngModelChange)="onGroupMemberChange($event)">
              <ng-container *ngFor="let data of ifcLegalDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #usrTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="7">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>IFC Controllers</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-select nzShowSearch formControlName="ifcControllers"
              [(ngModel)]="coordianatorIFCFormModel.ifcControllers" [nzSuffixIcon]="ControllersTemplate"
              [nzServerSearch]="true">
              <ng-container *ngFor="let data of ifcControllersDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #ControllersTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <!--  -->
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="7">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>IFC Tax</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-select nzShowSearch formControlName="ifcTax" [(ngModel)]="coordianatorIFCFormModel.ifcTax"
              [nzSuffixIcon]="taxTemplate" [nzServerSearch]="true">
              <ng-container *ngFor="let data of ifcTaxDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #taxTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="7">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>IFC Treasury</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-select nzShowSearch formControlName="ifcTreasury" [(ngModel)]="coordianatorIFCFormModel.ifcTreasury"
              [nzSuffixIcon]="treasuryTemplate" [nzServerSearch]="true">
              <ng-container *ngFor="let data of ifcTreasuryDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #treasuryTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <!--  -->
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="7">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>IFC Governance</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-select nzShowSearch formControlName="ifcGovernance" [(ngModel)]="coordianatorIFCFormModel.ifcGovernance"
              [nzSuffixIcon]="governanceTemplate" [nzServerSearch]="true">
              <ng-container *ngFor="let data of ifcGovernanceDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #governanceTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="7">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>IFC Secretary</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-select formControlName="ifcSecretary" [(ngModel)]="coordianatorIFCFormModel.ifcSecretary"
              [nzSuffixIcon]="usrTemplate">
              <ng-container *ngFor="let data of ifcSecretaryDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #usrTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>
}

@if(reviewComponent) {
<div>
  <nz-table #basicTable [nzData]="approvalsRecords" [ngClass]="reviewComponent ? 'reviewPageBlurTable' : ''"
    [nzShowPagination]="approvalsRecords.length > 10 ? true : false">
    <thead>
      <tr>
        <th scope="col">IFC Type</th>
        <th scope="col">Approver</th>
        <th scope="col">Concur Date</th>
        <th scope="col">Due Date</th>
        <th scope="col">Concurrence</th>
        <th scope="col">Comment</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of approvalsRecords">
        <td>{{ data?.type }}</td>
        <td>{{ data?.fullName }}</td>
        <td>{{ data?.concurDate | date:'shortDate' }}</td>
        <td>{{ data?.dueDate | date:'shortDate' }}</td>
        <td>{{ data?.status == true ? 'Concurred' : data?.status == false ? 'Declined':'Pending' }}</td>
        <td>{{ data?.comment }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>
}

<ng-template #confirmationModal>
  <nz-alert nzShowIcon nzType="info" [nzMessage]="confirmationModalHeading" [nzDescription]="descriptionTemplate"
    [nzAction]="actionTemplate"></nz-alert>
  <ng-template #descriptionTemplate>
    <p> {{confirmationModalContent}} </p>
  </ng-template>
  <ng-template #actionTemplate>
    <nz-space nzDirection="horizontal">
      <button nz-button nzType="primary" (click)="getConfirmation('yes')" class="mr-2">
        <span>Yes</span>
      </button>
      <button nz-button nzType="default" (click)="getConfirmation('no')">
        <span>No</span>
      </button>
    </nz-space>
  </ng-template>
</ng-template>
