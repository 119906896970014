<main class="container">
  <section id="column2" npSetLayout [pageLayout]="PageLayout.Layout12">
    <np-menu [location]="NavigationLocation.Side" [navStartUrl]="'/3.html'"></np-menu>


  </section>
  <section id="column1">
    <app-page-header></app-page-header>
    @if(isAdmin){
      <div *ngIf="!enableEditUser">
        <div class="row">
          <nz-breadcrumb>
            <nz-breadcrumb-item>settings</nz-breadcrumb-item>
            <nz-breadcrumb-item>manage user account</nz-breadcrumb-item>
          </nz-breadcrumb>
        </div>
        <nz-table #nzTable [nzData]="usersData" nzTableLayout="fixed" nzSize="middle" nzAlign="center" [nzSimple]="true"
          class="users-info">
          <thead>
            <tr>
              <th nzCustomFilter nzAlign="center" scope="col">
                first name
              </th>
              <th nzAlign="center" scope="col">last name</th>
              <th nzAlign="center" scope="col">role title</th>
              <th nzAlign="center" nzWidth="250px" scope="col">email</th>
              <th nzAlign="center" scope="col">provisining id</th>
              <th nzAlign="center" scope="col">cai</th>
              <th nzAlign="center" scope="col">action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of nzTable.data">
              <td nzAlign="center">{{ data.firstName }}</td>
              <td nzAlign="center">{{ data.lastName }}</td>
              <td nzAlign="center">
                {{ data.roleTitle }}
              </td>
              <td nzAlign="center">{{ data.emailAddress }}</td>
              <td nzAlign="center">{{ data.provisioningId }}</td>
              <td nzAlign="center">{{ data.cai }}</td>
              <td nzAlign="center">
                <a nz-button nzType="link" (click)="editUserRole(data)">
                  <span nz-icon nzType="edit" nzTheme="twotone" nzTwotoneColor="#0066b2"></span>
                </a>
                <!-- <nz-divider nzType="vertical"></nz-divider>
                <a nz-button nzType="link">
                  <span nz-icon nzType="delete" nzTheme="twotone" nzTwotoneColor="#FF0000"></span>
                </a> -->
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <div *ngIf="enableEditUser" class="data-display">
        <app-edit-user [userData]="editUserData" (enableEditUser)="receiveEnableEditUser($event)"></app-edit-user>
      </div>
    }
    @else{
      <div class="data-display">
        <h3> You do not have permissions to view this page </h3>
      </div>
    }
    
  </section>
