import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CalAngularService } from "@cvx/cal-angular";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SessionStorageService } from "./session_storage";


@Injectable({
    providedIn: 'root'
  })
export class P30APIService{

    p30ApiBaseUri: string = environment.apiEndpoint
    authToken: any;
    // proposalId: any;
    constructor(private http: HttpClient, private authService: CalAngularService,
      private session:SessionStorageService) {
    }
    getAuthorization(){
      return new HttpHeaders().set('authorization',`Bearer ${this.session.getItem("token")}`);
    }
    getAccessToken():  Observable<any> {
      return this.authService.getAADToken();
    }
    sendEmail():Observable<any>{
        const data = {
            "email": [
                "ramkumarkommoju@chevron.com"
              ]
        }
       return this.http.post(this.p30ApiBaseUri+'email',data);
    }
    sendEmailNotification(emailData: any): Observable<HttpResponse<any>>{
           return this.http.request('POST',this.p30ApiBaseUri+'notifications',{headers: this.getAuthorization(),body: emailData ,observe:'response'});
    }
    createProposal(data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.p30ApiBaseUri+'proposals', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    updateProposal(pId: string, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PUT',this.p30ApiBaseUri+'proposals/'+pId, {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    editProposal(pId: string, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PATCH',this.p30ApiBaseUri+'proposals/'+pId, {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    getProposal(pId: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposals/'+pId, {headers: this.getAuthorization(),observe:'response'});
    }
    getProposalByUserIdAndStatusId(userId: number,statusId: number, cursor: string): Observable<HttpResponse<any>>{
      if(cursor){
        return this.http.request('GET',this.p30ApiBaseUri+'proposals?userId='+userId+"&statusId="+statusId+"&cursor="+cursor, {headers: this.getAuthorization(),observe:'response'});
      }else{
        return this.http.request('GET',this.p30ApiBaseUri+'proposals?userId='+userId+"&statusId="+statusId, {headers: this.getAuthorization(),observe:'response'});
      }
    }
    getAllProposalsByUserId(userId: number,cursor: string): Observable<HttpResponse<any>>{
      if(cursor){
        return this.http.request('GET',this.p30ApiBaseUri+'proposals?userId='+userId+"&cursor="+cursor, {headers: this.getAuthorization(),observe:'response'});
      }else{
        return this.http.request('GET',this.p30ApiBaseUri+'proposals?userId='+userId, {headers: this.getAuthorization(),observe:'response'});
      }
    }
    getAllProposals(optionalParams?: Record<string, any>): Observable<HttpResponse<any>>{
      let params = new HttpParams();
      const token = this.session.getItem("token");
      let headers = new HttpHeaders().set('authorization',`Bearer ${token}`);
      if (optionalParams) {
        for (const key in optionalParams) {
          if (optionalParams.hasOwnProperty(key)) {
            params = params.set(key, optionalParams[key]);
          }
        }
      }
      return this.http.request('GET',this.p30ApiBaseUri+'proposals?',{headers: headers, params:params, observe:'response'})
    }
    // getAllProposals(cursor: string): Observable<HttpResponse<any>>{
    //   if(cursor){
    //     return this.http.request('GET',this.p30ApiBaseUri+'proposals?cursor='+cursor, {headers: this.getAuthorization(),observe:'response'});
    //   }else{
    //     return this.http.request('GET',this.p30ApiBaseUri+'proposals', {headers: this.getAuthorization(),observe:'response'});
    //   }
    // }
    deleteProposal(pId: string):Observable<HttpResponse<any>>{
      return this.http.request('DELETE',this.p30ApiBaseUri+'proposals/'+pId,{headers: this.getAuthorization(),observe:'response'})
    }
    createLegalEntity(pId: string, data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.p30ApiBaseUri+'proposals/'+ pId +'/legal-entities', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    updateLegalEntity(pId: string, id: number, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PUT',this.p30ApiBaseUri+'proposals/'+ pId +'/legal-entities/'+id, {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    deleteLegalEntity(pId: string, id: number): Observable<HttpResponse<any>> {
      return this.http.request('DELETE',this.p30ApiBaseUri+'proposals/'+ pId +'/legal-entities/'+id, {headers: this.getAuthorization(),observe:'response'});
    }
    getLegalEntities(pId: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposals/'+ pId +'/legal-entities', {headers: this.getAuthorization(),observe:'response'});
    }
    createEntityIndicatorQuestion(pId: string, data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.p30ApiBaseUri+'proposals/'+ pId +'/entity-questions', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    getEntityIndicatorQuestion(pId: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposals/'+ pId +'/entity-questions', {headers: this.getAuthorization(),observe:'response'});
    }
    updateEntityIndicatorQuestion(pId: string, id: number, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PUT',this.p30ApiBaseUri+'proposals/'+ pId +'/entity-questions/'+id, {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    deleteEntityIndicatorQuestion(pId: string, id: number):Observable<HttpResponse<any>>{
      return this.http.request('DELETE',this.p30ApiBaseUri+'proposals/'+ pId +'/entity-questions/'+id,{headers: this.getAuthorization(),observe:'response'})
    }
    getRegions(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'regions', {headers: this.getAuthorization(),observe:'response'})
    }

    getRegion(id: number): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'regions/'+id, {headers: this.getAuthorization(),observe:'response'});
    }
    getBusinessSegmentUnit(id: number): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'business-segments/'+id, {headers: this.getAuthorization(),observe:'response'});
    }
    getBusinessSegmentUnits(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'business-segments', {headers: this.getAuthorization(),observe:'response'})
    }
    getProposalTypes(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposal-types', {headers: this.getAuthorization(),observe:'response'});
    }
    getStatuses(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'statuses', {headers: this.getAuthorization(),observe:'response'});
    }
    getRequests(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'get_requests', {observe:'response'})
    }
    getConcurrences(id: number): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'get_concurrence/'+id,{observe:'response'})
    }
    addUser(data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.p30ApiBaseUri+'users',{headers: this.getAuthorization(),body:data,observe:'response'})
    }
    getUser(id: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'users/'+id,{headers: this.getAuthorization(),observe:'response'})
    }
    updateUser(objectId: string, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PUT',this.p30ApiBaseUri+'users/'+ objectId, {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    getAllUsers(optionalParams?: Record<string, string>): Observable<HttpResponse<any>>{
      let params = new HttpParams();
      const token = this.session.getItem("token");
      let headers = new HttpHeaders().set('authorization',`Bearer ${token}`);
      if (optionalParams) {
        for (const key in optionalParams) {
          if (optionalParams.hasOwnProperty(key)) {
            params = params.set(key, optionalParams[key]);
          }
        }
      }
      return this.http.request('GET',this.p30ApiBaseUri+'users?',{headers: headers, params:params, observe:'response'})
    }
    getAllUserRoles(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'user-roles',{headers: this.getAuthorization(),observe:'response'})
    }
    getUserRole(id: number): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'user-roles/'+id,{headers: this.getAuthorization(),observe:'response'})
    }
    uploadFile( folder: any, file: any): Observable<HttpEvent<any> | HttpResponse<any>>{
      const formData = new FormData();
      formData.append('file',file);
      formData.append('requestId',folder)
      return this.http.request('POST',this.p30ApiBaseUri+'file',{headers: this.getAuthorization(),body:formData,reportProgress:true,observe:'response'})
    }
    getFiles(requestId:any):Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'files/'+requestId,{headers: this.getAuthorization(),observe:'response'})
    }
    deleteFile(fileName:any, folder:any):Observable<HttpResponse<any>>{
      return this.http.request('DELETE',this.p30ApiBaseUri+'file?fileName='+fileName+'&requestId='+folder,{headers: this.getAuthorization(),observe:'response'})
    }

    createAttachment(file: any,proposalId:string,upstreamDissolutionChecklistUploaded:string,uploadedBy:string,uploadedByEmail:string,uploadedDate:string): Observable<HttpEvent<any> | HttpResponse<any>>{
      const formData = new FormData();
      formData.append('file',file);
      formData.append('upstreamDissolutionChecklistUploaded',upstreamDissolutionChecklistUploaded);
      formData.append('uploadedBy',uploadedBy);
      formData.append('uploadedByEmail',uploadedByEmail)
      formData.append('uploadedDate',uploadedDate)
      return this.http.request('POST',this.p30ApiBaseUri+'proposals/'+proposalId+'/attachments', {headers: this.getAuthorization(),body: formData ,reportProgress:true,observe:'response'});
    }
    getAttachments(proposalId: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposals/'+proposalId+'/attachments', {headers: this.getAuthorization(),observe:'response'});
    }
    getAttachment(proposalId:string,id: number): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposals/'+proposalId+'/attachments/'+id, {headers: this.getAuthorization(),observe:'response'});
    }
    deleteAttachment(proposalId:string,id: number,fileName:string): Observable<HttpResponse<any>> {
      return this.http.request('DELETE',this.p30ApiBaseUri+ 'proposals/'+proposalId+'/attachments/'+id+'?file_name='+fileName, {headers: this.getAuthorization(),observe:'response'});
    }
    createProposalOverview(pId: string, data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.p30ApiBaseUri+'proposals/'+ pId +'/summaries', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    getProposalOverview(pId: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposals/'+ pId +'/summaries', {headers: this.getAuthorization(),observe:'response'});
    }
    updateProposalOverview(pId: string, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PUT',this.p30ApiBaseUri+'proposals/'+ pId +'/summaries', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    deleteProposalOverview(pId: string): Observable<HttpResponse<any>> {
      return this.http.request('DELETE',this.p30ApiBaseUri+'proposals/'+ pId +'/summaries', {headers: this.getAuthorization(),observe:'response'});
    }

    createContacts(pId: string, data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.p30ApiBaseUri+'proposals/'+ pId +'/contacts', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    getContacts(pId: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposals/'+ pId +'/contacts', {headers: this.getAuthorization(),observe:'response'});
    }
    updateContacts(pId: string, id: number, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PUT',this.p30ApiBaseUri+'proposals/'+ pId +'/contacts/'+id, {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    editContacts(pId: string, id: number, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PATCH',this.p30ApiBaseUri+'proposals/'+ pId +'/contacts/'+id, {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    deleteContacts(pId: string,id: number): Observable<HttpResponse<any>> {
      return this.http.request('DELETE',this.p30ApiBaseUri+'proposals/'+ pId +'/contacts/'+id, {headers: this.getAuthorization(),observe:'response'});
    }

    createApprovals(pId: string, data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.p30ApiBaseUri+'proposals/'+ pId +'/approvals', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    getApprovals(pId: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposals/'+ pId +'/approvals', {headers: this.getAuthorization(),observe:'response'});
    }
    updateApprovals(pId: string, id: number, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PUT',this.p30ApiBaseUri+'proposals/'+ pId +'/approvals/'+id, {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    editApprovals(pId: string, id: number, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PATCH',this.p30ApiBaseUri+'proposals/'+ pId +'/approvals/'+id, {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    deleteApprovals(pId: string,id: number): Observable<HttpResponse<any>> {
      return this.http.request('DELETE',this.p30ApiBaseUri+'proposals/'+ pId +'/approvals/'+id, {headers: this.getAuthorization(),observe:'response'});
    }

    // createAssignmentAndConcurrence(data: any): Observable<HttpResponse<any>>{
    //   return this.http.request('POST',this.p30ApiBaseUri+'assignment-and-concurrence', {headers: this.getAuthorization(),body: data ,observe:'response'});
    // }

    // getAssignmentAndConcurrence(id: number): Observable<HttpResponse<any>>{
    //   return this.http.request('GET',this.p30ApiBaseUri+'assignment-and-concurrence/'+id, {headers: this.getAuthorization(),observe:'response'});
    // }

    // updateAssignmentAndConcurrence(id: number, data: any): Observable<HttpResponse<any>>{
    //   return this.http.request('PUT',this.p30ApiBaseUri+'assignment-and-concurrence/'+id, {headers: this.getAuthorization(),body: data ,observe:'response'});
    // }
    createProposedSlate(pId: string,data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.p30ApiBaseUri+'proposals/'+ pId +'/proposed-slates', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    getProposedSlate(pId: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposals/'+ pId +'/proposed-slates', {headers: this.getAuthorization(),observe:'response'});
    }
    //udpate uses proposed slate ID instead of request ID
    updateProposedSlate(pId: string, id: number, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PUT',this.p30ApiBaseUri+'proposals/'+ pId +'/proposed-slates/'+id, {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    deleteProposedSlate(pId: string,id: number): Observable<HttpResponse<any>> {
      return this.http.request('DELETE',this.p30ApiBaseUri+'proposals/'+ pId +'/proposed-slates/'+id, {headers: this.getAuthorization(),observe:'response'});
    }

    createHistory(proposalId: string,data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.p30ApiBaseUri+'proposals/' + proposalId +'/history', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }

    getHistories(proposalId: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposals/' + proposalId + '/history', {headers: this.getAuthorization(),observe:'response'});
    }

    deleteHistory(proposalId: string): Observable<HttpResponse<any>> {
      return this.http.request('DELETE',this.p30ApiBaseUri+ 'proposals/' + proposalId + '/history', {headers: this.getAuthorization(),observe:'response'});
    }
    getCompanies(companyName: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'subsidiaries/companies?companyName=' + companyName, {headers: this.getAuthorization(),observe:'response'});
    }
    getCompanyDetails(companyCodeNumber: any): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'subsidiaries/companies/' + companyCodeNumber, {headers: this.getAuthorization(),observe:'response'});
    }

    // Assignments comments API's
    createComments(pId: string, data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.p30ApiBaseUri+'proposals/'+ pId +'/comments', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    getComments(pId: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'proposals/'+ pId +'/comments', {headers: this.getAuthorization(),observe:'response'});
    }
    updateComments(pId: string, id: number, data: any): Observable<HttpResponse<any>>{
      return this.http.request('PUT',this.p30ApiBaseUri+'proposals/'+ pId +'/comments/'+id, {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    deleteComments(pId: string,id: number): Observable<HttpResponse<any>> {
      return this.http.request('DELETE',this.p30ApiBaseUri+'proposals/'+ pId +'/comments/'+id, {headers: this.getAuthorization(),observe:'response'});
    }

    getReviewer(reiewerId: number): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'reviewers/' + reiewerId, {headers: this.getAuthorization(),observe:'response'});
    }

    getReviewers(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.p30ApiBaseUri+'reviewers', {headers: this.getAuthorization(),observe:'response'});
    }

    // AD assign user-role
    assignUserRole(groupName: string, objectId: string): Observable<HttpResponse<any>>{
      return this.http.request('GET',`${this.p30ApiBaseUri}users/${groupName}/${objectId}`, {headers: this.getAuthorization(),observe:'response'});
    }

    getProposalsByUserRole(role: string,userId:number,addCC:string,coordinatorEmail:string,procedureName:string): Observable<HttpResponse<any>>{
      return this.http.request('GET',`${this.p30ApiBaseUri}proposals/role/${role}/userId/${userId}?addCC=${addCC}&coordinators=${coordinatorEmail}&procedureName=${procedureName}`, {headers: this.getAuthorization(),observe:'response'});
    }



}
