import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroup, Validators, FormControl, Form } from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpResponse } from '@angular/common/http';
import { LegalEntity } from '../interfaces/LegalEntities';
import { SaleEntityQuestionsDTO } from '../interfaces/salesEntityQuestions';



@Injectable({
  providedIn: 'root'
})
export class DataServiceFormationEntity {
  storeFieldsValue: any;
  fromationForEntity$ = new BehaviorSubject<any[]>([]);
  commonEntityList: LegalEntity[] = [{
    companyName: "NA", ccn: "NA"
  }];

  formationTypeList: SaleEntityQuestionsDTO[] = [
    { entityID: 1, entityName: 'Corporation' },
    { entityID: 2, entityName: 'Partnership' },
    { entityID: 3, entityName: 'Limited Liability Company' },
    { entityID: 4, entityName: 'Other' },
  ];



  buyerLegalEntitySelected: LegalEntity[] = [];

  legalEntityFormationForm = new FormGroup({
   
    FormationEntityFormGroup: new FormGroup({
      formationType: new FormControl(null),
      formationTypeComments: new FormControl(''),
    }),
      formationTypeQuestionsFormGroup: new FormGroup({
      needBankAccounts: new FormControl(''),
      needBankAccountsComments: new FormControl(''),
      entityBeRegistered: new FormControl(''),
      entityBeRegisteredComments: new FormControl(''),
    }),
  });

  constructor(private session: SessionStorageService,
    private fb: UntypedFormBuilder,
    private apiService: P30APIService,
    private message: NzMessageService) {
  }

  getLegalEntities(): Observable<HttpResponse<any>> {
    return this.apiService.getLegalEntities(this.session.getItem(PROPOSAL_ID)).pipe(
      map(legalEntities => {
        return legalEntities;
      })
    );
  }

  getCompanies(name: string): Observable<HttpResponse<any>> {
    return this.apiService.getCompanies(name).pipe(
      map(legalEntities => {
        return legalEntities;
      })
    );
  }

  getEntityIndicatorQuestions(): Observable<HttpResponse<any>> {
    return this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID)).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );
  }


  getFormationEntityFormData(state: any) {
    this.fromationForEntity$.next(state);
  }

  reviewAndValidateEntitySelection(form: UntypedFormGroup): Promise<boolean> {
    let esValid = true;
    const buyerFromGroup = this.legalEntityFormationForm.controls['FormationEntityFormGroup'] as FormGroup;
    const buyerValidation = buyerFromGroup.controls['formationType'];
    buyerValidation.setValidators([Validators.required]);
    buyerValidation.markAsDirty();
    buyerValidation.updateValueAndValidity();
    if(buyerValidation.value == "Other"){
      const formationTypeComnets = buyerFromGroup.controls['formationTypeComments'];
      if (formationTypeComnets.value == null || formationTypeComnets.value == undefined
        || formationTypeComnets.value == "") {
          formationTypeComnets.setValidators([Validators.required]);
          formationTypeComnets.markAsDirty();
          formationTypeComnets.updateValueAndValidity();
          esValid = false;
        }
    }

  const salesTypeFormGroup: FormGroup = this.legalEntityFormationForm.controls['formationTypeQuestionsFormGroup'] as FormGroup;
  let entityBeRegistered = salesTypeFormGroup.controls['entityBeRegistered'];
  let entityBeRegisteredComments = salesTypeFormGroup.controls['entityBeRegisteredComments'];

if (entityBeRegistered.value == true){
  if (entityBeRegisteredComments.value == null || entityBeRegisteredComments.value == undefined
    || entityBeRegisteredComments.value == ""){
      entityBeRegisteredComments.setValidators([Validators.required]);
      entityBeRegisteredComments.markAsDirty();
      entityBeRegisteredComments.updateValueAndValidity();
      esValid = false;
    }
}
        if (esValid == false) {
          return Promise.resolve(false);
      }
      else {
          return Promise.resolve(true);
      }
    }

      reviewAndValidateNewEntitySelection(form: UntypedFormGroup): Promise<boolean> {
        let esValid = true;
        const formationEntityFormGroup = this.legalEntityFormationForm.controls['FormationEntityFormGroup'] as FormGroup;
        const formationType = formationEntityFormGroup.controls['formationType'];
        if (formationType.value == null || formationType.value == undefined
          || formationType.value == "") {
            formationType.setValidators([Validators.required]);
            formationType.markAsDirty();
            formationType.updateValueAndValidity();
        esValid = false;
          }

        if(formationType.value == "Other"){
          const formationTypeComnets = formationEntityFormGroup.controls['formationTypeComments'];
          if (formationTypeComnets.value == null || formationTypeComnets.value == undefined
            || formationTypeComnets.value == "") {
              formationTypeComnets.setValidators([Validators.required]);
              formationTypeComnets.markAsDirty();
              formationTypeComnets.updateValueAndValidity();
          esValid = false;
            }
        }

      const salesTypeFormGroup: FormGroup = this.legalEntityFormationForm.controls['formationTypeQuestionsFormGroup'] as FormGroup;
    let entityBeRegistered = salesTypeFormGroup.controls['entityBeRegistered'];
    let entityBeRegisteredComments = salesTypeFormGroup.controls['entityBeRegisteredComments'];
    
    if (entityBeRegistered.value == true){
      if (entityBeRegisteredComments.value == null || entityBeRegisteredComments.value == undefined
        || entityBeRegisteredComments.value == ""){
          entityBeRegisteredComments.setValidators([Validators.required]);
          entityBeRegisteredComments.markAsDirty();
          entityBeRegisteredComments.updateValueAndValidity();
          esValid = false;
        }
    }
    
   
    
          if (esValid == false) {
              return Promise.resolve(false);
          }
          else {
              return Promise.resolve(true);
          }
  }

  submitFormationLegalEntitySelection(form: FormGroup): Promise<boolean> {
    console.log('this.session.getItem(PROPOSAL_ID)', this.session.getItem(PROPOSAL_ID));
    console.log('form', form);
    if (form.valid) {
      this.getLegalEntities().subscribe({
        next: (event) => {
          if (event.body) {
            this.createOrUpdateFormationLegalEntityRecord(form, 'update', event.body);
          } else {
            this.createOrUpdateFormationLegalEntityRecord(form, 'create', []);
          }
        }
      });
      return Promise.resolve(true);
    } else {
      Object.values(form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return Promise.resolve(false);
    }
  }

  async createOrUpdateFormationLegalEntityRecord(legalEntityForm: FormGroup, state: string, entitiesRecords?: any) {
    let buyerPayloadLeglaEntity: LegalEntity;
    let recordsExists = entitiesRecords?.length > 0 ? entitiesRecords : []
    const buyerValidation = legalEntityForm.controls['FormationEntityFormGroup'] as FormGroup;
    let formationType = buyerValidation.controls['formationType'].value;
    let formationTypeComments = buyerValidation.controls['formationTypeComments'].value==undefined?'':buyerValidation.controls['formationTypeComments'].value;
      buyerPayloadLeglaEntity = this.createPayloadForNAEntityLegal(formationTypeComments, formationType, recordsExists);
    this.createFormationEntityQuestions(legalEntityForm);
    switch (state) {
      case 'create': {
        await this.createformationLegalEntity(buyerPayloadLeglaEntity);
        break;
      }
      case 'update': {
        await this.updateFormationLegalEntity(entitiesRecords,buyerPayloadLeglaEntity);
        break;
      }
    }
  }

  async createformationLegalEntity(buyerPayload: LegalEntity) {
    let entityForBuyer = this.apiService.createLegalEntity(this.session.getItem(PROPOSAL_ID), buyerPayload);
    combineLatest([entityForBuyer]).subscribe((result: any) => {
    })
  }

  async updateFormationLegalEntity(entitiesRecords: LegalEntity[],buyerPayload: LegalEntity) {
    let entityForBuyer = this.apiService.updateLegalEntity(this.session.getItem(PROPOSAL_ID), Number(buyerPayload.legalEntityId), buyerPayload);
    combineLatest([entityForBuyer]).subscribe((result: any) => {
    })
  }

  async createFormationEntityQuestions(form: FormGroup) {
    const foramtionTypeFormGroup: FormGroup = form.controls['formationTypeQuestionsFormGroup'] as FormGroup;
    const data = {
      "requestId": Number(this.session.getItem(REQUEST_ID)),
      "legalEntityId": null,
      "assests": null,
      "ownershipInterest": "",
      "nameOfEntityBeingAcquired": "",
      "sellerEntity": '',
      "approximateCost": null,
      "descriptionOfAsset": null,
      "jurisdiction": "",
      "haveActiveRegistrationsInOtherStatesCountries": false,
      "commentsForHavingActiveRegistrations": "",
      "haveSubsidiaries": false,
      "commentsForHavingSubsidiaries": null,
      "needBankAccounts": foramtionTypeFormGroup.controls["needBankAccounts"].value == ''?false: foramtionTypeFormGroup.controls["needBankAccounts"].value,
      "commentsForNeedingBankAccounts":foramtionTypeFormGroup.controls["needBankAccountsComments"].value == undefined?'':foramtionTypeFormGroup.controls["needBankAccountsComments"].value,
      "willThereBeaNeedForAdditionalContributionsAfterInitialInvestment": false,
      "commentsForNeedingAdditionalContributions": "",
      "haveAnyEmployees": false,
      "commentsForHavingEmployees": "",
      "haveActiveBankAccounts": false,
      "commentsForHavingBankAccounts":"" ,
      "willTheNewEntityBeRegisteredInOtherStatesCountries": foramtionTypeFormGroup.controls["entityBeRegistered"].value ==''?false : foramtionTypeFormGroup.controls["entityBeRegistered"].value,
      "commentsForNewEntityBeingRegistered": foramtionTypeFormGroup.controls["entityBeRegisteredComments"].value==undefined?'':foramtionTypeFormGroup.controls["entityBeRegisteredComments"].value,
      "willTheSurvivingEntityChangeItsNameAfterTheMerger": false,
      "commentsForSurvivingEntityChangingName": "",
      "entityType": "",
      "typeOfSale": "",
      "investmentMethod": "",
      "salesLegalEntitySummaryComment":""
    }
    this.getEntityIndicatorQuestions().subscribe((res: any) => {
      if (res['body']) {
        let questionID = res['body'][0]?.entityQuestionsId;
        this.apiService.updateEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), questionID, data).subscribe((res: any) => {
        })
      } else {
        this.apiService.createEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), data).subscribe((res: any) => {
        })
      }
    })
  }


 

  createPayloadForNAEntityLegal(comment: string, entityType: string, recordsExists: any) {
    let formationLeglaEntity: any = {
      ccn: "",
      companyName: "",
      jurisdiction: "",
      ownershipDetail: "",
      legalEntityComments: comment,
      typeofLegalEntity: entityType,
      //legalEntityId:recordsExists[0].legalEntityId,
      requestId: Number(this.session.getItem(REQUEST_ID))
    }
    if (recordsExists == undefined) {
      return formationLeglaEntity;
    } else if (recordsExists?.length > 0) {
      
          formationLeglaEntity.legalEntityId = recordsExists[0].legalEntityId;
    
    }
    return formationLeglaEntity;
  }

  async submitLegalEntity(form: FormGroup): Promise<boolean> {
    this.getLegalEntities().subscribe({
      next: (event) => {
        if (event.body) {
          this.createOrUpdateFormationLegalEntityRecord(form, 'update', event.body);
        } else {
          this.createOrUpdateFormationLegalEntityRecord(form, 'create', []);
        }
      }
    });
    return Promise.resolve(true);
  }
}
