import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { PROPOSAL_ID } from 'src/app/constants/constants';
import { CommentsHistoryDTO } from 'src/app/core/interfaces/assignmentCommentsHistory';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class CommentsComponent implements OnInit {
  @Output() isLoader = new EventEmitter<boolean>();
  @Input() reviewComponent!: boolean;
  isRequestorCoordinatorRole!: boolean;
  isCoordinatorRole!: boolean;
  notesRecords: CommentsHistoryDTO[] = [];
  commentValue!: string;

  constructor(
    private session: SessionStorageService,
    private commentsService: DataServiceComments
  ) {}

  ngOnInit() {
    this.getLoggedInUserRole();
    this.getCommentsRecords();
    this.commentsService.isCommentSaved$.subscribe((state: boolean) => {
      if (state) {
        this.getCommentsRecords();
        this.commentsService.getInputComment('');
      } else {
        this.setCommentsFieldValue();
      }
    });
  }

  getCommentsRecords() {
    this.isLoader.emit(true);
    let proposalID = this.session.getItem(PROPOSAL_ID);
    this.commentsService.getCommnets(proposalID).subscribe({
      next: (event) => {
        this.notesRecords = [];
        if (event.body) {
          this.notesRecords = [...event.body];
          this.isLoader.emit(false);
        }
      },
      error: (err) => {
        this.isLoader.emit(false);
        console.log('unable to get comments records', err);
      },
      complete: () => {
        this.isLoader.emit(false);
      },
    });
  }

  // coordinator notes tab will be visible only for Requestor and Coordinator Roles.
  // comments field will be only visible to Coordinator Roles
  getLoggedInUserRole() {
    let role = this.session.getItem('userRole');
    switch (role.roleTitle.toLocaleLowerCase()) {
      case 'requestor': {
        this.isRequestorCoordinatorRole = true;
        return;
      }
      case 'coordinator': {
        this.isRequestorCoordinatorRole = true;
        this.isCoordinatorRole = true;
        return;
      }
      default: {
        this.isRequestorCoordinatorRole = false;
        this.isCoordinatorRole = false;
        return;
      }
    }
  }

  getCommentInputData(event: any) {
    if(event) {
      this.commentsService.getInputComment(event?.target?.value);
      this.commentsService.retriveCommentsValue('set', event?.target?.value);
      let v = this.commentsService.retriveCommentsValue('get', '');
    }
  }

  setCommentsFieldValue() {
    let coment = this.session.getItem('commentsValue');
    if(coment) {
      this.commentValue = coment;
    } else {
      this.commentValue = '';
    }
  }
}
