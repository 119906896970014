import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { PROPOSAL_ID } from 'src/app/constants/constants';
import { AcquisitionEntityQuestion } from 'src/app/core/interfaces/EntityIndicatorQuestion';
import { LegalEntity } from 'src/app/core/interfaces/LegalEntities';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceEntityQuestions } from 'src/app/core/services/data_service_entity_questions';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-acquisition-entity-questions',
  templateUrl: './acquisition-entity-questions.component.html',
  styleUrls: ['./acquisition-entity-questions.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class AcquisitionEntityQuestionsComponent {
  componentIsVisible: boolean = false;
  asset: boolean = false;
  ownershipInterest: boolean = false;
  activeRegistrations: boolean = false;
  subsidiaries: boolean = false;
  additionalContribution: boolean = false;
  bankAccount: boolean = false;
  @Input() acquisitionAssetForm!: UntypedFormGroup;
  @Input() acquisitionOwnershipInterestForm!: UntypedFormGroup;
  @Input() acquisitionEntityQuestionsForm!: UntypedFormGroup;
  @Output() isSlatedisable = new EventEmitter<boolean>();
  selectedInvestmentMethod: any;
  isOwnerFormError: boolean = false;
  investmentType = [{ name: '', id: 0 }];
  legalEntities: LegalEntity[] = [];
  proposalId!: string;
  entityIndicatorQuestionId!: number;
  entityIndicatorQuestions: AcquisitionEntityQuestion[] = [];
  constructor(private fb: UntypedFormBuilder, private apiService: P30APIService,
    private session: SessionStorageService, private message: NzMessageService,
    private dataservice: DataService,
    private entityQuestionsDataService: DataServiceEntityQuestions) {
  }

  ngOnInit() {
    this.investmentType = [...this.entityQuestionsDataService.investmentType];
    // Retrieve all entity indicator questions
    let proposalID = this.session.getItem(PROPOSAL_ID);
    if (proposalID) {
      this.entityQuestionsDataService.getEntityIndicatorQuestions().subscribe({
        next: (event) => {
          let entityQuestions = [];
          if (event.body) {
            entityQuestions = [...event.body];
            let asset = this.actionOnEntityQuestionsAssets(entityQuestions[0].assests);
            let ownershipInterest = this.actionOnEntityQuestionsOwnershipInterest(entityQuestions[0].ownershipInterest);
            if (asset) {
              this.acquisitionAssetForm.patchValue({
                entityIndicatorQuestionId: entityQuestions[0].entityQuestionsId,
                sellerEntity: entityQuestions[0].sellerEntity,
                approximateCost: entityQuestions[0].approximateCost,
                assetDescription: entityQuestions[0].descriptionOfAsset
              });
              this.acquisitionEntityQuestionsForm.patchValue({
                asset: asset,
                ownershipInterest: ownershipInterest
              });
              this.acquisitionOwnershipInterestForm.reset();
            } else if (ownershipInterest) {
              this.selectedInvestmentMethod = entityQuestions[0]?.investmentMethod;
              this.subsidiaries = entityQuestions[0].haveSubsidiaries;
              this.activeRegistrations = entityQuestions[0].haveActiveRegistrationsInOtherStatesCountries;
              this.additionalContribution = entityQuestions[0].willThereBeANeedForAdditionalContributionsAfterInitialInvestment;
              this.bankAccount = entityQuestions[0].needBankAccounts;
              this.acquisitionOwnershipInterestForm?.controls['investmentMethod']?.setValue(entityQuestions[0].investmentMethod);
              this.acquisitionOwnershipInterestForm.patchValue({
                entityIndicatorQuestionId: entityQuestions[0].entityQuestionsId,
                nameOfEntityBeingAcquired: entityQuestions[0].nameOfEntityBeingAcquired,
                jurisdiction: entityQuestions[0].jurisdiction,
                haveActiveRegistrations: this.activeRegistrations,
                haveActiveRegistrationsComments: entityQuestions[0].commentsForHavingActiveRegistrations,
                haveSubsidiaries: this.subsidiaries,
                haveSubsidiariesComments: entityQuestions[0].commentsForHavingSubsidiaries,
                additionalContributions: this.additionalContribution,
                additionalContributionComments: entityQuestions[0].commentsForNeedingAdditionalContributions,
                needBankAccounts: this.bankAccount,
                needBankAccountComments: entityQuestions[0].commentsForNeedingBankAccounts,
                investmentMethod: entityQuestions[0].investmentMethod,
              });
              this.acquisitionEntityQuestionsForm.patchValue({
                asset: asset,
                ownershipInterest: ownershipInterest
              });
              this.acquisitionAssetForm.reset();
            }
          }
        }
      })
    }
    this.checkOwnershipFormValidation();
  }

  actionOnEntityQuestionsAssets(event: any) {
    let asset;
    if (event) {
      asset = JSON.parse(event.toLowerCase());
      return asset;
    } else {
      asset = false
      return asset;
    }
  }

  actionOnEntityQuestionsOwnershipInterest(event: any) {
    let ownershipInterest;
    if (event) {
      ownershipInterest = JSON.parse(event.toLowerCase());
      return ownershipInterest;
    } else {
      ownershipInterest = false
      return ownershipInterest;
    }
  }

  onAssetChange(event: boolean) {
    if (event) {
      this.ownershipInterest = false;
      this.isSlatedisable.emit(true);
    }
    else{
      this.isSlatedisable.emit(false);
    }
  }

  onOwnershipChange(event: boolean) {
    if (event) {
      this.asset = false;
      this.isSlatedisable.emit(false);
    }
  }

  checkOwnershipFormValidation() {
    this.entityQuestionsDataService.ownerShipFormError$.subscribe((res: any) => {
      if (res.includes("investmentMethod")) this.isOwnerFormError = true;
      else this.isOwnerFormError = false;
    })
  }
}
