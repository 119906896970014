import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrl: './server-error.component.less'
})
export class ServerErrorComponent {

  constructor(private router:Router){}
  backToHome(){
    location.reload();
  }

}
