import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { map } from 'rxjs';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { SV_USER_ROLE, SV_PROPOSAL_STATUS, SV_USER, PROPOSAL_ID, IFCConcurred, IFCDeclined, IFCPending, ConfirmationHeading, ConfirmationContent, IFCLEGAL, STATUSPendingGovernance, STATUSPendingIFCConcurrence, IFCLegalGroupName, IFCComptrollersGroupName, IFCTaxGroupName, IFCTreasuryGroupName, IFCGovernanceGroupName, IFCSecretaryGroupName, IFCCOMPTROLLERS, IFCTAX, IFCTREASURY, IFCGOVERNANCE, TAB_TITLE_DEFAULT_CLASS } from 'src/app/constants/constants';
import { UserDetails } from 'src/app/core/interfaces/User';
import { IFCGroupsDTO, IFCReviewersMembersDTO } from 'src/app/core/interfaces/ifcReviewers';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceApprovals } from 'src/app/core/services/data_service_approvals';
import { DataServiceIFCReviewers } from 'src/app/core/services/data_service_ifc_reviewers';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-ifc-reviewer',
  templateUrl: './ifc-reviewer.component.html',
  styleUrl: './ifc-reviewer.component.less',
  animations: [formControlElement, formLabelElement],
})
export class IfcReviewerComponent implements OnInit {
  @Output() ifcPayloadRecords = new EventEmitter<IFCGroupsDTO[]>();
  @Output() isApprovalSubmitted = new EventEmitter<boolean>();
  @Output() isResetEmailTrigger = new EventEmitter<boolean>();
  @Output() isReminderEmailTrigger = new EventEmitter<boolean>();
  @Output() isLoader = new EventEmitter<boolean>();
  @Output() ifcRevewerTabClass = new EventEmitter<string>();
  @Input() isIFCRequired!: boolean;
  @Input() reviewComponent!: boolean | string;
  ifcLegalDefaultMember: IFCReviewersMembersDTO[] = [];
  ifcControllersDefaultMember: IFCReviewersMembersDTO[] = [];
  ifcTaxDefaultMember: IFCReviewersMembersDTO[] = [];
  ifcTreasuryDefaultMember: IFCReviewersMembersDTO[] = [];
  ifcGovernanceDefaultMember: IFCReviewersMembersDTO[] = [];
  ifcSecretaryDefaultMember: IFCReviewersMembersDTO[] = [];
  userRoleTitle: any;
  isDueDate!: string | null;
  isConcurDate!: string;
  legalConcurrence!: string;
  controllersConcurrence!: string;
  taxConcurrence!: string;
  treasuryConcurrence!: string;
  governanceConcurrence!: string;
  proposalStatus!: string;
  loggedInUserID!: number;
  listOfGroupsMemberByDefault: IFCReviewersMembersDTO[] = [];
  approvalsRecords: IFCGroupsDTO[] = [];
  proposal_ID: string = this.session.getItem(PROPOSAL_ID);
  approverId = this.session.getItem(SV_USER).userId;
  isVisible!: boolean;
  isOkLoading!: boolean;
  isModalYesBtnClicked!: boolean;
  isModalNoBtnClicked!: boolean;
  confirmModal?: any;
  confirmationModalHeading = ConfirmationHeading;
  confirmationModalContent = ConfirmationContent;
  showHideModal: boolean = false;
  resetGroupName!: string;
  legalStatus!: boolean | null;
  controllersStatus!: boolean | null;
  taxStatus!: boolean | null;
  treasuryStatus!: boolean | null;
  governanceStatus!: boolean | null;
  ifcLegalApprovalsId!: number | null;
  ifcTaxApprovalsId!: number | null;
  ifcControllersApprovalsId!: number | null;
  ifcTresuryApprovalsId!: number | null;
  ifcGovernanceApprovalsId!: number | null;
  legalConcuredStatus!: boolean | null;
  controllersConcuredStatus!: boolean | null;
  taxConcuredStatus!: boolean | null;
  treasuryConcuredStatus!: boolean | null;
  governanceConcuredStatus!: boolean | null;
  sessionDueDate: any;
  isActionComplete!: boolean;

  coordianatorIFCForm!: UntypedFormGroup;
  coordianatorIFCFormModel = {
    dueDate: '',
    concurDate: '',
    ifcSecretary: '',
    ifcLegal: '',
    ifcControllers: '',
    ifcTax: '',
    ifcTreasury: '',
    ifcGovernance: '',
  };

  ifcSecretaryForm!: UntypedFormGroup;
  ifcSecretaryFormModel = {
    dueDate: '',
    concurDate: '',
    ifcSecretary: '',
  };
  ifcLegalForm!: UntypedFormGroup;
  ifcLegalFormModel = {
    ifcLegal: '',
    legalComments: '',
    ifcLegalSwitch: false,
  };
  ifcControllersForm!: UntypedFormGroup;
  ifcControllersFormModel = {
    ifcControllers: '',
    controllersComments: '',
    ifcControllersSwitch: false,
  };
  ifcTaxForm!: UntypedFormGroup;
  ifcTaxFormModel = {
    ifcTax: '',
    taxComments: '',
    ifcTaxSwitch: false,
  };
  ifcTreasuryForm!: UntypedFormGroup;
  ifcTreasuryFormModel = {
    ifcTreasury: '',
    treasuryComments: '',
    ifcTreasurySwitch: false,
  };
  ifcGovernanceForm!: UntypedFormGroup;
  ifcGovernanceFormModel = {
    ifcGovernance: '',
    governanceComments: '',
    ifcGovernanceSwitch: false,
  };

  constructor(
    private fb: UntypedFormBuilder,
    private dataservice: DataService,
    private session: SessionStorageService,
    private graph: MsGraphService,
    private ifcReviewersService: DataServiceIFCReviewers
  ) { }


  async ngOnInit() {
    this.isActionComplete = false;
    this.loggedInUserID = this.session.getItem(SV_USER).userId;
    this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE).roleTitle);
    this.proposalStatus = this.session.getItem(SV_PROPOSAL_STATUS).name.toLocaleLowerCase();
    this.userRoleTitle = this.dataservice.roleTitle.toLocaleLowerCase();
    this.sessionDueDate = this.session.getItem("duedate") ? this.session.getItem("duedate") : null;
    this.ifcReviewersService.reviewNsubmitStatus(undefined);
    this.ifcReviewersService.getIFCUpdateRecordsStatus(undefined);
    if (this.isIFCRequired) {
      this.createFormGroupsWithNestedFormControllers();
      await this.getDefaultMemberOfGroups();
      await this.getApprovalsList();
      this.enableDisableCommentsFormFields();
    }
  }

  // to get default members of all groups
  async getDefaultMemberOfGroups() {
    this.isLoader.emit(true);
    this.listOfGroupsMemberByDefault = [];
    let defaultUser = await this.ifcReviewersService.defaultGroupMemberAPICall();
    defaultUser.subscribe({
      next: (groupMember) => {
        if (groupMember['body']) {
          this.listOfGroupsMemberByDefault = [...groupMember['body']];
          this.storeDefaultMemberIntoVariable();
          this.isLoader.emit(false);
        }
      },
      error: (err) => {
        console.log("default members error => ", err);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    })
  }

  // to get all records of approval
  async getApprovalsList() {
    this.isLoader.emit(true);
    let approvalsRecords = await this.ifcReviewersService.getIFCApprovals();
    approvalsRecords.subscribe({
      next: (approvals) => {
        if (approvals['body']) {
          this.isLoader.emit(false);
          this.setDefaultSecretary();
          this.reviewComponentUIShowHideData(approvals['body']);
          if (approvals['body'][0].dueDate) {
            this.isApprovalSubmitted.emit(true);
            this.ifcReviewersService.getIFCUpdateRecordsStatus(true);
            this.isDueDate = approvals['body'][0].dueDate;
            this.ifcSecretaryForm?.controls['dueDate'].setValue(approvals['body'][0]?.dueDate);
            this.ifcSecretaryForm?.updateValueAndValidity();
            this.isActionComplete = true;
            this.ifcRevewerTabClass.emit(TAB_TITLE_DEFAULT_CLASS);
          }
          for (let approval of approvals['body']) {
            this.setUpdateIFCFormFieldsValue(approval);
          }
          this.setResetDueDateFormValidation();
        } else {
          this.isActionComplete = true;
          this.isDueDate = null;
          this.isApprovalSubmitted.emit(false);
          this.ifcReviewersService.getIFCUpdateRecordsStatus(false);
          this.storeDefaultMemberIntoVariable();
          this.setResetDueDateFormValidation();
        }
      },
      error: (err) => {
        console.log("approvals records error => ", err);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    })
  }

  // to set default secretary group member
  setDefaultSecretary() {
    let defaultSecretary = this.listOfGroupsMemberByDefault.find((ele: any) => {
      return ele['type'].toLocaleLowerCase() == IFCSecretaryGroupName;
    })
    switch (this.proposalStatus) {
      case STATUSPendingGovernance: {
        this.coordianatorIFCForm?.controls['ifcSecretary'].setValue(defaultSecretary?.defaultEmail);
        this.coordianatorIFCFormModel.ifcTreasury = <any>defaultSecretary?.defaultEmail;
        break;
      }
      case STATUSPendingIFCConcurrence: {
        this.ifcSecretaryForm?.controls['ifcSecretary'].setValue(defaultSecretary?.defaultEmail);
        this.ifcSecretaryFormModel.ifcSecretary = <any>defaultSecretary?.defaultEmail;
        break;
      }
      case 'default': {
        break;
      }
    }
  }

  setUpdateIFCFormFieldsValue(approvalRecords: IFCGroupsDTO) {
    if (this.proposalStatus == STATUSPendingGovernance) {
      this.setPendingGoverenceFormValues(approvalRecords);
    } else if (this.proposalStatus == STATUSPendingIFCConcurrence) {
      this.setIFCConcurrenceFormValues(approvalRecords);
    }
  }

  setPendingGoverenceFormValues(approvalRecords: IFCGroupsDTO) {
    const due = this.sessionDueDate ? this.sessionDueDate : null;
    const dueDate = this.isDueDate ? this.isDueDate : due;
    if (this.proposalStatus == STATUSPendingGovernance) {
      this.coordianatorIFCForm?.controls['dueDate'].setValue(dueDate);
      this.coordianatorIFCFormModel.dueDate = dueDate;
      this.coordianatorIFCForm?.controls['concurDate'].setValue(this.isConcurDate);
      this.coordianatorIFCFormModel.concurDate = this.isConcurDate;
      switch (approvalRecords.type.toLocaleLowerCase()) {
        case IFCLegalGroupName: {
          this.coordianatorIFCForm?.controls['ifcLegal'].setValue(approvalRecords['approverEmail']);
          this.coordianatorIFCFormModel.ifcLegal = approvalRecords['approverEmail'];
          this.ifcLegalApprovalsId = <any>approvalRecords['approvalsId'];
          break;
        }
        case IFCComptrollersGroupName: {
          this.coordianatorIFCForm?.controls['ifcControllers'].setValue(approvalRecords['approverEmail']);
          this.coordianatorIFCFormModel.ifcControllers = approvalRecords['approverEmail'];
          this.ifcControllersApprovalsId = <any>approvalRecords['approvalsId'];
          break;
        }
        case IFCTaxGroupName: {
          this.coordianatorIFCForm?.controls['ifcTax'].setValue(approvalRecords['approverEmail']);
          this.coordianatorIFCFormModel.ifcTax = approvalRecords['approverEmail'];
          this.ifcTaxApprovalsId = <any>approvalRecords['approvalsId'];
          break;
        }
        case IFCTreasuryGroupName: {
          this.coordianatorIFCForm?.controls['ifcTreasury'].setValue(approvalRecords['approverEmail']);
          this.coordianatorIFCFormModel.ifcTreasury = approvalRecords['approverEmail'];
          this.ifcTresuryApprovalsId = <any>approvalRecords['approvalsId'];
          break;
        }
        case IFCGovernanceGroupName: {
          this.coordianatorIFCForm?.controls['ifcGovernance'].setValue(approvalRecords['approverEmail']);
          this.coordianatorIFCFormModel.ifcGovernance = approvalRecords['approverEmail'];
          this.ifcGovernanceApprovalsId = <any>approvalRecords['approvalsId'];
          break;
        }
        case IFCSecretaryGroupName: {
          this.coordianatorIFCForm?.controls['ifcSecretary']?.setValue(approvalRecords['approverEmail']);
          this.coordianatorIFCFormModel.ifcSecretary = approvalRecords['approverEmail'];
          break;
        }
        case 'default': {
          break;
        }
      }
      this.coordianatorIFCForm?.updateValueAndValidity();
    }
  }

  setIFCConcurrenceFormValues(approvalRecords: IFCGroupsDTO) {
    let status = approvalRecords['status'] === false ? IFCDeclined : IFCPending;
    switch (approvalRecords.type.toLocaleLowerCase()) {
      case IFCLegalGroupName: {
        this.ifcLegalDefaultMember = [];
        this.ifcLegalForm?.setValue({
          ifcLegal: approvalRecords['approverEmail'],
          legalComments: approvalRecords['comment'],
          ifcLegalSwitch: approvalRecords['reset'],
        });
        this.ifcLegalForm?.updateValueAndValidity();
        this.ifcLegalDefaultMember.push({
          defaultEmail: approvalRecords['approverEmail'],
          reviewerId: approvalRecords['reviewerId'],
          type: approvalRecords['type'],
        });
        this.ifcLegalApprovalsId = <any>approvalRecords['approvalsId'];
        this.ifcLegalFormModel.ifcLegal = approvalRecords['approverEmail'];
        this.ifcLegalFormModel.legalComments = approvalRecords['comment'];
        this.ifcLegalFormModel.ifcLegalSwitch = <any>approvalRecords['reset'];
        this.legalConcuredStatus = approvalRecords['status'];
        this.legalConcurrence = approvalRecords['status'] === true ? IFCConcurred : status;
        if(this.legalConcurrence !== IFCConcurred) this.ifcLegalForm?.controls['ifcLegalSwitch'].disable();
        break;
      }
      case IFCComptrollersGroupName: {
        this.ifcControllersDefaultMember = [];
        this.ifcControllersForm.setValue({
          ifcControllers: approvalRecords['approverEmail'],
          controllersComments: approvalRecords['comment'],
          ifcControllersSwitch: approvalRecords['reset'],
        });
        this.ifcControllersForm?.updateValueAndValidity();
        this.ifcControllersDefaultMember.push({
          defaultEmail: approvalRecords['approverEmail'],
          reviewerId: approvalRecords['reviewerId'],
          type: approvalRecords['type'],
        });
        this.ifcControllersApprovalsId = <any>approvalRecords['approvalsId'];
        this.ifcControllersFormModel.ifcControllers = approvalRecords['approverEmail'];
        this.controllersConcuredStatus = approvalRecords['status'];
        this.controllersConcurrence = approvalRecords['status'] === true ? IFCConcurred : status;
        if(this.controllersConcurrence !== IFCConcurred) this.ifcControllersForm?.controls['ifcControllersSwitch'].disable();
        break;
      }
      case IFCTaxGroupName: {
        this.ifcTaxDefaultMember = [];
        this.ifcTaxForm.setValue({
          ifcTax: approvalRecords['approverEmail'],
          taxComments: approvalRecords['comment'],
          ifcTaxSwitch: approvalRecords['reset'],
        });
        this.ifcTaxForm?.updateValueAndValidity();
        this.ifcTaxDefaultMember.push({
          defaultEmail: approvalRecords['approverEmail'],
          reviewerId: approvalRecords['reviewerId'],
          type: approvalRecords['type'],
        });
        this.ifcTaxApprovalsId = <any>approvalRecords['approvalsId'];
        this.ifcTaxFormModel.ifcTax = approvalRecords['approverEmail'];
        this.taxConcuredStatus = approvalRecords['status'];
        this.taxConcurrence = approvalRecords['status'] === true ? IFCConcurred : status;
        if(this.taxConcurrence !== IFCConcurred) this.ifcTaxForm?.controls['ifcTaxSwitch'].disable();
        break;
      }
      case IFCTreasuryGroupName: {
        this.ifcTreasuryDefaultMember = []
        this.ifcTreasuryForm.setValue({
          ifcTreasury: approvalRecords['approverEmail'],
          treasuryComments: approvalRecords['comment'],
          ifcTreasurySwitch: approvalRecords['reset'],
        });
        this.ifcTreasuryForm?.updateValueAndValidity();
        this.ifcTreasuryDefaultMember.push({
          defaultEmail: approvalRecords['approverEmail'],
          reviewerId: approvalRecords['reviewerId'],
          type: approvalRecords['type'],
        });
        this.ifcTresuryApprovalsId = <any>approvalRecords['approvalsId'];
        this.ifcTreasuryFormModel.ifcTreasury = approvalRecords['approverEmail'];
        this.treasuryConcuredStatus = approvalRecords['status'];
        this.treasuryConcurrence = approvalRecords['status'] === true ? IFCConcurred : status;
        if(this.treasuryConcurrence !== IFCConcurred) this.ifcTreasuryForm?.controls['ifcTreasurySwitch'].disable();
        break;
      }
      case IFCGovernanceGroupName: {
        this.ifcGovernanceDefaultMember = [];
        this.ifcGovernanceForm?.controls['ifcGovernance'].setValue(approvalRecords['approverEmail']);
        this.ifcGovernanceForm?.controls['governanceComments'].setValue(approvalRecords['comment']);
        this.ifcGovernanceForm?.controls['ifcGovernanceSwitch'].setValue(approvalRecords['reset']);
        this.ifcGovernanceFormModel.governanceComments = approvalRecords['comment'];
        this.ifcGovernanceForm?.updateValueAndValidity();
        this.ifcGovernanceDefaultMember.push({
          defaultEmail: approvalRecords['approverEmail'],
          reviewerId: approvalRecords['reviewerId'],
          type: approvalRecords['type'],
        });
        this.ifcGovernanceApprovalsId = <any>approvalRecords['approvalsId'];
        this.governanceConcuredStatus = approvalRecords['status'];
        this.governanceConcurrence = approvalRecords['status'] === true ? IFCConcurred : status;
        if(this.governanceConcurrence !== IFCConcurred) this.ifcGovernanceForm?.controls['ifcGovernanceSwitch'].disable();
        break;
      }
      case IFCSecretaryGroupName: {
        this.ifcSecretaryForm.setValue({
          dueDate: approvalRecords['dueDate'],
          concurDate: approvalRecords['concurDate'],
          ifcSecretary: approvalRecords['approverEmail'],
        });
        break;
      }
    }
  }

  enableDisableCommentsFormFields() {
    if (this.proposalStatus == STATUSPendingIFCConcurrence) {
      this.ifcLegalForm.controls['legalComments'].disable();
      this.ifcLegalForm.controls['ifcLegal'].disable();
      this.ifcControllersForm.controls['controllersComments'].disable();
      this.ifcControllersForm.controls['ifcControllers'].disable();
      this.ifcTaxForm.controls['taxComments'].disable();
      this.ifcTaxForm.controls['ifcTax'].disable();
      this.ifcTreasuryForm.controls['treasuryComments'].disable();
      this.ifcTreasuryForm.controls['ifcTreasury'].disable();
      this.ifcGovernanceForm.controls['governanceComments'].disable();
      this.ifcGovernanceForm.controls['ifcGovernance'].disable();
      this.ifcSecretaryForm.controls['ifcSecretary'].disable();
    }
  }

  createFormGroupsWithNestedFormControllers() {
    if (this.proposalStatus == 'pending ifc concurrence') {
      this.ifcSecretaryForm = this.fb.group({
        dueDate: [null],
        concurDate: [null],
        ifcSecretary: [null],
      });
      this.ifcLegalForm = this.fb.group({
        ifcLegal: [null],
        legalComments: [null],
        ifcLegalSwitch: [false],
      });
      this.ifcControllersForm = this.fb.group({
        ifcControllers: [null],
        controllersComments: [null],
        ifcControllersSwitch: [false],
      });
      this.ifcTaxForm = this.fb.group({
        ifcTax: [null],
        taxComments: [null],
        ifcTaxSwitch: [false],
      });
      this.ifcTreasuryForm = this.fb.group({
        ifcTreasury: [null],
        treasuryComments: [null],
        ifcTreasurySwitch: [false],
      });
      this.ifcGovernanceForm = this.fb.group({
        ifcGovernance: [null],
        governanceComments: [null],
        ifcGovernanceSwitch: [false],
      });
    } else {
      this.coordianatorIFCForm = this.fb.group({
        dueDate: [null],
        concurDate: [null],
        ifcSecretary: [null],
        ifcLegal: [null],
        ifcControllers: [null],
        ifcTax: [null],
        ifcTreasury: [null],
        ifcGovernance: [null],
      });
    }
  }

  async storeDefaultMemberIntoVariable() {
    const dueDate = this.isDueDate ? this.isDueDate : this.sessionDueDate;
    this.coordianatorIFCForm?.controls['dueDate'].setValue(dueDate);
    if (this.listOfGroupsMemberByDefault) {
      this.listOfGroupsMemberByDefault.forEach(
        (member: IFCReviewersMembersDTO) => {
          switch (member['type'].toLocaleLowerCase()) {
            case 'ifc legal': {
              this.ifcLegalDefaultMember = [];
              this.ifcLegalDefaultMember.push({
                defaultEmail: member['defaultEmail'],
                reviewerId: member['reviewerId'],
                type: member['type'],
              });
              this.coordianatorIFCForm?.controls['ifcLegal'].setValue(member['defaultEmail']);
              this.coordianatorIFCFormModel.ifcLegal = member['defaultEmail'];
              break;
            }
            case 'ifc comptrollers': {
              this.ifcControllersDefaultMember = [];
              this.ifcControllersDefaultMember.push({
                defaultEmail: member['defaultEmail'],
                reviewerId: member['reviewerId'],
                type: member['type'],
              });
              this.coordianatorIFCForm?.controls['ifcControllers'].setValue(member['defaultEmail']);
              this.coordianatorIFCFormModel.ifcControllers = member['defaultEmail'];
              break;
            }
            case 'ifc tax': {
              this.ifcTaxDefaultMember = [];
              this.ifcTaxDefaultMember.push({
                defaultEmail: member['defaultEmail'],
                reviewerId: member['reviewerId'],
                type: member['type'],
              });
              this.coordianatorIFCForm?.controls['ifcTax'].setValue(member['defaultEmail']);
              this.coordianatorIFCFormModel.ifcTax = member['defaultEmail'];
              break;
            }
            case 'ifc treasury': {
              this.ifcTreasuryDefaultMember = [];
              this.ifcTreasuryDefaultMember.push({
                defaultEmail: member['defaultEmail'],
                reviewerId: member['reviewerId'],
                type: member['type'],
              });
              this.coordianatorIFCForm?.controls['ifcTreasury'].setValue(member['defaultEmail']);
              this.coordianatorIFCFormModel.ifcTreasury = member['defaultEmail'];
              break;
            }
            case 'ifc governance': {
              this.ifcGovernanceDefaultMember = [];
              this.ifcGovernanceDefaultMember.push({
                defaultEmail: member['defaultEmail'],
                reviewerId: member['reviewerId'],
                type: member['type'],
              });
              this.coordianatorIFCForm?.controls['ifcGovernance'].setValue(member['defaultEmail']);
              this.coordianatorIFCFormModel.ifcGovernance = member['defaultEmail'];
              break;
            }
            case 'ifc secretary': {
              this.ifcSecretaryDefaultMember = [];
              this.ifcSecretaryDefaultMember.push({
                defaultEmail: member['defaultEmail'],
                reviewerId: member['reviewerId'],
                type: member['type'],
              });
              this.coordianatorIFCForm?.controls['ifcSecretary']?.setValue(member['defaultEmail']);
              this.coordianatorIFCFormModel.ifcSecretary = member['defaultEmail'];
              this.ifcSecretaryForm?.controls['ifcSecretary']?.setValue(member['defaultEmail']);
              this.ifcSecretaryFormModel.ifcSecretary = member['defaultEmail'];
              break;
            }
          }
        }
      );
    }
  }

  setResetDueDateFormValidation() {
    switch (this.proposalStatus) {
      case STATUSPendingGovernance: {
        const controls = this.coordianatorIFCForm?.controls["dueDate"];
        if (this.isDueDate) {
          controls.removeValidators([Validators.required]);
          controls?.updateValueAndValidity();
        } else {
          controls.setValidators([Validators.required]);
          controls?.updateValueAndValidity({ onlySelf: true });
        }
        break;
      }
      case STATUSPendingIFCConcurrence: {
        const cocurrenceDueDateControl = this.ifcSecretaryForm?.controls["dueDate"];
        if (this.isDueDate) {
          cocurrenceDueDateControl.removeValidators([Validators.required]);
          cocurrenceDueDateControl?.updateValueAndValidity();
        } else {
          cocurrenceDueDateControl.setValidators([Validators.required]);
          cocurrenceDueDateControl?.updateValueAndValidity({ onlySelf: true });
        }
        break;
      }
    }
  }

  // to get confirmation from modal
  getConfirmation(action: string) {
    this.setResetToggleValue(action);
    this.showHideModal = false;
  }

  // toggle btn to reset the group members
  resetApprovalToggle(event: any, groupName: string) {
    if (event) {
      this.resetGroupName = groupName;
      this.showHideModal = true;
      this.updateIFCMemberResetValue(groupName, true);
    }
    // else {
    //   console.log("resetApprovalToggle => ", event, groupName)
    //   this.showHideModal = false;
    //   this.updateIFCMemberResetValue(groupName, false);
    //   this.enableDisableGroupMemeberField();
    // }
  }

  updateIFCMemberResetValue(group: string, resetAction: boolean) {
    switch (group) {
      case 'legal': {
        this.ifcLegalForm?.controls['ifcLegalSwitch'].setValue(resetAction);
        this.ifcLegalForm?.controls['ifcLegalSwitch'].disable();
        break;
      }
      case 'controllers': {
        this.ifcControllersForm?.controls['ifcControllersSwitch'].setValue(resetAction);
        this.ifcControllersForm.controls['ifcControllersSwitch'].disable();
        break;
      }
      case 'tax': {
        this.ifcTaxForm?.controls['ifcTaxSwitch'].setValue(resetAction);
        this.ifcTaxForm?.controls['ifcTaxSwitch'].disable();
        break;
      }
      case 'treasury': {
        this.ifcTreasuryForm?.controls['ifcTreasurySwitch'].setValue(resetAction);
        this.ifcTreasuryForm?.controls['ifcTreasurySwitch'].disable();
        break;
      }
      case 'governance': {
        this.ifcGovernanceForm?.controls['ifcGovernanceSwitch'].setValue(resetAction);
        this.ifcGovernanceForm?.controls['ifcGovernanceSwitch'].disable();
        break;
      }
    }
  }

  // based upon modal action status set values into forms fields.
  setResetToggleValue(action: string) {
    this.showHideModal = false;
    if (action == 'yes') {
      this.isResetEmailTrigger.emit(true);
      this.createPayload();
      switch (this.resetGroupName) {
        case 'legal': {
          this.legalConcurrence = '';
          this.ifcLegalForm?.controls['ifcLegal'].enable();
          this.ifcReviewersService.updateIFCApproval(IFCLEGAL);
          break;
        }
        case 'controllers': {
          this.controllersConcurrence = '';
          this.ifcControllersForm?.controls['ifcControllers'].enable();
          this.ifcReviewersService.updateIFCApproval(IFCCOMPTROLLERS);
          break;
        }
        case 'tax': {
          this.taxConcurrence = '';
          this.ifcTaxForm?.controls['ifcTax'].enable();
          this.ifcReviewersService.updateIFCApproval(IFCTAX);
          break;
        }
        case 'treasury': {
          this.treasuryConcurrence = '';
          this.ifcTreasuryForm?.controls['ifcTreasury'].enable();
          this.ifcReviewersService.updateIFCApproval(IFCTREASURY);
          break;
        }
        case 'governance': {
          this.governanceConcurrence = '';
          this.ifcGovernanceForm?.controls['ifcGovernance'].enable();
          this.ifcReviewersService.updateIFCApproval(IFCGOVERNANCE);
          break;
        }
        default: {
          break;
        }
      }
    } else {
      this.getApprovalsList();
      this.enableDisableGroupMemeberField();
    }


  }

  enableDisableGroupMemeberField() {
    switch (this.resetGroupName) {
      case 'legal': {
        this.ifcLegalForm?.controls['ifcLegal'].disable();
        break;
      }
      case 'controllers': {
        this.ifcControllersForm?.controls['ifcControllers'].disable();
        break;
      }
      case 'tax': {
        this.ifcTaxForm?.controls['ifcTax'].disable();
        break;
      }
      case 'treasury': {
        this.ifcTreasuryForm?.controls['ifcTreasury'].disable();
        break;
      }
      case 'governance': {
        this.ifcGovernanceForm?.controls['ifcGovernance'].disable();
        break;
      }
    }
  }

  selectDueByDate(event: any) {
    if(!event) {
      this.ifcReviewersService.getIFCUpdateRecordsStatus(undefined);
    } else {
      let dueDate = new Date(event).toLocaleString('en-US');
      this.session.setItem("duedate", dueDate);
      this.setResetDueDateFormValidation();
      let isNewDateSelected = this.compareDueDateAndSelectedDate(event);
      if(this.isActionComplete || isNewDateSelected) {
        if(this.isDueDate) {
          this.ifcReviewersService.getIFCUpdateRecordsStatus(true);
          this.isApprovalSubmitted.emit(true);
          this.isDueDate = dueDate;
          this.isActionComplete = false;
          this.createPayload();
        } else {
          this.ifcReviewersService.getIFCUpdateRecordsStatus(false);
          this.isApprovalSubmitted.emit(false);
          this.isDueDate = dueDate;
          this.isActionComplete = false;
          this.createPayload();
        }
      }
    }
  }

  compareDueDateAndSelectedDate(event: any) {
    if(this.isDueDate == event) {
      this.ifcReviewersService.reviewNsubmitStatus(undefined);
      this.ifcReviewersService.getIFCUpdateRecordsStatus(undefined);
      return false;
    } else {
      this.ifcReviewersService.reviewNsubmitStatus(true);
      return true;
    }
  }

  selectConcurByDate(event: any) {
    if (event) {
      this.isConcurDate = event;
    }
  }

  legalPayload() {
    const dueDate = this.isDueDate ? this.isDueDate : this.sessionDueDate;
    const legalPayload = [
      {
        reviewerId: this.ifcLegalDefaultMember[0]?.reviewerId,
        type: this.ifcLegalDefaultMember[0]?.type,
        approvalsId: Number(this.ifcLegalApprovalsId),
        approverId: null,
        approverEmail: this.proposalStatus === STATUSPendingGovernance ?
          this.coordianatorIFCForm?.controls['ifcLegal'].value : this.ifcLegalForm?.controls['ifcLegal'].value,
        dueDate: dueDate,
        concurDate: this.isConcurDate ? this.isConcurDate : null,
        status: this.proposalStatus === STATUSPendingGovernance ? null : this.legalConcuredStatus,
        comment: this.proposalStatus === STATUSPendingGovernance ? '' : this.ifcLegalForm?.controls['legalComments'].value,
        reset: this.proposalStatus === STATUSPendingGovernance ? null : this.ifcLegalForm?.controls['ifcLegalSwitch'].value,
      }
    ]
    return legalPayload;
  }

  createPayload() {
    const dueDate = this.isDueDate ? this.isDueDate : this.sessionDueDate;
    if(this.ifcLegalDefaultMember.length) {
      let legalPayload: IFCGroupsDTO[] = this.legalPayload();
      let payload: IFCGroupsDTO[] = [...legalPayload,
    {
      reviewerId: this.ifcControllersDefaultMember[0]?.reviewerId,
      type: this.ifcControllersDefaultMember[0]?.type,
      approverId: null,
      approvalsId: Number(this.ifcControllersApprovalsId),
      approverEmail: this.proposalStatus === STATUSPendingGovernance ?
        this.coordianatorIFCForm?.controls['ifcControllers'].value : this.ifcControllersForm?.controls['ifcControllers'].value,
      dueDate: dueDate,
      concurDate: this.isConcurDate ? this.isConcurDate : null,
      status: this.proposalStatus == STATUSPendingGovernance ? null : this.controllersConcuredStatus,
      comment: this.proposalStatus == STATUSPendingGovernance ? '' : this.ifcControllersForm?.controls['controllersComments'].value,
      reset: this.proposalStatus == STATUSPendingGovernance ? null : this.ifcControllersForm?.controls['ifcControllersSwitch'].value,
    },
    {
      reviewerId: this.ifcTaxDefaultMember[0].reviewerId,
      type: this.ifcTaxDefaultMember[0].type,
      approverId: null,
      approvalsId: Number(this.ifcTaxApprovalsId),
      approverEmail: this.proposalStatus === STATUSPendingGovernance ?
        this.coordianatorIFCForm?.controls['ifcTax'].value : this.ifcTaxForm?.controls['ifcTax'].value,
      dueDate: dueDate,
      concurDate: this.isConcurDate ? this.isConcurDate : null,
      status: this.proposalStatus == STATUSPendingGovernance ? null : this.taxConcuredStatus,
      comment: this.proposalStatus == STATUSPendingGovernance ? '' : this.ifcTaxForm?.controls['taxComments'].value,
      reset: this.proposalStatus == STATUSPendingGovernance ? null : this.ifcTaxForm?.controls['ifcTaxSwitch'].value,
    },
    {
      reviewerId: this.ifcTreasuryDefaultMember[0]?.reviewerId,
      type: this.ifcTreasuryDefaultMember[0]?.type,
      approverId: null,
      approvalsId: Number(this.ifcTresuryApprovalsId),
      approverEmail: this.proposalStatus === STATUSPendingGovernance ?
        this.coordianatorIFCForm?.controls['ifcTreasury'].value : this.ifcTreasuryForm?.controls['ifcTreasury'].value,
      dueDate: dueDate,
      concurDate: this.isConcurDate ? this.isConcurDate : null,
      status: this.proposalStatus == STATUSPendingGovernance ? null : this.treasuryConcuredStatus,
      comment: this.proposalStatus == STATUSPendingGovernance ? '' : this.ifcTreasuryForm?.controls['treasuryComments'].value,
      reset: this.proposalStatus == STATUSPendingGovernance ? null : this.ifcTreasuryForm?.controls['ifcTreasurySwitch'].value,
    },
    {
      reviewerId: this.ifcGovernanceDefaultMember[0].reviewerId,
      type: this.ifcGovernanceDefaultMember[0].type,
      approverId: null,
      approvalsId: Number(this.ifcGovernanceApprovalsId),
      approverEmail: this.proposalStatus === STATUSPendingGovernance ?
        this.coordianatorIFCForm?.controls['ifcGovernance'].value : this.ifcGovernanceForm?.controls['ifcGovernance'].value,
      dueDate: dueDate,
      concurDate: this.isConcurDate ? this.isConcurDate : null,
      status: this.proposalStatus == STATUSPendingGovernance ? null : this.governanceConcuredStatus,
      comment: this.proposalStatus == STATUSPendingGovernance ? '' : this.ifcGovernanceForm?.controls['governanceComments'].value,
      reset: this.proposalStatus == STATUSPendingGovernance ? null : this.ifcGovernanceForm?.controls['ifcGovernanceSwitch'].value,
    },
     ];
     this.ifcReviewersService.reviewersPayload(payload);
     this.ifcPayloadRecords.emit(payload);
    }
  }

  reviewComponentUIShowHideData(records: IFCGroupsDTO[]) {
    this.approvalsRecords = [...records];
    switch (this.reviewComponent) {
      case true: {
        this.getFullName();
        break;
      }
      case false: {
        break;
      }
      case undefined: {
        break;
      }
    }
  }

  async getFullName() {
    let userFullName: string;
    for (let user of this.approvalsRecords) {
      this.graph.getUserByEmail(user.approverEmail).subscribe((data: UserDetails) => {
        userFullName = `${data.givenName} ${data.surname}`;
        user.fullName = userFullName;
      })
    }
  }

  getErrorTipForDueDate(controlName: string): any {
    switch (this.proposalStatus) {
      case STATUSPendingGovernance: {
        const control = this.coordianatorIFCForm?.controls[controlName];
        this.showErrorMsg(control);
        break;
      }
      case STATUSPendingIFCConcurrence: {
        const control = this.ifcSecretaryForm?.controls[controlName];
        this.showErrorMsg(control);
        break;
      }
    }
  }

  showErrorMsg(control:any) {
    if (control.hasError('required')) {
      return "Please select Due by Date!"
    }
    return ""
  }

  async searchGroupMember(value: string, groupName: string) {
    this.ifcLegalDefaultMember = [];
    if (value) {
      let users = [...await this.dataservice.searchUser(value)];
      switch (groupName) {
        case 'ifcLegal': {
          for (let user of users) {
            this.ifcLegalDefaultMember.push({
              defaultEmail: user?.mail,
              reviewerId: 1,
              type: 'IFC Legal'
            })
          }
          break;
        }
        case 'ifcControllers': {
          for (let user of users) {
            this.ifcControllersDefaultMember.push({
              defaultEmail: user?.mail,
              reviewerId: 4,
              type: "IFC Comptrollers"
            })
          }
          break;
        }
        case 'ifcTax': {
          for (let user of users) {
            this.ifcTaxDefaultMember.push({
              defaultEmail: user?.mail,
              reviewerId: 3,
              type: "IFC Tax"
            })
          }
          break;
        }
        case 'ifcTreasury': {
          for (let user of users) {
            this.ifcTreasuryDefaultMember.push({
              defaultEmail: user?.mail,
              reviewerId: 2,
              type: "IFC Treasury"
            })
          }
          break;
        }
        case 'ifcGovernance': {
          for (let user of users) {
            this.ifcGovernanceDefaultMember.push({
              defaultEmail: user?.mail,
              reviewerId: 5,
              type: "IFC Governance"
            })
          }
          break;
        }
      }
    }
  }

  onGroupMemberChange(event: string) { }

  sendReminderEmail() {
    this.isReminderEmailTrigger.emit(true);
  }

  ngOnDestroy() {
    this.ifcReviewersService.reviewNsubmitStatus(undefined);
    this.ifcReviewersService.getIFCUpdateRecordsStatus(undefined);
  }
}
