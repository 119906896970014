import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';
import { ACQUISITION_PROPOSAL, ACQUISITION_PROPOSAL_URL_PATH, DISSOLUTION_PROPOSAL, DISSOLUTION_PROPOSAL_URL_PATH, FORMATION_PROPOSAL, FORMATION_PROPOSAL_URL_PATH, MERGER_PROPOSAL, MERGER_PROPOSAL_URL_PATH, OWNERSHIP_RESTRUCTURING_PROPOSAL, OWNERSHIP_RESTRUCTURING_PROPOSAL_URL_PATH,PROPOSAL_ID, REQUEST_ID, SALES_PROPOSAL, SALES_PROPOSAL_URL_PATH, QUALIFICATION_WITHDRAWAL_PROPOSAL, QUALIFICATION_WITHDRAWAL_PROPOSAL_URL_PATH, OTHERS_PROPOSAL, OTHERS_PROPOSAL_URL_PATH,SQL_PROC_DEFAULT_PROPOSALS, SV_USER_ROLE } from 'src/app/constants/constants';
import { Proposal, QueryParams } from 'src/app/core/interfaces/Proposal';
import { ProposalType } from 'src/app/core/interfaces/ProposalTypes';
import { Status } from 'src/app/core/interfaces/Statuses';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { SessionStorageService } from 'src/app/core/services/session_storage';



@Component({
  selector: 'app-view-requests',
  templateUrl: './view-requests.component.html',
  styleUrls: ['./view-requests.component.less']
})
export class ViewRequestsComponent {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  data: any[] = [];
  searchValue = '';
  visible = false;
  currentUserProfile!: ICvxClaimsPrincipal;
  openRequestVisible = false;
  listOfData: Proposal[] = [];
  isTableDataLoading: boolean = false;
  listOfDisplayData: any;
  p30ID: any;
  cardHidden: boolean = true;
  requestDetails: any;
  requestData: Request[] = [];
  request_id: any;
  userId!: number;
  queryParam: QueryParams = {};
  statuses: Status[] = [];
  proposalTypes: ProposalType[] = [];
  searchForm = this.fb.group({
    proposalId: [],
    proposalTitle: [],
    status: [],
    transactionType: [],
    region: [],
    coordinator: [],
    proposalOverview: []

  })

  constructor(private apiService: P30APIService,
    private authService: CalAngularService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private dataservice: DataService,
    private session: SessionStorageService) { }

  ngOnInit() {
    if (this.dataservice.listOfProposals.length > 0) {
      this.listOfData = this.dataservice.listOfProposals;
    } else {
      this.getAllProposals(this.searchForm);
    }
    this.getStatuses();
    this.getProposalTypes();
  }

  navigateToProposalDetails(proposalId: string, requestId: number, proposalType: string, proposalStatus: string) {
   
    this.session.removeItem(REQUEST_ID);
    this.setRequestStatus(proposalStatus);
    switch (proposalType) {
      case ACQUISITION_PROPOSAL: {
        this.setProposalNRequestId(proposalId, proposalId.split('-')[2]);
        this.router.navigate([ACQUISITION_PROPOSAL_URL_PATH, proposalId]);
        break;
      }
      case DISSOLUTION_PROPOSAL: {
        this.setProposalNRequestId(proposalId, proposalId.split('-')[2]);
        this.router.navigate([DISSOLUTION_PROPOSAL_URL_PATH, proposalId]);
        break;
      }
      case SALES_PROPOSAL: {
        this.setProposalNRequestId(proposalId, proposalId.split('-')[2]);
        this.router.navigate([SALES_PROPOSAL_URL_PATH, proposalId]);
        break;
      }
      case MERGER_PROPOSAL: {
        this.setProposalNRequestId(proposalId, proposalId.split('-')[2]);
        this.router.navigate([MERGER_PROPOSAL_URL_PATH,proposalId]);
        break;
      }
      case FORMATION_PROPOSAL: {
        this.setProposalNRequestId(proposalId, proposalId.split('-')[2]);
        this.router.navigate([FORMATION_PROPOSAL_URL_PATH,proposalId]);
        break;
      }
      case OWNERSHIP_RESTRUCTURING_PROPOSAL: {
        this.setProposalNRequestId(proposalId, proposalId.split('-')[2]);
        this.router.navigate([OWNERSHIP_RESTRUCTURING_PROPOSAL_URL_PATH, proposalId]);
        break;
      }
      case QUALIFICATION_WITHDRAWAL_PROPOSAL: {
        this.setProposalNRequestId(proposalId, proposalId.split('-')[2]);
        this.router.navigate([QUALIFICATION_WITHDRAWAL_PROPOSAL_URL_PATH, proposalId]);
        break;
      }
      case OTHERS_PROPOSAL: {
        this.setProposalNRequestId(proposalId, proposalId.split('-')[2]);
        this.router.navigate([OTHERS_PROPOSAL_URL_PATH, proposalId]);
        break;
      }
    }
  }

  setProposalNRequestId(proposalId: string, requestId: any) {
    this.session.setItem(PROPOSAL_ID, proposalId);
    this.session.setItem(REQUEST_ID, requestId);
}

  getStatuses() {
    this.apiService.getStatuses().subscribe({
      next: (out) => {
        this.statuses = [...out.body];
      },
      error: (err) => {
        console.log("Unable to retrieve statuses", err);
      },
      complete: () => {

      }
    });

  }
  getProposalTypes() {
    this.apiService.getProposalTypes().subscribe({
      next: (out) => {
        this.proposalTypes = [...out.body];
      },
      error: (err) => {
        console.log("Unable to retrieve proposal types", err);
      },
      complete: () => {
      }
    });
  }

  getAllProposals(form: UntypedFormGroup) {
    this.isTableDataLoading = true;
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE).roleTitle);
    this.listOfData = [];
    this.apiService.getUser(this.currentUserProfile.objectId).subscribe({
      next: (user) => {
        if (user.body) {
          this.apiService.getProposalsByUserRole(this.dataservice.roleTitle, user.body.userId, user.body.emailAddress, user.body.emailAddress, "1").subscribe({
            next: (proposal) => {
              if (proposal.body) {
                this.listOfData = [...proposal.body];
                this.dataservice.listOfProposals = this.listOfData;
                this.isTableDataLoading = false;
              }
            },
            error: (err) => {
              console.log("Unable to retrieve the proposals: view requests screen", err);
              this.isTableDataLoading = false;
            },
            complete: () => {
              this.isTableDataLoading = false;
              if (form) {
                if (!isNonEmptyString(form.value.proposalId) &&
                  !isNonEmptyString(form.value.proposalTitle) &&
                  !isNonEmptyString(form.value.status) &&
                  !isNonEmptyString(form.value.transactionType) &&
                  !isNonEmptyString(form.value.region) &&
                  !isNonEmptyString(form.value.coordinator) &&
                  !isNonEmptyString(form.value.proposalOverview)) {
                  this.listOfData = this.dataservice.listOfProposals;
                } else {
                  this.listOfData = this.dataservice.listOfProposals.filter(item => (!form.value.proposalId || item.proposalId?.toLowerCase().includes(form.value.proposalId.toLowerCase()))
                    && (!form.value.proposalTitle || item.title?.toLowerCase().includes(form.value.proposalTitle.toLowerCase()))
                    && (!form.value.status || item.status?.toLowerCase().includes(form.value.status.toLowerCase()))
                    && (!form.value.transactionType || item.proposalType?.toLowerCase().includes(form.value.transactionType.toLowerCase()))
                    && (!form.value.region || item.region?.toLowerCase().includes(form.value.region.toLowerCase()))
                    && (!form.value.coordinator || item.coordinator?.toLowerCase().includes(form.value.coordinator.toLowerCase()))
                    && (!form.value.proposalOverview || item.overviewOfProposal?.toLowerCase().includes(form.value.proposalOverview.toLowerCase())))
                }
              }
            }
          })
        }

      }
    })
  }

  submitForm() {
    this.getAllProposals(this.searchForm);
  }

  cardStyles = {
    backgroundColor: '#F5F5F5',
    color: '#565656'
  }


  // to set status of the Request to use in session
  setRequestStatus(proposalStatus: string) {
    let status: any;
    if (this.statuses) {
      status = this.statuses.find(data => data?.name.toLocaleLowerCase() === proposalStatus.toLocaleLowerCase());
      this.session.setItem('requestStatus', {});
      let state = {
        id: status.statusId,
        name: status.name,
      };
      this.session.setItem('requestStatus', state);
    }
  }
}
