import { Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ContactsComponent } from '../contacts/contacts.component';
import { DataServiceContacts } from 'src/app/core/services/data_service_contacts';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { DataService } from 'src/app/core/services/data_service';
import { COORDINATOR, SV_PROPOSAL_STATUS, SV_USER_ROLE } from 'src/app/constants/constants';
import { IFCGroupsDTO } from 'src/app/core/interfaces/ifcReviewers';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class AssignmentsComponent {
  @Output() ifcReviewersPayload = new EventEmitter<IFCGroupsDTO[]>();
  @Output() isIFCTabActive = new EventEmitter<boolean>();
  @Output() isApprovalSubmitted = new EventEmitter<boolean>();
  @Output() isResetEmailTrigger = new EventEmitter<boolean>();
  @Output() isReminderEmailTrigger = new EventEmitter<boolean>();
  @ViewChild(ContactsComponent) childContactsComponent!: ContactsComponent;
  @Output() isLoader = new EventEmitter<boolean>();
  @Output() isContactFormValid = new EventEmitter<boolean>();
  @Input() contactsForm!: UntypedFormGroup;
  @Input() contactsTabClass = 'tab-default';
  @Input() commentsTabClass = 'tab-default';
  @Input() ifcRevewerTabClass = 'tab-default';
  contacts: string = 'general';
  comments: string = 'coordinator notes';
  ifcRevewer: string = 'ifc reviewers'
  selectedTabTitle: string = this.contacts;
  proposalId!: string;
  isRequestorCoordinatorRole!: boolean;
  requestStateName!: string;
  roleTitle:any;
  coordinator:string = COORDINATOR;
  proposalStatusId!:number;
  isIfcRequired: boolean = false;

  constructor(
    private session: SessionStorageService,
    private message: NzMessageService,
    private fb: UntypedFormBuilder,
    private contactsDataService: DataServiceContacts,
    private dataservice:DataService,
    private commentService: DataServiceComments
  ) {}

  ngOnInit() {
    this.getLoggedInUserRole();
    this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE).roleTitle);
    this.proposalStatusId = this.session.getItem(SV_PROPOSAL_STATUS).statusId;
    this.roleTitle = this.dataservice.roleTitle;
    this.requestStateName = this.commentService.getRequestStatus(); // to get status of Request created
  }

  emitIsLoader(event: boolean) {
    this.isLoader.emit(event);
  }
  emitIsContactFormValid(event: boolean) {
    this.isContactFormValid.emit(event);
  }
  async getCurrentTab(event: any) {
    this.selectedTabTitle = event.tab.nzTitle;
  }

  // coordinator notes tab will be visible only for Requestor and Coordinator Roles.
  // comments field will be only visible to Coordinator Roles
  getLoggedInUserRole() {
    let role = this.session?.getItem('userRole');
    switch (role.roleTitle.toLocaleLowerCase()) {
      case 'requestor':
      case 'coordinator':
        this.isRequestorCoordinatorRole = true;
        return;
      default:
        this.isRequestorCoordinatorRole = false;
        return;
    }
  }

  // get ifc forms payload to save the records
  ifcPayload(event: any) {
    this.ifcReviewersPayload.emit(event);
  }

  // this.ifcReviewersService.getIFCUpdateRecordsStatus(undefined);
  ifIFCRequired(event: any) {
    this.isIfcRequired = event;
    this.isIFCTabActive.emit(event);
  }

  isApprovalSubmit(event: boolean) {
    this.isApprovalSubmitted.emit(event);
  }
  receiveResetEmailTrigger(event: boolean) {
    this.isResetEmailTrigger.emit(event);
  }
  receiveReminderEmailTrigger(event: boolean) {
    this.isReminderEmailTrigger.emit(event);
  }

  ifcRevewerTbClass(event: any) {
    this.ifcRevewerTabClass = event;
  }
}
