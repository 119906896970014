<p><strong>Proposal Overview</strong></p>
<form [nzLayout]="'vertical'" nz-form [formGroup]="OthersProposalOverviewForm">
  <div nz-row nzGutter="8">
    <div nz-col nzSpan="10">
      <nz-form-item>
        <nz-form-label [@formLabelAnimation]
          ><label class="mandate">*</label> Overview of Proposal</nz-form-label
        >
        <nz-form-control [@formControlAnimation]>
          <textarea
            nz-input
            placeholder="Overview of Proposal1"
            formControlName="overviewOfProposal"
            [nzAutosize]="{ minRows: 5, maxRows: 10 }"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSpan="10" nzOffset="1">
      <nz-form-item>
        <nz-form-label [@formLabelAnimation]>
          <label class="mandate">*</label>
            Business Reason for Proposal
          </nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <textarea
            nz-input
            placeholder="Business Reason for Proposal"
            formControlName="businessReasonForProposal"
            [nzAutosize]="{ minRows: 5, maxRows: 10 }"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row nzGutter="8">
    <div nz-col nzSpan="5">
      <nz-form-item>
        <nz-form-label [@formLabelAnimation]>
          <label class="mandate">*</label> Target Effective Date
        </nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <nz-date-picker
            formControlName="targetEffectiveDate"
            nzPlaceHolder="Target Completion Date"
            style="width: 100%"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
