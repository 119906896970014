<!-- commnets input field only visible to Coordinator Role user  -->
<form [nzLayout]="'vertical'" nz-form *ngIf="isCoordinatorRole && !reviewComponent">
  <p><strong>Notes</strong></p>
  <div nz-row nzGutter="8">
    <div nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label [@formLabelAnimation]> Coordinator Notes </nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <textarea nz-input placeholder="Coordinator Notes" #coordinatorCommnet [value]="commentValue"
            [(ngModel)]="commentValue" [nzAutosize]="{ minRows: 5, maxRows: 10 }"
            (keyup)="getCommentInputData($event)"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<!-- Comments Records in table -->
<p *ngIf="!reviewComponent"><strong>History</strong></p>
<nz-table #basicTable [nzData]="notesRecords" [nzShowPagination]="notesRecords.length > 10 ? true : false"
  id="coordinator-notes-table" [ngClass]="reviewComponent ? 'reviewPage' : ''">
  <thead>
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Status</th>
      <th scope="col">Type of Note</th>
      <th scope="col">Coordinator</th>
      <th scope="col">Notes</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of notesRecords">
      <td>{{ data.createdDate | date:'shortDate' }}</td>
      <td>{{ data.status }}</td>
      <td>{{ data.typeOfComment }}</td>
      <td>{{ data.coordinator }}</td>
      <td>{{ data.comment }}</td>
    </tr>
  </tbody>
</nz-table>
