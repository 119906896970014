<nz-tabset  (nzSelectedIndexChange)="getCurrentTab($event)" [(nzSelectedIndex)]="selectedTabIndexValue">
    <nz-tab [nzTitle]="entitySelect" nzTabPaneStyle>
      <ng-template nz-tab>
        <app-acquisition-entity-selection [acquisitionEntitySelectionForm]="acquisitionEntitySelectionForm" (isLoader)="emitIsLoader($event)"></app-acquisition-entity-selection>
      </ng-template>
      <ng-template #entitySelect>
        <button nz-button nzType="link" [ngClass]="entitySelectionTabClass">{{entitySelection}}</button>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="question">
      <app-acquisition-entity-questions [acquisitionAssetForm]="acquisitionAssetForm" 
      [acquisitionOwnershipInterestForm]="acquisitionOwnershipInterestForm"
      [acquisitionEntityQuestionsForm]="acquisitionEntityQuestionsForm"
      (isSlatedisable)="emitIsSlatedisable($event)"
      ></app-acquisition-entity-questions>
      <ng-template #question>
        <button nz-button nzType="link" [ngClass]="entityQuestionTabClass">{{entityQuestions}}</button>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="summary">
      <ng-template nz-tab>
        <app-acquisition-proposal-overview [acquisitionProposalOverviewForm]="proposalOverviewForm" [isSlatedisable]="isSlatedisable"></app-acquisition-proposal-overview>
      </ng-template>
      <ng-template #summary>
        <button nz-button nzType="link" [ngClass]="summaryTabClass">{{overview}}</button>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="slate" [nzDisabled]="isSlatedisable">
      <ng-template nz-tab>
        <app-acquisition-proposed-slate [acquistionProposedSlateForm]="proposedSlateForm"></app-acquisition-proposed-slate>
      </ng-template>
      <ng-template #slate>
        <button nz-button nzType="link" [ngClass]="proposedSlateTabClass" >{{proposedSlate}}</button>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="attachment">
        <ng-template nz-tab>
          <app-file-upload [proposalId]="proposalId" (isLoader)="emitIsLoader($event)"></app-file-upload>
        </ng-template>
        <ng-template #attachment>
          <button nz-button nzType="link" [ngClass]="attachmentTabClass">{{attachments}}</button>
        </ng-template>
    </nz-tab>
  </nz-tabset>
