import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { MergigEntityName, PROPOSAL_ID, REQUEST_ID, SurvivingEntityName } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, UntypedFormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpResponse } from '@angular/common/http';
import { LegalEntity } from '../interfaces/LegalEntities';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';
import { DataServiceEntityQuestions } from './data_service_entity_questions';
import { AcquisitionEntityQuestion } from '../interfaces/EntityIndicatorQuestion';
;

@Injectable({
  providedIn: 'root'
})
export class DataServiceEntitySelection {

  constructor(private session: SessionStorageService,
    private fb: UntypedFormBuilder,
    private apiService: P30APIService,
    private message: NzMessageService, private dataServiceEntityQuestions: DataServiceEntityQuestions) { }


  getLegalEntities(): Observable<HttpResponse<any>> {
    return this.apiService.getLegalEntities(this.session.getItem(PROPOSAL_ID)).pipe(
      map(legalEntities => {
        return legalEntities;
      })
    );
  }
  createLegalEntity(legalEntity: LegalEntity, eType: string): Observable<HttpResponse<any>> {
    const data = {
      "ccn": legalEntity.ccn,
      "companyName": legalEntity.companyName,
      "jurisdiction": legalEntity.jurisdiction,
      "ownershipDetail": legalEntity.ownershipDetail,
      "legalEntityComments": legalEntity.legalEntityComments ? legalEntity.legalEntityComments : null,
      "typeofLegalEntity": eType,
      "requestId": Number(this.session.getItem(REQUEST_ID))
    }
    return this.apiService.createLegalEntity(this.session.getItem(PROPOSAL_ID), data).pipe(
      map(legelaEntities => {
        return legelaEntities;
      })
    )
  }



  updateLegalEntity(legalEntity: LegalEntity, eType: string): Observable<HttpResponse<any>> {
    const data = {
      "ccn": legalEntity.ccn,
      "companyName": legalEntity.companyName,
      "jurisdiction": legalEntity.jurisdiction,
      "ownershipDetail": legalEntity.ownershipDetail,
      "legalEntityComments": legalEntity.legalEntityComments ? legalEntity.legalEntityComments : null,
      "typeofLegalEntity": eType,
      "requestId": Number(this.session.getItem(REQUEST_ID))
    }
    return this.apiService.updateLegalEntity(this.session.getItem(PROPOSAL_ID), Number(legalEntity.legalEntityId), data).pipe(
      map(legalEntity => {
        return legalEntity;
      })
    );
  }
  patchLegalEntitiesToFormGroup(loader: boolean, form: UntypedFormGroup, formArray: FormArray, eType: string) {
    let entityQuestions: any = [];
    let entityQuestionsSet: any = [];
    this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID))
      .subscribe({
        next: (event) => {
          if (event.body) {
            entityQuestions = [...event.body];

            console.log("patchLegalEntitiesToFormGroup" + entityQuestions)

            this.getLegalEntities().subscribe({
              next: (event) => {
                if (event.body) {
                  let entities: LegalEntity[] = [];
                  entities = [...event.body];

                  entities.forEach(item => {
                    if (item.ccn == "NA" && item.typeofLegalEntity == eType) {
                      const control = form.controls["comments"]
                      control.setValue(item.legalEntityComments);
                      control.updateValueAndValidity();
                      form.controls["legalEntity"].setValue(item.ccn);
                      form.updateValueAndValidity();
                    } else {
                      if (item.typeofLegalEntity == eType) {
                        entityQuestionsSet = entityQuestions.filter((x: { legalEntityId: any; eType: string }) => x.legalEntityId === item.legalEntityId);
                        const legalEntityForm = this.fb.group({
                          legalEntityId: item.legalEntityId,
                          legalEntityName: [item.companyName],
                          ccn: item.ccn,//[this.legalEntities.filter(data =>data.companyName === leglaEntity.companyName)[0].ccn],
                          companyName: item.companyName,
                          jurisdiction: item.jurisdiction,
                          ownershipDetail: item.ownershipDetail,
                          entityQuestionsId: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].entityQuestionsId : '',
                          activeRegistration: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].haveActiveRegistrationsInOtherStatesCountries : false,
                          activeRegistrationComments: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].commentsForHavingActiveRegistrations : '',
                          subsidiaries: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].haveSubsidiaries : false,
                          subsidiariesComments: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].commentsForHavingSubsidiaries : '',
                          entityChangeName: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].willTheSurvivingEntityChangeItsNameAfterTheMerger : false,
                          entityChangeNameComments: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].commentsForSurvivingEntityChangingName : '',
                        })
                        formArray.push(legalEntityForm);
                      }
                    }

                  })
                }

              },
              error: (err) => {
                console.log("Unable to get legal entity record in the database table ", err);
                this.message.error('Unable to retrieve legal entities!');
              },
              complete: () => {


              }
            });
          }
          else {
            this.getLegalEntities().subscribe({
              next: (event) => {
                if (event.body) {
                  let entities: LegalEntity[] = [];
                  entities = [...event.body];
                  entities.forEach(item => {
                    if (item.ccn == "NA") {
                      const control = form.controls["comments"]
                      control.setValue(item.legalEntityComments);
                      control.updateValueAndValidity();
                      form.controls["legalEntity"].setValue(item.ccn);
                      form.updateValueAndValidity();
                    }
                  });
                }
              }
            });
          }

        }
      });

  }

  createOrUpdateLegalEntityRecordIfRecordExist(form: UntypedFormGroup, formArray: FormArray, event: any, eType: string) {
    let legalEntities: LegalEntity[] = [];
    for (let i = 0; i < formArray.length; i++) {
      legalEntities.push(formArray.at(i).value);
    }
    for (let entity of event.body) {
      if (legalEntities.length > 0 && entity.ccn != "NA") {
        let index = legalEntities.findIndex(data => data.ccn == entity.ccn);
        legalEntities[index].legalEntityId = entity.legalEntityId;
      }
    }
    if (legalEntities.length == 0) {
      legalEntities = [...event.body];
      let index = legalEntities.findIndex(data => data.ccn == "NA");
      legalEntities[index].legalEntityComments = form.controls["comments"].value;

    }
    legalEntities.forEach(element => {
      if (element.legalEntityId) {
        this.updateLegalEntity(element, eType).subscribe({
          next: (event) => {


            return;
          }
        });
      } else {
        this.createLegalEntity(element, eType).subscribe({
          next: (event) => {
            element.legalEntityId = event.body;
            return;
          }
        });
      }

    });
  }


  createOrUpdateMergerLegalEntityRecordIfRecordExist(form: UntypedFormGroup, formArray: FormArray, event: any, eType: string) {
    let legalEntities: LegalEntity[] = [];
    let formQuestionsArray = form.controls["legalEntitiesQuestionsFormArray"] as FormArray;
    // let formQuestionsArray = FormArray;
    if (eType == "Merging Entity") {
      formQuestionsArray = form.controls["legalEntitiesQuestionsFormArray"] as FormArray;
    }
    else {
      formQuestionsArray = form.controls["legalEntitiesQuestionsFormArray1"] as FormArray;
    }
    for (let i = 0; i < formArray.length; i++) {
      legalEntities.push(formArray.at(i).value);
    }
    for (let entity of event) {
      if (legalEntities.length > 0 && entity.ccn != "NA") {
        let index = legalEntities.findIndex(data => data.ccn == entity.ccn);
        legalEntities[index].legalEntityId = entity.legalEntityId;
      }
    }
    if (legalEntities.length == 0) {
      legalEntities = [...event];
      let index = legalEntities.findIndex(data => data.ccn == "NA");
      legalEntities[index].legalEntityComments = form.controls["comments"].value;

    }
    legalEntities.forEach(element => {
      if (element.legalEntityId) {
        this.updateLegalEntity(element, eType).subscribe({
          next: (event) => {
            let entityQuestionsSet = formQuestionsArray.value.filter((item: AcquisitionEntityQuestion) => item.legalEntityId == element.legalEntityId);
            if (entityQuestionsSet && entityQuestionsSet.length > 0) {
              let mergerEntityQuestion: any = {
                "requestId": Number(this.session.getItem(REQUEST_ID)),
                "legalEntityId": element.legalEntityId,
                "assests": "",
                "ownershipInterest": "",
                "nameOfEntityBeingAcquired": "",
                "sellerEntity": "",
                "approximateCost": "",
                "descriptionOfAsset": "",
                "jurisdiction": element.jurisdiction,
                "haveActiveRegistrationsInOtherStatesCountries": eType == MergigEntityName ? entityQuestionsSet[0].activeRegistration : false,
                "commentsForHavingActiveRegistrations": eType == MergigEntityName ? entityQuestionsSet[0].activeRegistrationComments : '',
                "haveSubsidiaries": eType == MergigEntityName ? entityQuestionsSet[0].subsidiaries : false,
                "commentsForHavingSubsidiaries": eType == MergigEntityName ? entityQuestionsSet[0].subsidiariesComments : '',
                "needBankAccounts": false,
                "commentsForNeedingBankAccounts": "",
                "willThereBeaNeedForAdditionalContributionsAfterInitialInvestment": false,
                "commentsForNeedingAdditionalContributions": "",
                "haveAnyEmployees": false,
                "commentsForHavingEmployees": "",
                "haveActiveBankAccounts": false,
                "commentsForHavingBankAccounts": "",
                "willTheNewEntityBeRegisteredInOtherStatesCountries": false,
                "commentsForNewEntityBeingRegistered": "",
                "willTheSurvivingEntityChangeItsNameAfterTheMerger": eType == SurvivingEntityName ? entityQuestionsSet[0].entityChangeName : false,
                "commentsForSurvivingEntityChangingName": eType == SurvivingEntityName ? entityQuestionsSet[0].entityChangeNameComments : '',
                "entityType": "",
                "typeOfSale": "",
                "investmentMethod": ""
              };
              if (entityQuestionsSet[0].entityQuestionsId === 0) {
                let entityQuestionsDetails: any = [];
                this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID))
                  .subscribe({
                    next: (event) => {
                      if (event.body) {
                        entityQuestionsDetails = [...event.body];
                        console.log("new enity details" + entityQuestionsDetails)
                        entityQuestionsDetails = entityQuestionsDetails.filter((x: { legalEntityId: any; eType: string }) => x.legalEntityId === element.legalEntityId);
                        console.log(entityQuestionsDetails);
                      }
                      this.updateMergerEntityQuestion(entityQuestionsDetails[0].entityQuestionsId, mergerEntityQuestion).subscribe({
                        next: (event) => {
                          return;
                        }
                      });

                    }
                  });
              }
              else {
                this.updateMergerEntityQuestion(entityQuestionsSet[0].entityQuestionsId, mergerEntityQuestion).subscribe({
                  next: (event) => {
                    return;
                  }
                });
              }
            }
          }
        });
      } else {
        this.createLegalEntity(element, eType).subscribe({
          next: (event) => {
            element.legalEntityId = event.body.legalEntityId;
            let entityQuestionsSet = formQuestionsArray.value.filter((item: { ccn: number | undefined; }) => item.ccn == element.ccn);
            if (entityQuestionsSet && entityQuestionsSet.length > 0) {
              let mergerEntityQuestion: any = {
                "requestId": Number(this.session.getItem(REQUEST_ID)),
                "legalEntityId": event.body.legalEntityId,
                "assests": "",
                "ownershipInterest": "",
                "nameOfEntityBeingAcquired": "",
                "sellerEntity": "",
                "approximateCost": "",
                "descriptionOfAsset": "",
                "jurisdiction": element.jurisdiction,
                "haveActiveRegistrationsInOtherStatesCountries": eType == MergigEntityName ? entityQuestionsSet[0].activeRegistration : false,
                "commentsForHavingActiveRegistrations": eType == MergigEntityName ? entityQuestionsSet[0].activeRegistrationComments : '',
                "haveSubsidiaries": eType == MergigEntityName ? entityQuestionsSet[0].subsidiaries : false,
                "commentsForHavingSubsidiaries": eType == MergigEntityName ? entityQuestionsSet[0].subsidiariesComments : '',
                "needBankAccounts": false,
                "commentsForNeedingBankAccounts": "",
                "willThereBeaNeedForAdditionalContributionsAfterInitialInvestment": false,
                "commentsForNeedingAdditionalContributions": "",
                "haveAnyEmployees": false,
                "commentsForHavingEmployees": "",
                "haveActiveBankAccounts": false,
                "commentsForHavingBankAccounts": "",
                "willTheNewEntityBeRegisteredInOtherStatesCountries": false,
                "commentsForNewEntityBeingRegistered": "",
                "willTheSurvivingEntityChangeItsNameAfterTheMerger": eType == SurvivingEntityName ? entityQuestionsSet[0].entityChangeName : false,
                "commentsForSurvivingEntityChangingName": eType == SurvivingEntityName ? entityQuestionsSet[0].entityChangeNameComments : '',
                "entityType": "",
                "typeOfSale": "",
                "investmentMethod": ""
              };
              this.addMergerEntityQuestion(mergerEntityQuestion).subscribe({
                next: (event) => {
                  formQuestionsArray.value.filter((item: { ccn: number | undefined; }) => item.ccn == element.ccn)[0].entityQuestionId = event.body.entityQuestionId
                  console.log(formQuestionsArray.controls.filter(i => i.value.ccn === element.ccn)[0])

                  return;
                }
              });
            }
            return;
          }
        });
      }

    });
  }

  createOrUpdateLegalEntityRecordIfNoRecord(form: UntypedFormGroup, eType: string) {
    let formArray = form.controls["legalEntitiesFormArray"] as FormArray;
    if (formArray.length > 0) {
      for (let i = 0; i < formArray.length; i++) {
        let leglaEntity: LegalEntity = {
          ccn: formArray.value[i].ccn,
          companyName: formArray.value[i].companyName,
          jurisdiction: formArray.value[i].jurisdiction,
          ownershipDetail: formArray.value[i].ownershipDetail,
          legalEntityComments: '',
          typeofLegalEntity: formArray.value[i].typeofLegalEntity
        }
        this.createLegalEntity(leglaEntity, eType).subscribe({
          next: (event) => {
            leglaEntity.legalEntityId = event.body.legalEntityId;
            return;
          }
        });
      }
    } else {
      let leglaEntity: LegalEntity = {
        ccn: "NA",
        companyName: "",
        jurisdiction: "",
        ownershipDetail: "",
        legalEntityComments: form.controls["comments"].value,
        typeofLegalEntity: ""
      }
      this.createLegalEntity(leglaEntity, eType).subscribe({
        next: (event) => {
          leglaEntity.legalEntityId = event.body.legalEntityId;
          return;
        }
      });
    }
  }

  createOrUpdateMergerLegalEntityRecordIfNoRecord(form: UntypedFormGroup, eType: string) {
    let formArray = form.controls["legalEntitiesQuestionsFormArray"] as FormArray;
    let entityValidation= form.controls["legalEntity"] as FormGroup;
    if (eType == "Merging Entity") {
      formArray = form.controls["legalEntitiesQuestionsFormArray"] as FormArray;
    }
    else {
      formArray = form.controls["legalEntitiesQuestionsFormArray1"] as FormArray;
    }

    if (formArray.length > 0) {
      for (let i = 0; i < formArray.length; i++) {
        let leglaEntity: LegalEntity = {
          ccn: formArray.value[i].ccn,
          companyName: formArray.value[i].companyName,
          jurisdiction: formArray.value[i].jurisdiction,
          ownershipDetail: formArray.value[i].ownershipDetail,
          legalEntityComments: '',
          typeofLegalEntity: formArray.value[i].typeofLegalEntity
        }
        this.createLegalEntity(leglaEntity, eType).subscribe({
          next: (event) => {
            leglaEntity.legalEntityId = event.body.legalEntityId;
            let mergerEntityQuestion: any = {
              "requestId": Number(this.session.getItem(REQUEST_ID)),
              "legalEntityId": event.body.legalEntityId,
              "assests": "",
              "ownershipInterest": "",
              "nameOfEntityBeingAcquired": "",
              "sellerEntity": "",
              "approximateCost": "",
              "descriptionOfAsset": "",
              "jurisdiction": formArray.value[i].jurisdiction,
              "haveActiveRegistrationsInOtherStatesCountries": eType == MergigEntityName ? formArray.value[i].activeRegistration : false,
              "commentsForHavingActiveRegistrations": eType == MergigEntityName ? formArray.value[i].activeRegistrationComments : '',
              "haveSubsidiaries": eType == MergigEntityName ? formArray.value[i].subsidiaries : false,
              "commentsForHavingSubsidiaries": eType == MergigEntityName ? formArray.value[i].subsidiariesComments : '',
              "needBankAccounts": false,
              "commentsForNeedingBankAccounts": "",
              "willThereBeaNeedForAdditionalContributionsAfterInitialInvestment": false,
              "commentsForNeedingAdditionalContributions": "",
              "haveAnyEmployees": false,
              "commentsForHavingEmployees": "",
              "haveActiveBankAccounts": false,
              "commentsForHavingBankAccounts": "",
              "willTheNewEntityBeRegisteredInOtherStatesCountries": false,
              "commentsForNewEntityBeingRegistered": "",
              "willTheSurvivingEntityChangeItsNameAfterTheMerger": eType == SurvivingEntityName ? formArray.value[i].entityChangeName : false,
              "commentsForSurvivingEntityChangingName": eType == SurvivingEntityName ? formArray.value[i].entityChangeNameComments : '',
              "entityType": "",
              "typeOfSale": "",
              "investmentMethod": ""
            };
            this.addMergerEntityQuestion(mergerEntityQuestion).subscribe({
              next: (event) => {
                formArray.value[i].entityQuestionsId = event.body.entityQuestionId
                return;
              }
            });
          }
        });

      }
    } 
    else if(entityValidation.value == "NA"){ 
      let leglaEntity: LegalEntity = {
        ccn: "NA",
        companyName: "",
        jurisdiction: "",
        ownershipDetail: "",
        legalEntityComments: form.controls["comments"].value,
        typeofLegalEntity: ""
      }
      this.createLegalEntity(leglaEntity, eType).subscribe({
        next: (event) => {
          leglaEntity.legalEntityId = event.body.legalEntityId;
          return;
        }
      });
    }
  }

  submitLegalEntitySelection(form: UntypedFormGroup, formArray: FormArray, eType: string): Promise<boolean> {
    if (form.valid) {
      this.getLegalEntities().subscribe({
        next: (event) => {
          if (event.body) {
            this.createOrUpdateLegalEntityRecordIfRecordExist(form, formArray, event, eType);
          } else {
            this.createOrUpdateLegalEntityRecordIfNoRecord(form, eType);
          }
        }
      });
      return Promise.resolve(true);
    } else {
      Object.values(form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return Promise.resolve(false);
    }
  }

  submitMergerLegalEntitySelection(form: UntypedFormGroup, formArray: FormArray, eType: string): Promise<boolean> {
    if (form.valid) {
      this.getLegalEntities().subscribe({
        next: (event) => {
          if (event.body) {
            let evnt = event.body.filter((x: { typeofLegalEntity: any; }) => x.typeofLegalEntity === eType);

            if (evnt.length > 0) {
              this.createOrUpdateMergerLegalEntityRecordIfRecordExist(form, formArray, evnt, eType);
            } else {
              this.createOrUpdateMergerLegalEntityRecordIfNoRecord(form, eType);
            }
          } else {
            this.createOrUpdateMergerLegalEntityRecordIfNoRecord(form, eType);
          }
        }
      });
      return Promise.resolve(true);
    } else {
      Object.values(form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return Promise.resolve(false);
    }
  }

  reviewAndValidateEntitySelection(form: UntypedFormGroup): Promise<boolean> {
    let formArray = form.controls["legalEntitiesFormArray"] as FormArray;
    const control = form.controls["comments"]
    if (formArray.length == 0 && !isNonEmptyString(control.value)) {
      control.setValidators([Validators.required]);
      control.markAsDirty();
      control.updateValueAndValidity();
      return Promise.resolve(form.valid);
    } else {
      control.removeValidators([Validators.required]);
      control.updateValueAndValidity();
      return Promise.resolve(form.valid);
    }

  }

  //##Seletion entity
  addMergerEntityQuestion(data: any): Observable<HttpResponse<any>> {
    return this.apiService.createEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), data).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );

  }

  updateMergerEntityQuestion(entityIndicatoryQuestionId: number, data: any): Observable<HttpResponse<any>> {
    return this.apiService.updateEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), entityIndicatoryQuestionId, data).pipe(
      map(entityQuestions => {
        return entityQuestions;
      })
    );
  }
}
