<main class="container">
  <section id="column2" npSetLayout [pageLayout]="PageLayout.Layout12">
    <np-menu [location]="NavigationLocation.Side" [navStartUrl]="'/3.html'"></np-menu>
  </section>
  <section id="column1">
    <app-page-header></app-page-header>
    <div class="class-content">
      <div nz-row nzGutter="8">
        <div nz-col nzSpan="7">
          <form nz-form [formGroup]="searchForm" class="login-form" (ngSubmit)="submitForm()">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your Proposal Id">
                <nz-input-group >
                  <input type="text" nz-input formControlName="proposalId" placeholder="Proposal Id" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your Proposal Title">
                <nz-input-group >
                  <input type="text" nz-input formControlName="proposalTitle" placeholder="Proposal Title" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your Status!">
                <nz-input-group>
                  <input type="text" nz-input formControlName="status" placeholder="Status" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your Transaction Type">
                <nz-input-group >
                  <input type="text" nz-input formControlName="transactionType" placeholder="Transaction Type" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input region">
                <nz-input-group >
                  <input type="text" nz-input formControlName="region" placeholder="Region" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input coordinator">
                <nz-input-group >
                  <input type="text" nz-input formControlName="coordinator" placeholder="Coordinator" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input keyword">
                <nz-input-group >
                  <input type="text" nz-input formControlName="proposalOverview" placeholder="Keyword search" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Search</button>
          </form>
        </div>
        <div nz-col nzSpan="16">
          <nz-table #nzTable [nzData]="listOfData" nzTableLayout="fixed" nzSize="small" nzAlign="center" [nzLoading]="isTableDataLoading">
            <thead>
              <tr>
                <th scope="col">Proposal Id</th>
                <th scope="col">Proposal Title</th>
                <th scope="col">Status</th>
                <th scope="col">Transaction Type</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              @for(data of nzTable.data; track data.proposalId){
                <tr class="proposal-record">
                  <td><a nz-button nzType="link" (click)="navigateToProposalDetails(data.proposalId,data.requestId,data.proposalType,data.status)" [attr.value]="data.proposalId">{{ data.proposalId }}</a></td>
                  <td>{{ data.title }}</td>
                  <td>{{ data.status }}</td>
                  <td>{{ data.proposalType }}</td>
                  <td>
                    <div nz-row>
                      <div nz-col nzSpan="3">
                        <a nz-button nzType="link">
                          <span nz-icon nzType="edit" nzTheme="fill" (click)="navigateToProposalDetails(data.proposalId,data.requestId,data.proposalType,data.status)"></span>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </nz-table>
        </div>
        <div nz-col nzSpan="1"></div>
      </div>
    </div>
  </section>
</main>
