import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, UntypedFormBuilder, FormArray, Validators } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpResponse } from '@angular/common/http';
import { LegalEntity } from '../interfaces/LegalEntities';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';
import { DataServiceEntityQuestions } from './data_service_entity_questions';
import { AcquisitionEntityQuestion } from '../interfaces/EntityIndicatorQuestion';
;

@Injectable({
    providedIn: 'root'
})
export class DataServiceMergerSelection {
    constructor(
        private session: SessionStorageService,
        private apiService: P30APIService,
        private message: NzMessageService
    ) {
    }
    reviewAndValidateMergerLEntitySelection(form: UntypedFormGroup, action: string = "RS"): Promise<boolean> {
        let formArray = form.controls["legalEntitiesFormArray"] as FormArray;
        let formQuestionsArray = form.controls["legalEntitiesQuestionsFormArray"] as FormArray;
        const control = form.controls["comments"];
        if (formArray.length == 0 && !isNonEmptyString(control.value)) {
            control.setValidators([Validators.required]);
            control.markAsDirty();
            control.updateValueAndValidity();
            return Promise.resolve(false);
        }
        else
            if (formArray.length > 0) {
                for (let i = 0; i < formQuestionsArray.length; i++) {
                   
                    let control = formQuestionsArray.at(i);
                    let activeRegistration = formQuestionsArray.at(i).get('activeRegistration');
                    let activeRegistrationComments = formQuestionsArray.at(i).get('activeRegistrationComments');

                    activeRegistrationComments?.removeValidators([Validators.required]);
                    activeRegistrationComments?.updateValueAndValidity();
                    //if (control.value.ccn != "NA" && 
                    if (control.value.activeRegistration && activeRegistrationComments && action == "RS") {
                        if (activeRegistrationComments.value == null || activeRegistrationComments.value == undefined
                            || activeRegistrationComments.value == "") {
                            activeRegistrationComments.setValidators([Validators.required]);
                            activeRegistrationComments.markAsDirty();
                            activeRegistrationComments.updateValueAndValidity({ onlySelf: true });
                            return Promise.resolve(false);
                        }

                    }
                }
                return Promise.resolve(true);

            }
            else {
                control.removeValidators([Validators.required]);
                control.updateValueAndValidity();
                return Promise.resolve(true);
            }


    }

    reviewAndValidateMergerSEntitySelection(form: UntypedFormGroup, action: string = "RS"): Promise<boolean> {
        let formArray = form.controls["legalEntitiesFormArray1"] as FormArray;
        let formQuestionsArray = form.controls["legalEntitiesQuestionsFormArray1"] as FormArray;
        const control = form.controls["comments"];
        if (formArray.length == 0 && !isNonEmptyString(control.value)) {
            control.setValidators([Validators.required]);
            control.markAsDirty();
            control.updateValueAndValidity();
            return Promise.resolve(false);
        }
        else
            if (formArray.length > 0) {
                for (let i = 0; i < formQuestionsArray.length; i++) {
                   
                    let control = formQuestionsArray.at(i);
                    let activeRegistration = formQuestionsArray.at(i).get('entityChangeName');
                    let activeRegistrationComments = formQuestionsArray.at(i).get('entityChangeNameComments');

                    activeRegistrationComments?.removeValidators([Validators.required]);
                    activeRegistrationComments?.updateValueAndValidity();
                    //if (control.value.ccn != "NA" && 
                    if (control.value.entityChangeName && activeRegistrationComments && action == "RS") {
                        if (activeRegistrationComments.value == null || activeRegistrationComments.value == undefined
                            || activeRegistrationComments.value == "") {
                            activeRegistrationComments.setValidators([Validators.required]);
                            activeRegistrationComments.markAsDirty();
                            activeRegistrationComments.updateValueAndValidity({ onlySelf: true });
                            return Promise.resolve(false);
                        }

                    }
                }
                return Promise.resolve(true);

            }
            else {
                control.removeValidators([Validators.required]);
                control.updateValueAndValidity();
                return Promise.resolve(true);
            }


    }
   
}


