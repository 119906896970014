import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Approval } from '../interfaces/approval';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';

@Injectable({
  providedIn: 'root',
})
export class DataServiceApprovals {
  

  constructor(
    private session: SessionStorageService,
    private apiService: P30APIService,
    private message: NzMessageService
  ) {}

  getApprovals() {
    return this.apiService.getApprovals(this.session.getItem(PROPOSAL_ID)).pipe(
      map(approval =>{
        if(approval.body){
          return approval.body;
        }
      })
    )
  }
  checkApprovalExist(type:string){
    return new Promise<any>((resolve)=>{
      this.getApprovals().subscribe(data =>{
        if(data){
          let approvals = <Approval[]>data;
          let approval = approvals.find(e=> isNonEmptyString(e.comment) && e.type === type);
          if(approval){
            resolve(approval)
          }else{
            resolve(null);
          } 
        }else{
          resolve(null);
        }
      })
    })
    
  }
  
}
