import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot} from "@angular/router";
import { Observable } from "rxjs";
import { ProposalTypesComponent } from "src/app/pages/proposal-types/proposal-types.component";


@Injectable()
export class CanDeativateProposalTypeGuardService {
    
  canDeactivate(
    component: ProposalTypesComponent,
    currentRoute: ActivatedRouteSnapshot,
    
  ): Observable<boolean>|Promise<boolean>|boolean {
    return component.canExitComponent();
  }
}