<p><strong>Proposal Overview</strong></p>
<form [nzLayout]="'vertical'" nz-form  [formGroup]="formationProposalOverviewForm">
  <div nz-row nzGutter="8">
    <div nz-col nzSpan="11">
      <nz-form-item>
          <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Overview of Proposal</nz-form-label>
          <nz-form-control [@formControlAnimation]>
              <textarea nz-input placeholder="Overview of Proposal"
                  formControlName="overviewOfProposal" [nzAutosize]="{ minRows: 5, maxRows: 10 }" ></textarea>
          </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSpan="11" nzOffset="1">
      <nz-form-item>
          <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Business Reason for Proposal</nz-form-label>
          <nz-form-control [@formControlAnimation]>
              <textarea nz-input placeholder="Business Reason for Proposal" formControlName="businessReasonForProposal"
              [nzAutosize]="{ minRows: 5, maxRows: 10 }" ></textarea>
          </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row nzGutter="8">
      <div nz-col nzSpan="5">
      <nz-form-item>
          <nz-form-label  [@formLabelAnimation] nzTooltipTitle="If unknown, please use best estimate."><label class="mandate">*</label> Target Completion Date</nz-form-label>
          <nz-form-control [@formControlAnimation]>
              <nz-date-picker formControlName="targetCompletionDate" nzPlaceHolder="Target Completion Date" style="width: 100%" ></nz-date-picker>
          </nz-form-control>
      </nz-form-item>
    </div>
      <div nz-col nzSpan="5" nzOffset="1">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Proposed Legal Entity Name</nz-form-label>
          <nz-form-control [@formControlAnimation]>              
              <input nz-input PlaceHolder="Proposed Legal Entity Name" formControlName="proposedLegalEntityName" style="width: 100%">
          </nz-form-control>
        </nz-form-item>
      </div>
    
      <div nz-col nzSpan="11" nzOffset="1">
        <nz-form-item>
            <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Country Or State Of Incorporation
            </nz-form-label>
            <nz-form-control [@formControlAnimation]>
                <input nz-input placeholder="Country Or State Of Incorporation" formControlName="countryorStateofIncorporation" >
            </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row nzGutter="8">
    <div nz-col nzSpan="11">
      <nz-form-item>
          <nz-form-label [@formLabelAnimation]><label class="mandate asteric-19">*</label>Name of Shareholder(s) or Owner(s) and<br>
              Proposed Voting and Capital Structure Percentages
          </nz-form-label>
          <nz-form-control [@formControlAnimation]>
              <textarea nz-input placeholder="Name Of ShareHolder And More" formControlName="nameOfShareHolderAndMore"
              [nzAutosize]="{ minRows: 5, maxRows: 10 }" ></textarea>
          </nz-form-control>
      </nz-form-item>
    </div>
    </div>
  
</form>
