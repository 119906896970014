<form [formGroup]="legalEntitySalesForm">
  <!--  -->
  <div nz-row nzGutter="8" formGroupName="sellerFormGroup" class="d-block salesEntitySelection">
    <p><strong>Sales - Seller</strong></p>
    <div class="entityContainer">
      <div nz-col nzSpan="11">
        <nz-form-item class="salesLegalEntity">
          <nz-form-label [@formLabelAnimation]
            nzTooltipTitle="Comments will be required if Legal Entity is N/A."><label class="mandate">*</label>
            Legal Entity
          </nz-form-label>
          <nz-form-control [@formControlAnimation] [nzErrorTip]="getErrorTipForSellerLegalEntity('sellerLegalEntity')">
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Search by Active CCN or Company Name"
              formControlName="sellerLegalEntity" [(ngModel)]="selectedLegalSeller"
              (ngModelChange)="onSellerChange($event)" (nzOnSearch)="onSellerSearchEvent($event)"
              [nzSuffixIcon]="suffixIconSearch" [nzLoading]="nzLoading">
              @for(list of sellerEntityList;track list.ccn){
              <nz-option [nzValue]="list.companyName" [nzLabel]="list.companyName"></nz-option>
              }
            </nz-select>
            <ng-template #suffixIconSearch>
              <span nz-icon nzType="search" nzTheme="outline" (click)="onSearchSeller()"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="salesLegalEntity" *ngIf="showSellerComments">
          <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Comments</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <textarea nz-input placeholder="Comments" formControlName="sellerComments"
              [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12" nzOffset="1">
        <ng-container *ngTemplateOutlet="
            entityDetails;
            context: { $implicit: 'Seller Entity', data: selectedSellerEntityDetails, showHide: showEntityDetailsBlock }
          ">
        </ng-container>
      </div>
    </div>
  </div>

  <div nz-row nzGutter="8" formGroupName="buyerFormGroup" class="d-block salesEntitySelection">
    <p><strong>Sales - Buyer</strong></p>
    <div class="entityContainer">
      <div nz-col nzSpan="11">
        <nz-form-item class="salesLegalEntity">
          <nz-form-label [@formLabelAnimation]
            nzTooltipTitle="Comments will be required if Legal Entity is N/A."><label class="mandate">*</label>
            Legal Entity
          </nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Search by Active CCN or Company Name"
              formControlName="buyerLegalEntity" [(ngModel)]="selectedLegalBuyer"
              (nzOnSearch)="onBuyerSearchEvent($event)" (ngModelChange)="onBuyerChange($event)"
              [nzSuffixIcon]="suffixIconBuyerSearch" [nzLoading]="nzLoadingBuyer">
              @for(list of buyerEntityList;track list.ccn){
              <nz-option [nzValue]="list.companyName" [nzLabel]="list.companyName"></nz-option>
              }
            </nz-select>
            <ng-template #suffixIconBuyerSearch>
              <span nz-icon nzType="search" nzTheme="outline" (click)="onSearchBuyer()"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="salesLegalEntity" *ngIf="showBuyerComments">
          <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Comments</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <textarea nz-input placeholder="Comments" formControlName="buyerComments"
              [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12" nzOffset="1">
        <ng-container *ngTemplateOutlet="
            entityDetails;
            context: { $implicit: 'Buyer Entity', data: selectedBuyerEntityDetails, showHide: showEntityDetailsBuyerBlock}
          ">
        </ng-container>
      </div>
    </div>
  </div>

  <div nz-col nzSpan="11">
    <nz-form-item class="salesLegalEntity">
      <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>
        Type of Sale
      </nz-form-label>
      <nz-form-control [@formControlAnimation]>
        <nz-select nzPlaceHolder="Select One" formControlName="salesType" [(ngModel)]="selectedSaleType"
          (ngModelChange)="onSaleTypeChange($event)">
          @for(list of typeOfSalesRecords;track list.entityID){
          <nz-option [nzValue]="list.entityName" [nzLabel]="list.entityName"></nz-option>
          }
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div formGroupName="salesTypeQuestionsFormGroup">
    @if(showQuestionsList) {
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="5">
        <nz-form-label [@formLabelAnimation]>
          <label class="mandate">*</label>
          Have any employees?
        </nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <nz-switch [(ngModel)]="haveEmployee" formControlName="haveEmployees"
         ></nz-switch>
        </nz-form-control>
      </div>
      <div nz-col nzSpan="5" nzOffset="1" *ngIf="haveEmployee">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <textarea nz-input placeholder="Comments" formControlName="haveEmployeesComments"
              [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row nzGutter="8">
      <div nz-col nzSpan="5">
        <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>
          Have active bank accounts?
        </nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <nz-switch [(ngModel)]="bankAccount" formControlName="needBankAccounts"></nz-switch>
        </nz-form-control>
      </div>
      <div nz-col nzSpan="5" nzOffset="1" *ngIf="bankAccount">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <textarea nz-input placeholder="Comments" formControlName="needBankAccountsComments"
              [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row nzGutter="8">
      <div nz-col nzSpan="5">
        <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>
          Have subsidiaries?
        </nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <nz-switch [(ngModel)]="subsidiaries" formControlName="haveSubsidiaries"></nz-switch>
        </nz-form-control>
      </div>
      <div nz-col nzSpan="5" nzOffset="1" *ngIf="subsidiaries">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <textarea nz-input placeholder="Comments" formControlName="haveSubsidiariesComments"
              [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    }
  </div>
  <!-- </div> -->
</form>


<ng-template #entityDetails let-name let-data="data" let-showHide="showHide">
  @if(showHide) {
  <div nz-row nzGutter="8" [nzAlign]="'middle'" id="entityInfo">
    <div nz-col nzSpan="15" nzOffset="1">
      <nz-carousel [nzEffect]="'scrollx'" [nzDots]="false" #carouselLegalEntity style="height: 300px"
        [nzEnableSwipe]="false">
        <div nz-carousel-content *ngFor="let list of data">
          <nz-descriptions [nzColumn]="1">
            <nz-descriptions-item nzTitle="Company Code Number">
              {{list.ccn}}
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Company Name">
              {{list.companyName }}
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Jurisdiction">
              {{list.jurisdiction}}
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Ownership Details">
              {{list.ownershipDetail}}
            </nz-descriptions-item>
          </nz-descriptions>
        </div>
      </nz-carousel>
    </div>
    <div class="entityType">
      <p>{{ name }}</p>
    </div>
  </div>

  }
</ng-template>
