import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { CalAngularService } from '@cvx/cal-angular';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { CONCUR, PROPOSAL_ID, REQUEST_ID, SV_USER, SV_USER_ROLE } from 'src/app/constants/constants';
import { Approval } from 'src/app/core/interfaces/approval';
import { Reviewer } from 'src/app/core/interfaces/reviewer';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-concur-decline',
  templateUrl: './concur-decline.component.html',
  styleUrl: './concur-decline.component.less',
  animations: [formControlElement, formLabelElement],
})
export class ConcurDeclineComponent {
  @Input() actionType: any;
  @Output() enableReview = new EventEmitter<boolean>();
  @Output() submitConcurDecline = new EventEmitter<boolean>();
  concurDeclineForm = this.fb.group({
    concurDeclineComment: [null]
  });
  disableSubmit: boolean = true;
  comment!: string;
  concurDeclineFormData!: Approval;
  approvals: Approval[] = [];
  approverId!: number;

  constructor(private fb: FormBuilder, 
    private apiService: P30APIService, 
    private authService:CalAngularService,
    private dataservice: DataService,
    private session: SessionStorageService) { }

  ngOnInit() {
    this.getApprovals();
    this.approverId = this.session.getItem(SV_USER).userId;

  }

  onChange(event: string) {
    if (isNonEmptyString(event)) {
      this.disableSubmit = false;
    } else {
      this.disableSubmit = true;
    }
  }

  getApprovals() {
    this.apiService.getApprovals(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (approval) => {
        if (approval.body) {
          this.approvals = [...approval.body];
          let approval_data = this.approvals.find(e=>e.approverId==this.approverId);
          if(approval_data){
            this.concurDeclineForm.controls["concurDeclineComment"].disable();
            this.concurDeclineForm.updateValueAndValidity();
          }
        }
      },
      error: (err) => {
        console.log(`Unable to retrieve approvals ${err}`);
      },
      complete: () => {

      }
    })
  }
  submit() {
    this.apiService.getReviewers().subscribe({
      next: (reviewer) => {
        if (reviewer.body) {
          let reviewers: Reviewer[] = [...reviewer.body];
          let reviewerData = reviewers.find(e => e.type === this.session.getItem(SV_USER_ROLE).roleTitle)
          this.apiService.getApprovals(this.session.getItem(PROPOSAL_ID)).subscribe({
            next: (approval) => {
              const data: Approval = {
                requestId: this.session.getItem(REQUEST_ID),
                reviewerId: reviewerData?.reviewerId,
                type: reviewerData?.type,
                approverId: this.session.getItem(SV_USER).userId,
                approverEmail: this.session.getItem(SV_USER).emailAddress,
                status: this.actionType === CONCUR,
                comment: this.comment,
                reset: false
              }
              if (approval.body) {
                let approvals: Approval[] = [...approval.body];
                let approvalsId: any = approvals.find(e => e.type === reviewerData?.type)?.approvalsId;
                data.dueDate = approvals.find(e => e.type === reviewerData?.type)?.dueDate;
                if(approvalsId){
                  this.updateApproval(approvalsId,data);
                }else{
                  data.dueDate = '';
                  this.createApproval(data);
                }
                
              } else {
                data.dueDate = '';
                this.createApproval(data);
              }
            },
            error: (err) => {

            },
            complete: () => {
              if(this.actionType !== CONCUR){
                this.dataservice.saveHistory(this.authService.cvxClaimsPrincipal.objectId,
                  this.session.getItem(PROPOSAL_ID),
                  `Proposal declined`)
              }else{
                this.dataservice.saveHistory(this.authService.cvxClaimsPrincipal.objectId,
                  this.session.getItem(PROPOSAL_ID),
                  `Proposal concurred`)
              }
              
            }
          })


        }

      },
      error: (err) => {

      },
      complete: () => {
        
      }
    })
  }

  createApproval(data: Approval){
    this.apiService.createApprovals(this.session.getItem(PROPOSAL_ID), data).subscribe({
      next: (result) => {
        console.log(result.body)
      },
      error: (err) => {
        console.log(`Unable to save concur decline record ${err}`);
      },
      complete: () => {
        this.submitConcurDecline.emit(true);
      }

    })
  }
  updateApproval(approvalsId: number,data: Approval){
    this.apiService.updateApprovals(this.session.getItem(PROPOSAL_ID), approvalsId, data).subscribe({
      next: (result) => {
        console.log(result.body)
      },
      error: (err) => {
        console.log(`Unable to update concur decline record ${err}`);
      },
      complete: () => {
        this.submitConcurDecline.emit(true);
      }

    })
  }
  goBackToReviewPage(){
    this.enableReview.emit(true);
  }

  
}
