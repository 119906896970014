import { Component, Input } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { PROPOSAL_ID } from 'src/app/constants/constants';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceProposedSlate } from 'src/app/core/services/data_service_proposed_slate';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-formation-proposal-slate',
  templateUrl: './formation-proposal-slate.component.html',
  styleUrls: ['./formation-proposal-slate.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class FormationProposedSlateComponent {
  @Input() formationPropossedSlateForm!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private apiService: P30APIService,
    private session: SessionStorageService,
    private message: NzMessageService,
    private dataservice: DataService,
    private proposaSlateDataService: DataServiceProposedSlate
  ) {}

  ngOnInit() {
    if (this.session.getItem(PROPOSAL_ID)) {
      if (this.proposedSlates.length == 0) {
        this.addField();
      }
    }
  }

  get proposedSlates() {
    return this.formationPropossedSlateForm.controls[
      'proposedSlates'
    ] as FormArray;
  }

  //adds blank slate line when plus button is clicked; called from html file
  addField() {
    const proposedSlateForm = this.fb.group({
      proposedSlateId: [null],
      requestId: [null],
      name: [null],
      title: [null],
    });
    this.proposedSlates.push(proposedSlateForm);
  }

  //removes slate line when minus button is clicked; called from html file
  async removeForm(proposedSlateIndex: number) {
    if (this.proposedSlates.value.length > 1) {
      const id =
        this.proposedSlates.at(proposedSlateIndex).value.proposedSlateId;
      if (id) {
        this.proposaSlateDataService.deleteProposedSlate(id).subscribe({
          next: (event) => {},
          error: (err) => {
            console.log('Unable to delete proposed slate', err);
          },
          complete: () => {},
        });
        this.proposedSlates.removeAt(proposedSlateIndex);
      } else {
        this.proposedSlates.removeAt(proposedSlateIndex);
      }
    }
  }
}
