<nz-tabset (nzSelectedIndexChange)="getCurrentTab($event)" [(nzSelectedIndex)]="selectedTabIndexValue">
    <nz-tab [nzTitle]="entitySelect" nzTabPaneStyle>
      <ng-template nz-tab>
        <app-others-entity-selection
          [legalEntityOthersForm]="legalEntityOthersForm"
          [entitySelectionTabClass]="entitySelectionTabClass"
        >
        </app-others-entity-selection>
      </ng-template>
      <ng-template #entitySelect>
        <button nz-button nzType="link" [ngClass]="entitySelectionTabClass">
          {{ entitySelection }}
        </button>
      </ng-template>
    </nz-tab>
  
    <nz-tab [nzTitle]="summary">
      <ng-template nz-tab>
        <app-others-proposal-overview
          [OthersProposalOverviewForm]="proposalOverviewForm"
        ></app-others-proposal-overview>
      </ng-template>
      <ng-template #summary>
        <button nz-button nzType="link" [ngClass]="summaryTabClass">
          {{ overview }}
        </button>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="attachment">
      <ng-template nz-tab>
        <app-file-upload
          [proposalId]="proposalId"
          (isLoader)="emitIsLoader($event)"
        ></app-file-upload>
      </ng-template>
      <ng-template #attachment>
        <button nz-button nzType="link" [ngClass]="attachmentTabClass">
          {{ attachments }}
        </button>
      </ng-template>
    </nz-tab>
  </nz-tabset>
  