import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AcquisitionEntitySelectionComponent } from '../acquisition-entity-selection/acquisition-entity-selection.component';
import { AcquisitionEntityQuestionsComponent } from '../acquisition-entity-questions/acquisition-entity-questions.component';
import { AcquisitionProposalOverviewComponent } from '../acquisition-proposal-overview/acquisition-proposal-overview.component';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { PROPOSAL_ID } from 'src/app/constants/constants';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AcquisitionProposedSlateComponent } from '../acquisition-proposed-slate/acquisition-proposed-slate.component';
import { DataServiceProposalOverview } from 'src/app/core/services/data_service_proposal_overview';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-formation-proposal-details',
  templateUrl: './formation-proposal-details.component.html',
  styleUrls: ['./formation-proposal-details.component.less'],
  animations: [formControlElement, formLabelElement],
  providers: [DatePipe],
})

export class FormationProposalDetailsComponent {
  @ViewChild(AcquisitionEntitySelectionComponent) childAcquisitionEntitySelectionComponent!: AcquisitionEntitySelectionComponent;
  @ViewChild(AcquisitionEntityQuestionsComponent) childAcquisitionEntityQuestionsComponentComponent!: AcquisitionEntityQuestionsComponent;
  @ViewChild(AcquisitionProposalOverviewComponent) childAcquisitionProposalOverviewComponent!: AcquisitionProposalOverviewComponent;
  @ViewChild(AcquisitionProposedSlateComponent) childAcquisitionProposedSlateComponent!: AcquisitionProposedSlateComponent;

  @Output() isLoader = new EventEmitter<boolean>();
  @Output() selectedTabIndexEmitValue=new EventEmitter<number>();
  @Input() proposalOverviewForm!:  UntypedFormGroup;
  @Input() proposedSlateForm!: UntypedFormGroup;
  @Input() formationAssetForm!: UntypedFormGroup;
  @Input() formationOwnershipInterestForm!: UntypedFormGroup;
  @Input() formationEntityQuestionsForm!: UntypedFormGroup;
  //@Input() acquisitionEntitySelectionForm!: UntypedFormGroup;
  @Input() legalEntityFormationForm!: FormGroup;
  @Input() selectedTabIndexValue!:number;

  entitySelection: string = "entity selection";
  entityQuestions: string = "entity questions";
  overview: string = "overview";
  proposedSlate: string = "proposed directors and/or officers";
  attachments: string = "attachments";
  selectedTabTitle: string = this.entitySelection;
  @Input() entitySelectionTabClass = 'tab-default';
  @Input() entityQuestionTabClass = 'tab-default';
  @Input() summaryTabClass = 'tab-default';
  @Input() proposedSlateTabClass = 'tab-default';
  @Input() attachmentTabClass = 'tab-default';
  proposalId!: string;
  constructor(private session: SessionStorageService, private message: NzMessageService,
    private proposalOverviewDataService: DataServiceProposalOverview){}
  ngOnInit(){
    this.isLoader.emit(false);
    //being used by html file for attachments?
    if(this.session.getItem(PROPOSAL_ID)){
      this.proposalId = this.session.getItem(PROPOSAL_ID);
    }

  }
  emitIsLoader(event:boolean){
    this.isLoader.emit(event);
  }
  async getCurrentTab(event:any){
    this.selectedTabIndexEmitValue.emit(event);
  }


}
