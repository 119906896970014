import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { P30APIService } from './core/services/api';
import { MsGraphService } from './core/services/ms-graph';
import { Observable, Subject, map, takeUntil, timer } from 'rxjs';
import { NavigationLocation, NextPageService, PageLayout, SearchType, SliderType } from '@cvx/nextpage';
import { SessionStorageService } from './core/services/session_storage';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DataService } from './core/services/data_service';
import { UserRole } from './core/interfaces/UserRole';
import { DataServiceComments } from './core/services/data_service_comments';
import { ADCoordinatorGroupName, ADIFCControllersGroupName, ADIFCGovernanceGroupName, ADIFCLegalGroupName, ADIFCSecretaryGroupName, ADIFCTaxGroupName, ADIFCTaxGroupName2, ADIFCTreasuryGroupName, ADMIN, ADRequestorGroupName, COORDINATOR, HomeComponentRouterName, IFCCOMPTROLLERS, IFCGOVERNANCE, IFCLEGAL, IFCSECRETARY, IFCTAX, IFCTREASURY, REQUESTOR, RootComponentRouterName, SALES_PROPOSAL, SV_USER, SV_USER_ROLE, TestADCoordinatorGroupName, TestADIFCControllersGroupName, TestADIFCGovernanceGroupName, TestADIFCLegalGroupName, TestADIFCSecretaryGroupName, TestADIFCTaxGroupName, TestADIFCTaxGroupName2, TestADIFCTreasuryGroupName, VIEWERS } from './constants/constants';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  SearchType = SearchType;
  SliderType = SliderType;
  isCollapsed = true;
  currentYear = new Date().getFullYear();
  userName: string = '';
  userphoto!: any;
  displayPhoto = false;
  role_id!: number;
  currentUserProfile!: ICvxClaimsPrincipal;
  visible = false;
  userRoles: UserRole[] = [];
  isInitialized: boolean = false;
  serverError: boolean = false;
  requesterRoleId!: number;
  coordinatorRoleId!: number;
  legalRoleId!: number;
  treasuryRoleId!: number;
  taxRoleId!: number;
  comptrollersRoleId!: number;
  governanceRoleId!: number;
  secretaryRoleId!: number;
  adminRoleId!: number;
  viewersRoleId!: number;
  storeGroupIds: any[] = [];
  groupMembersWithGroupName: any[] = [];
  matchedUser: any;
  event$: any;
  // Session time out
  private sessionTimeoutInSeconds = 1800; // Session timeout duration in seconds
  private userActivity$ = new Subject<void>();
  private timer$: any;
  private destroy$ = new Subject<void>();
  routerURL!: string;
  adCoordinatorGroupName!: string;
  adIFCControllersGroupName!: string;
  adIFCTreasuryGroupName!: string;
  adIFCTaxGroupName!: string;
  adIFCTaxGroupName2!: string;
  adIFCLegalGroupName!: string;
  adIFCGovernanceGroupName!: string;
  adIFCSecretaryGroupName!: string;

  constructor(private NP: NextPageService, private authService: CalAngularService, private msgraph: MsGraphService,
    private sanitizer: DomSanitizer, private apiService: P30APIService, private router: Router, private activateRouter: ActivatedRoute, private commentService: DataServiceComments,
    private session: SessionStorageService, private modal: NzModalService, private dataservice: DataService) {
    this.NP.options.sitename = 'Policy 30 Proposals - Changes to Corporate Structure';
    this.startTimer();
    this.initUserActivityTracking();
    this.handleSessionTimeout();
    this.event$ = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
      this.routerURL = event.url
      }
    });
  }


  private startTimer(): void {
    this.timer$ = timer(0, 1800000)
      .pipe(
        takeUntil(this.destroy$)
      );
  }
  private initUserActivityTracking(): void {
    window.addEventListener('mousemove', () => this.resetTimer());
    window.addEventListener('keydown', () => this.resetTimer());
  }

  private resetTimer(): void {
    this.userActivity$.next();
  }

  private handleSessionTimeout(): void {
    this.timer$.subscribe((seconds: number) => {
      const remainingTime = this.sessionTimeoutInSeconds - seconds;
      if (remainingTime <= 0) {
        this.logout();
      }
    });
  }

  private logout(): void {
    // Perform logout logic here
    this.dataservice.getAccessToken();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.event$.unsubscribe();
  }
  getUserDetails() {
    return this.authService.getUserPropertiesFromMsGraph(["givenName", "cai", "jobTitle", "photo", "surname"]).pipe(
      map((user: any) => {
        if (user) return user;
      })
    );
  }

  async ngOnInit() {
    console.log('app component');
    this.authService.getAADToken().subscribe(token => {
      if (token) {
        this.session.setItem('token', JSON.parse(JSON.stringify(token)));
        this.currentUserProfile = this.authService.cvxClaimsPrincipal;
        if (this.authService.cvxClaimsPrincipal.objectId) {
          this.apiService.getUser(this.authService.cvxClaimsPrincipal.objectId).subscribe({
            next: async (result) => {
              this.dataservice.getUserRoles();
              this.dataservice.getStatuses();
              
              if (result.status == 200 && result.body) {
                this.storeLoggedUserDetails(result.body);
                this.session.setItem(SV_USER, result.body);
                this.setUserRole(result.body.userRoleId).subscribe(roles => {
                  if (roles.body) {
                    let role: UserRole = roles.body.find((userRole: UserRole) => userRole.userRoleId == result.body.userRoleId);
                    this.session.setItem(SV_USER_ROLE, role);
                    this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE));
                    this.getAndSetPhoto();
                  }
                })

              } else {
                this.addUser();
              }
             
              this.adCoordinatorGroupName = environment.sendExternalEmail ? ADCoordinatorGroupName : TestADCoordinatorGroupName;
              this.adIFCControllersGroupName = environment.sendExternalEmail ? ADIFCControllersGroupName : TestADIFCControllersGroupName;
              this.adIFCTreasuryGroupName = environment.sendExternalEmail ? ADIFCTreasuryGroupName : TestADIFCTreasuryGroupName;
              this.adIFCTaxGroupName = environment.sendExternalEmail ? ADIFCTaxGroupName : TestADIFCTaxGroupName;
              this.adIFCTaxGroupName2 = environment.sendExternalEmail ? ADIFCTaxGroupName2 : TestADIFCTaxGroupName2;
              this.adIFCLegalGroupName = environment.sendExternalEmail ? ADIFCLegalGroupName : TestADIFCLegalGroupName;
              this.adIFCGovernanceGroupName = environment.sendExternalEmail ? ADIFCGovernanceGroupName : TestADIFCGovernanceGroupName;
              this.adIFCSecretaryGroupName = environment.sendExternalEmail ? ADIFCSecretaryGroupName : TestADIFCSecretaryGroupName;

                if (this.routerURL == "" || this.routerURL== HomeComponentRouterName || this.routerURL == RootComponentRouterName) {
                  console.log('this.routerURL', this.routerURL);
                  this.getDefaultUserRolesFromDB();
                  let coorgroup = await this.assignRoles(this.adCoordinatorGroupName);
                  let ifclegalgroup = await this.assignRoles(this.adIFCLegalGroupName);
                  let ifctrsgroup = await this.assignRoles(this.adIFCTreasuryGroupName);
                  let ifctaxgroup = await this.assignRoles(this.adIFCTaxGroupName);
                  let ifctaxadgroup = await this.assignRoles(this.adIFCTaxGroupName2);
                  let ifccontgroup = await this.assignRoles(this.adIFCControllersGroupName);
                  let ifcgovgroup = await this.assignRoles(this.adIFCGovernanceGroupName);
                  let ifcsecgroup = await this.assignRoles(this.adIFCSecretaryGroupName);
                  console.log('coorgroup',coorgroup);
                  console.log('ifclegalgroup',ifclegalgroup);
                  console.log('ifctrsgroup',ifctrsgroup);
                  console.log('ifctaxgroup',ifctaxgroup);
                  console.log('ifctaxadgroup',ifctaxadgroup);
                  console.log('ifccontgroup',ifccontgroup);
                  console.log('ifcgovgroup',ifcgovgroup);
                  console.log('ifcsecgroup',ifcsecgroup);
                  console.log('all groups assigned');
                  if ((ifcsecgroup != undefined && ifcsecgroup != null) || (ifclegalgroup != undefined && ifclegalgroup != null) || (ifctrsgroup != undefined && ifctrsgroup != null) || (ifctaxgroup != undefined && ifctaxgroup != null) || (ifctaxadgroup != undefined && ifctaxadgroup != null) || (ifccontgroup != undefined && ifccontgroup != null) || (ifcgovgroup != undefined && ifcgovgroup != null) || (coorgroup != undefined && coorgroup != null)) {
                    console.log('all groups assigned');
                    if (ifcsecgroup?.userDetails) {
                       await this.checkNAssignUserRole(this.matchedUser?.userGroup, this.matchedUser?.userDetails);
                    }
                    console.log('user updated');
                  }
                  else
                  {
                    this.apiService.getUser(this.currentUserProfile.objectId).subscribe({
                      next: (userData) => {
                        this.storeLoggedUserDetails(userData?.body);
                        this.session.setItem(SV_USER, userData.body);
                        this.setUserRole(userData.body.userRoleId).subscribe({
                          next: (roles) => {
                          if (roles.body) {
                            let role: UserRole = roles.body.find((userRole: UserRole) => userRole.userRoleId == userData.body.userRoleId);
                            this.session.setItem(SV_USER_ROLE, role);
                            console.log('user role', role);
                            this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE));
                          }
                          },
                          error: (err) => {
                            this.isInitialized = true;
                            console.log("update user error => ", err);
                            return Promise.resolve(true);
                          },
                          complete: () => {
                            this.isInitialized = true;
                            return Promise.resolve(true);
                          }
                        });
                      }
                    });
                    this.isInitialized = true;
                  }
              }
              else
              {
                this.apiService.getUser(this.currentUserProfile.objectId).subscribe({
                  next: (userData) => {
                    this.storeLoggedUserDetails(userData?.body);
                    this.session.setItem(SV_USER, userData.body);
                    this.setUserRole(userData.body.userRoleId).subscribe({
                      next: (roles) => {
                      if (roles.body) {
                        let role: UserRole = roles.body.find((userRole: UserRole) => userRole.userRoleId == userData.body.userRoleId);
                        this.session.setItem(SV_USER_ROLE, role);
                        console.log('user role', role);
                        this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE));
                      }
                      },
                      error: (err) => {
                        this.isInitialized = true;
                        console.log("update user error => ", err);
                        return Promise.resolve(true);
                      },
                      complete: () => {
                        this.isInitialized = true;
                        return Promise.resolve(true);
                      }
                    });
                  }
                });
              }
            },
            error: (err) => {
              this.serverError = true;
              this.isInitialized = true;
            },
            complete: () => { }
          });
        }
      }
    });
  }


  addUser() {
    return this.getUserDetails().subscribe((user: any) => {
      const data = {
        'firstName': user.givenName,
        'lastName': user.surname,
        'emailAddress': this.currentUserProfile.email,
        'cai': this.currentUserProfile.cai,
        'jobTitle': this.currentUserProfile.jobTitle,
        'provisioningId': this.currentUserProfile.provisioningId,
        'objectId': this.currentUserProfile.objectId,
        'userRoleId': 1,
        'createdDate': new Date().toLocaleString("en-US")
      }
      this.apiService.getUser(this.authService.cvxClaimsPrincipal.objectId).subscribe(user => {
        if (user.status == 200) {
        } else {
          this.apiService.addUser(data).subscribe(out => {
            if (out.status === 201) {
              this.apiService.getUser(this.currentUserProfile.objectId).subscribe(userData => {
                if (userData.body) {
                  this.storeLoggedUserDetails(userData?.body);
                  this.session.setItem(SV_USER, userData.body);
                  this.setUserRole(userData.body.userRoleId);
                }
              });
            }
          });
        }
      })

    })
  }

  getAndSetPhoto() {
    this.msgraph.getUserPhoto().subscribe(data => {
      let jsonKey: string = "";
      new Uint8Array(data).forEach(function (byte: number) { jsonKey += String.fromCharCode(byte) })
      let base64String = btoa(jsonKey);
      localStorage.setItem('photo', base64String);
      this.userphoto = this.sanitizer.sanitize(SecurityContext.URL,
        `data:image/*;base64,${base64String}`
      );
      this.displayPhoto = true;
    });
  }

  // to store Logged_IN User_Role in session
  setUserRole(id: number): Observable<HttpResponse<any>> {
    return this.apiService.getAllUserRoles().pipe(
      map(userRoles => {
        return userRoles;
      })
    )
  }

  // to save assignments comments logged-in user details required as payload
  storeLoggedUserDetails(data: { firstName: string; lastName: string; emailAddress: string; objectId: string; }) {
    let fullname = `${data.firstName} ${data.lastName}`;
    let detail = {
      fullName: fullname,
      mail: data.emailAddress,
      objectId: data.objectId,
    };
    this.commentService.getUserDetail(detail);
  }

  async getMembersByID(ID: string) {
    return this.msgraph.getAADGroupMembersFromGraph(ID).pipe(
      map((data: any) => {
        if (data) return data;
      })
    );
  }

  async getGroupByName(groupName: string) {
    return this.msgraph.getAADGroupId(groupName).pipe(
      map((data: any) => {
        if (data) return data;
      })
    );
  }

  async getUserById(id: string) {
    return this.apiService.getUser(id).pipe(
      map((data: any) => {
        if (data) return data;
      })
    );
  }

  async getDBUserRoles() {
    return this.apiService.getAllUserRoles().pipe(
      map(userRoles => {
        return userRoles;
      })
    )
  }

  async assignRoles(groupName: string): Promise<any> { 
    return new Promise<any>(async (resolved) => {
    console.log('assignRoles ', groupName);
    let membersByGroupID;
    let ADGroupRecords: any, ID: string;
    this.storeGroupIds = [];
    ADGroupRecords = await this.getGroupByName(groupName);
    ADGroupRecords.subscribe(async (data: any) => {
      if (data.value.length) {
        this.storeGroupIds.push({
          displayName: data.value[0]?.displayName,
          id: data.value[0]?.id
        });
        console.log('storeGroupIds', this.storeGroupIds);
        this.groupMembersWithGroupName = [];
        for (let x of this.storeGroupIds) {
          membersByGroupID = await this.getMembersByID(x.id);
          membersByGroupID.subscribe({
            next: (response) => {
              response.groupname = x['displayName'];
              let record = { groupName: response['groupname'], data: response['value'] }
              if (this.groupMembersWithGroupName.find((user) => user.groupName === response['groupname']) === undefined) {
                this.groupMembersWithGroupName.push(record);
              }
              let result;
              if (this.groupMembersWithGroupName) {
                this.groupMembersWithGroupName.forEach((records: any) => {
                  result = records['data'].filter((x: any) => {
                    if (x.id == this.currentUserProfile.objectId) {
                      //if (x.id == "16999ef7-2dc0-4871-a01c-438061cbecef") {
                        //if(x.id == "02fbc70d-0d1c-47d6-b9fa-08a4bd424f63"){
                      console.log('matched user', x);
                      this.matchedUser = {
                        userDetails: x,
                        userGroup: records['groupName']
                      }
                      
                    }
                  })
                })
                return resolved(this.matchedUser)
              }
             //await this.mapGroupNameWithTheirMembers();
             //resolved(true);
            },
            error: (err) => {
              console.log("user details error ", err);
              //resolved(true);
            },
            complete: () => {
             // resolved(true);
            }
          })
        }
      }
      else {
        resolved(true);
      }
    });
  })
}


  async mapGroupNameWithTheirMembers() {
    let result;
    if (this.groupMembersWithGroupName) {
      this.groupMembersWithGroupName.forEach((records: any) => {
        result = records['data'].filter((x: any) => {
          if (x.id == this.currentUserProfile.objectId) {
            //if (x.id == "16999ef7-2dc0-4871-a01c-438061cbecef") {
              //if(x.id == "02fbc70d-0d1c-47d6-b9fa-08a4bd424f63"){
            console.log('matched user', x);
            this.matchedUser = {
              userDetails: x,
              userGroup: records['groupName']
            }
            return this.matchedUser
          }
        })
      })
      // if (result && this.matchedUser?.userDetails) {
      //  await this.checkNAssignUserRole(this.matchedUser?.userGroup, this.matchedUser?.userDetails);
      // }
    }
  }

  async checkNAssignUserRole(groupName: string, user: any) {
    switch (groupName.toLocaleLowerCase()) {
      case ADRequestorGroupName.toLocaleLowerCase(): { // Requestor
        await this.updateUserRecords(this.requesterRoleId);
        break;
      }
      case ADCoordinatorGroupName.toLocaleLowerCase(): { // Coordinator
        await this.updateUserRecords(this.coordinatorRoleId);
        break;
      }
      case TestADCoordinatorGroupName.toLocaleLowerCase(): { // Coordinator
        await this.updateUserRecords(this.coordinatorRoleId);
        break;
      }
      case ADIFCLegalGroupName.toLocaleLowerCase(): { // Legal
        await this.updateUserRecords(this.legalRoleId);
        break;
      }
      case TestADIFCLegalGroupName.toLocaleLowerCase(): { // Legal
        await this.updateUserRecords(this.legalRoleId);
        break;
      }
      case ADIFCTreasuryGroupName.toLocaleLowerCase(): { // Treasury
        await this.updateUserRecords(this.treasuryRoleId);
        break;
      }
      case TestADIFCTreasuryGroupName.toLocaleLowerCase(): { // Treasury
        await this.updateUserRecords(this.treasuryRoleId);
        break;
      }
      case ADIFCTaxGroupName.toLocaleLowerCase(): { // Tax
        await this.updateUserRecords(this.taxRoleId);
        break;
      }
      case TestADIFCTaxGroupName.toLocaleLowerCase(): { // Tax
        await this.updateUserRecords(this.taxRoleId);
        break;
      }
      case ADIFCTaxGroupName2.toLocaleLowerCase(): { // Tax
        await this.updateUserRecords(this.taxRoleId);
        break;
      }
      case TestADIFCTaxGroupName2.toLocaleLowerCase(): { // Tax
        await this.updateUserRecords(this.taxRoleId);
        break;
      }
      case ADIFCControllersGroupName.toLocaleLowerCase(): { // Comptrollers
        await this.updateUserRecords(this.comptrollersRoleId);
        break;
      }
      case TestADIFCControllersGroupName.toLocaleLowerCase(): { // Comptrollers
        await this.updateUserRecords(this.comptrollersRoleId);
        break;
      }
      case ADIFCGovernanceGroupName.toLocaleLowerCase(): { // Governance
        await this.updateUserRecords(this.governanceRoleId);
        break;
      }
      case TestADIFCGovernanceGroupName.toLocaleLowerCase(): { // Governance
        await this.updateUserRecords(this.governanceRoleId);
        break;
      }
      case ADIFCSecretaryGroupName.toLocaleLowerCase(): { // Secretary
        await this.updateUserRecords(this.secretaryRoleId);
        break;
      }
      case TestADIFCSecretaryGroupName.toLocaleLowerCase(): { // Secretary
        await this.updateUserRecords(this.secretaryRoleId);
        break;
      }
    }
  }

  async getDefaultUserRolesFromDB() {
    let defaultUsersRoles = await this.getDBUserRoles();
    defaultUsersRoles.subscribe((user: any) => {
      user['body'].forEach((records: any) => {
        switch (records.roleTitle.toLocaleLowerCase()) {
          case REQUESTOR.toLocaleLowerCase(): {
            this.requesterRoleId = records.userRoleId;
            break;
          }
          case COORDINATOR.toLocaleLowerCase(): {
            this.coordinatorRoleId = records.userRoleId;
            break;
          }
          case IFCLEGAL.toLocaleLowerCase(): {
            this.legalRoleId = records.userRoleId;
            break;
          }
          case IFCTREASURY.toLocaleLowerCase(): {
            this.treasuryRoleId = records.userRoleId;
            break;
          }
          case IFCTAX.toLocaleLowerCase(): {
            this.taxRoleId = records.userRoleId;
            break;
          }
          case IFCCOMPTROLLERS.toLocaleLowerCase(): {
            this.comptrollersRoleId = records.userRoleId;
            break;
          }
          case IFCGOVERNANCE.toLocaleLowerCase(): {
            this.governanceRoleId = records.userRoleId;
            break;
          }
          case IFCSECRETARY.toLocaleLowerCase(): {
            this.secretaryRoleId = records.userRoleId;
            break;
          }
          case ADMIN.toLocaleLowerCase(): {
            this.adminRoleId = records.userRoleId;
            break;
          }
          case VIEWERS.toLocaleLowerCase(): {
            this.viewersRoleId = records.userRoleId;
            break;
          }
        }
      })
    });
  }

  async updateUserRecords(assignRoleId: number): Promise<void> {
    const fName = this.session.getItem(SV_USER).firstName;
    const lName = this.session.getItem(SV_USER).lastName;
    const data = {
      'firstName': fName,
      'lastName': lName,
      'cai': this.currentUserProfile.cai,
      'jobTitle': this.currentUserProfile.jobTitle,
      'objectId': this.currentUserProfile.objectId,
      'provisioningId': this.currentUserProfile.provisioningId,
      'emailAddress': this.currentUserProfile.email,
      'userRoleId': assignRoleId,
      // 'updatedBy': `${fName} ${lName}`,
      'updatedBy': this.currentUserProfile.email,
      'updatedDate': new Date().toLocaleString("en-US")
    }
      this.apiService.updateUser(this.currentUserProfile.objectId, data).subscribe({
      next: (response) => {
        console.log('user updated', response);
        this.apiService.getUser(this.currentUserProfile.objectId).subscribe({
          next: (userData) => {
            this.storeLoggedUserDetails(userData?.body);
            this.session.setItem(SV_USER, userData.body);
            this.setUserRole(userData.body.userRoleId).subscribe({
              next: (roles) => {
              if (roles.body) {
                let role: UserRole = roles.body.find((userRole: UserRole) => userRole.userRoleId == userData.body.userRoleId);
                this.session.setItem(SV_USER_ROLE, role);
                console.log('user role', role);
                this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE));
              }
              },
              error: (err) => {
                this.isInitialized = true;
                console.log("update user error => ", err);
                return Promise.resolve(true);
              },
              complete: () => {
                this.isInitialized = true;
                return Promise.resolve(true);
              }
            });
          }
        });
        
      },
      error: (err) => {
        console.log("update user error => ", err);
        return Promise.resolve(true);
      },
      complete: () => {
        return Promise.resolve(true);
      }
    })
  }
}
