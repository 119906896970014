export const REQUESTOR = "Requestor";
export const COORDINATOR = "Coordinator";
export const IFCLEGAL = "IFC Legal"
export const IFCTREASURY = "IFC Treasury"
export const IFCTAX = "IFC Tax"
export const IFCCOMPTROLLERS = "IFC Comptrollers"
export const IFCGOVERNANCE = "IFC Governance"
export const IFCSECRETARY = "IFC Secretary"
export const ADMIN = "Admin"
export const VIEWERS = "Viewers"
export const IFCMEMBER = "IFC Member"
export const STEP_REQUEST_DETAIL = "request detail";
export const STEP_REVIEW_REQUEST = "review request";
export const STEP_DISSOLUTION = "dissolution";
export const STEP_FORMATION = "formation";
export const STEP_SALE = "sale";
export const STEP_CONCURRENCE = "concurrence";
export const STEP_MERGER = "merger";
export const STEP_OTHER = "others";
export const GENERAL_DETAILS = "General Details";
export const PROPOSAL_DETAILS = "Proposal Details";
export const ASSIGNMENTS = "Assignments";
export const REVIEW = "Review";
export const SUBMIT = "Submit";
export const REQUEST_ID = "Request Id";
export const ACQUISITION_PROPOSAL = "Acquisition";
export const DISSOLUTION_PROPOSAL = "Dissolution";
export const FORMATION_PROPOSAL = "Formation";
export const MERGER_PROPOSAL = "Merger";
export const SALES_PROPOSAL = "Sale";
export const PROPOSAL_ID = "Proposal Id";
export const HOME_URL_PATH = "home";
export const PROPOSAL_TYPES_URL_PATH = "proposal types";
export const VIEW_PROPOSALS_URL_PATH = "view proposals";
export const ACQUISITION_PROPOSAL_URL_PATH = "acquisition";
export const DISSOLUTION_PROPOSAL_URL_PATH = "dissolution";
export const FORMATION_PROPOSAL_URL_PATH = "formation";
export const MERGER_PROPOSAL_URL_PATH = "merger";
export const SALES_PROPOSAL_URL_PATH = "sales";
export const OWNERSHIP_RESTRUCTURING_PROPOSAL = "Ownership Restructuring";
export const OWNERSHIP_RESTRUCTURING_PROPOSAL_URL_PATH = "ownershiprestructuring";
export const QUALIFICATION_WITHDRAWAL_PROPOSAL = "Qualification/Withdrawal";
export const QUALIFICATION_WITHDRAWAL_PROPOSAL_URL_PATH = "qualificationwithdrawal";
export const OTHERS_PROPOSAL = "Others";
export const OTHERS_PROPOSAL_URL_PATH = "others";
export const STEP_TITLE_DEFAULT_CLASS = "custom-step-label";
export const TAB_TITLE_DEFAULT_CLASS = "tab-default";
export const STEP_TITLE_ERROR_CLASS = "custom-step-label-error";
export const ATTACHMENTS_ERROR_CLASS = "uploaded-attachments-error";
export const TAB_SUMMARY_TITLE_ERROR_CLASS = "tab-summary-error";
export const TAB_ATTACHMENT_TITLE_ERROR_CLASS = "tab-attachment-error";
export const TAB_PROPOSED_SLATE_TITLE_ERROR_CLASS = "tab-entity-proposed-slate-error";
export const TAB_ENTITY_SELECTION_TITLE_ERROR_CLASS = "tab-entity-selection-error";
export const TAB_ENTITY_QUESTIONS_TITLE_ERROR_CLASS = "tab-entity-question-error";
export const API_FAILING_ERROR_MESSAGE = "Something went wrong, Please try again!";
export const TAB_CONTACTS_TITLE_ERROR_CLASS = "tab-contacts-error";
export const TAB_COMMENTS_TITLE_ERROR_CLASS = "tab-comments-error";
export const TAB_IFCREVIEWERS_TITLE_ERROR_CLASS = "tab-ifc-reviewers-error";
export const SV_USER_ROLE = "userRole";
export const SV_USER = "user";
export const EMAIL_SUBJECT_SUBMIT_PROPOSAL="Policy 30 Proposal Submitted - {0}: {1} "
export const EMAIL_SUBJECT_PENDING_GOV_REVIEW="Policy 30 ACTION: Proposal Returned - {0}: {1}"
export const EMAIL_SUBJECT_CONCURED_IFC_NOT_REQUIRED="Policy 30 Governance Concurred - {0}_{1}"
export const EMAIL_SUBJECT_CONCURED_IFC_REQUIRED="Policy 30 ACTION: IFC Concurrence Required - {0}: {1}"
export const EMAIL_SUBJECT_DECLINE_PROPOSAL="Policy 30 IFC Concurrence Declined - {0}: {1}"
export const EMAIL_SUBJECT_CONCURRENCE_RESET="Policy 30 ACTION: IFC Concurrence Reset - {0}: {1}"
export const EMAIL_SUBJECT_SEND_REMINDER_EMAIL="Policy 30 ACTION: Reminder - IFC Concurrence Required - {0}: {1}"
export const EMAIL_SUBJECT_ALL_IFC_ROLES_CONCUR="Policy 30 IFC Endorsed - {0}: {1}"
export const EMAIL_SUBJECT_COMPLETE_PROPOSAL="Policy 30 Proposal Complete - {0}: {1}"
export const EMAIL_SUBJECT_WITHDRAW="Policy 30 Proposal Withdrawn - {0}: {1}"
export const CONCUR="Concur"
export const DECLINE="Decline"
export const ADRequestorGroupName = "All Chevron"
export const ADCoordinatorGroupName = "ifcgov-GMB-Users"
export const TestADCoordinatorGroupName = "ifc-test-coordinators-group"
export const ADIFCLegalGroupName = "ifclegal-GMB-Users"
export const TestADIFCLegalGroupName = "ifc-test-legal-group"
export const ADIFCTreasuryGroupName = "ifctr-gmb-users"
export const TestADIFCTreasuryGroupName = "ifc-test-treasury-group"
export const ADIFCTaxGroupName = "CorpTx_IFC_Compliance-Access"
export const TestADIFCTaxGroupName = "ifc-test-tax-group"
export const ADIFCTaxGroupName2 = "CORPTAX-IFC"
export const TestADIFCTaxGroupName2 = "ifc-test-tax-group2"
export const ADIFCControllersGroupName = "IFCCOMP-gmb-users"
export const TestADIFCControllersGroupName = "ifc-test-comptrollers-group"
export const ADIFCGovernanceGroupName = "ifcgov-GMB-Users"
export const TestADIFCGovernanceGroupName = "ifc-test-governance-group"
export const ADIFCSecretaryGroupName = "ifcsec-gmb-users"
export const TestADIFCSecretaryGroupName = "ifc-test-secretary-group"

export const RETURNTOREQUESTOR = "Return to Requestor"
export const WITHDRAW = "Withdraw"
export const COMPLETE = "Complete"
export const SV_PROPOSAL_STATUS = "proposalStatus"
export const SQL_PROC_DEFAULT_PROPOSALS = "DefualtProposals"
export const SQL_PROC_PENDING_PROPOSALS = "PendingProposals"
export const SQL_PROC_INPROGRESS_PROPOSALS = "InProgressProposals"

export const IFCConcurred = "Concurred"
export const IFCDeclined = "Declined"
export const IFCPending = "Pending"

export const ConfirmationHeading = "Please confirm!"
export const ConfirmationContent = "Do you want to reset this IFC Group Member?"

export const STATUSPendingGovernance = 'pending governance review';
export const STATUSPendingIFCConcurrence = 'pending ifc concurrence';
export const IFCLegalGroupName = 'ifc legal';
export const IFCComptrollersGroupName = 'ifc comptrollers';
export const IFCTaxGroupName = 'ifc tax';
export const IFCTreasuryGroupName = 'ifc treasury';
export const IFCGovernanceGroupName = 'ifc governance';
export const IFCSecretaryGroupName = 'ifc secretary';

export const IFCCreateSuccess = 'Successfully created IFC Groups Records.';
export const IFCCreateError = 'Error in creating IFC Groups Records.';
export const IFCDueDateUpdateSuccess = 'Successfully updated Due Date for all groups.';
export const IFCDueDateUpdateError = 'Error in updating Due Date for all groups.';

export const HomeComponentRouterName = '/home';

export const RootComponentRouterName = '/';

// to send a note in Mail, when IFC required
export const RequesterProposalSubmissionNote = 'Please expect a response within 3 business days for a routine request or 2 business days for an urgent request.'

export const SurvivingEntityName = 'Surviving Entity';
export const MergigEntityName = 'Merging Entity';
export const FormEmptyFieldErrorMessage = 'This field is required.'
export const SalesSeller = 'Seller'
export const SalesBuyer = 'Buyer'
//qulification proposals
export const Qualification = 'qualification'
export const Withdrawal = 'withdrawal'