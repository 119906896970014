import { Injectable} from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, UntypedFormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { Observable, map} from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpResponse } from '@angular/common/http';
import { ProposedSlate } from '../interfaces/ProposedSlate';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';


@Injectable ({
    providedIn: 'root'
})
export class DataServiceProposedSlate {

  constructor(private session:SessionStorageService,
    private fb: UntypedFormBuilder,
    private apiService:P30APIService,
    private message: NzMessageService){ }


    getProposedSlates():Observable<HttpResponse<any>>{
      return this.apiService.getProposedSlate(this.session.getItem(PROPOSAL_ID)).pipe(
        map(proposedSlate =>{
          return proposedSlate;
        })
      );
    }
    updateProposedSlate(slate: ProposedSlate):Observable<HttpResponse<any>>{
      const data = {
        requestId: slate.requestId,
        name: slate.name,
        title: slate.title
      }
      return this.apiService.updateProposedSlate(this.session.getItem(PROPOSAL_ID),slate.proposedSlateId, data).pipe(
        map(proposedSlate=>{
          return proposedSlate;
        })
      )
    }
    createProposedSlate(slate: ProposedSlate):Observable<HttpResponse<any>>{
      const data = {
        requestId: slate.requestId,
        name: slate.name,
        title: slate.title
      }
      return this.apiService.createProposedSlate(this.session.getItem(PROPOSAL_ID), data).pipe(
        map(proposedSlate =>{
          return proposedSlate;
        })
      )

    }
    deleteProposedSlate(proposedSlateId:any): Observable<HttpResponse<any>>{
      return this.apiService.deleteProposedSlate(this.session.getItem(REQUEST_ID), Number(proposedSlateId)).pipe(
        map(proposedSlate =>{
          return proposedSlate;
        })
      )

    }
    patchProposedSlateDataToFormGroup(formArray:FormArray){
      this.getProposedSlates().subscribe({
        next: (event) => {
          if(event.body){
            for(let slate of event.body.proposedSlates){
              const proposedSlateForm = this.fb.group({
                proposedSlateId: slate.proposedSlateId,
                requestId: slate.requestId,
                name: slate.name,
                title: slate.title
              })
              formArray.push(proposedSlateForm);
            }
          }
        },
        error: (err) =>{
          console.log("Unable to get proposal overview from the database table ",err);
        },
        complete: () =>{

        }
       });
    }
    async CreateOrUpdateProposedSlate(form:UntypedFormGroup){
      const slates = form.controls["proposedSlates"].value as ProposedSlate[];
      const result = new Promise<boolean>((resolved)=>{
        for(let i=0; i<slates.length; i++){
          if(slates[i].proposedSlateId){
            this.updateProposedSlate(slates[i]).subscribe({
              next: (event)=>{
                if(i==slates.length-1){
                  // this.message.success("Successfully updated proposed slate(s)")
                  resolved(true);
                }
              }
            });
          }else{
            this.createProposedSlate(slates[i]).subscribe({
              next: (event)=>{
                const formArray = form.controls["proposedSlates"] as FormArray;
                  this.getProposedSlates().subscribe({
                    next:(event)=>{
                      formArray.at(i).patchValue({
                        proposedSlateId: event.body.proposedSlates[i].proposedSlateId,
                        requestId: this.session.getItem(REQUEST_ID)
                        });
                        form.controls["proposedSlates"] = formArray;
                    }
                  })
                if(i==slates.length-1){
                  resolved(true);
                }
              },
              error:(err)=>{

              },complete:()=>{

              }
            });
          }

        }
      });
      await result
  }
  submitProposedSlate(form:UntypedFormGroup, tabClass:string):Promise<boolean>{
    if(form.valid){
      this.getProposedSlates().subscribe({
        next: (event) =>{
          const slates = form.controls["proposedSlates"].value as ProposedSlate[];
          if(slates.length > 0){
            this.CreateOrUpdateProposedSlate(form);
          }
        }
      });
      return Promise.resolve(true);
    }else{
      Object.values(form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: false });
        }
      });
      return Promise.resolve(false);
    }
  }
  reviewAndValidateProposedSlate(form:UntypedFormGroup):Promise<boolean>{
    let formArray = form.controls["proposedSlates"] as FormArray;
    if(formArray.length > 0){
      for(let i=0;i<formArray.length;i++){
        const formGroup = formArray.at(i) as FormGroup;
        const titleControl = formGroup.controls["title"];
        const nameControl = formGroup.controls["name"];
        if(isNonEmptyString(nameControl.value)){
          titleControl.setValidators([Validators.required]);
          titleControl.markAsDirty();
          titleControl.updateValueAndValidity();
        }
      }
      return Promise.resolve(form.valid);
    }else{
      return Promise.resolve(form.valid);
    }

  }

}
