<form [nzLayout]="'vertical'" nz-form [formGroup]="concurDeclineForm">
    <div nz-row nzGutter="8">
        <div nz-col nzSpan="12" nzOffset="6">
            <nz-page-header [nzTitle]="header"></nz-page-header>
            <ng-template #header>
                <span class="custom-header">Add Comments and Submit</span>
            </ng-template>
            @for(approval of approvals;track approval.approvalsId){
            @if(approval.approverId == approverId){
            <div nz-row nzGutter="8" style="margin-left: 20px;">
                @if(approval.status){
                <label>{{approval.type}} Concurrence: Concurred</label>
                }@else {
                <label>{{approval.type}} Concurrence: Declined</label>
                }
            </div>
            <div nz-row nzGutter="8" style="margin-left: 20px;">
                <label>{{approval.type}} Comments: {{approval.comment}}</label>
            </div>
            }
            }
            <nz-form-item>
                <nz-form-control [@formControlAnimation]>
                    <textarea nz-input placeholder="Comments field for the respective IFC role" #concurCommnet
                        [nzAutosize]="{ minRows: 5, maxRows: 10 }" formControlName="concurDeclineComment"
                        [(ngModel)]="comment" (ngModelChange)="onChange($event)"></textarea>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
</form>
<div class="nzButtonRight">
    <button nz-button nzType="default" (click)="goBackToReviewPage()"><span>back</span></button>
    <nz-divider nzType="vertical"></nz-divider>
    <button nz-button nzType="primary" [disabled]="disableSubmit" (click)="submit()"><span>submit</span></button>
</div>