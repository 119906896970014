import { Component } from '@angular/core';

@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.less'],
})
export class MyDashboard {
  options: any;
  barOptions: any;
  data = [
    { value: 2, name: 'In Progress' },
    { value: 4, name: 'Completed' },
  ];
  data2 = ['In Progress', 'Completed'];

  ngOnInit() {
    this.options = {
      title: {
        text: 'Request Status',
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          data: this.data,
          areaStyle: {},
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
        },
      ],
    };

    this.barOptions = {
      title: {
        text: 'Request Status',
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
      },
      legend: {
        top: '5%',
        left: 'center',
      },
      xAxis: {
        type: 'category',
        data: this.data2,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: [
            {
              value: 2,
              itemStyle: {
                color: '#5470c6',
              },
            },
            {
              value: 4,
              itemStyle: {
                color: '#91cc75',
              },
            },
          ],
          type: 'bar',
        },
      ],
    };
  }
}
