import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
import { P30APIService } from './api';
import { UntypedFormGroup, UntypedFormBuilder, FormArray, Validators } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpResponse } from '@angular/common/http';
import { LegalEntity } from '../interfaces/LegalEntities';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';
import { DataServiceEntityQuestions } from './data_service_entity_questions';
import { AcquisitionEntityQuestion } from '../interfaces/EntityIndicatorQuestion';
;

@Injectable({
    providedIn: 'root'
})
export class DataServiceDissolutionSelection {
    constructor(
        private session: SessionStorageService,
        private apiService: P30APIService,
        private message: NzMessageService
    ) {
    }


    reviewAndValidateDissolutionEntitySelection(form: UntypedFormGroup, action: string = "RS"): Promise<boolean> {
        console.log("reviewAndValidateDissolutionEntitySelection");
        let formArray = form.controls["legalEntitiesFormArray"] as FormArray;
        let formQuestionsArray = form.controls["legalEntitiesQuestionsFormArray"] as FormArray;
        const control = form.controls["comments"];
        let esValid = true;
        if (formArray.length == 0 && !isNonEmptyString(control.value)) {
            control.setValidators([Validators.required]);
            control.markAsDirty();
            control.updateValueAndValidity();
            return Promise.resolve(false);
        }
        else
            if (formArray.length > 0) {
                for (let i = 0; i < formQuestionsArray.length; i++) {
                let activeRegistration = formQuestionsArray.at(i).get('activeRegistration');    
                let activeRegistrationComments = formQuestionsArray.at(i).get('activeRegistrationComments');
                let subsidiaries = formQuestionsArray.at(i).get('subsidiaries');    
                let subsidiariesComments = formQuestionsArray.at(i).get('subsidiariesComments');
                    console.log("subsidiariesComments1", subsidiariesComments);
                    let anyEmployees = formQuestionsArray.at(i).get('anyEmployees');    
                    console.log("anyEmployees", anyEmployees);
                    let anyEmployeesComments = formQuestionsArray.at(i).get('anyEmployeesComments');
                    let activeBankaccount = formQuestionsArray.at(i).get('activeBankaccount');
                    let activeBankaccountComments = formQuestionsArray.at(i).get('activeBankaccountComments');
                    if (activeRegistration?.value == true && activeRegistrationComments && action == "RS") {
                        if (activeRegistrationComments.value == null || activeRegistrationComments.value == undefined
                            || activeRegistrationComments.value == "") {
                            activeRegistrationComments.setValidators([Validators.required]);
                            activeRegistrationComments.markAsDirty();
                            activeRegistrationComments.updateValueAndValidity({ onlySelf: true });
                            esValid = false
                       }
                    }
                }
                if (esValid == false) {
                    return Promise.resolve(false);
                }
                else {
                    return Promise.resolve(true);
                }
            }
            else {
                control.removeValidators([Validators.required]);
                control.updateValueAndValidity();
                return Promise.resolve(true);
            }
    }
}
