import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';
import { NzMessageService } from 'ng-zorro-antd/message';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { ACQUISITION_PROPOSAL, API_FAILING_ERROR_MESSAGE, PROPOSAL_ID } from 'src/app/constants/constants';
import { BusinessSegmentUnit } from 'src/app/core/interfaces/BusinessSegmentUnits';
import { GeneralDetails } from 'src/app/core/interfaces/GeneralDetails';
import { CreateProposal, Proposal, UpdateProposal } from 'src/app/core/interfaces/Proposal';
import { ProposalType } from 'src/app/core/interfaces/ProposalTypes';
import { Region } from 'src/app/core/interfaces/Regions';
import { Status } from 'src/app/core/interfaces/Statuses';
import { UserDetails } from 'src/app/core/interfaces/User';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { DataServiceGeneralDetails } from 'src/app/core/services/data_service_general_details';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { SessionStorageService } from 'src/app/core/services/session_storage';


@Component({
  selector: 'app-request-general-details',
  templateUrl: './request-general-details.component.html',
  styleUrls: ['./request-general-details.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class RequestGeneralDetailsComponent {
  @Output() isLoader = new EventEmitter<boolean>;
  @Output() isEnable = new EventEmitter<boolean>;
  generalDetailsFormModel: GeneralDetails = {
    requestedFor: '',
    requestedBy: '',
    proposalId: '',
    urgent: false,
    requestTitle: '',
    businessSegmentUnit: '',
    region: '',
    urgencyExplanation: '',
    opco: '',
  };
  currentLoggedInUser: UserDetails[] = [];
  requestedForUser: UserDetails[] = [];
  regions: Region[] = [];
  businessSegmentUnits: BusinessSegmentUnit[] = [];
  proposalTypes: ProposalType[] = [];
  statuses: Status[] = [];
  generalDetailsForm = this.fb.group({
    requestedFor: [null, [Validators.required]],
    requestedBy: [],
    proposalId: [null, [Validators.required]],
    urgent: [false],
    requestTitle: ['', [Validators.required, Validators.maxLength(210)]],
    businessSegmentUnit: [null],
    region: [null],
    urgencyExplanation: [null],
    opco: [null],
  });
  proposal!: Proposal;
  currentUserProfile!: ICvxClaimsPrincipal;
  cursor!: string;
  //requestId!: number;
  currentStatusId!: number;
  proposalType: string = '';
  @Output() stepGeneralDetailsClass = new EventEmitter<string>;
  apiErrorMSG: string = API_FAILING_ERROR_MESSAGE;
  proposalStatus!: string;

  constructor(private fb: UntypedFormBuilder, private apiService: P30APIService, private authService: CalAngularService, private session: SessionStorageService,
    private message: NzMessageService, private dataservice: DataService, private commentService: DataServiceComments,
    private generalDetailsDataService: DataServiceGeneralDetails, private cdr: ChangeDetectorRef, private graph: MsGraphService) {
  }

  async ngOnInit() {
    this.proposalStatus = this.commentService.getRequestStatus();
    // Initialize general details form
    if (this.dataservice.aqGeneralDetailsForm !== undefined && this.session.getItem(PROPOSAL_ID)) {
       this.generalDetailsForm = this.dataservice.aqGeneralDetailsForm;
    } else {
      let userFullName = this.loogedUserFullName();
      this.generalDetailsForm.controls["requestedBy"].setValue(userFullName);
      this.generalDetailsForm.updateValueAndValidity();
    }
    // proposalId and requestedBy, will always be disabled, user cannot enter or edit proposalId.
    this.generalDetailsForm.controls['requestedBy'].disable();
    this.generalDetailsForm.controls['proposalId'].disable();
    // Get Statuses and update the list to local variable statuses
    this.getStatuses();
    // Get Regions and update the list to local variable regions
    this.getRegions();
    // Get proposal types and update the list to local vaiable proposal type
    this.getProposalTypes();
    // Get Business segment units and update the list to local variable business segment unit
    this.getBusinessSegmentUnits();
    // retrieve current user profile from the angular cal
    this.getCurrentLoggedInUser();
    // set flag as enabled or disabled for "save for later" and "next" buttons by checking values provided for the requested for and request title
    // intialize request id if request id session variable is not null and pull the request details
    if (this.session.getItem(PROPOSAL_ID)) {
      this.dataservice.setVarRequestId(this.session.getItem(PROPOSAL_ID));
      await this.getExistiningProposal(this.session.getItem(PROPOSAL_ID));
    }
    else {
      // Default current logged in user will be inputed for requestedFor field in UI
      this.defaultRequesterFor();
      // disable proposal Id field disable in the UI
      this.generalDetailsForm.updateValueAndValidity();
    }
    this.disableSaveAndNextButtons();

    this.onFormChange();

    let proposalSelected = this.session.getItem('proposalSelected');
    if (this.dataservice.proposalType) {
      this.proposalType = this.dataservice.proposalType;
    } else if (proposalSelected) {
      this.proposalType = proposalSelected;
    } else {
      this.proposalType = ACQUISITION_PROPOSAL;
    }
  }

  ngAfterViewInit() {
    this.isLoader.emit(false);
  }

  ngOnDestroy() {
    this.dataservice.aqGeneralDetailsForm = this.generalDetailsForm;
  }

  getCurrentProposalStatus() {
    this.generalDetailsDataService.getProposal().subscribe(output => {
      if (output.status == 200) {
        this.apiService.getStatuses().subscribe(event => {
          if (event.status == 200) {
            let statuses = [...event.body];
            this.currentStatusId = Number(statuses.find(data => data.name === output.body.status).statusId);
          }
        })
      } else {
        this.currentStatusId = this.statuses.filter(data => data.name.toLowerCase().includes('draft'))[0].statusId;
      }
    })
  }

  defaultRequesterFor() {
    let userFullName = this.loogedUserFullName();
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    this.graph.getUserByEmail(this.currentUserProfile.email).subscribe(event => {
      if (event) {
        this.requestedForUser.push({
          objectId: event.id,
          displayName: event.displayName,
          mail: event.mail,
          fullName: `${event.givenName} ${event.surname}`
        });
        this.generalDetailsForm.controls["requestedFor"].setValue(this.requestedForUser[0]?.fullName);
        this.generalDetailsFormModel.requestedFor = this.requestedForUser[0]?.fullName;
        this.generalDetailsForm.controls["requestedBy"].setValue(userFullName);
        this.generalDetailsFormModel.requestedBy = <any>userFullName;
      } else {
        this.message.error(this.apiErrorMSG);
      }
    });
  }


  async getExistiningProposal(proposalId: string) {
    /* if session proposal id is available, get the current user profile and add to the local variable "requestedForUser"
     and also get the proposal from the database */
    let proposal:any;
    this.isLoader.emit(true);
    const getUserResult = await new Promise((resolve) => {
      this.apiService.getUser(this.authService.cvxClaimsPrincipal.objectId).subscribe(result => {
        if (result.status == 200) {
          this.apiService.getProposalByUserIdAndStatusId(result.body.userId, 1, this.cursor).subscribe(output => {
            if (output.status == 200) {
              proposal = output.body;
            } else {
              //this.message.error(this.apiErrorMSG);
              this.defaultRequesterFor();
            }
            this.generalDetailsForm.controls['proposalId'].disable();
            resolve(true);
          });
        } else {
          this.message.error(this.apiErrorMSG);
          resolve(false);
        }
      });
    });
    if(getUserResult){
      this.dataservice.setVarRequestId(this.session.getItem(PROPOSAL_ID));
      //this.dataservice.proposalType = proposal.proposals[0].proposalType;
      // check proposal type
       //if (this.dataservice.proposalType) {
        //this.proposalType = this.dataservice.proposalType;
      //} else {
        //this.proposalType = ACQUISITION_PROPOSAL;
      //}
      //this.requestedForUser = [...await this.dataservice.searchUser(proposal.proposals[0].requestedForEmail)];
      //this.generalDetailsForm.controls["requestedFor"].setValue(this.requestedForUser[0]?.fullName);
      //this.generalDetailsFormModel.requestedFor = this.requestedForUser[0]?.fullName;
      this.getProposal(this.session.getItem(PROPOSAL_ID));
      this.isLoader.emit(false);
    }
  }

  async getCurrentLoggedInUser() {
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    this.currentLoggedInUser = [... await this.dataservice.searchUser(this.currentUserProfile.email)];
  }
  getRegions() {
    this.isLoader.emit(true);
    this.apiService.getRegions().subscribe({
      next: (out) => {
        this.regions = [...out.body];
      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
        console.log("Unable to retrieve regions", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }
  async getStatuses() {
    this.isLoader.emit(true);
    this.apiService.getStatuses().subscribe({
      next: (out) => {
        this.statuses = [...out.body];
        this.getCurrentProposalStatus();
      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
        console.log("Unable to retrieve statuses", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });

  }
  getProposalTypes() {
    this.isLoader.emit(true);
    this.apiService.getProposalTypes().subscribe({
      next: (out) => {
        this.proposalTypes = [...out.body];
        this.isLoader.emit(false);
      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
        console.log("Unable to retrieve proposal types", err);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }
  async getBusinessSegmentUnits() {
    this.isLoader.emit(true);
    this.apiService.getBusinessSegmentUnits().subscribe({
      next: (out) => {
        this.businessSegmentUnits = [...out.body]
      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
        console.log("Unable to retrieve business segment units", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });

  }

  disableSaveAndNextButtons() {
    if (!this.generalDetailsForm.controls["requestedFor"].value || !this.generalDetailsForm.controls["requestTitle"].value) {
      this.isEnable.emit(true);
    } else {
      this.isEnable.emit(false);
    }
  }
  onChangeRequestedFor(event: any) {
    this.disableSaveAndNextButtons();
  }
  onChangeRequestTitle() {
    this.disableSaveAndNextButtons();
  }
  
  async getProposal(proposalId: string) {
    let proposal:any;
    const proposalResult = await new Promise((resolve)=>{
      this.isLoader.emit(true);
      this.apiService.getProposal(proposalId).subscribe({
        next: (event) => {
          proposal = event.body;
        },
        error: (err) => {
          this.message.error(this.apiErrorMSG);
          console.log("Unable to retrieve proposal record from the database table ", err);
          this.isLoader.emit(false);
          resolve(false);
        },
        complete: () => {
          this.isLoader.emit(false);
          resolve(true);
        }
      });
    });
    if(proposalResult){
      if (proposal) {
        let bsu = ""
        let region = ""
        if (proposal.businessSegmentId) {
          bsu = this.businessSegmentUnits.filter(data => data.businessSegmentId == proposal.businessSegmentId)[0].name
        }
        if (proposal.regionId) {
          region = this.regions.filter(data => data.regionId == proposal.regionId)[0].name
        }
        this.dataservice.proposalType = proposal.proposalType;
      // check proposal type
       if (this.dataservice.proposalType) {
        this.proposalType = this.dataservice.proposalType;
      } else {
        this.proposalType = ACQUISITION_PROPOSAL;
      }
          this.generalDetailsFormModel = {
          requestedFor: proposal.requestedFor,
          requestedBy: proposal.requestedBy,
          proposalId: proposal.proposalId,
          urgent: proposal.urgent,
          requestTitle: proposal.title,
          businessSegmentUnit: bsu,
          region: region,
          urgencyExplanation: proposal.urgencyExplanation,
          opco:proposal.opco
        };
        // set local variable requestedForUser by calling ms graph api
        this.requestedForUser = [...await this.dataservice.searchUser(proposal["requestedForEmail"])];
        this.generalDetailsForm.controls['proposalId'].disable();
      }
    }
  }

  async createProposal(): Promise<any> {
    return await new Promise<boolean>((resolve)=>{
      this.apiService.getUser(this.currentUserProfile.objectId).subscribe({
        next: (user) => {
          let data: CreateProposal = {
            statusId: this.currentStatusId,
            title: this.generalDetailsFormModel.requestTitle,
            requestedBy: this.currentLoggedInUser[0]?.fullName,
            requestedByEmail: this.currentLoggedInUser[0].mail,
            proposalTypeId: this.proposalTypes.filter(data => data?.name == this.proposalType)[0]?.proposalTypeId,
            requestedFor: this.requestedForUser.filter(data => data?.fullName == this.generalDetailsFormModel.requestedFor)[0]?.fullName,
            requestedForEmail: this.requestedForUser.filter(data => data?.fullName == this.generalDetailsFormModel.requestedFor)[0]?.mail,
            userId: user.body.userId,
            urgent: this.generalDetailsFormModel.urgent,
            urgencyExplanation: this.generalDetailsFormModel.urgencyExplanation,
            createdDate: new Date().toLocaleString("en-US"),
            opco: this.generalDetailsFormModel.opco,
          };
          if (isNonEmptyString(this.generalDetailsFormModel.businessSegmentUnit)) {
            data.businessSegmentId = this.businessSegmentUnits.filter(data => data.name == this.generalDetailsFormModel.businessSegmentUnit)[0].businessSegmentId;
          } else {
            data.businessSegmentId = null;
          }
          if (isNonEmptyString(this.generalDetailsFormModel.region)) {
            data.regionId = this.regions.filter(data => data.name == this.generalDetailsFormModel.region)[0].regionId;
          } else {
            data.regionId = null
          }
          this.isLoader.emit(true);
          return this.apiService.createProposal(data).subscribe({
            next: (event) => {
              this.message.success(`Successfully saved your proposal ${event.body.proposalId}`);
              this.session.setItem(PROPOSAL_ID, event.body["proposalId"]);
              this.dataservice.setVarRequestId(this.session.getItem(PROPOSAL_ID));
            },
            error: (err) => {
              this.message.error('Unable to save proposal, please try again!');
              this.isLoader.emit(false);
              resolve(false);
              return false;
            },
            complete: () => {
              this.getProposal(this.session.getItem(PROPOSAL_ID));
              this.isLoader.emit(false);
              resolve(true);

            }
          });
        },
        error: (err) => {
          this.message.error(this.apiErrorMSG);
          console.log("unable to retrieve user details", err);
          resolve(false);
        },
        complete: () => {

        }
      });
    })



  }
  updateProposal(): any {
    this.isLoader.emit(true);
    return this.apiService.getUser(this.authService.cvxClaimsPrincipal.objectId).subscribe({
      next: (user) => {
        this.getCurrentLoggedInUser();
        let data: UpdateProposal = {
          statusId: this.currentStatusId,
          proposalId: this.generalDetailsFormModel.proposalId,
          title: this.generalDetailsFormModel.requestTitle,
          requestedBy: this.currentLoggedInUser[0]?.fullName,
          requestedByEmail: this.currentLoggedInUser[0].mail,
          proposalTypeId: this.proposalTypes.filter(data => data?.name == this.proposalType)[0]?.proposalTypeId,
          requestedFor: this.requestedForUser.filter(data => data?.fullName == this.generalDetailsFormModel.requestedFor)[0]?.fullName,
          requestedForEmail: this.requestedForUser.filter(data => data?.fullName == this.generalDetailsFormModel.requestedFor)[0]?.mail,
          userId: user.body.userId,
          urgent: this.generalDetailsFormModel.urgent,
          urgencyExplanation: this.generalDetailsFormModel.urgencyExplanation,
          updatedDate: new Date().toLocaleString("en-US"),
          updatedBy: this.currentLoggedInUser[0]?.fullName,
          updatedByEmail: this.currentLoggedInUser[0].mail,
          opco:this.generalDetailsFormModel.opco,
        };

        
        if (isNonEmptyString(this.generalDetailsFormModel.businessSegmentUnit)) {
          data.businessSegmentId = this.businessSegmentUnits.filter(data => data.name == this.generalDetailsFormModel.businessSegmentUnit)[0].businessSegmentId;
        } else {
          data.businessSegmentId = null;
        }
        if (isNonEmptyString(this.generalDetailsFormModel.region)) {
          data.regionId = this.regions.filter(data => data.name == this.generalDetailsFormModel.region)[0].regionId;
        } else {
          data.regionId = null
        }
        this.apiService.updateProposal(this.session.getItem(PROPOSAL_ID), data).subscribe({
          next: (event) => {
            this.message.success(`Successfully updated your proposal ${event.body.proposalId}.`);
          },
          error: (err) => {
            this.message.error('Unable to update proposal, please try again!');
            this.isLoader.emit(false);
            return false;
          },
          complete: () => {
            this.isLoader.emit(false);
            this.dataservice.setVarRequestId(this.session.getItem(PROPOSAL_ID));
          }
        });
      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
        console.log("unable to retrieve user details", err);
      },
      complete: () => {

      }
    });

  }
  // When user selects urgent as true then add required validator to the urgency explanation field
  setUrgencyExplanationControlValidator() {
    const control = this.generalDetailsForm.controls["urgencyExplanation"];
    if (this.generalDetailsForm.controls["urgent"].value) {
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    } else {
      control.removeValidators([Validators.required]);
      control.updateValueAndValidity();
    }
  }
  async createGeneralDetails(): Promise<any> {
    if (this.generalDetailsForm.valid) {
      return await this.createProposal();
    } else {
      Object.values(this.generalDetailsForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  updateGeneralDetails(): any {
    if (this.generalDetailsForm.valid) {
      return this.updateProposal();
    } else {
      Object.values(this.generalDetailsForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  getErrorTipForRequestTitle(controlName: string) {
    const control = this.generalDetailsForm.controls[controlName];
    switch (control) {
      case (<any>control.hasError('required')) : {
        return "Please input request title!";
      }
      case (<any>control.hasError('maxlength')) : {
        return "Maximum length exceeded!";
      }
      default : {
        return '';
      }
    }
  }

  async searchUser(value: string) {
    let users = await this.dataservice.searchUser(value);
    this.requestedForUser = [...new Set(users)];
  }
  onFormChange() {
    this.generalDetailsForm.valueChanges.subscribe(val => {
      if (this.generalDetailsForm.valid) {
        this.stepGeneralDetailsClass.emit("custom-step-label");
      }
    })
  }

  loogedUserFullName() {
    let fullName, user;
    user = this.session?.getItem('user');
    if(user) {
      fullName = `${user?.firstName} ${user?.lastName}`;
    }
    return fullName;
  }
}
