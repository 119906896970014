import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { P30APIService } from 'src/app/core/services/api';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { API_FAILING_ERROR_MESSAGE, PROPOSAL_ID } from 'src/app/constants/constants';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { DataService } from 'src/app/core/services/data_service';
import { LegalEntity } from 'src/app/core/interfaces/LegalEntities';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataServiceEntitySelection } from 'src/app/core/services/data_service_entity_selection';

@Component({
  selector: 'app-acquisition-entity-selection',
  templateUrl: './acquisition-entity-selection.component.html',
  styleUrls: ['./acquisition-entity-selection.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class AcquisitionEntitySelectionComponent{
  @Output() isLoader = new EventEmitter<boolean>;
  componentIsVisible:boolean =false;
  legalEntityForm!: UntypedFormGroup;
  nzLoading:boolean = false;
  nzSearchValue!:string;
  @Input() acquisitionEntitySelectionForm!: UntypedFormGroup;
  legalEntities: LegalEntity[] = [{
    companyName: "NA"
  }];
  current = 0;
  selectedLegalEntities: LegalEntity[] = [];
  showComments:boolean = false;
  selectedEntity: string[] =[];
  apiErrorMSG: string = API_FAILING_ERROR_MESSAGE;

  @ViewChild('carouselLegalEntity')
  carouselLegalEntity!: NzCarouselComponent;
  constructor(private fb: UntypedFormBuilder, private apiService: P30APIService, private session: SessionStorageService, private message:NzMessageService ,
    private dataservice: DataService,
    private entitySelectionDataService: DataServiceEntitySelection){
  }

  async ngOnInit(){
    if(this.acquisitionEntitySelectionForm.controls["legalEntity"].value == "NA"){
      this.showComments = true;
    }else{
      for(let i=0;i<this.legalEntitiesFormArray.length;i++){
        this.selectedEntity.push(this.legalEntitiesFormArray.at(i).value.companyName);
      }
      await this.getLegalEntities();
      this.legalEntities = [...this.selectedLegalEntities];
      this.legalEntities.unshift({
        companyName: "NA"
      });
    }
    if(this.dataservice.legalEntities.length > 0){
      this.legalEntities = [...this.dataservice.legalEntities];
    }
    if(this.dataservice.selectedLegalEntities.length > 0){
      this.selectedLegalEntities = [...this.dataservice.selectedLegalEntities];
    }
  }
  ngOnDestroy(){
    this.dataservice.legalEntities = [...this.legalEntities];
    this.dataservice.selectedLegalEntities = [...this.selectedLegalEntities];
  }

  get legalEntitiesFormArray(){
    return this.acquisitionEntitySelectionForm.controls["legalEntitiesFormArray"] as FormArray;
  }

  onSearch(){
    this.nzLoading = true;
    this.legalEntities = [{
        companyName: "NA"
      }];
    if(this.nzSearchValue){
      this.apiService.getCompanies(this.nzSearchValue).subscribe({
        next: (event) =>{
          let arrResult = [];
          arrResult = [...event.body.companies];
          arrResult.forEach(element => {
            let legalEntity:LegalEntity = {
              ccn: element.companyCodeNumber,
              companyName: element.companyName,
              jurisdiction: element.countryName
            }
            this.legalEntities.push(legalEntity);
          });
        },
        error: (err)=>{
          this.message.error(this.apiErrorMSG);
          this.nzLoading = false;
        },
        complete: () =>{
          this.nzLoading = false;
        }
      });
    }
  }

  onSearchEvent(event:string){
    this.nzSearchValue = event;
  }

  async onChange(event:string){
    if(event==='NA'){
      this.removeFormArray();
      this.selectedEntity = [];
      await this.onChangeGetLegalEntities(false);
      await this.getLegalEntities();
      this.showComments = true;
      const control = this.acquisitionEntitySelectionForm.controls["comments"]
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    }else{
      await this.onChangeGetLegalEntities(true);
      await this.getLegalEntities();
      if(!this.selectedEntity.includes(event)){
        this.isLoader.emit(true);
        this.apiService.getCompanyDetails(this.legalEntities.find(data=>data.companyName===event)?.ccn).subscribe({
          next: (output) =>{
            if(output.body){
              const modifiedLegalEntities = this.legalEntities.map(obj => {
                if (obj.ccn === output.body.companyCodeNumber) {
                    return { ...obj, ownershipDetail: output.body.ownership };
                }
                return obj;
              });
              this.legalEntities = [...modifiedLegalEntities];
            }
          },
          error: (err)=>{
            this.message.error(this.apiErrorMSG);
            this.isLoader.emit(false);
          },
          complete: () =>{
            this.addField(event);
            this.selectedEntity.push(event);
            this.isLoader.emit(false);
          }
        });
      }
      this.showComments=false;
      const control = this.acquisitionEntitySelectionForm.controls["comments"]
      control.removeValidators([Validators.required]);
      control.setValue(null);
      control.updateValueAndValidity();
    }
  }

  async onChangeGetLegalEntities(isNa: boolean) {
    this.entitySelectionDataService.getLegalEntities().subscribe({
      next:(event)=>{
        let legalEntities = [];
        if(event.body){
          legalEntities = [...event.body];
          legalEntities.forEach(findItem =>{
            switch (isNa) {
              case (true): {
                if(findItem.legalEntityComments || findItem.companyName == "NA"){
                  this.deleteLegalEntity(findItem.legalEntityId);
                }
                return;
              }
              case (false) : {
                if(findItem.legalEntityId && findItem.companyName !=="NA"){
                  this.deleteLegalEntity(findItem.legalEntityId);
                }
                return;
              } default: {
                return
              }
            }



          });
        }
      },
      error: (err)=>{
        this.message.error(this.apiErrorMSG);
      }
    });
  }

  addField(value:string) {
    const legalEntityForm = this.fb.group({
      legalEntityName:[value],
      ccn:[this.legalEntities.filter(data =>data.companyName === value)[0].ccn],
      companyName:[value],
      jurisdiction:[this.legalEntities.filter(data =>data.companyName === value)[0].jurisdiction],
      ownershipDetail:[this.legalEntities.filter(data =>data.companyName === value)[0].ownershipDetail],
    })
    this.legalEntitiesFormArray.push(legalEntityForm);
  }

  removeFormArray(){
    this.legalEntitiesFormArray.clear();
  }

  deleteLegalEntity(legalEntityId:any){
    this.isLoader.emit(true);
      this.apiService.deleteLegalEntity(this.session.getItem(PROPOSAL_ID), legalEntityId).subscribe({
        next: (out) =>{

        },
        error: (err) =>{
          this.message.error(this.apiErrorMSG);
          console.log("Unable to create legal entity record in the database table ",err);
          this.isLoader.emit(false);
        },
        complete: () => {
          this.getLegalEntities();
          this.isLoader.emit(false);
        }
      })
  }

  onCloseTag(event:string){
    let index = this.selectedEntity.indexOf(event);
    this.selectedEntity.splice(index,1);
    let findItem = this.selectedLegalEntities.find(e=>e.companyName===event);
    if(findItem){
      if(findItem.legalEntityId){
        this.deleteLegalEntity(findItem.legalEntityId);
      }
    }
    this.removeFormArray();
    for(let entityName of this.selectedEntity){
      this.addField(entityName);
    }
  }

  getLegalEntities(): Promise<boolean> {
    return new Promise<boolean> ((resolved)=>{
      if(this.session.getItem(PROPOSAL_ID)){
        this.isLoader.emit(true);
        this.entitySelectionDataService.getLegalEntities().subscribe({
          next: (event) =>{
            this.selectedLegalEntities =[];
            if(event.body){
              for(const element of event.body){
                let leglaEntity: LegalEntity = {
                  legalEntityId: element.legalEntityId,
                  ccn: element.ccn,
                  companyName: element.companyName,
                  jurisdiction: element.jurisdiction,
                  ownershipDetail: element.ownershipDetail,
                  legalEntityComments: element.legalEntityComments,
                  typeofLegalEntity: element.typeofLegalEntity
                }
                this.selectedLegalEntities.push(leglaEntity);
              }
            }
          },
          error: (err) =>{
            this.message.error(this.apiErrorMSG);
            console.log("Unable to get legal entity record in the database table ",err);
            this.isLoader.emit(false);
            resolved(false);
          },
          complete: () =>{
            this.isLoader.emit(false);
            resolved(true);
          }
        });
      }
    });
  }

  nextSlide(){
    this.carouselLegalEntity.next();
    this.current++;
  }

  prevSlide(){
    this.carouselLegalEntity.pre();
    this.current--;
  }


}
