import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-submit-failed',
  templateUrl: './submit-failed.component.html',
})
export class SubmitFailedComponent {
  @Output() appError = new EventEmitter<boolean>();

  updateAppError() {
    this.appError.emit(true);
  }
}
