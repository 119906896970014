import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  formControlElement,
  formLabelElement,
} from 'src/app/animations/animations';
import { API_FAILING_ERROR_MESSAGE, FormEmptyFieldErrorMessage, PROPOSAL_ID, REQUEST_ID } from 'src/app/constants/constants';
// import {FormBuilder} from '@angular/forms'
import { LegalEntity } from 'src/app/core/interfaces/LegalEntities';
import { SaleEntityQuestionsDTO } from 'src/app/core/interfaces/salesEntityQuestions';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceEntitySelection } from 'src/app/core/services/data_service_entity_selection';
import { DataServiceFormationEntity } from 'src/app/core/services/data_service_formation_entityQuestion';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-formation-entity-selection',
  templateUrl: './formation-entity-selection.component.html',
  styleUrl: './formation-entity-selection.component.less',
  animations: [formControlElement, formLabelElement],
  providers: [DatePipe],
})

export class FormationEntitySelectionComponent {
  @Output() isLoader = new EventEmitter<boolean>;
  @Input() entitySelectionTabClass = '';
  @Input() legalEntityFormationForm!: FormGroup;
  apiErrorMSG: string = API_FAILING_ERROR_MESSAGE;
  nzLoading: boolean = false;
  nzLoadingBuyer: boolean = false;
  nzSearchValue: string = '';
  selectedLegalBuyer!: string;
  selectedFormationType!: string;
  entityRegistered: boolean = false;
  bankAccount: boolean = false;
  showEntityDetailsBlock: boolean = false;
  showEntityDetailsBuyerBlock: boolean = false;
  emptyFormFieldErrorMSG: string = FormEmptyFieldErrorMessage;
  typeOfFormationRecords: SaleEntityQuestionsDTO[] = this.formationEntityService.formationTypeList;
  isPageReload: boolean = false;
  showQuestionsList: boolean = false;
  showTypeOfformationComment: boolean = false;
  showBuyerComments:boolean = false;
  selectedEntity: string[] = [];
  investmentType = [
    { name: 'Cash', id: 1 },
    { name: 'Non-Cash', id: 2 },
  ]
  

  constructor(private fb: FormBuilder, private formationEntityService: DataServiceFormationEntity,
    private entitySelectionDataService: DataServiceEntitySelection,
    private apiService: P30APIService, private session: SessionStorageService,
    private message: NzMessageService,
    private dataservice: DataService,
    private acEntitySelectionService: DataServiceEntitySelection) {
  }

  

  ngOnInit() {
    this.legalEntityFormationForm.reset();
    this.getLegalEntities();
    this.getEntityQuestions();
  }


  storeEntitiesValues(records: LegalEntity) {
    let formationLeglaEntity = {
      companyName: "",
      legalEntityComments: records.legalEntityComments?.toString(),
      typeofLegalEntity: records.typeofLegalEntity,
      legalEntityId: records.legalEntityId,
      requestId: Number(this.session.getItem(REQUEST_ID))
    }
    return formationLeglaEntity;
  }

  getLegalEntities(): Promise<boolean> {
    return new Promise<boolean>((resolved) => {
      if (this.session.getItem(PROPOSAL_ID)) {
        this.isLoader.emit(true);
        this.entitySelectionDataService.getLegalEntities().subscribe({
          next: (event) => {
            if (event['body']) {
              const formationEntityFormGroup: FormGroup = this.legalEntityFormationForm.controls['FormationEntityFormGroup'] as FormGroup;

              let buyerEntity = event['body'];
              let b: LegalEntity = this.storeEntitiesValues(buyerEntity[0]);
              this.selectedLegalBuyer = b.companyName;
              formationEntityFormGroup.controls['formationType'].setValue(b.typeofLegalEntity);
              formationEntityFormGroup.controls['formationTypeComments'].setValue(b.legalEntityComments);
              
            }
          },
          error: (err) => {
            this.message.error(this.apiErrorMSG);
            console.log("Unable to get legal entity record in the database table ", err);
            this.isLoader.emit(false);
            resolved(false);
          },
          complete: () => {
            this.isLoader.emit(false);
            resolved(true);
          }
        });
      }
    });
  }

  getEntityQuestions() {
    this.formationEntityService.getEntityIndicatorQuestions().subscribe((response: any) => {
      if (response['body']) {
        let records: any = [...response['body']]
        const formationTypeFormGroup: FormGroup = this.legalEntityFormationForm.controls['formationTypeQuestionsFormGroup'] as FormGroup;
        formationTypeFormGroup.controls['needBankAccounts'].setValue(records[0].needBankAccounts);
        formationTypeFormGroup.controls['needBankAccountsComments'].setValue(records[0].commentsForNeedingBankAccounts);
        formationTypeFormGroup.controls['entityBeRegistered'].setValue(records[0].willTheNewEntityBeRegisteredInOtherStatesCountries);
        formationTypeFormGroup.controls['entityBeRegisteredComments'].setValue(records[0].commentsForNewEntityBeingRegistered);
        this.entityRegistered = records[0].willTheNewEntityBeRegisteredInOtherStatesCountries;
        this.bankAccount = records[0].needBankAccounts;
      }
    })
  }

 
  getBuyerForm() {
    const formationEntityFormGroup = this.formationEntityService.legalEntityFormationForm.controls['FormationEntityFormGroup'] as FormGroup;
    return formationEntityFormGroup;
  }

 

  



  typeOfEntity: string = ''
  


  

  async onformationTypeChange(event: string) {
    this.formationEntityService.legalEntityFormationForm = this.legalEntityFormationForm;

    
    if (event === 'Other') {
      this.showQuestionsList = true;
      this.showTypeOfformationComment = true;  
      
    } else if(event != undefined) {
      this.showQuestionsList = true;
      this.showTypeOfformationComment = false;  
      const formationGroup: FormGroup = this.legalEntityFormationForm.controls['FormationEntityFormGroup'] as FormGroup;
    formationGroup.controls['formationTypeComments'].setValue(null);
    }
  }


  

  
}
