<div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" >
  <nz-space>
    <div nz-col class="gutter-row" [nzXs]="{ span: 5, offset: 1 }" [nzLg]="{ span: 6, offset: 2 }">
      <div class="inner-box"><app-card-dashboard title="In Progress"></app-card-dashboard></div>
    </div>
    <div nz-col class="gutter-row" [nzXs]="{ span: 11, offset: 1 }" [nzLg]="{ span: 6, offset: 2 }">
      <div class="inner-box"><app-card-dashboard title="Completed"></app-card-dashboard ></div>
    </div>
    <div nz-col class="gutter-row" [nzXs]="{ span: 5, offset: 1 }" [nzLg]="{ span: 6, offset: 2 }">
      <div class="inner-box"><app-card-dashboard title="Action Required"></app-card-dashboard ></div>
    </div>
  </nz-space>
</div>
<br>
