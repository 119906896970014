<form [formGroup]="legalEntityFormationForm">
  <!--  -->


  <div nz-row nzGutter="24" formGroupName="FormationEntityFormGroup">
    <div nz-col nzSpan="6">
      <nz-form-item class="fomrationLegalEntity">
        <div nz-col nzSpan="24">
          <nz-form-label [@formLabelAnimation]>
            <label class="mandate asteric-19">*</label>
            Entity Type
          </nz-form-label>
        </div>
        <nz-form-control [@formControlAnimation]>
          <nz-select nzPlaceHolder="Select One" formControlName="formationType" [(ngModel)]="selectedFormationType"
                     (ngModelChange)="onformationTypeChange($event)">
            @for(list of typeOfFormationRecords;track list.entityID){
            <nz-option [nzValue]="list.entityName" [nzLabel]="list.entityName"></nz-option>
            }
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSpan="6" nzOffset="1" id="divEntityTypeComments">
      @if(showTypeOfformationComment)  {
      <div id="legalSummaryField">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <textarea nz-input placeholder="Comments" formControlName="formationTypeComments"
                      [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
      }
    </div>



  </div>


  <div formGroupName="formationTypeQuestionsFormGroup">
    @if(showQuestionsList) {
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="6">
        <nz-form-label [@formLabelAnimation]>
          Need bank accounts?
        </nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <nz-switch [(ngModel)]="bankAccount" formControlName="needBankAccounts"></nz-switch>
        </nz-form-control>
      </div>
      <div nz-col nzSpan="6" nzOffset="1" *ngIf="bankAccount">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <textarea nz-input placeholder="Comments" formControlName="needBankAccountsComments"
                      [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row nzGutter="8">
      <div nz-col nzSpan="6">
        <nz-form-label [@formLabelAnimation] style="text-align: left;white-space: normal;">
          Will the new Entity be registered in other states/countries?
        </nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <nz-switch [(ngModel)]="entityRegistered" formControlName="entityBeRegistered"></nz-switch>
        </nz-form-control>
      </div>
      <div nz-col nzSpan="6" nzOffset="1" *ngIf="entityRegistered">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Comments</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <textarea nz-input placeholder="Comments" formControlName="entityBeRegisteredComments"
                      [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>


    }

  </div>
  <!-- </div> -->
</form>


