import { Component } from '@angular/core';
import { P30APIService } from 'src/app/core/services/api';
import { Router } from '@angular/router';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';
import { ADMIN, IFCSECRETARY, PROPOSAL_ID, PROPOSAL_TYPES_URL_PATH, REQUESTOR, REQUEST_ID, SQL_PROC_DEFAULT_PROPOSALS, SQL_PROC_INPROGRESS_PROPOSALS, SQL_PROC_PENDING_PROPOSALS, SV_USER, SV_USER_ROLE, VIEWERS, VIEW_PROPOSALS_URL_PATH } from 'src/app/constants/constants';
import { Proposal, QueryParams } from 'src/app/core/interfaces/Proposal';
import { DataServiceGeneralDetails } from 'src/app/core/services/data_service_general_details';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  currentUserProfile!: ICvxClaimsPrincipal;
  request_id: any;
  openRequestVisible: boolean = false;
  cardBodyStyle = {
    backgroundColor: '#0066b2',
    color: '#FFFFFF',
    // height: '120px'
  };
  proposalQueryParam: QueryParams = {};
  selectedValue: string = "";
  navigateURL: string = "";
  requestsCount: number = 0;
  requestData: Proposal[] = [];
  inProgressProposalCount!: number;
  pendingProposalCount!: number;
  isLoader: boolean = false;
  isPendingProposalLoading: boolean = false;
  isInProgressProposalLoading: boolean = false;
  isAdminLoggedIn: boolean = false;

  constructor(private apiService: P30APIService, private router: Router, private session: SessionStorageService,
    private authService: CalAngularService,
    private dataservice: DataService,
    private generalDetailsDataService: DataServiceGeneralDetails) {

  }

  async ngOnInit() {
    if (this.session.getItem(SV_USER_ROLE).roleTitle.toLocaleLowerCase() == ADMIN.toLocaleLowerCase()) {
      this.isAdminLoggedIn = true;
    } else {
      this.isAdminLoggedIn = false;
    }
    this.isLoader = true;
    let result = await this.dataservice.getAuthorizationToken();
    if (result) {
      this.getInProgressRequests();
      this.getPendingRequests();
      this.dataservice.getProposalTypes();
      this.dataservice.getUserRoles();
      this.dataservice.getStatuses();
      this.getLoggedUserRole();
      this.isLoader = false;
      this.removeSessionValues();
    }
  }

  removeSessionValues() {
    //this.session.removeItem(PROPOSAL_ID);
    //this.session.removeItem(REQUEST_ID);
    this.session.removeItem('duedate');
    this.session.removeItem('commentsValue');
    this.session.removeItem('ifcRequiredValue');
    this.session.removeItem('coordinatorSelected');
    this.session.removeItem('additionalCcSelected');
  }

  sendemail(): void {
    this.apiService.sendEmail().subscribe(value => {
      console.log(value);
    });
  }

  onChangeRequest(event: any) {
    this.selectedValue = event;
    this.navigateURL = "/" + event;
  }

  getPendingRequests() {
    this.isPendingProposalLoading = true;
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE).roleTitle);
    this.apiService.getUser(this.currentUserProfile.objectId).subscribe({
      next: (user) => {
        if (user.body) {
          this.apiService.getProposalsByUserRole(this.dataservice.roleTitle, user.body.userId, user.body.emailAddress, user.body.emailAddress, "2").subscribe({
            next: (proposal) => {
              let proposals = [];
              if (proposal.body) {
                proposals = [...proposal.body];
                this.pendingProposalCount = proposals.length
              }
            },
            error: (err) => {

            },
            complete: () => {

            }
          })
        }

      },
      error: (err) => {
        this.isPendingProposalLoading = false;
      },
      complete: () => {
        this.isPendingProposalLoading = false;
      }
    });
  }

  getInProgressRequests() {
    this.isInProgressProposalLoading = true;
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE).roleTitle);
    this.apiService.getUser(this.currentUserProfile.objectId).subscribe({
      next: (user) => {
        if (user.body) {
          this.apiService.getProposalsByUserRole(this.dataservice.roleTitle, user.body.userId, user.body.emailAddress, user.body.emailAddress, "3").subscribe({
            next: (proposal) => {
              let proposals = [];
              if (proposal.body) {
                proposals = [...proposal.body];
                this.inProgressProposalCount = proposals.length
              }
            },
            error: (err) => {

            },
            complete: () => {

            }
          })
        }
      },
      error: (err) => {
        this.isInProgressProposalLoading = false;
      },
      complete: () => {
        this.isInProgressProposalLoading = false;
      }
    });
  }

  stepBack() {
    this.openRequestVisible = false;

  }
  navigateToRequest(req_id: number) {
    this.router.navigate(['/request', req_id]);
  }
  navigateToPageComponent(data: string) {
    this.router.navigate([data]);
  }

  navigateToProposalTypes() {
    this.navigateToPageComponent(PROPOSAL_TYPES_URL_PATH);
    this.session.removeItem(PROPOSAL_ID);
    this.setRequestStatus();
  }
  navigateToViewProposals() {
    this.getAllProposals("1");
  }
  getAllProposals(procedureName: string) {
    console.log("Procedure Name: ", procedureName);
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE).roleTitle);
    this.isLoader = true;
    this.dataservice.listOfProposals = [];
    this.apiService.getUser(this.currentUserProfile.objectId).subscribe({
      next: (user) => {
        if (user.body) {
          this.apiService.getProposalsByUserRole(this.dataservice.roleTitle, user.body.userId, user.body.emailAddress, user.body.emailAddress, procedureName).subscribe({
            next: (proposal) => {
              if (proposal.body) {
                this.dataservice.listOfProposals = [...proposal.body];
              }
            },
            error: (err) => {
              console.log("Unable to retrieve the proposals: view requests screen", err);
              this.isLoader = false;
            },
            complete: () => {
              this.navigateToPageComponent(VIEW_PROPOSALS_URL_PATH);
              this.isLoader = false;
            }
          });
        }
      }
    })
  }

  navigateToViewPendingProposal() {
    const userRole = this.session.getItem(SV_USER_ROLE).roleTitle;
    if (userRole.toLocaleLowerCase() == ADMIN.toLocaleLowerCase() || userRole.toLocaleLowerCase() == VIEWERS.toLocaleLowerCase()
      || userRole.toLocaleLowerCase() == IFCSECRETARY.toLocaleLowerCase()) {
      this.getAllProposals("1");
    } else {
      this.getAllProposals("2");
    }
  }

  navigateToViewInProgressProposal() {
    const userRole = this.session.getItem(SV_USER_ROLE).roleTitle;
    if (userRole.toLocaleLowerCase() == ADMIN.toLocaleLowerCase()) {
      this.getAllProposals("1");
    } else {
      this.getAllProposals("3");
    }
  }

  // to set status of the Request to use in session
  setRequestStatus() {
    let state = {
      id: 1,
      name: 'Draft',
    };
    this.session.setItem('requestStatus', state);
  }


  getLoggedUserRole() {
    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    let role = this.session.getItem(SV_USER_ROLE).roleTitle.toLocaleLowerCase();
    switch (role) {
      case IFCSECRETARY.toLocaleLowerCase(): {
        this.isPendingProposalLoading = true;
        this.apiService.getUser(this.currentUserProfile.objectId).subscribe((user: any) => {
          if (user.body) {
            this.apiService.getProposalsByUserRole(this.dataservice.roleTitle, user.body.userId, user.body.emailAddress,
              user.body.emailAddress, "1").subscribe({
                next: (proposal) => {
                  if(proposal.body) {
                    this.pendingProposalCount = proposal.body.length;
                  }
                },
                error: (err) => {
                  this.isPendingProposalLoading = false;
                },
                complete: () => {
                  this.isPendingProposalLoading = false;
                }
              })
          }
        })
        break;
      }
    }
  }
}
