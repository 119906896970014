import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { P30APIService } from 'src/app/core/services/api';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { API_FAILING_ERROR_MESSAGE, MergigEntityName, PROPOSAL_ID } from 'src/app/constants/constants';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { DataService } from 'src/app/core/services/data_service';
import { LegalEntity } from 'src/app/core/interfaces/LegalEntities';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataServiceEntitySelection } from 'src/app/core/services/data_service_merger_entity_selection';
import { DataServiceEntityQuestions } from 'src/app/core/services/data_service_entity_questions';
import { filter, map } from 'rxjs';
import { DataServiceMergerSelection } from 'src/app/core/services/data_service_merger_selection';

@Component({
  selector: 'app-merger-entity-details',
  templateUrl: './merger-entity-details.component.html',
  styleUrl: './merger-entity-details.component.less',
  animations: [formControlElement, formLabelElement],
})
export class MergerEntityDetailsComponent {
  @Output() isLoader = new EventEmitter<boolean>;
  componentIsVisible: boolean = false;
  legalEntityForm!: UntypedFormGroup;
  nzLoading: boolean = false;
  nzSurvivingLoading: boolean = false;
  nzSearchValue!: string;
  @Input() mergerEntityDetailsForm!: UntypedFormGroup;
  @Input() mergingSurviveEntitySelectionForm!: UntypedFormGroup;
  legalEntities: LegalEntity[] = [{
    companyName: "NA"
  }];
  survivingEntities: LegalEntity[] = [{
    companyName: "NA"
  }];
  current = 0;
  scurrent = 0;
  selectedLegalEntities: LegalEntity[] = [];
  selectedSurvingLegalEntities: LegalEntity[] = [];
  selectedSurvivingEntities: LegalEntity[] = [];
  entityQuestions: any = [];
  entityQuestionsSet: any = [];
  entitySurvivingQuestions: any = [];
  entitySurvivingQuestionsSet: any = [];
  showComments: boolean = false;
  showSurvivingComments: boolean = false;
  selectedEntity: string[] = [];
  selectedSurvivingEntity: string[] = [];
  apiErrorMSG: string = API_FAILING_ERROR_MESSAGE;

  @ViewChild('carouselLegalEntity')
  carouselLegalEntity!: NzCarouselComponent;
  @ViewChild('carouselSurviveLegalEntity')
  carouselSurviveLegalEntity!: NzCarouselComponent;
  constructor(private fb: UntypedFormBuilder, private apiService: P30APIService, private session: SessionStorageService, private message: NzMessageService,
    private dataservice: DataService,
    private entitySelectionDataService: DataServiceEntitySelection,
    private entityQuestionsDataService: DataServiceEntityQuestions,
    private dataServiceMergerSelection: DataServiceMergerSelection) {
  }

  async ngOnInit() {
    if (this.mergerEntityDetailsForm.controls["legalEntity"].value == "NA") {
      this.showComments = true;
    } else {
      for (let i = 0; i < this.legalEntitiesFormArray.length; i++) {
        this.selectedEntity.push(this.legalEntitiesFormArray.at(i).value.companyName);
        console.log("loop"+this.legalEntitiesFormArray.at(i).value.companyName)
      }
      await this.getLegalEntities("Merging Entity");
      this.legalEntities = [...this.selectedLegalEntities];
      this.legalEntities.unshift({
        companyName: "NA"
      });
    }
  


    if (this.mergingSurviveEntitySelectionForm.controls["legalEntity"].value == "NA") {
      this.showSurvivingComments = true;
    } else {
      for (let i = 0; i < this.legalEntitiesFormArray1.length; i++) {
        this.selectedSurvivingEntity.push(this.legalEntitiesFormArray1.at(i).value.companyName);
      }
      await this.getLegalEntities("Surviving Entity");
      this.survivingEntities = [...this.selectedSurvivingEntities];
      this.survivingEntities.unshift({
        companyName: "NA"
      });
    }
    if (this.dataservice.legalEntities.length > 0) {
      this.survivingEntities = [...this.dataservice.legalEntities];
    }
    if (this.dataservice.selectedSurvingLegalEntities.length > 0) {
      this.selectedSurvivingEntities = [...this.dataservice.selectedSurvingLegalEntities];
    }




  }


  ngOnDestroy() {
    this.dataservice.legalEntities = [...this.legalEntities];
    this.dataservice.selectedLegalEntities = [...this.selectedLegalEntities];
  }

  get legalEntitiesFormArray() {
    return this.mergerEntityDetailsForm.controls["legalEntitiesFormArray"] as FormArray;
  }

  get legalEntitiesQuestionsFormArray() {
    return this.mergerEntityDetailsForm.controls["legalEntitiesQuestionsFormArray"] as FormArray;
  }

  get legalEntitiesFormArray1() {
    return this.mergingSurviveEntitySelectionForm.controls["legalEntitiesFormArray1"] as FormArray;
  }

  get legalEntitiesQuestionsFormArray1() {
    return this.mergingSurviveEntitySelectionForm.controls["legalEntitiesQuestionsFormArray1"] as FormArray;
  }


  onSearch() {
    this.nzLoading = true;
    this.legalEntities = [{
      companyName: "NA"
    }];
    if (this.nzSearchValue) {
      this.apiService.getCompanies(this.nzSearchValue)
        .subscribe({
          next: (event) => {
            let arrResult = [];
            arrResult = [...event.body.companies];
            arrResult.forEach(element => {
              let legalEntity: LegalEntity = {
                ccn: element.companyCodeNumber,
                companyName: element.companyName,
                jurisdiction: element.countryName
              }
              this.legalEntities.push(legalEntity);
            });
          },
          error: (err) => {
            this.message.error(this.apiErrorMSG);
            this.nzLoading = false;
          },
          complete: () => {
            this.nzLoading = false;
          }
        });
    }
  }

  onSurvivingSearch() {
    this.nzSurvivingLoading = true;
    this.survivingEntities = [{
      companyName: "NA"
    }];
    if (this.nzSearchValue) {
      this.apiService.getCompanies(this.nzSearchValue)
        .subscribe({
          next: (event) => {
            let arrResult = [];
            arrResult = [...event.body.companies];
            arrResult.forEach(element => {
              let legalEntity: LegalEntity = {
                ccn: element.companyCodeNumber,
                companyName: element.companyName,
                jurisdiction: element.countryName
              }
              this.survivingEntities.push(legalEntity);
            });
          },
          error: (err) => {
            this.message.error(this.apiErrorMSG);
            this.nzSurvivingLoading = false;
          },
          complete: () => {
            this.nzSurvivingLoading = false;
          }
        });
    }
  }

  onSearchEvent(event: string) {
    this.nzSearchValue = event;
  }
  onSurvivingSearchEvent(event: string) {
    this.nzSearchValue = event;
  }
  async onChangeActiveReg(form: any) {
    console.log(this.mergerEntityDetailsForm);
    let formArray = this.mergerEntityDetailsForm.controls["legalEntitiesFormArray"] as FormArray;
    let formQuestionsArray = this.mergerEntityDetailsForm.controls["legalEntitiesQuestionsFormArray"] as FormArray;
    let flagES = await this.dataServiceMergerSelection.reviewAndValidateMergerLEntitySelection(this.mergerEntityDetailsForm,"NX");
    console.log("formArray", formArray);
    console.log("formQuestionsArray", formQuestionsArray);
  }
  async onSurvivingChangeActiveReg(form: any) {
    console.log(this.mergingSurviveEntitySelectionForm);
    let formArray = this.mergingSurviveEntitySelectionForm.controls["legalEntitiesFormArray1"] as FormArray;
    let formQuestionsArray = this.mergingSurviveEntitySelectionForm.controls["legalEntitiesQuestionsFormArray1"] as FormArray;
    let flagESS = await this.dataServiceMergerSelection.reviewAndValidateMergerSEntitySelection(this.mergingSurviveEntitySelectionForm,"NX");
    console.log("formArray", formArray);
    console.log("formQuestionsArray", formQuestionsArray);
  }

  async onChange(event: string) {
    if (event === 'NA') {
      this.removeFormArray();
      this.removeFormArray1();
      this.selectedEntity = [];
      await this.onChangeGetLegalEntities(false);
      await this.getLegalEntities("Merging Entity");
      this.showComments = true;
      const control = this.mergerEntityDetailsForm.controls["comments"]
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    } else {
      await this.onChangeGetLegalEntities(true);
      await this.getLegalEntities("Merging Entity");
      if (!this.selectedEntity.includes(event)) {
        this.isLoader.emit(true);
        this.apiService.getCompanyDetails(this.legalEntities.find(data => data.companyName === event)?.ccn).subscribe({
          next: (output) => {
            if (output.body) {
              const modifiedLegalEntities = this.legalEntities.map(obj => {
                if (obj.ccn === output.body.companyCodeNumber) {
                  return { ...obj, ownershipDetail: output.body.ownership };
                }
                return obj;
              });
              this.legalEntities = [...modifiedLegalEntities];
            }
            this.addField(event);
          },
          error: (err) => {
            this.message.error(this.apiErrorMSG);
            this.isLoader.emit(false);
          },
          complete: () => {

            this.selectedEntity.push(event);
            this.isLoader.emit(false);
          }
        });
      }
      this.showComments = false;
      const control = this.mergerEntityDetailsForm.controls["comments"]
      control.removeValidators([Validators.required]);
      control.setValue(null);
      control.updateValueAndValidity();
    }
  }

  async onSurvivingChange(event: string) {
    if (event === 'NA') {
      this.removeSurviveFormArray();
      this.removeSurviveFormArray1();
      this.selectedSurvivingEntity = [];
      await this.onChangeGetSurvivingLegalEntities(false);
      await this.getSurvivingLegalEntities("Surviving Entity");
      this.showSurvivingComments = true;
      const control = this.mergingSurviveEntitySelectionForm.controls["comments"]
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    } else {
      await this.onChangeGetSurvivingLegalEntities(true);
      await this.getSurvivingLegalEntities("Surviving Entity");
      if (!this.selectedSurvivingEntity.includes(event)) {
        this.isLoader.emit(true);
        this.apiService.getCompanyDetails(this.survivingEntities.find(data => data.companyName === event)?.ccn).subscribe({
          next: (output) => {
            if (output.body) {
              const modifiedLegalEntities = this.survivingEntities.map(obj => {
                if (obj.ccn === output.body.companyCodeNumber) {
                  return { ...obj, ownershipDetail: output.body.ownership };
                }
                return obj;
              });
              this.survivingEntities = [...modifiedLegalEntities];
            }
            this.addSurvivingField(event);
          },
          error: (err) => {
            this.message.error(this.apiErrorMSG);
            this.isLoader.emit(false);
          },
          complete: () => {

            this.selectedSurvivingEntity.push(event);
            this.isLoader.emit(false);
          }
        });
      }
      this.showSurvivingComments = false;
      const control = this.mergingSurviveEntitySelectionForm.controls["comments"]
      control.removeValidators([Validators.required]);
      control.setValue(null);
      control.updateValueAndValidity();
    }
  }


  async onChangeGetLegalEntities(isNa: boolean) {
    this.entitySelectionDataService.getLegalEntities().subscribe({
      next: (event) => {
        let legalEntities = [];
        if (event.body) {
          legalEntities = [...event.body];
          legalEntities.forEach(findItem => {
            switch (isNa) {
              case (true): {
                if (findItem.legalEntityComments || findItem.companyName == "NA") {
                  this.deleteLegalEntity(findItem.legalEntityId);
                }
                return;
              }
              case (false): {
                if (findItem.legalEntityId && findItem.companyName !== "NA") {
                  this.deleteLegalEntity(findItem.legalEntityId);
                }
                return;
              } default: {
                return
              }
            }



          });
        }
      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
      }
    });
  }

  async onChangeGetSurvivingLegalEntities(isNa: boolean) {
    this.entitySelectionDataService.getLegalEntities().subscribe({
      next: (event) => {
        let survivingEntities = [];
        if (event.body) {
          survivingEntities = [...event.body];
          survivingEntities.forEach(findItem => {
            switch (isNa) {
              case (true): {
                if (findItem.legalEntityComments || findItem.companyName == "NA") {
                  this.deleteSurvivingLegalEntity(findItem.legalEntityId);
                }
                return;
              }
              case (false): {
                if (findItem.legalEntityId && findItem.companyName !== "NA") {
                  this.deleteSurvivingLegalEntity(findItem.legalEntityId);
                }
                return;
              } default: {
                return
              }
            }



          });
        }
      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
      }
    });
  }

  addField(value: string) {
    const legalEntityForm = this.fb.group({
      legalEntityId: [this.legalEntities.filter(data => data.companyName === value)[0].legalEntityId],
      legalEntityName: [value],
      ccn: [this.legalEntities.filter(data => data.companyName === value)[0].ccn],
      companyName: [value],
      jurisdiction: [this.legalEntities.filter(data => data.companyName === value)[0].jurisdiction],
      ownershipDetail: [this.legalEntities.filter(data => data.companyName === value)[0].ownershipDetail],
      entityQuestionsId: 0,
      activeRegistration: false,
      activeRegistrationComments: "",
      subsidiaries: false,
      subsidiariesComments: ""
    })
    this.legalEntitiesFormArray.push(legalEntityForm);
    this.legalEntitiesQuestionsFormArray.push(legalEntityForm);
  }

  addSurvivingField(value: string) {
    const legalEntityForm = this.fb.group({
      legalEntityId: [this.survivingEntities.filter(data => data.companyName === value)[0].legalEntityId],
      legalEntityName: [value],
      ccn: [this.survivingEntities.filter(data => data.companyName === value)[0].ccn],
      companyName: [value],
      jurisdiction: [this.survivingEntities.filter(data => data.companyName === value)[0].jurisdiction],
      ownershipDetail: [this.survivingEntities.filter(data => data.companyName === value)[0].ownershipDetail],
      entityQuestionsId: 0,
      activeRegistration: false,
      activeRegistrationComments: "",
      entityChangeName: false,
      entityChangeNameComments: ""
    })
    this.legalEntitiesFormArray1.push(legalEntityForm);
    this.legalEntitiesQuestionsFormArray1.push(legalEntityForm);
  }

  removeFormArray() {
    this.legalEntitiesFormArray.clear();
  }

  removeFormArray1() {
    this.legalEntitiesQuestionsFormArray.clear();
  }
  removeSurviveFormArray() {
    this.legalEntitiesFormArray1.clear();
  }

  removeSurviveFormArray1() {
    this.legalEntitiesQuestionsFormArray1.clear();
  }

  deleteLegalEntity(legalEntityId: any) {
    this.isLoader.emit(true);
    this.apiService.deleteLegalEntity(this.session.getItem(PROPOSAL_ID), legalEntityId).subscribe({
      next: (out) => {

      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
        console.log("Unable to create legal entity record in the database table ", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        console.log("this.entityQuestions", this.entityQuestions);
        this.entityQuestionsSet = this.entityQuestions.filter((x: { legalEntityId: any; }) => x.legalEntityId === legalEntityId)
        console.log("this.entityQuestionsSet", this.entityQuestionsSet);
        if (this.entityQuestionsSet.body) {
          this.apiService.deleteEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), this.entityQuestionsSet[0].entityQuestionsId).subscribe
            ({
              next: (out) => {

              },
              error: (err) => {
                this.message.error(this.apiErrorMSG);
                console.log("Unable to create legal entity questions record in the database table ", err);
                this.isLoader.emit(false);
              },
              complete: () => {

                this.getLegalEntities("Merging Entity");
                this.isLoader.emit(false);
              }
            });
        }
        // this.getLegalEntities();
         this.isLoader.emit(false);
      }
    })
  }

  deleteSurvivingLegalEntity(legalEntityId: any) {
    this.isLoader.emit(true);
    this.apiService.deleteLegalEntity(this.session.getItem(PROPOSAL_ID), legalEntityId).subscribe({
      next: (out) => {

      },
      error: (err) => {
        this.message.error(this.apiErrorMSG);
        console.log("Unable to create legal entity record in the database table ", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.entitySurvivingQuestionsSet = this.entitySurvivingQuestions.filter((x: { legalEntityId: any; }) => x.legalEntityId === legalEntityId)
        if (this.entitySurvivingQuestionsSet.body) {
          this.apiService.deleteEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID), this.entitySurvivingQuestionsSet[0].entityQuestionsId).subscribe
            ({
              next: (out) => {

              },
              error: (err) => {
                this.message.error(this.apiErrorMSG);
                console.log("Unable to create legal entity questions record in the database table ", err);
                this.isLoader.emit(false);
              },
              complete: () => {

                this.getLegalEntities("Merging Entity");
                this.isLoader.emit(false);
              }
            });
        }
        // this.getLegalEntities();
         this.isLoader.emit(false);
      }
    })
  }

  onCloseTag(event: string) {
    let index = this.selectedEntity.indexOf(event);
    this.selectedEntity.splice(index, 1);
    let findItem = this.selectedLegalEntities.find(e => e.companyName === event);
    const index1 = this.legalEntitiesQuestionsFormArray.controls.findIndex(i=>i.value.legalEntityName === event)
    if(index1 !== -1){ 
    this.legalEntitiesQuestionsFormArray.removeAt(index1);
    this.legalEntitiesFormArray.removeAt(index1);
    }
    if (findItem) {
      if (findItem.legalEntityId) {
        this.deleteLegalEntity(findItem.legalEntityId);
      }
      console.log('this.legalEntitiesQuestionsFormArray',this.legalEntitiesQuestionsFormArray.controls);
      console.log('this.legalEntitiesFormArray',this.legalEntitiesFormArray);
    }
  }

  onCloseTag1(event: string) {
    let index = this.selectedSurvivingEntity.indexOf(event);
    this.selectedSurvivingEntity.splice(index, 1);
    let findItem = this.selectedSurvivingEntities.find(e => e.companyName === event);
    const index1 = this.legalEntitiesQuestionsFormArray1.controls.findIndex(i=>i.value.legalEntityName === event)
    if(index1 !== -1){ 
      this.legalEntitiesQuestionsFormArray1.removeAt(index1);
      this.legalEntitiesFormArray1.removeAt(index1);
      }
    if (findItem) {
      if (findItem.legalEntityId) {
        this.deleteSurvivingLegalEntity(findItem.legalEntityId);
      }
    }
    // this.removeSurviveFormArray();
    // this.removeSurviveFormArray1();
    // for (let entityName of this.selectedSurvivingEntity) {
    //   this.addSurvivingField(entityName);
    // }
  }


  getLegalEntities(eType: string): Promise<boolean> {
    return new Promise<boolean>((resolved) => {
      if (this.session.getItem(PROPOSAL_ID)) {
        this.isLoader.emit(true);
        this.entityQuestionsDataService.getEntityIndicatorQuestions()
          .subscribe({
            next: (event) => {
              if (event.body) {
                this.entityQuestions = [...event.body];
              }
            },
            complete: () => {
              this.entitySelectionDataService.getLegalEntities().subscribe({
                next: (event) => {
                  if(eType== MergigEntityName){
                  this.selectedLegalEntities = [];
                  }
                  else{
                    this.selectedSurvivingEntities =[];
                  }
                  if (event.body) {
                    for (const element of event.body) {
                      if (element.typeofLegalEntity == eType) {
                        let leglaEntity: LegalEntity = {
                          legalEntityId: element.legalEntityId,
                          ccn: element.ccn,
                          companyName: element.companyName,
                          jurisdiction: element.jurisdiction,
                          ownershipDetail: element.ownershipDetail,
                          legalEntityComments: element.legalEntityComments,
                          typeofLegalEntity: element.typeofLegalEntity
                        }
                        if(eType== MergigEntityName){
                          this.selectedLegalEntities.push(leglaEntity);
                        }
                        else{
                          this.selectedSurvivingEntities.push(leglaEntity);
                        }
                        //this.selectedLegalEntities.push(leglaEntity);
                        this.entityQuestionsSet = this.entityQuestions.filter((x: { legalEntityId: any; }) => x.legalEntityId === element.legalEntityId)
                        const legalEntityForm = this.fb.group({
                          legalEntityId: element.legalEntityId,
                          legalEntityName: leglaEntity.companyName,
                          ccn: leglaEntity.ccn,//[this.legalEntities.filter(data =>data.companyName === leglaEntity.companyName)[0].ccn],
                          companyName: leglaEntity.companyName,
                          jurisdiction: leglaEntity.jurisdiction,
                          ownershipDetail: leglaEntity.ownershipDetail,
                          entityQuestionsId: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].entityQuestionsId : '',
                          activeRegistration: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].haveActiveRegistrationsInOtherStatesCountries : '',
                          activeRegistrationComments: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].commentsForHavingActiveRegistrations : '',
                          subsidiaries: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].haveSubsidiaries : '',
                          subsidiariesComments: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].commentsForHavingSubsidiaries : ''

                        })
                      }
                    }
                  }
                },
                error: (err) => {
                  this.message.error(this.apiErrorMSG);
                  console.log("Unable to get legal entity record in the database table ", err);
                  this.isLoader.emit(false);
                  resolved(false);
                },
                complete: () => {
                  this.isLoader.emit(false);
                  resolved(true);
                }
              });
              this.isLoader.emit(false);
            }
          });

      }
    });
  }

  getSurvivingLegalEntities(eType: string): Promise<boolean> {
    return new Promise<boolean>((resolved) => {
      if (this.session.getItem(PROPOSAL_ID)) {
        this.isLoader.emit(true);
        this.entityQuestionsDataService.getEntityIndicatorQuestions()
          .subscribe({
            next: (event) => {
              if (event.body) {
                this.entitySurvivingQuestions = [...event.body];
              }
            },
            complete: () => {
              this.entitySelectionDataService.getLegalEntities().subscribe({
                next: (event) => {
                  this.selectedSurvivingEntities = [];
                  if (event.body) {
                    for (const element of event.body) {
                      if (element.typeofLegalEntity == eType) {
                        let leglaEntity: LegalEntity = {
                          legalEntityId: element.legalEntityId,
                          ccn: element.ccn,
                          companyName: element.companyName,
                          jurisdiction: element.jurisdiction,
                          ownershipDetail: element.ownershipDetail,
                          legalEntityComments: element.legalEntityComments,
                          typeofLegalEntity: element.typeofLegalEntity
                        }
                        this.selectedSurvivingEntities.push(leglaEntity);
                        this.entitySurvivingQuestionsSet = this.entityQuestions.filter((x: { legalEntityId: any; }) => x.legalEntityId === element.legalEntityId)
                        const legalEntityForm = this.fb.group({
                          legalEntityId: element.legalEntityId,
                          legalEntityName: leglaEntity.companyName,
                          ccn: leglaEntity.ccn,//[this.legalEntities.filter(data =>data.companyName === leglaEntity.companyName)[0].ccn],
                          companyName: leglaEntity.companyName,
                          jurisdiction: leglaEntity.jurisdiction,
                          ownershipDetail: leglaEntity.ownershipDetail,
                          entityQuestionsId: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].entityQuestionsId : '',
                          activeRegistration: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].haveActiveRegistrationsInOtherStatesCountries : '',
                          activeRegistrationComments: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].commentsForHavingActiveRegistrations : '',
                          entityChangeName: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].willTheSurvivingEntityChangeItsNameAfterTheMerger : '',
                          entityChangeNameComments: this.entityQuestionsSet.length > 0 ? this.entityQuestionsSet[0].commentsForSurvivingEntityChangingName : ''

                        })
                      }
                    }
                  }
                },
                error: (err) => {
                  this.message.error(this.apiErrorMSG);
                  console.log("Unable to get legal entity record in the database table ", err);
                  this.isLoader.emit(false);
                  resolved(false);
                },
                complete: () => {
                  this.isLoader.emit(false);
                  resolved(true);
                }
              });
              this.isLoader.emit(false);
            }
          });

      }
    });
  }

  nextSlide() {
    this.carouselLegalEntity.next();
    this.current++;
  }

  prevSlide() {
    this.carouselLegalEntity.pre();
    this.current--;
  }

  nextSurvivingSlide() {
    this.carouselSurviveLegalEntity.next();
    this.scurrent++;
  }

  prevSurvivingSlide() {
    this.carouselSurviveLegalEntity.pre();
    this.scurrent--;
  }
}

