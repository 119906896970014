import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { PROPOSAL_ID } from 'src/app/constants/constants';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataServiceProposalOverview } from 'src/app/core/services/data_service_proposal_overview';
import { UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';

@Component({
  selector: 'app-qualification-withdrawal-proposal-details',
  templateUrl: './qualification-withdrawal-proposal-details.component.html',
  styleUrls: ['./qualification-withdrawal-proposal-details.component.less'],
  animations: [formControlElement, formLabelElement],
})

export class QualificationWithdrawalProposalDetailsComponent {
  @Output() isLoader = new EventEmitter<boolean>();
  @Output() selectedTabIndexEmitValue=new EventEmitter<number>();
  @Input() proposalOverviewForm!: UntypedFormGroup;
  @Input() ownershipRestructuringEntitySelectionForm!: UntypedFormGroup;
  @Input() legalEntityQualificationWithdrawalForm!: UntypedFormGroup;
  @Input() entitySelectionTabClass = 'tab-default';
  @Input() entityQuestionTabClass = 'tab-default';
  @Input() summaryTabClass = 'tab-default';
  @Input() attachmentTabClass = 'tab-default';
  @Input() selectedTabIndexValue!:number;
  entitySelection: string = "entity selection";
  entityQuestions: string = "entity questions";
  overview: string = "overview";
  attachments: string = "attachments";
  selectedTabTitle: string = this.entitySelection;
  proposalId!: string;
  constructor(private session: SessionStorageService, private message: NzMessageService,
    private proposalOverviewDataService: DataServiceProposalOverview) { }

  ngOnInit() {
    this.isLoader.emit(false);
    //being used by html file for attachments?
    if (this.session.getItem(PROPOSAL_ID)) {
      this.proposalId = this.session.getItem(PROPOSAL_ID);
    }
  }

  emitIsLoader(event: boolean) {
    this.isLoader.emit(event);
  }

  async getCurrentTab(event: any) {
    this.selectedTabIndexEmitValue.emit(event);
  }
}
