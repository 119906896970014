<main class="container">
  <section id="column2" npSetLayout [pageLayout]="PageLayout.Layout12">
    <np-menu [location]="NavigationLocation.Side" [navStartUrl]="'/3.html'"></np-menu>
  </section>
  <section id="column1">
    <app-page-header></app-page-header>
    <div class="loader-container" *ngIf="isLoader">
      <nz-spin nzTip="Loading..." style="position: fixed;left: 50%;top:50%;" [nzSize]="'large'"></nz-spin>
    </div>
    <div class="home-content">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }"  class="grid-row" nzJustify="start">
        <div nz-col [nzSpan]="12" nzOffset="6">
          <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzJustify="start">
            <div nz-col nzOffset="1">
              <button nz-button nzType="primary" nzShape="circle" nzSize="large" (click)="navigateToProposalTypes()">new <br>request</button>
            </div>
            <div nz-col >
              <button nz-button nzType="primary" nzShape="circle" nzSize="large" (click)="navigateToViewProposals()">search</button>
            </div>
          </div>
        </div>
      </div>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="bottom" class="grid-row">
        <div nz-col  [nzSpan]="12" nzOffset="6">
          <nz-card nzTitle="my dashboard" style="text-align: center;">
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="middle" *ngIf="!isAdminLoggedIn">
              <div nz-col  [nzSpan]="11" nzOffset="1">
                <nz-card nzTitle="pending my action" [nzExtra]="pendingReqTemplate" nzSize="small" [nzLoading]="isPendingProposalLoading">
                  @if(pendingProposalCount){
                    <span class="span-class" style="font-size: 20px;"><strong>{{pendingProposalCount}}</strong></span>
                  }@else {
                    <ng-container>
                      No data found
                    </ng-container>
                  }
                </nz-card>
                <ng-template #pendingReqTemplate>
                  <a (click)="navigateToViewPendingProposal()"><span nz-icon nzType="double-right" nzTheme="outline" style="color: white;"></span></a>
                </ng-template>
              </div>
              <div nz-col [nzSpan]="11">
                <nz-card nzTitle="in-progress" [nzExtra]="progressReqTemplate" nzSize="small" [nzLoading]="isInProgressProposalLoading">
                  @if(inProgressProposalCount){
                    <span class="span-class" style="font-size: 20px;"><strong>{{inProgressProposalCount}}</strong></span>
                  }@else {
                    <ng-container>
                      No data found
                    </ng-container>
                  }

                </nz-card>
                <ng-template #progressReqTemplate>
                  <a (click)="navigateToViewInProgressProposal()"><span nz-icon nzType="double-right" nzTheme="outline" style="color: white;"></span></a>
                </ng-template>
              </div>
            </div>
            <span *ngIf="isAdminLoggedIn">No data found</span>
          </nz-card>
        </div>
      </div>
    </div>
  </section>
</main>
