import { trigger, animate, transition, style, query, stagger, keyframes } from '@angular/animations';

export const divBtnAnimation = 
  trigger('divBtnAnimation', [
      transition("* => *", [
          // each time the binding value changes
          query(".col", style({ opacity: 0, transform: 'translateY(30px)' }), { optional: true} ),
          query(
            ".col",
            stagger(10, [animate("400ms", 
              style({ opacity: 1,  transform: 'translateY(0)'})
            )
          ]), { optional: true}
        )
      ])
    ]);
export const bounceBtnAnimation = 
    trigger('bounceBtnAnimation', [
      transition('true <=> false', [
        animate('1s', keyframes( [
          style({ background: '#0066b2', color:'#FFF', transform: 'scale(1)' }),
          style({ background: '#0066b2', color:'#FFF', transform: 'scale(1.2)' }),
          style({ background: '#0066b2', color:'#FFF',transform: 'scale(1)' })
        ]))
      ])
    ]);

export const formControlElement = 
    trigger('formControlAnimation', [
      transition(':enter', [
        animate('1s', keyframes( [
          style({ opacity: 0, transform: 'translateX(-10px)' }),
          style({ opacity: 0.5, transform: 'translateX(10px)' }),
          style({ opacity: 1, transform: 'translateX(0)' })
        ]))
      ]),
      transition(':leave', [
        animate(
          "1s",
          style({ transform: "translateX(100%)" })
      )
      ])
    ]);
   
export const formLabelElement = 
    trigger('formLabelAnimation', [
      transition(':enter', [
        animate('1s', keyframes( [
          style({ opacity: 0, transform: 'translateX(10px)' }),
          style({ opacity: 0.5, transform: 'translateX(-10px)' }),
          style({ opacity: 1, transform: 'translateX(0)' })
        ]))
      ]),
      transition(':leave', [
        animate(
            "1s",
            style({ transform: "translateX(100%)" })
        )
      ])
    ]);
export const listAnimation = 
    trigger('listAnimation', [
        transition("* => *", [
            query(":enter", style({ opacity: 0 }), { optional: true} ),
            query(
              ":enter",
              stagger('300ms', [animate("1s ease-in",
              keyframes([
                style({ opacity: 1,  transform: 'translateY(-75px)',offset:0}),
                style({ opacity: 1,  transform: 'translateY(35px)',offset:0.3}),
                style({ opacity: 1,  transform: 'translateY(0)',offset:1})
              ]))
            ]), { optional: true}
          ),
          query(
            ":leave",
            stagger('300ms', [animate("1s ease-in",
            keyframes([
              style({ opacity: 1,  transform: 'translateY(o)',offset:0}),
              style({ opacity: 1,  transform: 'translateY(35px)',offset:0.3}),
              style({ opacity: 1,  transform: 'translateY(-75px)',offset:1})
            ]))
          ]), { optional: true}
        )
        ])
]);